import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    content: {
        position: 'absolute',
        // padding: '1rem',
        top: '40%',
        left: '45%',
        '&:focus': {
            outline: 'none',
        },
    },
    message: {
        margin: theme.spacing(2),
    },
}));
