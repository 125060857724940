/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { Fragment } from 'react';
import { MapMarker, EnforcementDataType, MapSourceType, MapDashboardStateModel } from '../../../state/mapDashboardStateV2';
import { Modal, Typography, Paper, Button, Grid } from '@material-ui/core';
import { DriveEta as CarIcon, CameraAlt as ImageIcon } from '@material-ui/icons';
import { useStyles } from './helpListPopupStyles';
import moment from 'moment';
import OSM from 'ol/source/OSM';
import OLMap from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import readsLayerStyle  from '../../Map/readsLayerStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationState } from '../../../../../../app/store';
import { DashboardFilterState } from '../../../../state/DashboardFilterState';
import { useSelector } from 'react-redux';
import { SessionState } from '../../../../../../app/session/sessionTypes';

type HelpPopupProps = {
    openModal: boolean;
    onClose: () => void;
}


export const HelpPopup = (props: HelpPopupProps): JSX.Element => {
    const { openModal, onClose } = props;
    const classes = useStyles();

    const selector = (state: ApplicationState) => {
        return {
            session: state.session as SessionState,
            dashboardFilter: state.dashboardFilter as DashboardFilterState,
        };
    };
    const globalState = useSelector(selector);

    const currentCustomer = globalState.session.currentUser?.customers.find(x => x.id == globalState.dashboardFilter.customerId);

    let showDebtInformationDetail = false;
    let showDebtInformationSummary = false;
    if(currentCustomer!= null)
    {
        showDebtInformationDetail = currentCustomer.hotlistVehicleDebtDetail;
        showDebtInformationSummary = currentCustomer.hotlistVehicleDebtSummary;
    }

    
   


    return (
        <Modal
            open={openModal}
            onClose={onClose}
            //onRendered={onRendered}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
            <Paper elevation={2} className={classes.modalContent}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="h4">
                   How to
                </Typography>
                <IconButton onClick={onClose}>
                        <CloseIcon />
                </IconButton>
            </Grid>
                <Typography variant="body2">
                  <strong>Sort:</strong> You can sort by pressing the column title, multiple sort is available by pressing &apos;Ctrl&apos; key and that columns that you want to sort in order.
                  <img src='/img/optiroute-grid-sort.JPG' />
                </Typography>
                <Typography variant="body2">
                  <strong>Filter by plate:</strong> You can filter by a specific plate by selecting the plate on the grid.
                  <img src='/img/optiroute-grid-plate-filter.JPG' />
                </Typography>
                <Typography variant="body2">
                  <strong>Detail Popup:</strong> You can access the detail popup by double clicking over the highlighted section.
                  <img src='/img/optiroute-grid-detail-popup.JPG' />
                </Typography>
            </Paper>
        </Modal>
    );
};
