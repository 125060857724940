import React, { useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { Box, Toolbar, Typography, Grid, Checkbox, FormControlLabel, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, FormControl, InputLabel, Select, MenuItem, ListItemText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, ButtonGroup, Button, Popper, Grow, ClickAwayListener, MenuList, TextField, Modal, IconButton, Tooltip    } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MapFilterBar from './FilterBar/MapFilterBar';
import Map from './Map/Map';
import { ApplicationState } from '../../../../app/store';
import * as DashboardFilterState from '../../state/DashboardFilterState';
import { all, AssignEnforcementZoneModificationType, EnforcementZoneAssignation, MapDashboardStateModel, MapLayerType, monday, tuesday, wednesday, thursday, friday, saturday, sunday } from '../state/mapDashboardStateV2';
import { useSelector, useDispatch } from 'react-redux';
import { applyFilterAndFetchMarkers, changeEnforcementZoneAssignation, fetchEnforcementZone, fetchEnforcementZoneAssignation, initializeMapFilterSource, fetchUsersByCustomer } from '../state/mapDashboardThunks';
import { useStyles } from './mapSearchStyles';
import { MarkersList } from './MarkerList/MarkersList';
import { MapFilterState } from './FilterBar/mapFilterState';
import LoadingModal from '../../Commons/LoadingModal/LoadingModal';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { selectMarker, unselectMarker } from '../state/mapOptiRouteDashboardSlice';
import { SessionState } from '../../../../app/session/sessionTypes';
import CloseIcon from '@material-ui/icons/Close';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MultipleSelect } from '../../Commons/MultipleSelect/MultipleSelect';
import { ACTION_TYPES, mapZoneAssignReducer, mapZoneAssignStateInit } from '../state/mapZoneAssignState';
import { ColDef, GridApi, GridReadyEvent, ITooltipParams, SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact, ChangeDetectionStrategyType } from 'ag-grid-react';
//import { CustomTooltip } from './customTooltip';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const MapSearch = (): JSX.Element => {
    const classes = useStyles();
    const queryParameters = useQuery();
    const [selectedCustomerSubDomain, setSelectedCustomerSubDomain] = useState<undefined | string>(undefined);

    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mapOptiRouteDashboard as MapDashboardStateModel,
            session: state.session as SessionState,
        };
    };

    
    const state = useSelector(selector);
    const dispatch = useDispatch();
    

    // TODO: Validate dashboard filter state in a better way
    useEffect(() => {
        const mapFilterCookie: MapFilterState = (Cookies.getJSON('mapOptiRouteFilter') as unknown as MapFilterState);
        
        if(mapFilterCookie) { 
            mapFilterCookie.status = 1;//default status to active
            searchMapMarkers(mapFilterCookie);
        } else {
            dispatch(initializeMapFilterSource());
        }
    }, []);

    useEffect(() => {
        dispatch(initializeMapFilterSource());
        let selectedCustomerSubDomain = undefined;
        if (state.session.currentUser !== null) {
            const selectedCustomer = state.session.currentUser.customers.find(customer => customer.id === state.dashboardFilter.customerId);
            if (selectedCustomer) {
                selectedCustomerSubDomain = selectedCustomer.parkingviewClientSubDomain;
            }
        }

        setSelectedCustomerSubDomain(selectedCustomerSubDomain);
        dispatch(fetchEnforcementZone());//load enforcement zones
        dispatch(fetchEnforcementZoneAssignation());//load enforcement zones assignations
        dispatch(fetchUsersByCustomer());//load bootviewUsers
    }, [state.dashboardFilter.customerId]);

    const searchMapMarkers = (filter: MapFilterState): void => {
        dispatch(applyFilterAndFetchMarkers(filter));
        Cookies.set('mapOptiRouteFilter', filter);
    };

    useEffect(() => {
        // fetchEnforcementData();
    }, [state.dashboardFilter]);

    
    //filter by plate 
	const [licensePlate, setLicensePlate] = useState('');
    const filterByPlate = (licensePLateParam: string): void => {
        setLicensePlate(licensePLateParam);
    };

    //set plate not filter automatically
    const [licensePlateNoSearch, setLicensePlateNoSearch] = useState('');
    const setPlate = (licensePLateList: string): void => {
        setLicensePlateNoSearch(licensePLateList);
    };

    //Zone Assingation grid 
    ////////////////////////////////////
    const [routeName, setRouteName] = React.useState<string[]>([]);


    function createData(key: number, name: string, officer: string, dayWeek: string) {
        return {key, name, officer, dayWeek };
    }

    const onGridReady = function (event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
        setGridApi(event.api);
        /*const defaultSortModel = [
            {
              colId: 'occurrences',
              sort: 'desc',
              sortIndex: 0,
            },
            {
              colId: 'licPlate',
              sort: 'desc',
              sortIndex: 1,
            },
            {
                colId: 'typeDate',
                sort: 'desc',
                sortIndex: 2,
              },
          ];
          event.columnApi.applyColumnState({ state: defaultSortModel });*/
    };

    const columns: ColDef [] = [
        /*{
            field: 'id',
            headerName: 'Id',
            hide: true
        },*/
        {
            field: 'officerIds',
            headerName: 'Officers',
            flex: 1,
            editable: false,
            sortable: true,
            resizable: true,
            //tooltipField: 'officerIds',
            tooltipValueGetter: (params: ITooltipParams): string => {
                return getOfficerRender(params);
            },
            valueFormatter: (params: ValueFormatterParams ) => {
                return getOfficerRender(params);
            },
        },
        {
            field: 'enforcementZoneIds',
            headerName: 'Zones',
            flex: 1.4,
            editable: false,
            sortable: true,
            resizable: true,
            cellClass: 'license-theme--cell',
            tooltipValueGetter: (params: ITooltipParams): string => {
                return getEnforcementZoneRender(params);
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return getEnforcementZoneRender(params);
             },
        },
        {
          field: 'weekDays',
          headerName: 'Week Days',
          flex: 2.5,
          sortable: true,
          editable: false,
          resizable: true,
          tooltipValueGetter: (params: ITooltipParams): string => {
            return getWeekDayRender(params);
        },
          valueFormatter: (params: ValueFormatterParams ) => {
            return getWeekDayRender(params);
          },
        },
      ];

      function isFirstColumn(params: { columnApi: { getAllDisplayedColumns: () => any; }; column: any; }) {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        const thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
      }

      function getOfficerRender(params: any) {
        const  officers = JSON.parse(params.data.officerIds as string);
        let officersResult = '';
        if(officers.length > 0)
        {
            officers.forEach((officerId: string) => {
            officerId = !officerId.includes('-') ? officerId.replace(/([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,'$1-$2-$3-$4-$5') : officerId;
            const officerResult =  state.data.bootviewUsers?.find(x => {
                const fixedId = !x.id.includes('-') ? x.id.replace(/([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,'$1-$2-$3-$4-$5') : x.id;
                return fixedId == officerId;
            });
            if(officerResult)
            {
                officersResult += officerResult.firstName + ' ' +officerResult.lastName + ', ';
            }
            });
        }
        
        return officersResult.slice(0, -2);
      }

      function getEnforcementZoneRender(params: any) {
        const  zones: Array<string> = JSON.parse(params.data.enforcementZoneIds as string);
                
        let zoneResults = '';
        if(zones.length > 0)
        {
            zones.forEach((zoneId: string) => {
                const zoneResult =  state.data.enforcementZones?.find(x => x.enforcementZoneId == zoneId);
                if(zoneResult)
                {
                    zoneResults += zoneResult.zoneName + ', ';
                }
            });
        }
        
        return zoneResults.slice(0, -2);
      }

      function getWeekDayRender(params: any) {
        const  weekDays = JSON.parse(params.data.weekDays as string);
        let weekDaysResult = '';
        if(weekDays.length > 0)
            {
            weekDays.forEach((day: string) => {
                weekDaysResult += day + ', ';
                });
            }
        return weekDaysResult.slice(0, -2);
      }
    // End Zone Assingation grid 
    ////////////////////////////////////

    //zone layer show hide
    /////////////////////////
    const [showZoneLayer, setShowZoneLayer] = React.useState(true);
    const showZoneLayerChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setShowZoneLayer(checked);
    };


     //button group
    ////////////////////
    const options = ['Create Hotspot', 'Generate Hotspots', 'Hotspot by Address', 'Create Zone'];
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(3);

    const handleZoneCreationClick = () => {
        if(selectedIndex == 3)//3 = draw zone
        {
            onOpenModalName();
        }
        else if(selectedIndex == 2)//2 = generate hotspot address
        {
            onOpenModalHotspotAddress();
        }
        else if(selectedIndex == 1)//1 = generate hotspots
        {
            onOpenModalHotspotNumber();
        }
        else//draw a point
        {
            updateDrawPointActive();
        }
        
        updateModifyActive();
    };
   
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleZoneButtonToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
        }

        setOpen(false);
    };
    //end button group
    ////////////////////////

    //Zone Event Handlers
    ///////////////////////
    const [drawActive, setDrawActive] = React.useState<boolean>(false);
    const [drawType, setType] = React.useState<string>('zone');
    const [modifyActive, setModifyActive] = React.useState<boolean>(false);
    const [isDeleteActive, setIsDeleteActive] = React.useState<boolean>(false);
    

    const updateDrawZoneActive = (): void => {
        setType('zone');
        setDrawActive(true);
    };

    const updateDrawPointActive = (): void => {
        setType('point');
        setDrawActive(true);
    };

    const updateModifyActive = (): void => {
        setModifyActive(true);
    };

    const cancelDrawing = (): void => {
        setDrawActive(false);
        //setModifyActive(false); Leave modify always active 
        onDeleteEnd();
    };

    const deleteZone = (): void => {
        setIsDeleteActive(true);
    };

    const onDrawEnd = (): void => {
        setZoneName('');
        cancelDrawing();
    };

    const onDeleteEnd = (): void => {
        setIsDeleteActive(false);
    };

    //assignation
    const [stateAssign, dispatchAssing] = useReducer(mapZoneAssignReducer, {}, mapZoneAssignStateInit);
    const  changeSelectedSourceHandler = (selected: string[]): void => {
        dispatchAssing({ type: ACTION_TYPES.UPDATE_EFORCEMENT_ZONE, payload: selected });
    };

    const changeSelectedAgentsHandler = (selected: string[]): void => {
        dispatchAssing({ type: ACTION_TYPES.UPDATE_SELECTED_AGENTS, payload: selected });
    };

    const changeUpdateAssignWeekDay = (selected: string[]): void => {
        dispatchAssing({ type: ACTION_TYPES.UPDATE_WEEK_DAY, payload: selected });
    };

    const AssignEnforcementZoneHandler = (): void => {
        //first parameter is just a place holder, it's not used on the server on inserts, we autogenerate one on DB insert
        if(passZoneAssignationValidation())
        {
            dispatch(changeEnforcementZoneAssignation('', stateAssign.enforcementZones, stateAssign.selectedAgentsToSearchBy, stateAssign.weekDay,  AssignEnforcementZoneModificationType.Insert));
            clearAssignSelections();
        }
        else
        {
            alert('Please select all zone assignation fields');
        }
    };

    const DeleteAssignationEnforcementZoneHandler = (): void => {
        const confirmAction = confirm('Are you sure to delete selected assignations?');
        if (confirmAction) {
            const selectedRows: Array<string> = []; 
            gridApi?.getSelectedNodes().forEach(element => {
                selectedRows.push(element.data.enforcementZoneAssignationId);
            });  
            dispatch(changeEnforcementZoneAssignation(JSON.stringify(selectedRows), [],[],[],  AssignEnforcementZoneModificationType.Delete));
        } 
    };

    const clearAssignSelections = (): void => {
        dispatchAssing({ type: ACTION_TYPES.UPDATE_EFORCEMENT_ZONE, payload: [] });
        dispatchAssing({ type: ACTION_TYPES.UPDATE_SELECTED_AGENTS, payload: [] });
        dispatchAssing({ type: ACTION_TYPES.UPDATE_WEEK_DAY, payload: [] });
    };

    const passZoneAssignationValidation = (): boolean => {
        let result = true;
        if(stateAssign.enforcementZones.length == 0 || stateAssign.selectedAgentsToSearchBy.length == 0 || stateAssign.weekDay.length == 0)
        {
            result = false;
        }

        return result;
    };

    const changeSelectedWeekDayHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const selected = event.target.value as string;
        dispatchAssing({ type: ACTION_TYPES.CHANGE_WEEK_DAY, payload: selected });
        //dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LAYER_TYPE, payload: selected });
    };
    //End Zone Event Handlers
    ///////////////////////


    //zone name modal
    /////////////////////////
    const [modalZoneNameOpen, setModalZoneNameOpen] = React.useState<boolean>(false);
    const [zoneName, setZoneName] = React.useState<string>('');

    const onOpenModalName = (): void =>{
        setModalZoneNameOpen(true);
    };

    const onCloseModalName = (): void =>{
        if(zoneName == '')
        {
            alert('Please enter a zone name');
        }
        else
        {
            setModalZoneNameOpen(false);
            updateDrawZoneActive();
        }
    };

    const onCloseModalWithX = (): void =>{
        setModalZoneNameOpen(false);
    };

    const handleZoneNameChanged = (event: any): void =>{
        setZoneName(event.target.value);
    };
    //end zone name modal
    ////////////////////////

    //hotspot number modal
    /////////////////////////
    const [modalHotspotNumberOpen, setModalHotspotNumberOpen] = React.useState<boolean>(false);
    const [hotspotNumber, setHotspotNumber] = React.useState<number>(0);
    const [tempHotspotNumber, setTempHotspotNumber] = React.useState<number>(0);

    const onOpenModalHotspotNumber = (): void =>{
        setModalHotspotNumberOpen(true);
    };

    const onCloseModalHotspotNumber = (): void =>{
        if(tempHotspotNumber == 0)
        {
            alert('Please enter the number of hotspots to generate');
        }
        else
        {
            setModalHotspotNumberOpen(false);
            setHotspotNumber(tempHotspotNumber);
        }
    };

    const onCloseModalHotspotNumberWithX = (): void =>{
        setModalHotspotNumberOpen(false);
    };

    const handleZoneHotspotNumberChanged = (event: any): void =>{
        setTempHotspotNumber(event.target.value);
    };

    const onGenerateHotspotsEnd = (): void => {
        setHotspotNumber(0);//clears the generator for next call
        setTempHotspotNumber(0);
    };
    //end hotspot number modal
    ////////////////////////
    
    //hotspot address modal
    /////////////////////////
    const [modalHotspotAddressOpen, setModalHotspotAddressOpen] = React.useState<boolean>(false);
    const [hotspotAddress, setHotspotAddress] = React.useState<string>('');
    const [tempHotspotAddress, setTempHotspotAddress] = React.useState<string>('');

    const onOpenModalHotspotAddress = (): void =>{
        setModalHotspotAddressOpen(true);
    };

    const onCloseModalHotspotAddress = (): void =>{
        if(tempHotspotAddress == '')
        {
            alert('Please enter the address of hotspots to generate');
        }
        else
        {
            setModalHotspotAddressOpen(false);
            setHotspotAddress(tempHotspotAddress);
        }
    };

    const onCloseModalHotspotAddressWithX = (): void =>{
        setModalHotspotAddressOpen(false);
    };

    const handleZoneHotspotAddressChanged = (event: any): void =>{
        setTempHotspotAddress(event.target.value);
    };

    const onGenerateHotspotAddressEnd = (): void => {
        setHotspotAddress('');//clears the generator for next call
        setTempHotspotAddress('');
    };
    //end hotspot address modal
    ////////////////////////

    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <MapFilterBar
                        licenseFilter={licensePlate}
                        licenseFilterNoSearch={licensePlateNoSearch}
                        onApply={searchMapMarkers}/>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.contentWrapper} alignContent="stretch" alignItems="stretch" id="mapSearchContent" style={{ position: 'relative' }}>
                <LoadingModal
                    isOpen={state.data.isLoading}
                    container={(() => document.getElementById('mapSearchContent'))()}
                    message="Loading LPR reads, please wait..." />
                <Grid item xs={12}  lg={8} className={classes.openLayersMapWrapper}>
                    <Map
                        center={[state.data.filterSource.customerLongitude, state.data.filterSource.customerLatitude]}
                        markers={state.data.markers}
                        liveMarkers={state.data.liveMarkers}
                        enforcementZones={state.data.enforcementZones}
                        enforcementAssignationZones={state.data.enforcementAssignationZones}
                        layerType={state.data.filter ? state.data.filter.layerType : MapLayerType.Reads}
                        customerId={state.dashboardFilter.customerId ?? ''}
                        mapFilter={state.data.filter}
                        selectedMarker={state.data.selectedMarker}
                        drawActive = {drawActive}
                        drawType = {drawType}
                        modifyActive = {modifyActive}
                        showZoneLayer = {showZoneLayer}
                        zoneName = {zoneName}
                        weekDay={stateAssign.selectedWeekDay}
                        onDrawEnd = {onDrawEnd}
                        isDeleteActive = {isDeleteActive}
                        onSelectedMarker={(selectedMarkerId: string) => dispatch(selectMarker(selectedMarkerId))}
                        hotspotNumber={hotspotNumber}
                        onGenerateHotspotsEnd = {onGenerateHotspotsEnd}
                        hotspotAddress={hotspotAddress}
                        onGenerateHotspotAddressEnd = {onGenerateHotspotAddressEnd}
                    />
                </Grid>
                <Grid item xs={12}  lg={4} container className={classes.markersListWrapper} alignContent="stretch" alignItems="stretch" justify="center">
                    <Box className={classes.rightColumn}>
                        <Box >
                            <ExpansionPanel >
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"  id="panel1a-header">
                                    <Typography variant="body1" >Enforcement Zones </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.enforcementZoneDetailWrapper}>
                                    <Box >
                                        <Grid  direction="column">
                                            {/*<FormControlLabel control={<Checkbox name="showRouteChbx" onChange={showZoneLayerChanged} checked={showZoneLayer} />} label="Show All Zones on Map" />*/}
                                            <FormControl className={classes.weekSelect} fullWidth>
                                                <InputLabel shrink className={classes.label}>Show Enforcement Zones By Week Day</InputLabel>
                                                <Select
                                                    displayEmpty
                                                    value={stateAssign.selectedWeekDay}
                                                    name="Weed Days"
                                                    onChange={changeSelectedWeekDayHandler}
                                                    autoWidth={false}>
                                                    <MenuItem value={all}>All</MenuItem>
                                                    <MenuItem value={monday}>Monday</MenuItem>
                                                    <MenuItem value={tuesday}>Tuesday</MenuItem>
                                                    <MenuItem value={wednesday}>Wednesday</MenuItem>
                                                    <MenuItem value={thursday}>Thursday</MenuItem>
                                                    <MenuItem value={friday}>Friday</MenuItem>
                                                    <MenuItem value={saturday}>Saturday</MenuItem>
                                                    <MenuItem value={sunday}>Sunday</MenuItem>
                                                    {/*<MenuItem value={MapLayerType.Route}>Route</MenuItem>*/}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <ExpansionPanel >
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"  id="panel1a-header">
                                                <Typography variant="body1" >Step 1: Create Zones and Hotspots</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid  direction="column">
                                                    <Grid  direction="row" className={classes.gridAssignBase} >
                                                        <Grid >
                                                            <ButtonGroup  ref={anchorRef} aria-label="split button" >
                                                                <Button onClick={handleZoneCreationClick} className={classes.buttonGroup}>{options[selectedIndex]}</Button>
                                                                <Button
                                                                    color="primary"
                                                                    size="small"
                                                                    className={classes.buttonGroup}
                                                                    aria-controls={open ? 'split-button-menu' : undefined}
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    aria-label="select merge strategy"
                                                                    aria-haspopup="menu"
                                                                    onClick={handleZoneButtonToggle}
                                                                >
                                                                    <ArrowDownwardIcon fontSize={'small'}/>
                                                                </Button>
                                                            </ButtonGroup>
                                                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.buttonGroupContainer}>
                                                            {({ TransitionProps, placement }) => (
                                                                <Grow
                                                                {...TransitionProps}
                                                                style={{
                                                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                                                }}
                                                                >
                                                                <Paper>
                                                                    <ClickAwayListener onClickAway={handleClose}>
                                                                    <MenuList id="split-button-menu">
                                                                        {options.map((option, index) => (
                                                                        <MenuItem
                                                                            key={option}
                                                                            disabled={index === 4}
                                                                            selected={index === selectedIndex}
                                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                                        >
                                                                            {option}
                                                                        </MenuItem>
                                                                        ))}
                                                                    </MenuList>
                                                                    </ClickAwayListener>
                                                                </Paper>
                                                                </Grow>
                                                            )}
                                                            </Popper>
                                                        </Grid>
                                                        <button className={!isDeleteActive ? classes.button : classes.buttonRed} onClick={() => deleteZone()}>Delete</button>
                                                        <button className={classes.button} onClick={() => cancelDrawing()}>Finish</button>
                                                    </Grid>
                                                    <Box hidden={!isDeleteActive} className={classes.deleteMessage}>Please select a zone or hotspot to delete</Box>
                                                    {stateAssign.selectedWeekDay != 'All' && <Box style={{ color: '#8C4600', marginTop: 5 }}>*Only showing {stateAssign.selectedWeekDay} zones</Box>}
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"  id="panel1a-header">
                                                <Typography variant="body1">Step 2: Manage Enforcement Zones</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.enforcementZoneAssignationDetailWrapper}>
                                                <Box  >
                                                    <div className="opti-route-list" >
                                                        <div className="ag-theme-alpine" style={{ height: '25vh', width: '100%' }}>
                                                            <AgGridReact
                                                                gridOptions={{ suppressCellSelection: true }}
                                                                columnDefs={columns}
                                                                rowData={state.data.enforcementAssignationZones}
                                                                multiSortKey={'ctrl'}
                                                                rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                                                                onGridReady={onGridReady}
                                                                rowSelection= {'multiple'}
                                                                defaultColDef={{
                                                                    flex: 1,
                                                                    minWidth: 100,
                                                                    resizable: true,
                                                                    headerCheckboxSelection: isFirstColumn,
                                                                    checkboxSelection: isFirstColumn,
                                                                    //tooltipComponent: 'customTooltip',
                                                                }}
                                                                tooltipShowDelay={0}
                                                                //frameworkComponents={{ customTooltip: CustomTooltip }}
                                                                /*onSelectionChanged={(event: SelectionChangedEvent) => {
                                                                    const selectedRows = event.api.getSelectedNodes();
                                                                    const rowIds: Array<string> = [];
                                                                    selectedRows.forEach(row => {
                                                                        rowIds.push(row.data.e
                                                                    });
                                                                }}*/
                                                                /*onRowDoubleClicked={(rowParams: RowDoubleClickedEvent) => {
                                                                    onSelectMarker(rowParams.data.lprDataID);
                                                                }}
                                                                onCellClicked={(params: CellClickedEvent ) => {
                                                                    if(params.colDef.field == 'licPlate')
                                                                    {
                                                                        onSelectedPlate(params.data.licPlate);
                                                                    }
                                                                }}*/
                                                                onGridColumnsChanged={event => event.api.sizeColumnsToFit()} />

                                                        </div>
                                                    </div>
                                                    <button className={classes.deleteButton} onClick={() => DeleteAssignationEnforcementZoneHandler()}>Delete Selected</button>
                                                    <Grid container   direction="row" className={classes.bottoAssigControls}>
                                                        {
                                                        state.data.bootviewUsers &&
                                                        <Grid item xs={4}>
                                                            <FormControl className={classes.formControl} >
                                                                <InputLabel shrink className={classes.label}>Officers</InputLabel>
                                                                <MultipleSelect
                                                                    //id="searchByAgentsAssign"
                                                                    includeSelectAll={true}
                                                                    currentSelected={stateAssign.selectedAgentsToSearchBy}
                                                                    renderAllSelected={false}
                                                                    type="checkbox"
                                                                    onChange={changeSelectedAgentsHandler}
                                                                    selectableItems={state.data.bootviewUsers.map((bo) => ({ id: bo.id, name: bo.firstName + ' ' + bo.lastName  }))}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        }
                                                        {
                                                        state.data.enforcementZones &&
                                                        <Grid item xs={4}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel shrink className={classes.label}>Zones</InputLabel>
                                                                <MultipleSelect
                                                                    includeSelectAll={true}
                                                                    currentSelected={stateAssign.enforcementZones}
                                                                    type="checkbox"
                                                                    onChange={changeSelectedSourceHandler}
                                                                    selectableItems={state.data.enforcementZones.map((zone) => ({ id: zone.enforcementZoneId, name: zone.zoneName }))}
                                                                    renderAllSelected={false}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        }
                                                        <Grid item xs={4}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel shrink className={classes.label}>Day</InputLabel>
                                                            <MultipleSelect
                                                                includeSelectAll={true}
                                                                currentSelected={stateAssign.weekDay}
                                                                type="checkbox"
                                                                onChange={changeUpdateAssignWeekDay}
                                                                selectableItems={[
                                                                    { id: 'Monday', name: 'Monday' },
                                                                    { id: 'Tuesday', name: 'Tuesday' },
                                                                    { id: 'Wednesday', name: 'Wednesday' },
                                                                    { id: 'Thursday', name: 'Thursday' },
                                                                    { id: 'Friday', name: 'Friday' },
                                                                    { id: 'Saturday', name: 'Saturday' },
                                                                    { id: 'Sunday', name: 'Sunday' },
                                                                ]}
                                                                renderAllSelected={false}
                                                            />
                                                        </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <button className={classes.buttonAssign} onClick={() => AssignEnforcementZoneHandler()}>Assign</button>
                                                    {stateAssign.selectedWeekDay != 'All' && <label style={{ color: '#8C4600' }}>*Only showing {stateAssign.selectedWeekDay} zones</label>}
                                                </Box>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Box>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Box>
                         <MarkersList
                        isLoading={state.data.isLoading}
                        markers={state.data.filteredMarkers}
                        mapState={state.data}
                        selectedMarker={state.data.selectedMarker}
                        onSelectedMarker={(selectedMarkerId: string) => dispatch(selectMarker(selectedMarkerId))}
                        onClearSelectedMarker={() => dispatch(unselectMarker())}
                        onSelectedPlate={filterByPlate}
                        onSelectedPlateNoSearch={setPlate}
                        selectedCustomerSubDomain={selectedCustomerSubDomain}
                        //pagination={state.data.pagination}
                        //onPageChanged={(page: number) => dispatch(changePageAndFetchMarkers(page))}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Modal
            open={modalZoneNameOpen}
            onClose={onCloseModalName}
            //onRendered={onRendered}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
                <Paper elevation={2} className={classes.modalContent}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h4">
                        Zone Name
                        </Typography>
                        <IconButton onClick={onCloseModalWithX}>
                                <CloseIcon />
                        </IconButton>
                    </Grid>
                    <TextField id="zone-name" label="Name" value={zoneName} onChange={handleZoneNameChanged} />
                    <Button onClick={onCloseModalName} className={classes.button}>Set</Button>
                </Paper>
            </Modal>
            <Modal
            open={modalHotspotNumberOpen}
            onClose={onCloseModalHotspotNumber}
            //onRendered={onRendered}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
                <Paper elevation={2} className={classes.modalContent}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h4">
                        Hotspots
                        </Typography>
                        <IconButton onClick={onCloseModalHotspotNumberWithX}>
                                <CloseIcon />
                        </IconButton>
                    </Grid>
                    <TextField id="zone-name" label="Hotsposts" type='number' onChange={handleZoneHotspotNumberChanged} />
                    <Button onClick={onCloseModalHotspotNumber} className={classes.button}>Generate</Button>
                </Paper>
            </Modal>
            <Modal
            open={modalHotspotAddressOpen}
            onClose={onCloseModalHotspotAddress}
            //onRendered={onRendered}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
                <Paper elevation={2} className={classes.modalContent}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h4">
                        Hotspot
                        </Typography>
                        <IconButton onClick={onCloseModalHotspotAddressWithX}>
                                <CloseIcon />
                        </IconButton>
                    </Grid>
                    <TextField id="zone-name" label="Hotspost Address"  onChange={handleZoneHotspotAddressChanged} />
                    <Button onClick={onCloseModalHotspotAddress} className={classes.button}>Generate</Button>
                </Paper>
            </Modal>
        </Box>
    );
};

export default MapSearch;
