import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch',
        justifyContent: 'center',
        height: '100%'
    },
    grid: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
    },
    titleContainer: {
        flexGrow: 0,
        // maxHeight: 33,
    },
    titleIcon: {
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(1.5),
    },
    details: {
        padding: theme.spacing(0, 1.5),
        cursor: 'default',
        color: 'blue'
    },
    listContainer: {
        flexGrow: 1,
        // maxHeight: 759,
    },
    listItem: {
        padding: theme.spacing(2),
    },
    startSearchMessage: {
        padding: theme.spacing(2),
    },
    loadingSearchMessage: {
        padding: theme.spacing(2),
    },
    noResultsMessage: {
        padding: theme.spacing(2),
    },
    paginationContainer: {
        flexGrow: 0,
        alignSelf: 'flex-end',
        marginTop: theme.spacing(1),
    },
    pagination: {
        padding: theme.spacing(1),
    },
    gridStyles:{
        '& .license-theme--cell': {
            fontWeight: 'bold',
            textDecorationLine: 'underline',
            cursor: 'pointer'
        }
    },
    clearButton: {
        fontSize: '10px',
        margin: 0,
        height: 22,
        width: 150,
        marginTop: 5,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 5,
            marginBottom: 0,
        },
        
    },
}));
