import 'ol/ol.css';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';

const routeLayerStyles = (type: string): Style => {
    const routeStyle = (): Style => {
        return new Style({
            stroke: new Stroke({
                width: 6,
                color: [0, 109, 142, 0.8],
            }),
        });
    };

    const geoMakerStyle = (): Style => {
        return new Style({
            image: new CircleStyle({
                radius: 7,
                //snapToPixel: false,
                fill: new Fill({
                    color: 'black',
                }),
                stroke: new Stroke({
                    color: 'white',
                    width: 2,
                }),
            }),
            zIndex: 1,
        });
    };

    const startEndPoint = (): Style => {
        const color = type == 'startpoint' ? '#009f00' : '#9b0000';
        const text = type == 'startpoint' ? 'start' : 'end';

        return new Style({
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: color,
                }),
                stroke: new Stroke({
                    color: 'white',
                    width: 2,
                }),
            })
            ,
            text: new Text({
                font: '12px sans-serif',
                backgroundFill: new Fill({ color: 'white' }),
                overflow: true,
                offsetY: 15,
                text: text,
                fill: new Fill({ color: color }),
            })
            , zIndex: 0,
        });
    };

    switch (type) {
        case 'route':
            return routeStyle();
        case 'geoMarker':
            return geoMakerStyle();
        case 'startpoint':
        case 'endpoint':
        default:
            return startEndPoint();
    }
};

export default routeLayerStyles;
