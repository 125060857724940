import { Grid, Paper, Typography } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import React, { useEffect, useState } from 'react';
import clone from 'rfdc';

import { Get } from '../../../../services/RestClient';
import AverageCard from '../../Commons/AverageCard/AverageCard';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import { useStyles } from './EnforcementStyles';
import { LprStatsSummary } from './EnforcementSummary';

type EnforcementProps = {
    filter: DashboardFilterState;
}

export default function Enforcement (props: EnforcementProps): JSX.Element {
    const classes = useStyles();

    const { filter } = props;

    const [state, setState] = useState(new LprStatsSummary);

    useEffect(() => {
        fetchData(setState, state, filter);
    }, [filter]);

    return (
        <Paper className={classes.root} elevation={1}>
            <Grid container spacing={2}>
                <Grid item container alignContent="center" alignItems="center">
                    <DriveEtaIcon className={classes.icon} />
                    <Typography variant="h6" className={classes.title}>
                        Enforcement
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="column" justify="center" alignItems="stretch" alignContent="stretch">
                <Grid item>
                    <AverageCard title="Scans"
                        subtitle={filter.averageDescription}
                        total={state.scans.total}
                        averagePerPeriod={state.scans.averagePerPeriod}
                        isLoading={state.isLoading} />
                </Grid>
                <Grid item>
                    <AverageCard title="Hits"
                        subtitle={filter.averageDescription}
                        total={state.hits.total}
                        averagePerPeriod={state.hits.averagePerPeriod}
                        isLoading={state.isLoading} />
                </Grid>
                <Grid item>
                    <AverageCard title="Booted Vehicles"
                        subtitle={filter.averageDescription}
                        total={state.boots.total}
                        averagePerPeriod={state.boots.averagePerPeriod}
                        // averagesCollection={state.boots.averagesCollection}
                        isLoading={state.isLoading} />
                </Grid>
            </Grid>
        </Paper>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchData = (setState: React.Dispatch<any>, state: LprStatsSummary, filter: DashboardFilterState): Promise<void> => {
    if (!filter.customerId) {
        setState({...state, isLoading: true, error: null});
        return Promise.resolve();
    }

    if (!state.isLoading) {
        setState({...state, isLoading: true, error: null});
    }

    return Get({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/lpr/summary`,
        params: {
            customerId: filter.customerId,
            daysToAverage: filter.daysToAverage,
            date: filter.date,
        },
    }).then((summary: LprStatsSummary) => {
        summary.isLoading = false;
        summary.error = null;
        setState(clone({proto: true})(summary));
    }).catch((err) => {
        setState(new LprStatsSummary(false, err));
    });
};
