import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        border: 0,
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
        color: theme.palette.primary.contrastText,
        '& svg': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiDivider-root': {
            backgroundColor: theme.palette.primary.light,
        },
        '& a': {
            color: theme.palette.primary.contrastText,
        },
    },
    nested: {
        paddingLeft: theme.spacing(9.25),
        '& .MuiTypography-root': {
            paddingBottom: theme.spacing(.6),
            paddingTop: theme.spacing(.6),

            '& > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
                paddingTop: theme.spacing(2),
            },
        },
    },
    logoWrapper: {
        backgroundColor: '#ffffff',
    },
    cameraIcon: {
        color: '#eae9ef',
        width: 27,
        display: 'inline-block',
        marginRight: '1.3rem!important',
    },
    logo: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    collapse: {
        border: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& svg': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiDivider-root': {
            backgroundColor: theme.palette.primary.light,
        },
        '& a': {
            color: theme.palette.primary.contrastText,
        },
    },
    saveButton: {
        marginBottom: theme.spacing(2),
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        width: '100%',
    },
    deleteButton: {
        margin: 0,
        color: theme.palette.info.contrastText,
        backgroundColor: 'red',
        '&:hover, &:active, &:focus': {
            backgroundColor: 'red',
            outline: 0,
        },
        width: '100%',
    },
    tileCategoryTitle: {
        display: 'inline',
    },
    labelTF: {
        color: 'white !important',
    },
    inputTF: {
        color: 'white !important',
        borderColor: 'white',
        '&:hover': {
            borderColor: 'white',
        },
        '&$focused': {
            borderColor: 'white',
        }
    },
    select: {
        margin: 0,
        width: '100%',
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
        minWidth: 160,
        color: theme.palette.primary.dark,
        '&:before, &:after': {
            borderBottom: '2px solid ' + theme.palette.primary.dark,
        },
        '&:hover:before, &:active:before, &:focus:before, &:hover:after, &:active:after, &:focus:after': {
            borderBottom: '2px solid ' + theme.palette.secondary.main + ' !important',
        },
        '& .MuiIconButton-label': {
            color: theme.palette.primary.dark,
        },
    },
    sIcon: {
        fill: 'white',
    },
    formControl: {
        borderColor: 'green',
        margin: 0,
        minWidth: 120,
        color: theme.palette.primary.dark,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: 0,
            marginBottom: 0,
        },
    },
    tileModal: {
        position: 'absolute',
        width: 600,
        backgroundColor: 'white',
        border: '3px solid #332643',
        padding: theme.spacing(2, 4, 9),
        '& > div': {
            backgroundColor: 'white !important'
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
            visibility: 'hidden'
        }
    },
    box: {
        display: 'flex'
    },
    rightBox: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    menuElement: {
        '& .MuiListItemIcon-root': {
            marginRight: '.5rem !important',
            minWidth: '0px',
        },
    },
}));
