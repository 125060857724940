import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    '& .MuiTypography-root': {
      fontWeight: '500',
    },
  },
  iconSettings: {
    color: '#2b78e4',
    margin: 0,
    padding: 0,
    alignSelf: 'center',
  },
}));

export default useStyles;
