import { createSlice } from '@reduxjs/toolkit';
import { MapMarker, MapDashboardStateModel, MapFilterSource } from './mapDashboardStateV2';
import { updateMapStateAction, setLoadingAction, setListLoadingAction,  setErrorAction, selectMarkerAction, unselectMarkerAction, applyFilterAction, changePageOnFilterAction, setFilterSourceAction } from './mapDashboardActions';

const mapGPSDevicesDashboardSlice = createSlice({
    name: 'mapGPSDevicesDashboard',
    initialState: {
        isLoading: false,
        error: null,
        listIsLoading: false,
        markers: Array<MapMarker>(),
        pagination: {
            currentPage: 1,
            totalPages: 0,
        },
        selectedMarker: undefined,
        filterSource: {
            bootingOfficers: [],
            parkingAreas: [],
            customerLongitude: -100.964709,
            customerLatitude: 39.259336,
        } as MapFilterSource,
    } as MapDashboardStateModel,
    reducers: {
        setLoading: setLoadingAction,
        setError: setErrorAction,
        setListLoading: setListLoadingAction,
        setFilterSource: setFilterSourceAction,
        applyFilter: applyFilterAction,
        changePageOnFilter: changePageOnFilterAction,
        updateMapState: updateMapStateAction,
        selectMarker: selectMarkerAction,
        unselectMarker: unselectMarkerAction,
    },
});

export const { reducer } = mapGPSDevicesDashboardSlice;
export const { setLoading, setError, setListLoading,  setFilterSource, updateMapState, applyFilter, changePageOnFilter, selectMarker, unselectMarker } = mapGPSDevicesDashboardSlice.actions;
