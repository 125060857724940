import { MapDashboardStateModel, MapSearchResult, MapFilterSource, MapMarker  } from './mapDashboardStateV2';
import { PayloadAction } from '@reduxjs/toolkit';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';

const setLoadingAction = (state: MapDashboardStateModel, action: PayloadAction<boolean>): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setErrorAction = (state: MapDashboardStateModel, action: PayloadAction<string | null>): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
};


const setListLoadingAction = (state: MapDashboardStateModel, action: PayloadAction<boolean>): MapDashboardStateModel => {
    return {
        ...state,
        listIsLoading: action.payload,
    };
};

const setFilterSourceAction = (state: MapDashboardStateModel, action: PayloadAction<MapFilterSource>): MapDashboardStateModel => {
    return {
        ...state,
        filterSource: {
            ...action.payload,
            bootingOfficers: [...action.payload.bootingOfficers],
            parkingAreas: [...action.payload.parkingAreas],
        },
    };
};

const applyFilterAction = (state: MapDashboardStateModel, action: PayloadAction<MapFilterState>): MapDashboardStateModel => {
    const filter: MapFilterState = {
        ...action.payload,
        selectedAgentsToSearchBy: [...action.payload.selectedAgentsToSearchBy],
        selectedDataTypes: [...action.payload.selectedDataTypes],
    };

    return {
        ...state,
        isLoading: true,
        filter: filter,
    };
};


const selectMarkerAction = (state: MapDashboardStateModel, action: PayloadAction<number>): MapDashboardStateModel => {
    const selectedMarker = state.markers.find(a => a.id == action.payload);

    return {
        ...state,
        selectedMarker: selectedMarker,
    };
};

const unselectMarkerAction = (state: MapDashboardStateModel, action: PayloadAction<void>): MapDashboardStateModel => {
    return {
        ...state,
        selectedMarker: undefined,
    };
};

const changePageOnFilterAction = (state: MapDashboardStateModel, action: PayloadAction<number>): MapDashboardStateModel => {
    const filter: MapFilterState = {
        ...state.filter,
        currentPage: action.payload,
    };

    let paginatedMarkers = [...state.markers];
    if (state.markers.length > filter.pageSize) {
        const start = (filter.currentPage - 1) * filter.pageSize;
        paginatedMarkers = [...state.markers].slice(start, (start + filter.pageSize));
    }

    return {
        ...state,
        // isLoading: true,
        filter,
        paginatedMarkers: paginatedMarkers,
    };
};

const updateMapStateAction = (state: MapDashboardStateModel, action: PayloadAction<MapSearchResult>): MapDashboardStateModel => {
    const pagination = action.payload.pagination;
    const markers = [...action.payload.result.items];


    let paginatedMarkers = [...markers];
    if (pagination != null && markers.length > pagination.pageSize) {
        const start = (pagination.currentPage - 1) * pagination.pageSize;
        paginatedMarkers = [...markers].slice(start, (start + pagination.pageSize));
    }

    const filteredMarkers = [...markers];
    let identifier = 1;
    filteredMarkers.forEach(marker => {
        marker.id = identifier;//unidque identifier
        identifier++;
    });

    //order by name
    filteredMarkers.sort(function(a, b){ 
        if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          // names must be equal
          return 0;
    });
    

    return {
        ...state,
        isLoading: false,
        error: null,
        markers: markers,
        paginatedMarkers: paginatedMarkers,
        filteredMarkers: filteredMarkers,
        pagination: action.payload.pagination,
    };
};


export { setLoadingAction, setErrorAction, updateMapStateAction, selectMarkerAction, unselectMarkerAction,  applyFilterAction, changePageOnFilterAction, setFilterSourceAction, setListLoadingAction };
