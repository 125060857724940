import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';

export interface MapDashboardStateModel {
    isLoading: boolean;
    error: string | null;
    filter: MapFilterState;
    markers: MapMarker[];
    paginatedMarkers: MapMarker[];
    filteredMarkers: MapMarker[];
    pagination: PaginationResult;
    selectedMarker: MapMarker | null | undefined;
    filterSource: MapFilterSource;
    listIsLoading: boolean;
}

export type MapSearchResult = {
    success: boolean;
    message: string;
    result: DeviceListResult;
    pagination: PaginationResult;
}

export type GPSDeviceMessageResult = {
    success: boolean;
    message: string;
    result: GPSDeviceMessagesResult;
}

export type GPSDeviceMessagesResult = {
    items: GPSDeviceMessage [];
}

export type GPSDeviceMessage = {
    type: string;
    gpsUTCTime: string;
    imei: string;
    latitude: number;
    longitude: number;
    odoMileage: number;
    sendTime: Date;
    message_id: string;
    speed: number;
}

export type GPSDeviceAlertResult = {
    success: boolean;
    message: string;
    result: GPSDeviceAlertsResult;
}

export type GPSDeviceAlertsResult = {
    items: GPSDeviceAlert [];
}

export type GPSDeviceAlert = {
    alertType: string;
    imei: string;
    created: Date;
    sendTime: Date;
    userId: string;
    coordinates: Coordinate;
    address: string;
    clientId: number;
    alertId: number;
    value: string;
    isBuffer: boolean;
    isNotification: boolean;
}

export type Coordinate = {
    latitude: number;
    longitude: number;
}

export type GPSDeviceBehaviorResult = {
    success: boolean;
    message: string;
    result: GPSDeviceBehaviorTaskResult;
}

export type GPSDeviceBehaviorTaskResult = {
    taskId: string;
}

export type DeviceListResult = {
    items: MapMarker[];
}

export type MapMarker = {
    id: number;//for datagrid columns created manually on mapDashboardActions.tsx
    imei: string;
    brand: string;
    clientId: number;
    created: Date;
    name: string;
    status: string;
    iccid: string;
    modified: Date;
    createdBy: string;
    modifiedBy: string;
    deviceId: string;
    color: string;
    icon: string;
    description: string;
    connectivityProvider?: string;
    model: ModelElement;
    deviceStatus: DeviceStatusResult;
}

export type ModelElement = {
    name: string;
    description: string;
    pictureUrl: string;
}

export type DeviceStatusResult = {
    batteryAlertInAlarm: number;
    gpsAccuracy: number;
    tripDurationInAlarm: string;
    cellId: string;
    messageId: string;
    reportType: string;
    azimuth: string;
    odoMileage: string;
    speed: string;
    altitude: number;
    latitude: number;
    longitude: number;
    tripGenerator: Date;
    reportId: string;
    gpsUTCTime: string;
    batteryPercentage: string;
    created: Date;
    sendTime: Date;
    address: string;
    clientId: number;
    tripDistanceInAlarm: number;
    tripId: number;
    imei: string;
    speedAlertInAlarm: string;
    tripStopDurationInAlarm: string;
    mnc: string;
    mcc: string;
    lac: string;
    protocolVersion: string;
}

export type Pagination = {
    currentPage: number;
    pageSize: number;
}

export type PaginationResult = {
    totalPages: number;
    totalItems: number;
} & Pagination;





export type MapFilterSource = {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    customerLongitude: number;
    customerLatitude: number;
}

export type BootingOfficer = {
    bootingOfficerId: string;
    name: string;
}

export type ParkingArea = {
    parkingAreaId: string;
    name: string;
}

export enum MapFilterSearchBy {
    All = 0,
    Agents = 1,
    Locations = 2
}

export enum MapFilterDataType {
    Scan = 0,
    Hit = 1,
    Booted = 2
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export enum MapLayerType {
    Reads = 1,
    Heat = 2,
    Route = 3,
}

