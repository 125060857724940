import { PayloadAction } from '@reduxjs/toolkit';

import { FieldColumn } from '../templates/types';
import {
    ChartType,
    SqlResult,
    TileModel,
    TileModelCategory,
    TileSettingsModel,
    TileSettingsTestValues,
} from '../tileBuilderTypes';
import { DashboardTileBuilderState } from './tileBuilderState';
import { removeTimeFromDate } from './utils';

const updateTileSettingsAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<TileSettingsModel>
): DashboardTileBuilderState => {
  const newState = { ...state, tileSettings: { ...action.payload } };
  return newState;
};

const setTileIdAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<string>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { ...state.tileSettings, tileId: action.payload },
  };
  return newState;
};

const setTileSettingsNameAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<string>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { ...state.tileSettings, name: action.payload },
  };
  return newState;
};

const setTileSettingsChartTypeAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<ChartType>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { ...state.tileSettings, chartType: action.payload },
  };
  return newState;
};

const setTileSettingsCategoryIdAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<TileModelCategory>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { ...state.tileSettings, categoryId: action.payload },
  };
  return newState;
};

const setTileSettingsDatabaseIdAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<string>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { ...state.tileSettings, databaseId: action.payload },
  };
  return newState;
};

const setTileSettingsSqlQueryAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<string>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { ...state.tileSettings, sqlQuery: action.payload },
  };
  return newState;
};

const setTileSettingsTestDataAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<TileSettingsTestValues>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: { 
      ...state.tileSettings, 
      testValues: { 
        customerId: action.payload.customerId,
        date: removeTimeFromDate(action.payload.date),
        dateTo: removeTimeFromDate(action.payload.dateTo ?? action.payload.date),
      } 
    },
  };
  return newState;
};

const setSqlQueryResultAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<SqlResult>
): DashboardTileBuilderState => {
  const newState = { ...state, sqlResult: { ...action.payload } };
  return newState;
};

const setFieldsColumnsAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<FieldColumn[]>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: {
      ...state.tileSettings,
      fieldsColumns: [...action.payload],
    },
  };
  return newState;
};

const setCustomTilesListAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<TileModel[]>
): DashboardTileBuilderState => {
  const newState = { ...state, customTilesList: [...action.payload] };
  return newState;
};

const setTileSizeAction = (
  state: DashboardTileBuilderState,
  action: PayloadAction<{ width: number; height: number }>
): DashboardTileBuilderState => {
  const newState = {
    ...state,
    tileSettings: {
      ...state.tileSettings,
      width: action.payload.width,
      height: action.payload.height,
    },
  };
  return newState;
};

export {
  updateTileSettingsAction,
  setTileIdAction,
  setTileSettingsNameAction,
  setTileSettingsChartTypeAction,
  setTileSettingsCategoryIdAction,
  setTileSettingsDatabaseIdAction,
  setTileSettingsSqlQueryAction,
  setTileSettingsTestDataAction,
  setSqlQueryResultAction,
  setFieldsColumnsAction,
  setCustomTilesListAction,
  setTileSizeAction,
};
