import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImportFilesSummary, NoticesTowsSummary, InformationOverTimeSummary } from '..';
import EnforcementSummary from '../Enforcement/EnforcementSummary';

export interface MainDashboardState {
  isLoading: boolean;
  error: string | null;
  initialized: boolean;
  importFiles?: ImportFilesSummary;
  enforcement?: EnforcementSummary;
  noticesTows?: NoticesTowsSummary;
}

const mainDashboardSlice = createSlice({
  name: 'mainDashboard',
  initialState: {
    isLoading: false,
    error: null,
    initialized: false,
    importFiles: new ImportFilesSummary(),
    enforcement: new EnforcementSummary(),
    noticesTows: new NoticesTowsSummary(),
  } as MainDashboardState,
  reducers: {
    isLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
        error: null,
      };
    },
    isError: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    updateAll: (state, action: PayloadAction<MainDashboardState>) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        initialized: true,
      };
    },
    updateScofflaw: (state, action: PayloadAction<ImportFilesSummary>) => {
      return {
        ...state,
        importFiles: action.payload,
      };
    },
    updateEnforcement: (state, action: PayloadAction<EnforcementSummary>) => {
      return {
        ...state,
        enforcement: action.payload,
      };
    },
    updateNoticesAndTows: (state, action: PayloadAction<NoticesTowsSummary>) => {
      return {
        ...state,
        noticesTows: action.payload,
      };
    },
    updateInformationOverTime: (state, action: PayloadAction<InformationOverTimeSummary>) => {
      return {
        ...state,
        informationOverTime: action.payload,
      };
    },
  },
});

export const { actions, reducer } = mainDashboardSlice;
export const { isLoading, isError, updateAll, updateInformationOverTime, updateScofflaw, updateEnforcement, updateNoticesAndTows } = actions;
