import React from 'react';
import BorderLinearProgress from '../BorderLinearProgress/BorderLinearProgress';
import { getProgressBarPercentage, ProgressLabels } from '../ProgressLabels/ProgressLabels';

type AverageCardProgressBarProps = {
    value: number;
    averageValue: number;
}

export default function AverageCardProgressBar (props: AverageCardProgressBarProps): JSX.Element {
    const { value, averageValue } = props;
    const progressBarPercentage = getProgressBarPercentage(value, averageValue);

    return (
        <React.Fragment>
            <BorderLinearProgress value={progressBarPercentage} height={10} />
            <ProgressLabels value={value} averageValue={averageValue} />
        </React.Fragment>
    );
}
