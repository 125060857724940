import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStylesSqlQueryEditorModal = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    cancelButton: {
      marginLeft: theme.spacing(2),
    }
  }),
);
