import { Icon, Style, Stroke, Fill } from 'ol/style';
import IconStyle from 'ol/style/Icon';
import CircleStyle from 'ol/style/Circle';
import {  GPSDeviceMessage, MapMarker } from '../../state/mapDashboardStateV2';
import { MapFilterState } from '../FilterBar/mapFilterState';


/*const getMarkerIconColor = (marker: MapMarker): string => {
    switch (marker.type) {
        case EnforcementDataType.Scan:
            return '#473b55';
        case EnforcementDataType.Hit:
            return '#FF6633';
        case EnforcementDataType.Boot:
        default:
            return 'red';
    }
};*/

const getReadIconStyle = (marker: MapMarker, mapFilter?: MapFilterState ): any => {
    return new Style({
        image: new CircleStyle({
            radius: 9,
            fill: new Fill({
                color: marker.color,
            }),
            stroke: new Stroke({
                color: 'white',
                width: 2,
            }),
        }),
        zIndex: 100,
    });
}


const readsLayerStyle = (marker: MapMarker, mapFilter?: MapFilterState ): Style => {
    //const markerIconColor = getMarkerIconColor(marker);

    return getReadIconStyle(marker, mapFilter);

};

const onHoverReadLayerStyle = (marker: MapMarker): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = getReadIconStyle(marker);
    //style.image.radius += 2;
    return style;

};

const getSelectedStyle = (): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = new Style({
        
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#FBD337',
            }),
            stroke: new Stroke({
                color: 'black',
                width: 1,
            }),
        }),
        zIndex: 1000,
    });
    //style.image.radius += 2;
    return style;

};

export default readsLayerStyle;
export { onHoverReadLayerStyle, getSelectedStyle };
