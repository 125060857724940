import axios from 'axios';
import { getDefaultHeaders } from './utils';

const TIMEOUT = 30000;

export const api = axios.create({
    baseURL: process.env.REACT_APP_STATTRAK_SERVICES_URL,
    timeout: TIMEOUT,    
});

api.interceptors.request.use((config) => {
        config.headers = getDefaultHeaders();
        return config;
    }, (error) => Promise.reject(error)
);

api.interceptors.response.use((response) => response, (error) => {
    console.log('Unexpected error while trying to reach StatTrak API. Error: ', error);
    return Promise.reject(error);
})

export default api;
