import DateFnsUtils from '@date-io/date-fns';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Cookies from 'js-cookie';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Customer, SessionState } from '../../../../app/session/sessionTypes';
import { getFatureToogle, getInitialCustomer } from '../../../../app/session/sessionThunks';
import { ApplicationState } from '../../../../app/store';
import { SceneType } from '../../LiveMap/state/mapDashboardStateV2';
import { Scenario } from '../../Scenarios/state/mapDashboardStateV2';
import { updateFilterActionPayload } from '../../state/DashboardFilterActions';
import {
    DashboardFilterState,
    updateCustomer,
    updateDate,
    updateDateTo,
    updateWeekDays,
    updateDaysToAverage,
    updateOccurrences,
    updateSceneType,
    updateScenario,
} from '../../state/DashboardFilterState';
import { MultipleSelect } from '../MultipleSelect/MultipleSelect';
import AutoReload from './AutoReload';
import { useStyles } from './DashboardFilterStyles';
import {getScenarioColor} from '../../Scenarios/MapSearch/Map/readsLayerStyles';
import {FiberManualRecord as CircleIcon} from '@material-ui/icons';
import { Get } from '../../../../services/RestClient';

const averageRanges = [
    { name: '5 Weeks', daysToAverage: 35 },
    { name: '4 Week', daysToAverage: 28 },
    { name: '3 Week', daysToAverage: 21 },
    { name: '2 Week', daysToAverage: 14 },
    { name: '1 Week', daysToAverage: 7 },
    // { name: '10 Weeks', daysToAverage: 70 },
];

type Props = {
    onApply: () => void;
    applyEnabled?: boolean;
    hideViewButton?: boolean;
    hideTimer?: boolean;
    hideToDate?: boolean;
    hideFromDate?: boolean;
    showWeekDays?: boolean;
    showOccurrences?: boolean;
    showSceneSelection?: boolean;
    showScenarioSelection?: boolean;
    filterType?: string;
    excludeAverage?: boolean; // TODO: If true overrides all
};

const DashboardFilterBar = (props: Props): JSX.Element => {
    const classes = useStyles();

    const { 
        onApply, 
        applyEnabled, 
        hideViewButton, 
        hideTimer, 
        hideFromDate, 
        hideToDate, 
        showWeekDays, 
        showOccurrences, 
        showSceneSelection,
        showScenarioSelection,
        filterType = null,
        excludeAverage = false,
    } = props;

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState,
            session: state.session as SessionState,
        };
    };
    const state = useSelector(selector);
    const dispatch = useDispatch();

    const [excludeAverageState, setExcludeAverageState] = useState(excludeAverage);
    const [filterTypeState, setFilterTypeState] = useState(filterType ? filterType : state.dashboardFilter.type);
    const [allowedCustomers, setAllowedCustomers] = useState(Array<Customer>());
    const [resetAutoReloadTimerFlag, setResetAutoReloadTimerFlag] = useState(false);
    const [scenarioListOpen, setScenarioListOpen] = useState(false);

    const  hideFromDateBecauseScene = (showSceneSelection && state.dashboardFilter.sceneType == SceneType.NewPlates)?  true : hideFromDate;
    const  hideToDateBecauseScene = (showSceneSelection && state.dashboardFilter.sceneType == SceneType.NewPlates)?  true : hideToDate;
    const  hideWeekDaysBecauseScene = (showSceneSelection && state.dashboardFilter.sceneType == SceneType.NewPlates)?  true : !showWeekDays;
    const  hideOcurrencesBecauseScene = (showSceneSelection && state.dashboardFilter.sceneType == SceneType.NewPlates)?  true : !showOccurrences;

    const  hideToDateBecauseScenario = (showScenarioSelection && (state.dashboardFilter.scenario == Scenario.Runaways || state.dashboardFilter.scenario == Scenario.QueueTow || state.dashboardFilter.scenario == Scenario.QueueRelease || state.dashboardFilter.scenario == Scenario.TowEligible))?  true : hideToDate;

    const [showAllGpsDevices, setShowAllGpsDevices] = useState(false);

    useEffect(() => {
        setFilterTypeState(filterType ? filterType : state.dashboardFilter.type);
    }, [state.dashboardFilter.type]);

    useEffect(() => {
        setExcludeAverageState(excludeAverage === true ? excludeAverage : state.dashboardFilter.excludeAverage);
    }, [state.dashboardFilter.excludeAverage]);

    useEffect(() => {
        setCustomer();
    }, [state.session, state.session.currentUser, state.session.currentUser?.customers]);

    useEffect(() => {
        getFatureToogle(state.session.currentUser?.id, 'NBA-948-show-all-gpsdevices-option').then((response)=>{
            setShowAllGpsDevices(response);
        });
    }, []);

    useEffect(() => {
        setCustomer();
    }, [showAllGpsDevices]);

    const setCustomer= (): void =>{
        const c = state.session.currentUser ? state.session.currentUser.customers : [];
        const customerShadow = [...c];
        const location = window.location;
        if (location.pathname.includes('gpsdevices') && showAllGpsDevices) 
        {
            customerShadow.unshift({id: '00000000-0000-0000-0000-000000000000', name: 'all', code: 'all', parkingviewClientSubDomain: '', parkingviewSubDomain: '', hotlistVehicleDebtDetail: false, hotlistVehicleDebtSummary: false} )
        }
        setAllowedCustomers(customerShadow);
    };

    const autoReloadHandler = () => {
        if (!state.dashboardFilter.customerId) {
            let customerId: string | null = null;

            // Try to get current customer from cookie
            const dashboardFilterCookie: updateFilterActionPayload = (Cookies.getJSON('dashboardFilter') as unknown as updateFilterActionPayload);
            if (dashboardFilterCookie != null) {
                customerId = dashboardFilterCookie.customerId;

                // else get customer from allowed customers list
            } else {
                const initialCustomer = getInitialCustomer(allowedCustomers);
                if (initialCustomer != null) {
                    customerId = initialCustomer.id;
                }
            }

            if (customerId) {
                dispatch(updateCustomer({ customerId: customerId }));
            }
        }

        onApply();
    };

    const onClickViewNow = () => {
        setResetAutoReloadTimerFlag(() => true);
        onApply();
    };

    const changeSelectedWeekDaysHandler = (selected: string[]): void => {

        dispatch(updateWeekDays({ weekDays: selected }));
    };

    const changeSelectedOccurrencesHandler = (selected: string[]): void => {
        dispatch(updateOccurrences({ occurrences: selected }));
    };

    const closeScenarioList = (event: ChangeEvent<{}>): void => {
        setScenarioListOpen(false);
    };
    const openScenarioList = (event: ChangeEvent<{}>): void => {
        setScenarioListOpen(true);
    };

    const changeCustomer = (e: React.ChangeEvent<{name?: string | undefined; value: unknown;}>): void =>{
        dispatch(updateCustomer({ customerId: e.target.value as string }));
        window.location.reload();
    };

    return (
        <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={1} className={classes.root}>
            
            {
            showSceneSelection &&
            <Grid item xs={12} md="auto">
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel shrink className={classes.label}>Scenario</InputLabel>
                    <Select
                        displayEmpty
                        value={state.dashboardFilter.sceneType}
                        name="filterType"
                        onChange={(e) => {
                            dispatch(updateSceneType({ sceneType: e.target.value as number, description: (e.currentTarget as HTMLInputElement).innerText}))
                        }}
                        autoWidth={false}>
                        <MenuItem value={SceneType.RegularPlates}>No Scenario</MenuItem>
                        <MenuItem value={SceneType.NewPlates}>New Plates</MenuItem>
                    </Select>
                </FormControl>
            </Grid> 
            }
            <Grid id="MainGrid" item xs={12} md={12} lg="auto">
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel className={classes.label}>Customer</InputLabel>
                    <Select
                        value={state.dashboardFilter.customerId}
                        onChange={e => {changeCustomer(e)}}
                        displayEmpty
                        className={classes.select}
                        classes={{ icon: classes.selectIcon }}
                        inputProps={{ 'aria-label': 'Without label' }}
                        autoWidth={false}>
                        {
                            allowedCustomers.map((allowedCustomer) =>
                                <MenuItem value={allowedCustomer.id} key={allowedCustomer.id}>{allowedCustomer.name}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            {
            showScenarioSelection &&
            <Grid item xs={12} md="auto">
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel shrink className={classes.label}>Scenario</InputLabel>
                    <Select
                        open={scenarioListOpen}
                        onClose={closeScenarioList}
                        onOpen={openScenarioList}
                        displayEmpty
                        value={state.dashboardFilter.scenario}
                        name="ScenarioType"
                        onChange={(e) => {
                            dispatch(updateScenario({ scenario: e.target.value as number, description: (e.currentTarget as HTMLInputElement).innerText}))
                        }}
                        autoWidth={false}>
                        <MenuItem value={Scenario.Scans}>{scenarioListOpen ? <Grid container direction="row"><div style={{color: getScenarioColor(Scenario.Scans), background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)', borderRadius: '25px', width:'15px', height: '15px', margin: '5px'}}/><InputLabel className={classes.scenarioListlabel}>Reads</InputLabel></Grid>: <InputLabel  className={classes.scenarioListlabel}>Reads</InputLabel>}</MenuItem>
                        <MenuItem value={Scenario.Hits}>{scenarioListOpen ? <Grid container direction="row"><CircleIcon style={{color: getScenarioColor(Scenario.Hits)}}/><InputLabel className={classes.scenarioListlabel}>Hits</InputLabel></Grid>: <InputLabel  className={classes.scenarioListlabel}>Hits</InputLabel>}</MenuItem>
                        <MenuItem value={Scenario.Boots}>{scenarioListOpen ? <Grid container direction="row"><CircleIcon style={{color: getScenarioColor(Scenario.Boots)}}/><InputLabel className={classes.scenarioListlabel}>Boots</InputLabel></Grid>: <InputLabel  className={classes.scenarioListlabel}>Boots</InputLabel>}</MenuItem>
                        <MenuItem value={Scenario.TowEligible}>{scenarioListOpen ? <Grid container direction="row"><CircleIcon style={{color: getScenarioColor(Scenario.TowEligible)}}/><InputLabel className={classes.scenarioListlabel}>Tow Eligible</InputLabel></Grid>: <InputLabel  className={classes.scenarioListlabel}>Tow Eligible</InputLabel>}</MenuItem>
                        <MenuItem value={Scenario.Runaways}>{scenarioListOpen ? <Grid container direction="row"><CircleIcon style={{color: getScenarioColor(Scenario.Runaways)}}/><InputLabel className={classes.scenarioListlabel}>Runaways</InputLabel></Grid>: <InputLabel  className={classes.scenarioListlabel}>Runaways</InputLabel>}</MenuItem>
                    </Select>
                </FormControl>
            </Grid> 
            }
            {
                excludeAverageState === false &&
                <Grid item xs={12} md={12} lg="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel className={classes.label}>Average</InputLabel>
                        <Select
                            value={state.dashboardFilter.daysToAverage}
                            onChange={(e) => {
                                dispatch(updateDaysToAverage({ daysToAverage: e.target.value as number, description: (e.currentTarget as HTMLInputElement).innerText }))
                            }}
                            displayEmpty
                            className={classes.select}
                            classes={{ icon: classes.selectIcon }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            autoWidth={false}>
                            {
                                averageRanges.map((avg) => <MenuItem value={avg.daysToAverage} key={avg.daysToAverage}>{avg.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
            {
            !hideFromDate  && !hideFromDateBecauseScene &&
            <Grid item xs={12} md={12} lg="auto">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date From"
                        value={state.dashboardFilter.date}
                        onChange={d => dispatch(updateDate({ date: d as Date }))}
                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                        className={classes.formControl}
                        InputProps={{ className: classes.select }}
                        InputLabelProps={{ className: classes.label }}
                        SelectProps={{ className: classes.select }}
                        autoOk={true}
                        fullWidth
                        maxDate={new Date()}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            }
            {
                filterTypeState == 'range' && !hideToDate && !hideToDateBecauseScene && !hideToDateBecauseScenario &&
                <Grid item xs={12} md={12} lg="auto">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date To"
                            value={state.dashboardFilter.dateTo ? state.dashboardFilter.dateTo : state.dashboardFilter.date}
                            //onChange={d => console.log('d', d)}
                            onChange={d => dispatch(updateDateTo({ date: d as Date }))}
                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            className={classes.formControl}
                            InputProps={{ className: classes.select }}
                            InputLabelProps={{ className: classes.label }}
                            SelectProps={{ className: classes.select }}
                            autoOk={true}
                            fullWidth
                            maxDate={new Date()}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            }
            {
            showWeekDays && !hideWeekDaysBecauseScene &&
            <Grid item xs={12} md={12} lg="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Week Days</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.dashboardFilter.weekDays}
                            type="checkbox"
                            onChange={changeSelectedWeekDaysHandler}
                            selectableItems={[
                                { id: 'Monday', name: 'Monday' },
                                { id: 'Tuesday', name: 'Tuesday' },
                                { id: 'Wednesday', name: 'Wednesday' },
                                { id: 'Thursday', name: 'Thursday' },
                                { id: 'Friday', name: 'Friday' },
                                { id: 'Saturday', name: 'Saturday' },
                                { id: 'Sunday', name: 'Sunday' },
                            ]}
                            renderAllSelected={false}
                        />
                    </FormControl>
                </Grid>
            }
            {
            showOccurrences && !hideOcurrencesBecauseScene &&
                <Grid item xs={12} md={12} lg="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Occurrences</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.dashboardFilter.occurrences}
                            type="checkbox"
                            onChange={changeSelectedOccurrencesHandler}
                            selectableItems={[
                                { id: '1', name: '0-5 occurrences' },
                                { id: '2', name: '6-15 occurrences' },
                                { id: '3', name: '>15 occurrences' },
                            ]}
                            renderAllSelected={false}
                        />
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12} md={12} lg="auto">
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.applyButton}
                    // startIcon={<RefreshIcon />}
                    onClick={onClickViewNow}
                    style={{ display: hideViewButton ? 'none' : 'block' }}
                    disabled={!applyEnabled}>
                    {applyEnabled ? 'View Now' : 'Loading...'}
                </Button>
            </Grid>
            {
             !hideTimer && 
            <Grid item xs={12} md={12} lg="auto">
                <AutoReload actionToExecute={autoReloadHandler} resetTimerFlag={resetAutoReloadTimerFlag} onTimerReseted={() => setResetAutoReloadTimerFlag(false)} />
            </Grid>
            }
        </Grid>
    );
};

export { DashboardFilterBar };
export default DashboardFilterBar;
