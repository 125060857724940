import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export default function useInterval (callback: () => void, delayMs: number): void {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        let id: NodeJS.Timeout;

        if (delayMs > 0) {
            id = setInterval(() => {
                if (savedCallback.current) {
                    savedCallback.current();
                }
            }, delayMs);
        }

        return () => {
            if (id) { clearInterval(id); }
        };
    }, [delayMs]);
}
