import { Icon, Style, Stroke, Fill, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { EnforcementDataType,  MapFilterDataType,  MapMarker, Scenario } from '../../state/mapDashboardStateV2';
import { MapFilterState } from '../FilterBar/mapFilterState';


const getReadIconStyle = (marker: MapMarker, mapFilter?: MapFilterState): any => {
    
    const isScans = mapFilter != null ? mapFilter.selectedDataTypes.includes(MapFilterDataType.Scan as unknown as string) : false;

    if(marker.isLastPosition && isScans)
    {
        switch (marker.type) {
            case EnforcementDataType.Boot:
                return new Style({
                    image: new Icon({
                        color: '#BADA55',
                        crossOrigin: 'anonymous',
                        // For Internet Explorer 11
                        imgSize: [40, 40],
                        src: '/img/green-star-boot.png',
                    }),
                    zIndex: 150,
                });

            case EnforcementDataType.Hit:
                return new Style({
                    image: new Icon({
                        color: '#BADA55',
                        crossOrigin: 'anonymous',
                        // For Internet Explorer 11
                        imgSize: [40, 40],
                        src: '/img/green-star-hit.png',
                    }),
                    zIndex: 150,
                });
            case EnforcementDataType.Scan:
            default:
                return new Style({
                    image: new Icon({
                        color: '#BADA55',
                        crossOrigin: 'anonymous',
                        // For Internet Explorer 11
                        imgSize: [40, 40],
                        src: '/img/green-star.png',
                    }),
                    zIndex: 150,
                });
        }
    }
    else
    {
        switch (marker.scenarioType)
        {
            case Scenario.Scans:
                return new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: (mapFilter != null && marker != null) ? (mapFilter.selectedAgentsColors[mapFilter.selectedAgentsToSearchBy.indexOf(marker.agentId)]) : getScenarioColor(Scenario.Scans),
                        }),
                        stroke: new Stroke({
                            color: 'black',
                            width: 0.5,
                        }),
                    }),
                    zIndex: 0,
                });

            case Scenario.Hits:
                return new Style({
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: getScenarioColor(Scenario.Hits),
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 1,
                });
            case Scenario.Boots:
                return new Style({
                    image: new CircleStyle({
                        radius: 9,
                        fill: new Fill({
                            color: getScenarioColor(Scenario.Boots),
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 2,
                });
            case Scenario.Runaways:
                return new Style({
                    image: new CircleStyle({
                        radius: 9,
                        fill: new Fill({
                            color: getScenarioColor(Scenario.Runaways),
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 4,
                });
            case Scenario.TowEligible:
                return new Style({
                    image: new CircleStyle({
                        radius: 9,
                        fill: new Fill({
                            color: getScenarioColor(Scenario.TowEligible),
                        }),
                        stroke: new Stroke({
                            color: 'Black',
                            width: 2,
                        }),
                    }),
                    zIndex: 3,
                });
            default:
                return new Style({
                    image: new CircleStyle({
                        radius: 9,
                        fill: new Fill({
                            color: getScenarioColor(Scenario.Boots),
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 0,
                });
        }
    }  
}

const getScenarioColor = (scenario: Scenario): string => {
        switch (scenario)
        {
            case Scenario.Scans:
                return '#473b55';
            case Scenario.Hits:
                return '#FF6633';
            case Scenario.Boots:
                return '#C0382A';
            case Scenario.Runaways:
                return '#000';
            case Scenario.TowEligible:
                return '#D9D900';
            default:
                return '#C0382A';
        }
};

const readsLayerStyle = (marker: MapMarker, mapFilter?: MapFilterState ): Style => {
    return getReadIconStyle(marker, mapFilter);
};

const getSelectedStyle = (): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = new Style({
        
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#FBD337',
            }),
            stroke: new Stroke({
                color: 'black',
                width: 1,
            }),
        }),
        zIndex: 1000,
    });
    //style.image.radius += 2;
    return style;

};

const getMapZoneDrawingStyle = (newZoneName: string | null): Style => {

    const style = new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color: '#8C0000',
            width: 2,
        }),
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#8C0000',
            }),
        }),
        text: newZoneName != null ? createTextStyle(newZoneName) : undefined
    })
    return style;

};

const createTextStyle = function (newZoneName: string): Text {
    return new Text({
      textAlign: 'center',
      textBaseline: 'middle',
      font: '14px sans-serif',
      text: newZoneName,
      fill: new Fill({color: '#8C0000'}),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
      }),
      offsetX: 0,
      offsetY: 15,
      placement: 'line',
      maxAngle: 360,
      overflow: true,
      rotation: 0,//45 = 0.785398164, 90 = 1.570796327
    });
  };

export default readsLayerStyle;
export { getSelectedStyle, getScenarioColor, getMapZoneDrawingStyle };
