import { MultipleAverageData, TopPerformerAverageData } from '../../Commons/AverageCard/AverageData';

export default class LprSummary {
    scans: MultipleAverageData = new MultipleAverageData();
    hits: MultipleAverageData = new MultipleAverageData();
    boots: MultipleAverageData = new MultipleAverageData();
}

export function MapLprSummary (data: any): LprSummary {
    const summary = new LprSummary();

    if (data == null) {
        return summary;
    }

    summary.scans = new MultipleAverageData(data.lpr.scans.summary.total, data.lpr.scans.summary.averagePerPeriod, [['Hourly Avg.', data.lpr.scans.summary.hourlyAverage]]);
    summary.hits = new MultipleAverageData(data.lpr.hits.summary.total, data.lpr.hits.summary.averagePerPeriod, [['Hourly Avg.', data.lpr.hits.summary.hourlyAverage]]);
    summary.boots = new MultipleAverageData(data.boots.boots.summary.total, data.boots.boots.summary.averagePerPeriod, [['Hourly Avg.', data.boots.boots.summary.hourlyAverage]]);

    return summary;
}

export function MapLprTopPerformers (topPerformersData: any[]): TopPerformerAverageData[] {
    return topPerformersData.map((item) => {
        return new TopPerformerAverageData(item.name, item.total, item.averagePerPeriod, item.hourlyAverage);
    });
}
