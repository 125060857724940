import {
    AppBar,
    Box,
    Checkbox,
    Collapse,
    Divider,
    Drawer,
    FormControlLabel,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { DataGrid, GridColDef, GridRowId, GridRowSelectedParams } from '@material-ui/data-grid';
import {
    AddBox as AddIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Close as CloseIcon,
    Dashboard as DashboardIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Lock as LockIcon,
    Menu as MenuIcon,
} from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Customer, SessionState } from '../../app/session/sessionTypes';
import { ApplicationState } from '../../app/store';
import { Get, Post, Put } from '../../services/RestClient';
import { DashboardBuilderState } from '../dashboard-builder/state/dashboardBuilderState';
import { updateBuilderState } from '../dashboard-builder/state/dashboardBuilderThunks';
import { CustomDashboardTile, DashboardSetup, OpenTileCatagories } from '../dashboard-builder/types';
import { ChartType, TileModel, TileModelCategory } from '../dashboard-tile-builder/tileBuilderTypes';
import { DashboardFilterState } from '../dashboards/state/DashboardFilterState';
import { useStyles } from './LeftNavigationMenuStyles';
import { ListItemLink } from './ListItemLink';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 120, disableColumnMenu: true },
    { field: 'desc', headerName: 'Description', width: 180, disableColumnMenu: true, sortable: false }
];

export default function BuilderLeftNavigationMenu(): JSX.Element {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);

    const dispatch = useDispatch();
    const dashboardFilter = useSelector((state: ApplicationState) => state.dashboardFilter as DashboardFilterState);
    const session = useSelector((state: ApplicationState) => state.session as SessionState);
    const dashboardBuilder = useSelector((state: ApplicationState) => state.dashboardBuilder as DashboardBuilderState);
    const availableTiles = useSelector((state: ApplicationState) => state.dashboardTileBuilder.customTilesList as TileModel[]);

    const handleSelectedTileChange = (param: GridRowSelectedParams) => {
        const alreadyImported = dashboardBuilder?.selectedTiles.some(t => t.name === param.data.id);

        console.log('handleSelectedTileChange', param);

        if (param.isSelected) {
            if (!alreadyImported) {
                const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder });
                const selectedTilesData = Object.assign([], dashboardBuilderData.selectedTiles);

                const m = availableTiles.find(t => t.tileId === param.data.id);

                console.log('param.isSelected !alreadyImported', availableTiles, m, m?.chartType)

                selectedTilesData.push({
                    // id: 0,
                    id: param.data.id,
                    tileId: param.data.id,
                    name: param.data.name,
                    positionX: 0,
                    positionY: 0,
                    width: 'auto',
                    height: 'auto',
                    deleted: false,
                    // model: tileCategories[0].tiles.find(t => t.name === param.data.id)
                    chartType: (m?.chartType || ChartType.Grid),
                    model: m
                });

                dashboardBuilderData.selectedTiles = selectedTilesData;
                dispatch(updateBuilderState(dashboardBuilderData));
            } else {
                const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder });
                const selectedTilesData = Object.assign([], dashboardBuilderData.selectedTiles);
                let singleTile: any = selectedTilesData.find((t: CustomDashboardTile) => t.tileId === param.data.id);

                if (singleTile && singleTile.id > 0) {
                    singleTile = {
                        ...singleTile,
                        deleted: false,
                    };

                    const sIndex = selectedTilesData.findIndex((e: any) => e.id === singleTile.id);
                    selectedTilesData.splice(sIndex, 1, singleTile);
                    dashboardBuilderData.selectedTiles = selectedTilesData;
                    dispatch(updateBuilderState(dashboardBuilderData));
                }
            }
        } else {
            if (alreadyImported) {
                const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder });
                const selectedTilesData = Object.assign([], dashboardBuilderData.selectedTiles);
                let singleTile: any = selectedTilesData.find((t: CustomDashboardTile) => t.tileId === param.data.id);

                if (singleTile && singleTile.id > 0) {
                    singleTile = {
                        ...singleTile,
                        deleted: true,
                    };

                    const sIndex = selectedTilesData.findIndex((e: any) => e.id === singleTile.id);
                    selectedTilesData.splice(sIndex, 1, singleTile);
                    dashboardBuilderData.selectedTiles = selectedTilesData;
                    dispatch(updateBuilderState(dashboardBuilderData));
                } else {
                    const newTilesValue = dashboardBuilderData.selectedTiles.filter(t => t.tileId !== param.data.id);
                    dashboardBuilderData.selectedTiles = newTilesValue || [];
                    dispatch(updateBuilderState(dashboardBuilderData));
                }
            }
        }
    };

    const [selectedCustomerCode, setSelectedCustomerCode] = useState('paylock');
    const [selectedCustomerSubDomain, setSelectedCustomerSubDomain] = useState<null | string>(null);
    const [bootViewMapMenuOpen, setBootViewMapMenuOpen] = useState(false);

    useEffect(() => {
        let selectedCustomerCode = 'paylock';
        let selectedCustomerSubDomain = null;
        if (session.currentUser !== null) {
            const selectedCustomer = session.currentUser.customers.find(customer => customer.id === dashboardFilter.customerId);
            if (selectedCustomer) {
                selectedCustomerCode = selectedCustomer.code;
                selectedCustomerSubDomain = selectedCustomer.parkingviewSubDomain;
            }
        }

        setSelectedCustomerSubDomain(selectedCustomerSubDomain);
        setSelectedCustomerCode(selectedCustomerCode);
    }, [dashboardFilter.customerId]);

    const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
    const [myDashboardsMenuOpen, setMyDashboardsMenuOpen] = useState(true);

    const [customDashboards, setCustomDashboards] = useState([] as DashboardSetup[]);

    const handleLogoError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const images = document.getElementsByClassName('main-logo');
        for (let i = 0; i < images.length; i++) {
            images[i].setAttribute('src', '/img/customers/paylock.jpeg');
        }
    };

    // TODO: Validate customerId
    // TODO: Add loader
    const fetchCustomDashboards = () => {
        Get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/dashboardBuilder/all`,
            params: {
                customerId: null,
            },
        }).then((dashboards: DashboardSetup[]) => {
            // console.log('dashboards >>>', dashboards);
            setCustomDashboards(dashboards);
        });
    };

    useEffect(() => {
        fetchCustomDashboards();
    }, []);

    const handleDashboardNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder }, { name: event.target.value });
        dispatch(updateBuilderState(dashboardBuilderData));
    };

    const handleDashboardDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder }, { description: event.target.value });
        dispatch(updateBuilderState(dashboardBuilderData));
    };

    const handleDashboardCustomerChange = (event: React.ChangeEvent<any>) => {
        event.preventDefault();
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder }, { customerId: event.target.value });
        dispatch(updateBuilderState(dashboardBuilderData));
    };

    const handleDefaultDashboardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder }, { isDefault: event.target.checked });
        dispatch(updateBuilderState(dashboardBuilderData));
    };

    const handleMakeHomepageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder }, { isHomePage: event.target.checked });
        dispatch(updateBuilderState(dashboardBuilderData));
    };

    const handleTileSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder }, { searchTile: event.target.value });
        dispatch(updateBuilderState(dashboardBuilderData));
    };

    const [allowedCustomers, setAllowedCustomers] = useState(Array<Customer>());
    useEffect(() => {
        const c = session.currentUser ? session.currentUser.customers : [];
        setAllowedCustomers([...c]);
    }, [session, session.currentUser, session.currentUser?.customers]);

    const [openTileCategories, setOpenTileCategories] = useState({} as OpenTileCatagories);

    const toggleCategoryOpenState = (categoryId: string) => {
        openTileCategories[categoryId] = openTileCategories[categoryId] ? !openTileCategories[categoryId] : true;
        setOpenTileCategories({ ...openTileCategories });
    };

    const handleSaveAll = () => {
        const dashboardBuilderData = Object.assign({}, { ...dashboardBuilder });
        const selectedTilesData = Object.assign([], dashboardBuilderData.selectedTiles);

        console.log('selectedTilesData');
        console.log(selectedTilesData);

        const requestPayload = {
            Id: dashboardBuilderData.id,
            DashboardName: dashboardBuilderData.name,
            DashboardDescription: dashboardBuilderData.description,
            CustomerId: dashboardBuilderData.customerId,
            IsDefault: dashboardBuilderData.isDefault,
            IsHomePage: dashboardBuilderData.isHomePage,
            Tiles: selectedTilesData.map((i: CustomDashboardTile) => {
                return {
                    id: i.id,
                    tileId: i.tileId,
                    name: i.name,
                    positionX: i.positionX,
                    positionY: i.positionY,
                    width: i.width === 'auto' ? -1 : i.width,
                    height: i.height === 'auto' ? -1 : i.height,
                    deleted: i.deleted,
                    chartType: i.chartType
                };
            })
        };

        console.log('requestPayload');
        console.log(requestPayload);

        // if (requestPayload && requestPayload.DashboardName && requestPayload.CustomerId) {
        if (requestPayload && requestPayload.DashboardName) {
            const apiAction = requestPayload.Id > 0 ? Put : Post;

            apiAction({
                url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/dashboardBuilder`,
                data: requestPayload,
            }).then((dashboardId) => {
                console.log('response!', dashboardId);
                if (dashboardId && dashboardId > 0) {
                    // setBuilderMessage('Dashboard saved successfully.');
                    // history.push(`/customer-dashboards/custom/${dashboardId}`);
                    window.location.href = `/customer-dashboards/custom/${dashboardId}`;
                }
            }).catch((err) => {
                console.log('err!', err);
            });
        }
    }

    // const [tileCategories, setTilesCategories] = useState((): TileBuilder[] => [{
    //     categoryId: 'booting',
    //     name: 'Booting',
    //     tiles: []
    // }])

    // useEffect(() => {
    //     Get({
    //         url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/tile-builder/tile`,            
    //     }).then((response) => {
    //         console.log('getAllTiles', response)
    //         tileCategories[0].tiles = response;
    //         setTilesCategories(clone({ proto: true })(tileCategories))
    //     })
    // }, [])

    const tileCategories = useMemo(() => {
        return [
            {
                categoryId: TileModelCategory.Booting,
                name: 'Booting',
            },
            {
                categoryId: TileModelCategory.EasyLPR,
                name: 'Easy LPR',
            },
            {
                categoryId: TileModelCategory.HelpCenter,
                name: 'Help Center',
            }
        ]
    }, [availableTiles]);

    return (
        <Box>
            <Hidden mdUp>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    <Collapse in={hamburgerMenuOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                        {MenuItems()}
                    </Collapse>
                </AppBar>
                <List component="div" disablePadding className={classes.logoWrapper}>
                    <ListItem className={classes.logo}>
                        <img className="main-logo" src={`/img/customers/${selectedCustomerCode}.jpeg`} onError={handleLogoError} />
                    </ListItem>
                </List>
            </Hidden>
            <Hidden smDown>
                <Box boxShadow={3}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left">
                        <List component="div" disablePadding className={classes.logoWrapper}>
                            <ListItem className={classes.logo}>
                                <img className="main-logo" src={`/img/customers/${selectedCustomerCode}.jpeg`} onError={handleLogoError} />
                            </ListItem>
                        </List>
                        <Divider />
                        {MenuItems()}
                    </Drawer>
                </Box>
            </Hidden>
        </Box>
    );

    function MenuItems(): JSX.Element {
        return (
            <React.Fragment>
                <List>
                    <ListItem button onClick={() => setMyDashboardsMenuOpen(!myDashboardsMenuOpen)} className={classes.menuElement}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard Setup" />
                        {myDashboardsMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={myDashboardsMenuOpen} timeout="auto" unmountOnExit>
                        <Box id='dashboardSetupContent'>
                            <Box style={{ padding: '12px', paddingTop: 0, paddingBottom: 0 }}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    style={{ marginTop: '12px' }}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ className: classes.labelTF, shrink: true }}
                                    InputProps={{ classes: { notchedOutline: classes.inputTF }, className: classes.labelTF }}
                                    placeholder="My Custom Dashboard"
                                    value={dashboardBuilder?.name}
                                    onChange={handleDashboardNameChange} />
                                {/* <TextField
                  id="description"
                  label="Description"
                  variant="outlined"
                  style={{ marginTop: '12px' }}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ className: classes.labelTF, shrink: true }}
                  InputProps={{ classes: { notchedOutline: classes.inputTF }, className: classes.labelTF }}
                  placeholder="Custom Dashboard Example"
                  value={state?.dashboardBuilder?.description}
                  onChange={handleDashboardDescriptionChange} />
                {
                  allowedCustomers.length > 1 &&
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel>Customer</InputLabel>
                    <Select
                      value={state?.dashboardBuilder?.customerId}
                      onChange={handleDashboardCustomerChange}
                      displayEmpty
                      className={classes.select}
                      inputProps={{ 'aria-label': 'Without label' }}
                      autoWidth={false}>
                      {
                        AllAvailableCustomers.map((allowedCustomer) =>
                          <MenuItem value={allowedCustomer.id} key={allowedCustomer.id}>{allowedCustomer.name}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                } */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dashboardBuilder?.isDefault}
                                            onChange={handleDefaultDashboardChange}
                                            name="defaultDashboardCB"
                                        />
                                    }
                                    label="Default Dashboard"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dashboardBuilder?.isHomePage}
                                            onChange={handleMakeHomepageChange}
                                            name="makeHomepageCB"
                                        />
                                    }
                                    label="Make My Homepage"
                                />
                            </Box>
                            <List style={{paddingTop: '0px'}}>
                                <ListItem button onClick={() => handleSaveAll()} className={classes.menuElement}>
                                    <ListItemIcon>
                                        <LockIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Permissions" />
                                </ListItem>
                            </List>
                        </Box>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => setBootViewMapMenuOpen(!bootViewMapMenuOpen)} className={classes.menuElement}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tiles" />
                        {bootViewMapMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={bootViewMapMenuOpen} timeout="auto" unmountOnExit>
                        <Box id='availableTilesContent'>
                            <Box style={{ padding: '12px', paddingTop: 0, paddingBottom: 0 }}>
                                <TextField
                                    id="tileSearch"
                                    label="Search Tile"
                                    variant="outlined"
                                    style={{ marginTop: '12px' }}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ className: classes.labelTF, shrink: true }}
                                    InputProps={{ classes: { notchedOutline: classes.inputTF }, className: classes.labelTF }}
                                    placeholder="Enter a name to search"
                                    value={dashboardBuilder?.searchTile}
                                    onChange={handleTileSearchChange} />
                            </Box>
                            <Divider style={{ marginTop: '6px', marginBottom: 0 }} />
                            <List>
                                {
                                    // state?.dashboardBuilder?.availableTiles.map((category: TileBuilder) =>
                                    tileCategories.map((tileCategory: any) =>
                                        <React.Fragment key={tileCategory.categoryId}>
                                            <ListItem button onClick={() => { toggleCategoryOpenState(tileCategory.categoryId); }}>
                                                {/* <ListItemText primary={`${category.name} (${category.tiles.length})`} /> */}
                                                <ListItemText primary={`${tileCategory.name}`} />
                                                {openTileCategories[tileCategory.categoryId] === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                            </ListItem>
                                            {
                                                openTileCategories[tileCategory.categoryId] === true &&
                                                <Modal
                                                    className={classes.tileModal}
                                                    style={modalStyle}
                                                    open={openTileCategories[tileCategory.categoryId] === true}
                                                    onClose={() => toggleCategoryOpenState(tileCategory.categoryId)}
                                                    aria-labelledby="simple-modal-title"
                                                    aria-describedby="simple-modal-description">
                                                    <React.Fragment>
                                                        <Box className={`${classes.rightBox} ${classes.box}`}>
                                                            <IconButton aria-label="close" onClick={() => toggleCategoryOpenState(tileCategory.categoryId)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                        <DataGrid
                                                            rows={availableTiles.filter((tile) => tile.categoryId === tileCategory.categoryId).filter((tile: TileModel) =>
                                                                dashboardBuilder?.searchTile === ''
                                                                || tile.name.toLowerCase().includes(dashboardBuilder?.searchTile?.toLowerCase()))
                                                                .map((tile) =>
                                                                    Object.assign({ id: tile.tileId, tileId: tile.tileId, name: tile.name, desc: tile.name })
                                                                )}
                                                            columns={columns}
                                                            pageSize={5}
                                                            checkboxSelection
                                                            disableSelectionOnClick
                                                            selectionModel={dashboardBuilder?.selectedTiles.map((st: CustomDashboardTile) => st.deleted ? 'na' as GridRowId : st.tileId as GridRowId)}
                                                            onRowSelected={handleSelectedTileChange}
                                                            hideFooter={true}
                                                        />
                                                    </React.Fragment>
                                                </Modal>
                                            }
                                            <Divider />
                                        </React.Fragment>
                                    )
                                }
                            </List>
                        </Box>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => handleSaveAll()} className={classes.menuElement}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Save" />
                    </ListItem>
                    <ListItemLink primary="Preview" to="/" icon={<AddIcon />} />
                    <ListItemLink primary="Exit" to="/" icon={<AddIcon />} />
                </List>
                {/* {customComponent} */}
            </React.Fragment>
        );
    }
}
