import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    formControl: {
        marginRight: theme.spacing(1),
        minWidth: 120,
    },
    select: {
        margin: 0,
        width: 200,
        minWidth: 160,
        '&:before': {
            // borderBottom: '2px solid ' + theme.palette.secondary.main
        },
    },
}));
