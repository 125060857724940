import { Typography, IconButton } from '@material-ui/core';
import {
  Settings as SettingsIcon,
} from '@material-ui/icons';
import React from 'react';
import { TileEditMode } from '../types';
import useStyles from './ChartTitle.styles';

interface ChartTitleProps {
  mode: TileEditMode;
  title: string;
  onSettingsClick?: () => void;
}

const ChartTitle = (props: ChartTitleProps): JSX.Element => {
  const {
    mode,
    title,
    onSettingsClick
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.title}>
      <Typography variant="button">
        {/* {
          mode === TileEditMode.DashboardBuilder && onSettingsClick &&
          <IconButton className={classes.iconSettings} aria-label="settings" onClick={(e) => onSettingsClick()}>
            <SettingsIcon />
          </IconButton>
        } */}
        {title}
      </Typography>
    </div>    
  );
}

export default ChartTitle;