import { BootingOfficer, MapFilterSearchBy, ParkingArea, MapFilterDataType, MapLayerType, MapSourceType, noDebtRange, debtRangeOne, debtRangeTwo, debtRangeThree, debtRangeFour, debtRangeFive } from './mapDashboardStateV2';
import clone from 'rfdc';
import Cookies from 'js-cookie';
import { LocalStatePayloadAction } from '../../../../app/types';

export type mapZoneAssignState = {
    enforcementZones: string[];
    selectedAgentsToSearchBy: string[];
    weekDay: string[];
    selectedWeekDay: string;
}

const ACTION_TYPES = {
    UPDATE_EFORCEMENT_ZONE: 'UPDATE_EFORCEMENT_ZONE',
    UPDATE_SELECTED_AGENTS: 'UPDATE_SELECTED_AGENTS',
    UPDATE_WEEK_DAY: 'UPDATE_WEEK_DAY',
    CHANGE_WEEK_DAY: 'CHANGE_WEEK_DAY',
};

const cloneFilterState = (state: mapZoneAssignState): mapZoneAssignState => {
    return clone({ proto: true })(state);
};




/*const UpdateSelectedStatus = (state: MapFilterState, status: number): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.status = status;
    return newState;
};*/



/*const UpdateSelectedToTime = (state: MapFilterState, selected: string): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.toTime = selected;
    return newState;
};*/

const UpdateSelectedAgents = (state: mapZoneAssignState, selected: string[]): mapZoneAssignState => {
    const newState = cloneFilterState(state);
    newState.selectedAgentsToSearchBy = [...selected];
    return newState;
};

const UpdateEnforcementZone = (state: mapZoneAssignState, selected: string[]): mapZoneAssignState => {
    const newState = cloneFilterState(state);
    newState.enforcementZones = selected;
    return newState;
};

const UpdateWeekDay = (state: mapZoneAssignState, selected: string[]): mapZoneAssignState => {
    const newState = cloneFilterState(state);
    newState.weekDay = [...selected];
    return newState;
};

const ChangeWeekDay = (state: mapZoneAssignState, selected: string): mapZoneAssignState => {
    const newState = cloneFilterState(state);
    newState.selectedWeekDay = selected;
    return newState;
};



const mapZoneAssignReducer = (state: mapZoneAssignState, action: LocalStatePayloadAction): mapZoneAssignState => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_EFORCEMENT_ZONE:
            return UpdateEnforcementZone(state, action.payload);
        case ACTION_TYPES.UPDATE_SELECTED_AGENTS:
            return UpdateSelectedAgents(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_WEEK_DAY:
            return UpdateWeekDay(state, action.payload as string[]);
        case ACTION_TYPES.CHANGE_WEEK_DAY:
            return ChangeWeekDay(state, action.payload as string);
            
       default:
            return state;
    }
};

const mapZoneAssignStateInit = (params: {
    //enforcementZones: BootingOfficer[];
}): mapZoneAssignState => {
    //const { bootingOfficers = [], parkingAreas = [] } = params;
    
   
    return {
        enforcementZones: [],
        selectedAgentsToSearchBy: [],
        weekDay: [],
        selectedWeekDay: 'All',
    };
    

    
};

export { ACTION_TYPES, mapZoneAssignReducer, mapZoneAssignStateInit };
