import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        height: '100%',
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#1976d2',
            color: '#eae9ef',
        },
    },
    contentWrapper: {
        marginTop: theme.spacing(1),
    },
    openLayersMapWrapper: {
        position: 'relative',
        boxSizing: 'border-box',
    },
    markersListWrapper: {
        flexGrow: 1,
    },
}));
