import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions } from '@material-ui/core';
import { useStyles } from './confirmDialogStyles';

type ConfirmDialogProps = {
    isOpen: boolean;
    onAccept: () => any;
    onDeny: () => any;
    message: string;
}

type ConfirmState = {
    openWarning: boolean;
    onDenyCallback?: () => any;
    onAcceptCallback?: () => any;
    warningMessage?: string;
    usingProps: boolean;
}

//this allow to call the modal from outside an jsx component
let toggleModal = (openDialog: boolean, messageDialog?: string, onAcceptDialog?: () => any, onDenyDialog?: () => any) => {console.log('Not Implemented yet')};

const ConfirmDialog = (props: ConfirmDialogProps): JSX.Element => {
    const { isOpen, onAccept, onDeny, message } = props;
    const classes = useStyles();

    const [state, setState] = useState<ConfirmState>({openWarning: isOpen, warningMessage: message, onAcceptCallback: onAccept, onDenyCallback: onDeny, usingProps: true});
    

    toggleModal = (openDialog: boolean, messageDialog?: string, onAcceptDialog?: () => any, onDenyDialog?: () => any) => {
        setState({...state, openWarning: openDialog, warningMessage: messageDialog, onAcceptCallback: onAcceptDialog, onDenyCallback: onDenyDialog, usingProps: openDialog ? false : true});//usingProp needs to be reseted to true when finished using ouside jsx component so it can be used normally
    };

    useEffect(()=>{
        if(state.usingProps)//when calling the dialog outside jsx component we don't need to use the props change.
        {
            setState({...state, openWarning: isOpen, warningMessage: message, onAcceptCallback: onAccept, onDenyCallback: onDeny});
        }
    },[props]);

    return (
        <Dialog
            open={state.openWarning}
            //onClose={onClose}
            //onRendered={onRendered}
            id='simple-modal-title'
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            //PaperComponent={dialogRender}
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}
            hideBackdrop={true}>
            <DialogTitle id='alert-dialog-title'>
                {'Warning'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {state.warningMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={state.onAcceptCallback} autoFocus>Yes</Button>
                <Button onClick={state.onDenyCallback}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export { toggleModal }
export default ConfirmDialog;
