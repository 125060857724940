import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        boxSizing: 'border-box',
        textAlign: 'left',
        flexGrow: 1,
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    card: {
        width: '100%',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'rgba(51, 38, 67, .10)',
        },
        color: '#263238',
    },
    cardContent: {
        padding: theme.spacing(1),
        paddingBottom: `${theme.spacing(1)} !important`,
    },
    plate: {
        fontWeight: 'bold',
    },
    boot: {
        color: 'red',
    },
    hit: {
        color: 'orange',
    },
    subText: {
        fontWeight: 'bold',
        fontSize: '12.5px',
        [theme.breakpoints.up('xs')]: {
            fontSize: '12.5px',
        },
        [theme.breakpoints.up(1280)]: {//>1280
            fontSize: '11.5px',
            maxWidth: '79px',
        },
        [theme.breakpoints.up(1666)]: {//>1620
            fontSize: '12.5px',
            maxWidth: '200px',
        },
    },
}));
