import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        '&.success': {
            // '& .MuiDivider-root': {
            //     backgroundColor: theme.palette.success.main,
            // },
            '& .value, .file-date-message': {
                color: theme.palette.success.main,
            },
        },
        '&.warning': {
            // '& .MuiDivider-root': {
            //     backgroundColor: theme.palette.warning.main,
            // },
            '& .value, .file-date-message': {
                color: theme.palette.warning.main,
            },
        },
        '&.error': {
            // '& .MuiDivider-root': {
            //     backgroundColor: theme.palette.error.main,
            // },
            '& .value, .file-date-message': {
                color: theme.palette.error.main,
            },
        },
    },
    divider: {
        height: '1px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(1),
        '&:last-child': {
          paddingBottom: theme.spacing(1),
        },
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        },
      },
      mainLabels: {
        '& .MuiTypography-root': {
          fontWeight: '500',
        },
      },
      secondaryLabels: {
        color: theme.palette.grey[700],
      },
}));
