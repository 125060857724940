
export const removeTimeFromDate = (date: string | Date) => {
    let d: Date;
    if (typeof(date) === 'string') {
        d = new Date(date);
    } else if (date) {
        d = date;
    } else {
        d = new Date();
    }
    
    return new Date(d.setHours(0,0,0,0));
}
