import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    modalContent: {
        background: 'white',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            width: '310px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '480px',
        },
        padding: '10px',
        '&:focus': {
            outline: 'none',
        },
        '& > .MuiTypography-root': {
            marginBottom: theme.spacing(.5),
        },
        '& > .MuiButtonBase-root': {
            marginRight: theme.spacing(1),
        },
    },
    hideComponent:{
        display: 'none'
    },
    map:{
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            height: '100px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '150px',
        },
        backgroundColor: '#fff',
        position: 'relative',
        boxSizing: 'border-box',
    },

    historyMap:{
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            height: '300px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '350px',
        },
        backgroundColor: '#fff',
        position: 'relative',
        boxSizing: 'border-box',
    },
    tooltip:{
        //width: '130px',
        height: '50px',
        padding: '5px',
        textAlign: 'center',
        backgroundColor: '#fff',
        position: 'relative',
        boxSizing: 'border-box',
    },
    subText:{
        marginTop: '7px',
        marginLeft: '5px',
    },
    readButton:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formControl: {
        margin: 0,
        minWidth: 60,
        color: theme.palette.primary.dark,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: 0,
            marginBottom: 0,
        },
    },
    label: {
        color: theme.palette.primary.dark,
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
    },
    confirmedStatusLabel: {
        color: 'green',
        marginTop: '15px',
        marginLeft: '5px',
        fontSize: '12px',
    },
    pendingStatusLabel: {
        color: '#e09f00',
        marginTop: '15px',
        marginLeft: '5px',
        fontSize: '12px',
    },
    controls:{
        margin: '5px'
    },
    red: {
        color: 'red',
    },
    orange: {
        color: 'orange',
    },
    green: {
        color: 'green',
    },
    black: {
        color: 'black',
    },
    tabs: {
        [theme.breakpoints.up('xs')]: {
            width: '310px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '420px',
        },
    },
}));
