import { createSlice } from '@reduxjs/toolkit';
import { MapMarker, MapDashboardStateModel, MapFilterSource } from './mapDashboardStateV2';
import { updateMapStateAction, updateLiveMapStateAction, selectMarkerAction, unselectMarkerAction, setLoadingAction, setErrorAction,  applyFilterAction, changePageOnFilterAction, setFilterSourceAction, updateEnforcementZonesAction, updateEnforcementZonesAssignationAction, updateBootviewUserAction } from './mapDashboardActions';

const mapOptiRouteDashboardSlice = createSlice({
    name: 'mapOptiRouteDashboard',
    initialState: {
        isLoading: false,
        error: null,
        markers: Array<MapMarker>(),
        pagination: {
            currentPage: 1,
            totalPages: 0,
        },
        selectedMarker: undefined,
        filterSource: {
            bootingOfficers: [],
            parkingAreas: [],
            customerLongitude: -100.964709,
            customerLatitude: 39.259336,
        } as MapFilterSource,
    } as MapDashboardStateModel,
    reducers: {
        setLoading: setLoadingAction,
        setError: setErrorAction,
        setFilterSource: setFilterSourceAction,
        applyFilter: applyFilterAction,
        changePageOnFilter: changePageOnFilterAction,
        updateMapState: updateMapStateAction,
        updateEnforcementZoneState: updateEnforcementZonesAction,
        updateBootviewUserState: updateBootviewUserAction,
        updateEnforcementZoneAssignationState: updateEnforcementZonesAssignationAction,
        updateLiveMapState: updateLiveMapStateAction,
        selectMarker: selectMarkerAction,
        unselectMarker: unselectMarkerAction,
    },
});

export const { reducer } = mapOptiRouteDashboardSlice;
export const { setLoading, setError, setFilterSource, updateMapState, updateLiveMapState, applyFilter, changePageOnFilter, selectMarker, unselectMarker, updateEnforcementZoneState, updateEnforcementZoneAssignationState, updateBootviewUserState } = mapOptiRouteDashboardSlice.actions;
