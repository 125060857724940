import React from 'react';
import { Link, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';

type ListItemLinkProps = {
    icon?: JSX.Element;
    primary: string;
    to?: string;
    exact?: boolean;
    openTab?: boolean;
    href?: string;
    isSubmenu?: boolean;
};

export const useStyles = makeStyles<Theme, ListItemLinkProps>((theme) => ({
    menuElement: {
        '& .MuiListItemIcon-root': {
            marginRight: '.5rem !important',
            minWidth: (props: ListItemLinkProps) => props.icon ? '0px' : '1.5rem !important',
        },
    },
    submenuElement: {
        paddingLeft: theme.spacing(9.25),
        '& .MuiTypography-root': {
            paddingBottom: theme.spacing(.6),
            paddingTop: theme.spacing(.6),

            '& > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
                paddingTop: theme.spacing(2),
            },
        },
    },
}));

export function ListItemLink(props: ListItemLinkProps): JSX.Element {
    const classes = useStyles(props);
    const {
        icon,
        primary,
        to,
        openTab,
        href,
        isSubmenu
    } = props;

    // eslint-disable-next-line react/display-name
    const CustomLink = React.useMemo(() => React.forwardRef((linkProps: any, ref: any) => (openTab ? <RouteLink rel="noopener noreferrer" target="_blank" ref={ref} to={to} {...linkProps} /> : <RouteLink ref={ref} to={to} {...linkProps} />)), [to]);

    const ListItemComponent = to ? CustomLink : Link;

    return (
        <ListItem button component={ListItemComponent} underline="none" className={isSubmenu ? classes.submenuElement : classes.menuElement}>
            {
                to &&
                <>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={primary} />
                </>
            }
            {
                href &&
                <>
                    <Link rel="noopener noreferrer" target="_blank" href={href} className="btn" underline="none">
                        {primary}
                    </Link>
                </>
            }
        </ListItem>
    )
}
