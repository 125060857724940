import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    colorCodes: {
        marginRight: theme.spacing(4),
        position: 'relative',
        marginBottom: '12px',
        paddingTop: '12px'
    },
    successCode: {
        backgroundColor: '#28A745',
        color: '#fff',
        marginRight: theme.spacing(1),
    },
    warningCode: {
        backgroundColor: '#FFC107',
        color: '#000',
        marginRight: theme.spacing(1),
    },
    dangerCode: {
        backgroundColor: '#DC3545',
        color: '#fff',
    },
}));
