import { PayloadAction } from '@reduxjs/toolkit';
import { EnforcementDashboardState } from './EnforcementDashboardState';
import { MapLprSummary, MapLprTopPerformers } from '../Lpr/LprSummary';
import { MapOverTimeFromDTO, MergeOverTimeData } from '../OverTimeTable/OverTimeMappers';

export const updateAllAction = (state: EnforcementDashboardState, action: PayloadAction<any>): EnforcementDashboardState => {
    const payload = action.payload;

    const newState = {
        lpr: MapLprSummary(payload),
        overTimeData: MapOverTimeFromDTO(payload.overTimeData),
        scans: {
            topPerformers: MapLprTopPerformers(payload.lpr.scans.topPerformers),
            topPerformersOTD: MapOverTimeFromDTO(payload.lpr.scans.topPerformersOverTime),
            topZones: MapLprTopPerformers(payload.lpr.scans.topZones),
            topZonesOTD: MapOverTimeFromDTO(payload.lpr.scans.topZonesOverTime),
        },
        hits: {
            topPerformers: MapLprTopPerformers(payload.lpr.hits.topPerformers),
            topPerformersOTD: MapOverTimeFromDTO(payload.lpr.hits.topPerformersOverTime),
            topZones: MapLprTopPerformers(payload.lpr.hits.topZones),
            topZonesOTD: MapOverTimeFromDTO(payload.lpr.hits.topZonesOverTime),
        },
        boots: {
            topPerformers: MapLprTopPerformers(payload.boots.boots.topPerformers),
            topPerformersOTD: MapOverTimeFromDTO(payload.boots.boots.topPerformersOverTime),
            topZones: MapLprTopPerformers(payload.boots.boots.topZones),
            topZonesOTD: MapOverTimeFromDTO(payload.boots.boots.topZonesOverTime),
        },
    };

    const mergedTopPerformersOTD = MergeOverTimeData(
        { type: 'Scans', otd: newState.scans.topPerformersOTD }, { type: 'Hits', otd: newState.hits.topPerformersOTD }, { type: 'Boots', otd: newState.boots.topPerformersOTD });
    const mergedTopZonesOTD = MergeOverTimeData(
        { type: 'Scans', otd: newState.scans.topZonesOTD }, { type: 'Hits', otd: newState.hits.topZonesOTD }, { type: 'Boots', otd: newState.boots.topZonesOTD });

    return {
        ...state,
        isLoading: false,
        error: null,
        lpr: newState.lpr,
        overTimeData: newState.overTimeData,
        activeTab: state.activeTab,
        scans: newState.scans,
        hits: newState.hits,
        boots: newState.boots,
        topPerformersOTD: mergedTopPerformersOTD,
        topZonesOTD: mergedTopZonesOTD,
    };
};
