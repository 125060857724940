import AverageData from '../../Commons/AverageCard/AverageData';
import { DashboardModel } from '../../Commons/types';
export default class EnforcementSummary implements DashboardModel {
    isLoading = false;
    error: string | null = null;
    lpr: LprSummary = new LprSummary();
    boots: BootsSummary = new BootsSummary();

    constructor (
        isLoading?: boolean,
        error?: string | null,
        lpr?: LprSummary,
        boots?: BootsSummary) {
        this.isLoading = isLoading ?? false;
        this.error = error ?? null;
        this.lpr = lpr ?? new LprSummary();
        this.boots = boots ?? new BootsSummary();
    }
}

export class LprSummary {
    scans: EnforcementDetail = new EnforcementDetail();
    hits: EnforcementDetail = new EnforcementDetail();
}

export class BootsSummary {
    boots: EnforcementDetail = new EnforcementDetail();
    bootsOnACar = 0;
    bootsAvailable = 0;
}

export class EnforcementDetail {
    summary: AverageData = new AverageData();
}

export class EnforcementSummaryOld {
    scans: AverageData = new AverageData();
    hits: AverageData = new AverageData();
}

export class LprStatsSummary implements DashboardModel {
    isLoading: boolean;
    error: string | null;
    scans: AverageData = new AverageData();
    hits: AverageData = new AverageData();
    boots: AverageData = new AverageData();

    constructor (
        isLoading?: boolean,
        error?: string | null,
        scans?: AverageData,
        hits?: AverageData,
        boots?: AverageData) {
        this.isLoading = isLoading ?? false;
        this.error = error ?? null;
        this.scans = scans ?? new AverageData();
        this.hits = hits ?? new AverageData();
        this.boots = boots ?? new AverageData();
    }
}

export class BootsStatsSummary implements DashboardModel {
    isLoading: boolean;
    error: string | null;
    onCar: number;
    available: number;


    constructor (
        isLoading?: boolean,
        error?: string | null,
        onCar?: number,
        available?: number) {
        this.isLoading = isLoading ?? false;
        this.error = error ?? null;
        this.onCar = onCar ?? 0;
        this.available = available ?? 0;
    }
}