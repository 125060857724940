export default class OverTimeData {
    type: number;
    typeName: string;
    rows: Array<OverTimeRow> = new Array<OverTimeRow>();

    constructor (type?: number | undefined, typeName?: string | undefined) {
        this.type = type ? type : 0;
        this.typeName = typeName ? typeName : 'Consolidated';
    }
}

export class OverTimeHeader {
    name: string;
    ordinal: number;

    constructor (name: string, ordinal: number) {
        this.name = name;
        this.ordinal = ordinal;
    }
}

export class OverTimeColumn {
    ordinal: number;
    totalEvents: number;

    constructor (ordinal: number, totalEvents: number) {
        this.ordinal = ordinal;
        this.totalEvents = totalEvents;
    }
}

export class OverTimeRow {
    name: string;
    ordinal: number;
    columns: Array<OverTimeColumn>;
    type?: string;

    constructor (name: string, ordinal: number, items: Array<OverTimeColumn>, type?: string) {
        this.name = name;
        this.ordinal = ordinal;
        this.columns = items;
        this.type = type;
    }
}

export class DataGridRow {
    data: string | undefined;
    dataType: string | undefined;
    [key: string]: any;
}

export enum TableType {
    Consolidated = 0,
    AM = 1,
    PM = 2,
    Range = 3
}
