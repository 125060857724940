import { DashboardFilterState } from './DashboardFilterState';
import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export type updateFilterActionPayload = {
    scenario: number;
    sceneType: number;
    customerId: string;
    type: 'average' | 'range';
    daysToAverage: number;
    date: Date;
    dateTo: Date | null;
    excludeAverage: boolean; 
}

export const updateFilterAction = (state: DashboardFilterState, action: PayloadAction<updateFilterActionPayload>): DashboardFilterState => {
    const dateFrom = moment(action.payload.date).format('YYYY/MM/DD hh:mm a');
    const dateTo = action.payload.dateTo ? moment(action.payload.dateTo).format('YYYY/MM/DD hh:mm a') : null;

    return {
        ...state,
        scenario: action.payload.scenario,
        sceneType: action.payload.sceneType,
        customerId: action.payload.customerId,
        daysToAverage: action.payload.daysToAverage,
        type: action.payload.type,
        date: dateFrom,
        dateTo: dateTo,
        excludeAverage: action.payload.excludeAverage
    };
};

export const updateFilterTypeAction = (state: DashboardFilterState, action: PayloadAction<({ filterType: 'average' | 'range'; excludeAverage?: boolean })>): DashboardFilterState => {
    const dateFrom = moment(new Date()).format('YYYY/MM/DD hh:mm a');
    const dateTo = moment(new Date()).format('YYYY/MM/DD hh:mm a');

    return {
        ...state,
        type: action.payload.filterType,
        date: dateFrom,
        dateTo: dateTo,
        excludeAverage: action.payload.excludeAverage ?? false,
    };
};
