import { Card, CardContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { executeSqlQueryOnServer } from '../state/tileBuilderThunks';
import { ChartType, getChartTypeName, SqlResult, TileModel, TileParamaterValues, TileSettingsTestValues } from '../tileBuilderTypes';
import ChartLoader from './ChartLoader/ChartLoader';
import ChartTitle from './ChartTitle/ChartTitle';
import ChartUserSettings from './ChartUserSettings/ChartUserSettings';
import { TileControllerState, TileEditMode, TileUserSettings } from './types';

interface TileControllerProps {
    tileModel: TileModel;
    dashboardId?: number;
    chartType?: ChartType;
    paramValues?: TileSettingsTestValues | TileParamaterValues;
    mode?: TileEditMode;
    onResizeTile?: (e: any, dir: any, ref: HTMLElement, delta: ResizableDelta, position: Position) => void;
    onDragTile?: (e: any, data: any) => void;
    onChartTypeSelected?: (chartType: ChartType) => void;
    onChartUserSettingsChange?: (settings: TileUserSettings) => void;
}

export const TileController = (props: TileControllerProps): JSX.Element => {
    const classes = useStyles();

    const {
        tileModel = {} as TileModel,
        chartType,
        paramValues,
        mode = TileEditMode.None,
        onResizeTile,
        onDragTile,
        onChartTypeSelected,
        onChartUserSettingsChange,
    } = props;

    const [isLoadingSqlResult, setIsLoadingSqlResult] = useState(false);
    const [sqlResult, setSqlResult] = useState((): SqlResult | null => null);

    const rndRef = useRef<Rnd>(null);

    const [isOpenUserSettings, toggleIsOpenUserSettings] = useState(false);
    const [userSettings, setUserSettings] = useState({} as TileUserSettings);

    const importComponent = (name: string) => lazy(() => import('./' + name + '/' + name).then(module => ({ default: module.default })));

    const [chartState, setChartState] = useState({
        Component: importComponent('EmptyChart')
    } as TileControllerState)

    useEffect(() => {
        const chartTypeName = getChartTypeName(chartType || tileModel.chartType);
        setChartState({
            Component: importComponent(chartTypeName)
        });

        if (rndRef != null && rndRef.current != null) {
            rndRef.current.updateSize({ width: tileModel.width, height: tileModel.height });
            rndRef.current.updatePosition({ x: tileModel.positionX, y: tileModel.positionY });
        }
    }, [chartType, tileModel.chartType, tileModel.width, tileModel.height, tileModel.positionX, tileModel.positionY])

    useEffect(() => {
        if (isLoadingSqlResult || !tileModel.databaseId || !tileModel.sqlQuery) {
            return;
        }

        setIsLoadingSqlResult(true);
        executeSqlQueryOnServer(tileModel.databaseId, tileModel.sqlQuery, paramValues ?? (tileModel.includedParams ?? {}))
            .then((response) => {
                setSqlResult(response);
            })
            .catch((err) => {
                console.log('Error while trying to fetch sql result from server.', err)
            })
            .finally(() => {
                setIsLoadingSqlResult(false);
            });
    }, [tileModel.databaseId, tileModel.sqlQuery, tileModel.includedParams, paramValues?.customerId, paramValues?.date, paramValues?.dateTo])

    const chartTypeChangeHandler = (chartType: ChartType) => {
        toggleIsOpenUserSettings(false);

        if (onChartTypeSelected) {
            onChartTypeSelected(chartType);
        }
    };

    const chartUserSettingsChangeHandler = (settings: TileUserSettings) => {
        if (onChartUserSettingsChange) {
            onChartUserSettingsChange(settings);
        }
    }

    return (
        <Suspense fallback={<Alert severity="info">Loading tile, please wait...</Alert>}>
            <Rnd
                cancel='.MuiIconButton-root, #tileSettingsModal'
                key={`key-${tileModel.tileId}`}
                data-id={tileModel.tileId}
                data-name={tileModel.name}
                default={{
                    x: tileModel.positionX,
                    y: tileModel.positionY,
                    width: tileModel.width,
                    height: tileModel.height,
                }}
                minWidth={300}
                minHeight={100}
                onDragStop={onDragTile}
                onResizeStop={onResizeTile}
                bounds="parent"
                enableResizing={mode !== TileEditMode.None}
                disableDragging={mode !== TileEditMode.DashboardBuilder}
                ref={rndRef}
                className={classes.rnd}
            >
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        {
                            !isLoadingSqlResult ?
                                <>
                                    <ChartTitle title={tileModel.name} mode={mode} onSettingsClick={() => toggleIsOpenUserSettings(true)} />
                                    <chartState.Component
                                        sqlResult={sqlResult}
                                        fieldsColumns={tileModel.fieldsColumns}
                                        mode={mode}
                                    />
                                </> :
                                <ChartLoader />
                        }
                    </CardContent>
                </Card>
            </Rnd>
            <ChartUserSettings
                isOpen={isOpenUserSettings}
                onClose={() => toggleIsOpenUserSettings(false)}
                onSave={chartUserSettingsChangeHandler}
                onChartTypeSelected={chartTypeChangeHandler}
            />
        </Suspense>
    )
}

const useStyles = makeStyles((theme) => ({
    rnd: {
        zIndex: 1,
    },
    card: {
        width: '100%',
        height: '100%',
    },
    cardContent: {
        height: '100%',
        boxSizing: 'border-box',
    },
}));

export default TileController;
