/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react';
import { MapSearchResult, MapFilterSource, GPSDeviceBehaviorResult, GPSDeviceMessageResult, GPSDeviceAlertResult } from './mapDashboardStateV2';
import { updateMapState, setError, applyFilter, changePageOnFilter, setFilterSource, setListLoading } from './mapGPSDevicesMapDashboardcDashboardSlice';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import RestClient, { RestClientV2 } from '../../../../services/RestClient';
import { ApplicationState } from '../../../../app/store';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';


const fetchMapFilterSource = (customerId: string): Promise<MapFilterSource> => {
    return new Promise((resolve, reject) => {
        RestClient.get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/filterSource`,
            params: {
                customerId: customerId,
            },
        }).then((response: any) => {
            resolve(response.data as MapFilterSource);
        }).catch((err) => {
            reject(err);
        });
    });
};

const initializeMapFilterSource = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        const state = getState();
        if (!state.dashboardFilter.customerId) {
            return Promise.resolve();
        }

        return fetchMapFilterSource(getState().dashboardFilter.customerId).then((filterSource: MapFilterSource) => {
            dispatch(setFilterSource(filterSource));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const fetchMapMarkersFromAPI = (mapFilter: MapFilterState, dashboardFilter: DashboardFilterState): Promise<MapSearchResult> => {
    return new Promise((resolve, reject) => {
        console.log('fetchMapMarkersFromAPI mapFilter: ', mapFilter);

        //change local date to server so initial time matches
        const dt = new Date(dashboardFilter.date);
        dt.setSeconds( dt.getSeconds() - 180 );
        const ETdate = (dt).toLocaleString('en-Us', {timeZone: 'America/New_York'});

        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/GPSDevicesDevices`,
            data: {
                customerId: dashboardFilter.customerId,
            },
        }).then((response: any) => {
            console.log('GPSDevices response: ', response);
            resolve(response as MapSearchResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

const applyFilterAndFetchMarkers = (mapFilter: MapFilterState) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        mapFilter.currentPage = getState().mapDashboard.filter ? getState().mapDashboard.filter.currentPage : 1;
        console.log('filter before apply', mapFilter);
        dispatch(applyFilter(mapFilter));

        const dashboardFilter = getState().dashboardFilter;
        //dashboardFilter.date = Date.now.toString();

        return fetchMapMarkersFromAPI(mapFilter, getState().dashboardFilter).then((mapSearchResult: MapSearchResult) => {
            console.log('mapSearchResult >>', mapSearchResult)
            dispatch(updateMapState(mapSearchResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};


const changePageAndFetchMarkers = (page: number) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): void => {
        dispatch(changePageOnFilter(page));

        // const state = getState();

        // return fetchMapMarkersFromAPI(state.mapDashboard.filter, state.dashboardFilter).then((mapSearchResult: MapSearchResult) => {
        //     dispatch(updateMapState(mapSearchResult));
        // }).catch((err: string) => {
        //     dispatch(setError(err));
        // });
    };
};

const getDeviceMessagesList = (imei: string, messageDays: number): Promise<GPSDeviceMessageResult> => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - messageDays)
    return new Promise((resolve, reject) => {
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/GPSDeviceMessages`,
            data: {
                imei: imei,
                startDate: start,
                endDate: now,
                limit: 1000//1000 max allowed for the application
            },
        }).then((response: any) => {
            resolve(response as GPSDeviceMessageResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

const getDeviceAlertsList = (imei: string, alertDays: number, types: string): Promise<GPSDeviceAlertResult> => {
    const now = new Date();
    const start = new Date();
    start.setDate(now.getDate() - alertDays)
    return new Promise((resolve, reject) => {
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/GPSDeviceAlerts`,
            data: {
                imei: imei,
                startDate: start,
                endDate: now,
                limit: 100,//100 max allowed for the application
                types: types
            },
        }).then((response: any) => {
            resolve(response as GPSDeviceAlertResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

const sendBehavior = (name: string, imei: string, params: Record<string, any>): Promise<GPSDeviceBehaviorResult> => {
    return new Promise((resolve, reject) => {
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/GPSDevicesSendBehavior`,
            data: {
                imei: imei,
                name: name,
                parameters: params,
                highPriority: true,
            },
        }).then((response: any) => {
            console.log('GPSDevices response: ', response);
            resolve(response as GPSDeviceBehaviorResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

export { initializeMapFilterSource, applyFilterAndFetchMarkers, changePageAndFetchMarkers, sendBehavior, getDeviceMessagesList, getDeviceAlertsList };
