import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { OpenInNew as ExpandSqlQueryIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../app/store';
import SqlQueryEditorModal from './SqlQueryEditor';
import { setTileSettingsCategoryId, setTileSettingsDatabaseId, setTileSettingsSqlQuery } from './state/tileBuilderReducer';
import { setDatabaseIdAndExecuteSqlQuery, setSqlQueryAndExecute } from './state/tileBuilderThunks';
import TestValuesSettingsForm from './TestValuesSettingsForm';
import { SqlResult, TileModelCategory, TileSettingsModel } from './tileBuilderTypes';

const SqlSettingsForm = (): JSX.Element => {
  
  const dispatch = useDispatch();
  const tileSettings = useSelector((state: ApplicationState) => state.dashboardTileBuilder.tileSettings as TileSettingsModel);
  const sqlResult = useSelector((state: ApplicationState) => state.dashboardTileBuilder.sqlResult as SqlResult);
  
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);

  const onToggleSqlQueryEditorModal = () => {
    setIsEditorModalOpen(!isEditorModalOpen);
  }
  
  const categoryIdChangeHandler = (event: React.ChangeEvent<any>) => dispatch(setTileSettingsCategoryId(parseInt(event.target.value as string)));
  const databaseIdChangeHandler = (event: React.ChangeEvent<any>) => dispatch(setTileSettingsDatabaseId(event.target.value as string));
  const sqlQueryChangeHandler = (sqlQuery: string) => dispatch(setTileSettingsSqlQuery(sqlQuery));

  return (
    <>
      <Grid item xs={12} md={12} >
        <FormControl fullWidth>
          <InputLabel shrink={tileSettings.categoryId != null}>Category</InputLabel>
          <Select
            value={tileSettings.categoryId}
            onChange={(event) => categoryIdChangeHandler(event)}
            displayEmpty={false}
            inputProps={{ 'aria-label': 'Without label' }}
            autoWidth={true}>
            <MenuItem value={TileModelCategory.Booting}>Booting</MenuItem>
            <MenuItem value={TileModelCategory.EasyLPR}>Easy LPR</MenuItem>
            <MenuItem value={TileModelCategory.HelpCenter}>Help Center</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} >
        <FormControl fullWidth>
          <InputLabel>Database</InputLabel>
          <Select
            value={tileSettings.databaseId}
            onChange={(event) => databaseIdChangeHandler(event)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            autoWidth={false}>
            <MenuItem value="bootview">BootView</MenuItem>
            <MenuItem value="booting_support">BootingSupport</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button size="small" style={{ float: 'right' }} title="Open In Modal" onClick={() => onToggleSqlQueryEditorModal()}>
          <ExpandSqlQueryIcon />
        </Button>
        <TextField
          label="SQL Query"
          multiline
          rows={2}
          onChange={(evt) => {
            console.log('trying to change');
            sqlQueryChangeHandler(evt.target.value as string)
          }}
          variant="outlined"
          style={{ width: '100%', marginTop: '6px' }}
          value={tileSettings.sqlQuery}
          inputProps={{ style: { fontFamily: 'monospace' } }}
        />
        {
          sqlResult.isValidQuery === false &&
          <Typography variant="caption" style={{ color: 'red' }}>
            Invalid query, please verify it.
          </Typography>
        }
      </Grid>
      <Grid item xs={12} md={12}>
        <Alert severity="info" style={{ marginTop: '6px' }}>
          {/* TODO: Replace with dynamically created params */}
          Available params on the SQL queries: &#123;CustomerId&#125; | &#123;Date&#125; | &#123;DateTo&#125;
        </Alert>
      </Grid>
      <TestValuesSettingsForm />
      <SqlQueryEditorModal
        isOpen={isEditorModalOpen}
        sqlQuery={tileSettings.sqlQuery}
        onSqlQueryChange={sqlQueryChangeHandler}
        isValidSqlQuery={sqlResult.isValidQuery}
        onCloseModal={() => onToggleSqlQueryEditorModal()} />
    </>
  );
};

export default SqlSettingsForm;
