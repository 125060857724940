import React, { useEffect, useReducer, useState } from 'react';
import { Grid, Button, Select, MenuItem, Input, InputLabel, Paper, FormControl, Hidden } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { BootingOfficer, ParkingArea, ClearPlateConstant, MapFilterDataType, MapLayerType, MapSourceType, noDebtRange, debtRangeTwo, debtRangeFour, debtRangeFive, debtRangeOne, debtRangeThree, MapDashboardStateModel } from '../../state/mapDashboardStateV2';
import { useStyles } from './mapFilterBarStyles';
import { MultipleSelect } from '../../../Commons/MultipleSelect/MultipleSelect';
import { MapFilterState, mapFilterStateReducer, mapFilterStateInit, ACTION_TYPES } from './mapFilterState';
import { applyFilterAndFetchLiveMarkers } from '../../state/mapDashboardThunks';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../app/store';
import { SessionState } from '../../../../../app/session/sessionTypes';
import * as DashboardFilterState from '../../../state/DashboardFilterState';
import Cookies from 'js-cookie';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SceneType } from '../../../LiveMap/state/mapDashboardStateV2';

type MapFilterBarProps = {
    onApply: (filter: MapFilterState) => void;
    filterBy?: string | null;
    filterQ?: string | null;
    licenseFilter: string;
    licenseFilterNoSearch?: string;
}

export default function MapFilterBar (props: MapFilterBarProps): JSX.Element {
    const {  onApply, filterBy, filterQ, licenseFilter, licenseFilterNoSearch} = props;
    const classes = useStyles();

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mapOptiRouteDashboard as MapDashboardStateModel,
            session: state.session as SessionState,
        };
    };
    const globalState = useSelector(selector);   

    const isLoading = globalState.data.isLoading;
    const bootingOfficers=globalState.data.filterSource.bootingOfficers;
    const parkingAreas=globalState.data.filterSource.parkingAreas;

    const [state, dispatch] = useReducer(mapFilterStateReducer, { bootingOfficers, parkingAreas }, mapFilterStateInit);
    const [isLicensePlateSearch, setIsLicensePlateSearch] = useState(false);

    const mapDispatch = useDispatch();

    const  isNewPlates =  globalState.dashboardFilter.sceneType == SceneType.NewPlates ?  true : false;

    useEffect(() => {
        let newLicenseFilter = licenseFilter;
        if(newLicenseFilter != '')
        {
            if(newLicenseFilter == ClearPlateConstant)//clear plate filter
            {
                newLicenseFilter = '';
            }
            setIsLicensePlateSearch(true);
            dispatch({ type: ACTION_TYPES.UPDATE_PLATE, payload: newLicenseFilter });
        }
    }, [licenseFilter]);

    useEffect(() => {
        if(licenseFilterNoSearch != null)
        {
            dispatch({ type: ACTION_TYPES.UPDATE_PLATE, payload: licenseFilterNoSearch });
        }
    }, [licenseFilterNoSearch]);

    useEffect(() => {
        if(isLicensePlateSearch)
        {
            onApply(state);
            setIsLicensePlateSearch(false);
        }
    }, [state.plate]);

    useEffect(() => {
        dispatch({ type: ACTION_TYPES.UPDATE_AGENTS_LOCATIONS, payload: { bootingOfficers, parkingAreas, filterBy, filterQ } });
    }, [bootingOfficers, parkingAreas]);

    useEffect(() => {
        const fullDebtRange = [noDebtRange, debtRangeTwo, debtRangeFour, debtRangeFive, debtRangeOne, debtRangeThree];
        changeSelectedDebtHandler(fullDebtRange);
        state.debtRange = fullDebtRange;
        onApply(state);
    }, [globalState.dashboardFilter.customerId]);

    const changePlateHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const plate = event.target.value as unknown as string;
        dispatch({ type: ACTION_TYPES.UPDATE_PLATE, payload: plate });
    };

    
    const  changeSelectedSourceHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_SOURCE_TYPE, payload: selected });
    };

    const changeSelectedFromTimeHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const selected = event.target.value as string;
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_FROMTIME_TYPE, payload: selected });
    };

    const changeSelectedToTimeHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const selected = event.target.value as string;
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_TOTIME_TYPE, payload: selected });
    };

    const changeSelectedLayerTypeHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const selected = event.target.value as number;
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LAYER_TYPE, payload: selected });
    };

    const changeSelectedStatusHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const status = event.target.value as number;
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_STATUS, payload: status });
    };

    const changeSelectedDebtHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_DEBT_TYPE, payload: selected });
    };

    const changeSelectedRunAwaysHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_RUNAWAYS, payload: selected });
    };

    const changeSelectedAgentsHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_AGENTS, payload: selected });
    };

    const changeSelectedLocationsHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LOCATIONS, payload: selected });
    };

    //new plate filters handlers
    const changeSelectedDateFromHandler = (date: Date): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_DATE_FROM, payload: date });
    };

    //setup map auto refresh every 10 seconds
    useEffect(() => {
        const seconds = 10;
        const interval = setInterval(autoRefreshMap, seconds*1000);
        return () => clearInterval(interval);
     });
       
     //this will run every interval to refresh map data
    const autoRefreshMap = (): void => {
        mapDispatch(applyFilterAndFetchLiveMarkers(state));
    };

    return (
        <Paper elevation={1} className={classes.root}>
            <Grid  container spacing={3} alignItems="flex-end">
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Plate</InputLabel>
                        <Input
                            fullWidth
                            value={state.plate}
                            onChange={changePlateHandler}
                            name="plate"
                            placeholder="Plate" />
                    </FormControl>
                </Grid>
                {
                isNewPlates &&
                <Grid item xs={12} md={12} lg="auto">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date From"
                            value={state.date}
                            onChange={d => changeSelectedDateFromHandler(d as Date)}
                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            className={classes.formControl}
                            InputProps={{ className: classes.select }}
                            InputLabelProps={{ className: classes.label }}
                            SelectProps={{ className: classes.select }}
                            autoOk={true}
                            fullWidth
                            maxDate={new Date()}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Source</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.sourceTypes}
                            type="checkbox"
                            onChange={changeSelectedSourceHandler}
                            selectableItems={[
                                { id: MapSourceType.LPR as unknown as string, name: 'LPR' },
                                
                            ]}
                            renderAllSelected={true}
                        />
                    </FormControl>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Status</InputLabel>
                        <Select
                            displayEmpty
                            value={state.status}
                            name="status"
                            onChange={changeSelectedStatusHandler}
                            autoWidth={false}>
                            <MenuItem value={-1}>All</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> 
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Start Time</InputLabel>
                        <Select
                            displayEmpty
                            value={state.fromTime}
                            name="startTime"
                            onChange={changeSelectedFromTimeHandler}
                            autoWidth={false}>
                            <MenuItem value={'12:00:00 AM'}>12:00 AM</MenuItem>
                            <MenuItem value={'12:15:00 AM'}>12:15 AM</MenuItem>
                            <MenuItem value={'12:30:00 AM'}>12:30 AM</MenuItem>
                            <MenuItem value={'12:45:00 AM'}>12:45 AM</MenuItem>
                            <MenuItem value={'1:00:00 AM'}>1:00 AM</MenuItem>
                            <MenuItem value={'1:15:00 AM'}>1:15 AM</MenuItem>
                            <MenuItem value={'1:30:00 AM'}>1:30 AM</MenuItem>
                            <MenuItem value={'1:45:00 AM'}>1:45 AM</MenuItem>
                            <MenuItem value={'2:00:00 AM'}>2:00 AM</MenuItem>
                            <MenuItem value={'2:15:00 AM'}>2:15 AM</MenuItem>
                            <MenuItem value={'2:30:00 AM'}>2:30 AM</MenuItem>
                            <MenuItem value={'2:45:00 AM'}>2:45 AM</MenuItem>
                            <MenuItem value={'3:00:00 AM'}>3:00 AM</MenuItem>
                            <MenuItem value={'3:15:00 AM'}>3:15 AM</MenuItem>
                            <MenuItem value={'3:30:00 AM'}>3:30 AM</MenuItem>
                            <MenuItem value={'3:45:00 AM'}>3:45 AM</MenuItem>
                            <MenuItem value={'4:00:00 AM'}>4:00 AM</MenuItem>
                            <MenuItem value={'4:15:00 AM'}>4:15 AM</MenuItem>
                            <MenuItem value={'4:30:00 AM'}>4:30 AM</MenuItem>
                            <MenuItem value={'4:45:00 AM'}>4:45 AM</MenuItem>
                            <MenuItem value={'5:00:00 AM'}>5:00 AM</MenuItem>
                            <MenuItem value={'5:15:00 AM'}>5:15 AM</MenuItem>
                            <MenuItem value={'5:30:00 AM'}>5:30 AM</MenuItem>
                            <MenuItem value={'5:45:00 AM'}>5:45 AM</MenuItem>
                            <MenuItem value={'6:00:00 AM'}>6:00 AM</MenuItem>
                            <MenuItem value={'6:15:00 AM'}>6:15 AM</MenuItem>
                            <MenuItem value={'6:30:00 AM'}>6:30 AM</MenuItem>
                            <MenuItem value={'6:45:00 AM'}>6:45 AM</MenuItem>
                            <MenuItem value={'7:00:00 AM'}>7:00 AM</MenuItem>
                            <MenuItem value={'7:15:00 AM'}>7:15 AM</MenuItem>
                            <MenuItem value={'7:30:00 AM'}>7:30 AM</MenuItem>
                            <MenuItem value={'7:45:00 AM'}>7:45 AM</MenuItem>
                            <MenuItem value={'8:00:00 AM'}>8:00 AM</MenuItem>
                            <MenuItem value={'8:15:00 AM'}>8:15 AM</MenuItem>
                            <MenuItem value={'8:30:00 AM'}>8:30 AM</MenuItem>
                            <MenuItem value={'8:45:00 AM'}>8:45 AM</MenuItem>
                            <MenuItem value={'9:00:00 AM'}>9:00 AM</MenuItem>
                            <MenuItem value={'9:15:00 AM'}>9:15 AM</MenuItem>
                            <MenuItem value={'9:30:00 AM'}>9:30 AM</MenuItem>
                            <MenuItem value={'9:45:00 AM'}>9:45 AM</MenuItem>
                            <MenuItem value={'10:00:00 AM'}>10:00 AM</MenuItem>
                            <MenuItem value={'10:15:00 AM'}>10:15 AM</MenuItem>
                            <MenuItem value={'10:30:00 AM'}>10:30 AM</MenuItem>
                            <MenuItem value={'10:45:00 AM'}>10:45 AM</MenuItem>
                            <MenuItem value={'11:00:00 AM'}>11:00 AM</MenuItem>
                            <MenuItem value={'11:15:00 AM'}>11:15 AM</MenuItem>
                            <MenuItem value={'11:30:00 AM'}>11:30 AM</MenuItem>
                            <MenuItem value={'11:45:00 AM'}>11:45 AM</MenuItem>
                            <MenuItem value={'12:00:00 PM'}>12:00 PM</MenuItem>
                            <MenuItem value={'12:15:00 PM'}>12:15 PM</MenuItem>
                            <MenuItem value={'12:30:00 PM'}>12:30 PM</MenuItem>
                            <MenuItem value={'12:45:00 PM'}>12:45 PM</MenuItem>
                            <MenuItem value={'1:00:00 PM'}>1:00 PM</MenuItem>
                            <MenuItem value={'1:15:00 PM'}>1:15 PM</MenuItem>
                            <MenuItem value={'1:30:00 PM'}>1:30 PM</MenuItem>
                            <MenuItem value={'1:45:00 PM'}>1:45 PM</MenuItem>
                            <MenuItem value={'2:00:00 PM'}>2:00 PM</MenuItem>
                            <MenuItem value={'2:15:00 PM'}>2:15 PM</MenuItem>
                            <MenuItem value={'2:30:00 PM'}>2:30 PM</MenuItem>
                            <MenuItem value={'2:45:00 PM'}>2:45 PM</MenuItem>
                            <MenuItem value={'3:00:00 PM'}>3:00 PM</MenuItem>
                            <MenuItem value={'3:15:00 PM'}>3:15 PM</MenuItem>
                            <MenuItem value={'3:30:00 PM'}>3:30 PM</MenuItem>
                            <MenuItem value={'3:45:00 PM'}>3:45 PM</MenuItem>
                            <MenuItem value={'4:00:00 PM'}>4:00 PM</MenuItem>
                            <MenuItem value={'4:15:00 PM'}>4:15 PM</MenuItem>
                            <MenuItem value={'4:30:00 PM'}>4:30 PM</MenuItem>
                            <MenuItem value={'4:45:00 PM'}>4:45 PM</MenuItem>
                            <MenuItem value={'5:00:00 PM'}>5:00 PM</MenuItem>
                            <MenuItem value={'5:15:00 PM'}>5:15 PM</MenuItem>
                            <MenuItem value={'5:30:00 PM'}>5:30 PM</MenuItem>
                            <MenuItem value={'5:45:00 PM'}>5:45 PM</MenuItem>
                            <MenuItem value={'6:00:00 PM'}>6:00 PM</MenuItem>
                            <MenuItem value={'6:15:00 PM'}>6:15 PM</MenuItem>
                            <MenuItem value={'6:30:00 PM'}>6:30 PM</MenuItem>
                            <MenuItem value={'6:45:00 PM'}>6:45 PM</MenuItem>
                            <MenuItem value={'7:00:00 PM'}>7:00 PM</MenuItem>
                            <MenuItem value={'7:15:00 PM'}>7:15 PM</MenuItem>
                            <MenuItem value={'7:30:00 PM'}>7:30 PM</MenuItem>
                            <MenuItem value={'7:45:00 PM'}>7:45 PM</MenuItem>
                            <MenuItem value={'8:00:00 PM'}>8:00 PM</MenuItem>
                            <MenuItem value={'8:15:00 PM'}>8:15 PM</MenuItem>
                            <MenuItem value={'8:30:00 PM'}>8:30 PM</MenuItem>
                            <MenuItem value={'8:45:00 PM'}>8:45 PM</MenuItem>
                            <MenuItem value={'9:00:00 PM'}>9:00 PM</MenuItem>
                            <MenuItem value={'9:15:00 PM'}>9:15 PM</MenuItem>
                            <MenuItem value={'9:30:00 PM'}>9:30 PM</MenuItem>
                            <MenuItem value={'9:45:00 PM'}>9:45 PM</MenuItem>
                            <MenuItem value={'10:00:00 PM'}>10:00 PM</MenuItem>
                            <MenuItem value={'10:15:00 PM'}>10:15 PM</MenuItem>
                            <MenuItem value={'10:30:00 PM'}>10:30 PM</MenuItem>
                            <MenuItem value={'10:45:00 PM'}>10:45 PM</MenuItem>
                            <MenuItem value={'11:00:00 PM'}>11:00 PM</MenuItem>
                            <MenuItem value={'11:15:00 PM'}>11:15 PM</MenuItem>
                            <MenuItem value={'11:30:00 PM'}>11:30 PM</MenuItem>
                            <MenuItem value={'11:45:00 PM'}>11:45 PM</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>End Time</InputLabel>
                        <Select
                            displayEmpty
                            value={state.toTime}
                            name="endTime"
                            onChange={changeSelectedToTimeHandler}
                            autoWidth={false}>
                            <MenuItem value={'12:00:00 AM'}>12:00 AM</MenuItem>
                            <MenuItem value={'12:15:00 AM'}>12:15 AM</MenuItem>
                            <MenuItem value={'12:30:00 AM'}>12:30 AM</MenuItem>
                            <MenuItem value={'12:45:00 AM'}>12:45 AM</MenuItem>
                            <MenuItem value={'1:00:00 AM'}>1:00 AM</MenuItem>
                            <MenuItem value={'1:15:00 AM'}>1:15 AM</MenuItem>
                            <MenuItem value={'1:30:00 AM'}>1:30 AM</MenuItem>
                            <MenuItem value={'1:45:00 AM'}>1:45 AM</MenuItem>
                            <MenuItem value={'2:00:00 AM'}>2:00 AM</MenuItem>
                            <MenuItem value={'2:15:00 AM'}>2:15 AM</MenuItem>
                            <MenuItem value={'2:30:00 AM'}>2:30 AM</MenuItem>
                            <MenuItem value={'2:45:00 AM'}>2:45 AM</MenuItem>
                            <MenuItem value={'3:00:00 AM'}>3:00 AM</MenuItem>
                            <MenuItem value={'3:15:00 AM'}>3:15 AM</MenuItem>
                            <MenuItem value={'3:30:00 AM'}>3:30 AM</MenuItem>
                            <MenuItem value={'3:45:00 AM'}>3:45 AM</MenuItem>
                            <MenuItem value={'4:00:00 AM'}>4:00 AM</MenuItem>
                            <MenuItem value={'4:15:00 AM'}>4:15 AM</MenuItem>
                            <MenuItem value={'4:30:00 AM'}>4:30 AM</MenuItem>
                            <MenuItem value={'4:45:00 AM'}>4:45 AM</MenuItem>
                            <MenuItem value={'5:00:00 AM'}>5:00 AM</MenuItem>
                            <MenuItem value={'5:15:00 AM'}>5:15 AM</MenuItem>
                            <MenuItem value={'5:30:00 AM'}>5:30 AM</MenuItem>
                            <MenuItem value={'5:45:00 AM'}>5:45 AM</MenuItem>
                            <MenuItem value={'6:00:00 AM'}>6:00 AM</MenuItem>
                            <MenuItem value={'6:15:00 AM'}>6:15 AM</MenuItem>
                            <MenuItem value={'6:30:00 AM'}>6:30 AM</MenuItem>
                            <MenuItem value={'6:45:00 AM'}>6:45 AM</MenuItem>
                            <MenuItem value={'7:00:00 AM'}>7:00 AM</MenuItem>
                            <MenuItem value={'7:15:00 AM'}>7:15 AM</MenuItem>
                            <MenuItem value={'7:30:00 AM'}>7:30 AM</MenuItem>
                            <MenuItem value={'7:45:00 AM'}>7:45 AM</MenuItem>
                            <MenuItem value={'8:00:00 AM'}>8:00 AM</MenuItem>
                            <MenuItem value={'8:15:00 AM'}>8:15 AM</MenuItem>
                            <MenuItem value={'8:30:00 AM'}>8:30 AM</MenuItem>
                            <MenuItem value={'8:45:00 AM'}>8:45 AM</MenuItem>
                            <MenuItem value={'9:00:00 AM'}>9:00 AM</MenuItem>
                            <MenuItem value={'9:15:00 AM'}>9:15 AM</MenuItem>
                            <MenuItem value={'9:30:00 AM'}>9:30 AM</MenuItem>
                            <MenuItem value={'9:45:00 AM'}>9:45 AM</MenuItem>
                            <MenuItem value={'10:00:00 AM'}>10:00 AM</MenuItem>
                            <MenuItem value={'10:15:00 AM'}>10:15 AM</MenuItem>
                            <MenuItem value={'10:30:00 AM'}>10:30 AM</MenuItem>
                            <MenuItem value={'10:45:00 AM'}>10:45 AM</MenuItem>
                            <MenuItem value={'11:00:00 AM'}>11:00 AM</MenuItem>
                            <MenuItem value={'11:15:00 AM'}>11:15 AM</MenuItem>
                            <MenuItem value={'11:30:00 AM'}>11:30 AM</MenuItem>
                            <MenuItem value={'11:45:00 AM'}>11:45 AM</MenuItem>
                            <MenuItem value={'12:00:00 PM'}>12:00 PM</MenuItem>
                            <MenuItem value={'12:15:00 PM'}>12:15 PM</MenuItem>
                            <MenuItem value={'12:30:00 PM'}>12:30 PM</MenuItem>
                            <MenuItem value={'12:45:00 PM'}>12:45 PM</MenuItem>
                            <MenuItem value={'1:00:00 PM'}>1:00 PM</MenuItem>
                            <MenuItem value={'1:15:00 PM'}>1:15 PM</MenuItem>
                            <MenuItem value={'1:30:00 PM'}>1:30 PM</MenuItem>
                            <MenuItem value={'1:45:00 PM'}>1:45 PM</MenuItem>
                            <MenuItem value={'2:00:00 PM'}>2:00 PM</MenuItem>
                            <MenuItem value={'2:15:00 PM'}>2:15 PM</MenuItem>
                            <MenuItem value={'2:30:00 PM'}>2:30 PM</MenuItem>
                            <MenuItem value={'2:45:00 PM'}>2:45 PM</MenuItem>
                            <MenuItem value={'3:00:00 PM'}>3:00 PM</MenuItem>
                            <MenuItem value={'3:15:00 PM'}>3:15 PM</MenuItem>
                            <MenuItem value={'3:30:00 PM'}>3:30 PM</MenuItem>
                            <MenuItem value={'3:45:00 PM'}>3:45 PM</MenuItem>
                            <MenuItem value={'4:00:00 PM'}>4:00 PM</MenuItem>
                            <MenuItem value={'4:15:00 PM'}>4:15 PM</MenuItem>
                            <MenuItem value={'4:30:00 PM'}>4:30 PM</MenuItem>
                            <MenuItem value={'4:45:00 PM'}>4:45 PM</MenuItem>
                            <MenuItem value={'5:00:00 PM'}>5:00 PM</MenuItem>
                            <MenuItem value={'5:15:00 PM'}>5:15 PM</MenuItem>
                            <MenuItem value={'5:30:00 PM'}>5:30 PM</MenuItem>
                            <MenuItem value={'5:45:00 PM'}>5:45 PM</MenuItem>
                            <MenuItem value={'6:00:00 PM'}>6:00 PM</MenuItem>
                            <MenuItem value={'6:15:00 PM'}>6:15 PM</MenuItem>
                            <MenuItem value={'6:30:00 PM'}>6:30 PM</MenuItem>
                            <MenuItem value={'6:45:00 PM'}>6:45 PM</MenuItem>
                            <MenuItem value={'7:00:00 PM'}>7:00 PM</MenuItem>
                            <MenuItem value={'7:15:00 PM'}>7:15 PM</MenuItem>
                            <MenuItem value={'7:30:00 PM'}>7:30 PM</MenuItem>
                            <MenuItem value={'7:45:00 PM'}>7:45 PM</MenuItem>
                            <MenuItem value={'8:00:00 PM'}>8:00 PM</MenuItem>
                            <MenuItem value={'8:15:00 PM'}>8:15 PM</MenuItem>
                            <MenuItem value={'8:30:00 PM'}>8:30 PM</MenuItem>
                            <MenuItem value={'8:45:00 PM'}>8:45 PM</MenuItem>
                            <MenuItem value={'9:00:00 PM'}>9:00 PM</MenuItem>
                            <MenuItem value={'9:15:00 PM'}>9:15 PM</MenuItem>
                            <MenuItem value={'9:30:00 PM'}>9:30 PM</MenuItem>
                            <MenuItem value={'9:45:00 PM'}>9:45 PM</MenuItem>
                            <MenuItem value={'10:00:00 PM'}>10:00 PM</MenuItem>
                            <MenuItem value={'10:15:00 PM'}>10:15 PM</MenuItem>
                            <MenuItem value={'10:30:00 PM'}>10:30 PM</MenuItem>
                            <MenuItem value={'10:45:00 PM'}>10:45 PM</MenuItem>
                            <MenuItem value={'11:00:00 PM'}>11:00 PM</MenuItem>
                            <MenuItem value={'11:15:00 PM'}>11:15 PM</MenuItem>
                            <MenuItem value={'11:30:00 PM'}>11:30 PM</MenuItem>
                            <MenuItem value={'11:45:00 PM'}>11:45 PM</MenuItem>
                            <MenuItem value={'11:59:59 PM'}>11:59 PM</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                }
                {
                globalState.data.showDebtRange && !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Debt</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.debtRange}
                            type="checkbox"
                            onChange={changeSelectedDebtHandler}
                            selectableItems={[
                                { id: noDebtRange, name: 'No Amount' },
                                { id: debtRangeOne, name: '0-$100' },
                                { id: debtRangeTwo, name: '$101-$300' },
                                { id: debtRangeThree, name: '$301-$500' },
                                { id: debtRangeFour, name: '$501-$1000' },
                                { id: debtRangeFive, name: '> $1000' },
                            ]}
                            renderAllSelected={false}
                        />
                    </FormControl>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}># RunAways</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.runAways}
                            type="checkbox"
                            onChange={changeSelectedRunAwaysHandler}
                            selectableItems={[
                                { id: '0', name: '0' },
                                { id: '1', name: '1' },
                                { id: '2', name: '2' },
                                { id: '3', name: '3' },
                                { id: '4', name: '4' },
                                { id: '5', name: '5' },
                                { id: '6', name: '5+' },
                            ]}
                            renderAllSelected={true}
                        />
                    </FormControl>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Show as</InputLabel>
                        <Select
                            displayEmpty
                            value={state.layerType}
                            name="showAs"
                            onChange={changeSelectedLayerTypeHandler}
                            autoWidth={false}>
                            <MenuItem value={MapLayerType.Reads}>Reads</MenuItem>
                            <MenuItem value={MapLayerType.Heat}>Heat</MenuItem>
                            {/*<MenuItem value={MapLayerType.Route}>Route</MenuItem>*/}
                        </Select>
                    </FormControl>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.hideComponent} fullWidth>
                        <InputLabel shrink className={classes.label}>Agents</InputLabel>
                        <MultipleSelect
                            id="searchByAgents"
                            includeSelectAll={true}
                            currentSelected={state.selectedAgentsToSearchBy}
                            type="checkbox"
                            onChange={changeSelectedAgentsHandler}
                            selectableItems={bootingOfficers.map((bo) => ({ id: bo.bootingOfficerId, name: bo.name }))}
                        />
                    </FormControl>
                </Grid>
                }
                {
                !isNewPlates &&
                <Grid item xs={12} md="auto" >
                    <FormControl className={classes.hideComponent} fullWidth>
                        <InputLabel shrink className={classes.label}>Locations</InputLabel>
                        <MultipleSelect
                            id="searchByLocations"
                            includeSelectAll={true}
                            currentSelected={state.selectedLocationsToSearchBy}
                            type="checkbox"
                            onChange={changeSelectedLocationsHandler}
                            selectableItems={parkingAreas.map((pa) => ({ id: pa.name, name: pa.name }))}
                        />
                    </FormControl>
                </Grid>
                }
                {/* <Grid item xs={12} md="auto">
                    <Button variant="contained" fullWidth>
                        More Settings
                    </Button>
                </Grid> */}
                <Grid item xs={12} md="auto">
                    <Button
                        variant="contained"
                        className={classes.searchButton}
                        startIcon={<SearchIcon />}
                        onClick={() => onApply(state)}
                        disabled={isLoading}>
                        {
                            !isLoading ? 'Search' : 'Searching...'
                        }
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
