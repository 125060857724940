import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    layoutContainer: {
        padding: 0,
        '& button:focus': {
            outline: 'none',
        },
    },
    mainContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingLeft: 240,
        },
        '& ::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '& ::-webkit-scrollbar-track': {
            backgroundColor: 'white',
        },
        '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#ADA8B4',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    leftNavigationMenu: {
        '& span.MuiTypography-body1, a.MuiTypography-colorPrimary': {
            fontSize: '16px !important',
        },
        '& ::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '& ::-webkit-scrollbar-track': {
            backgroundColor: 'white',
        },
        '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#ADA8B4',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
}));
