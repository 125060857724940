/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react';
import { MapSearchResult, MapFilterSource, NoLocationGuid, NoLocationName, MapMarker, SuccessResult, EnforcementZoneResult, EnforcementZoneModificationType, AssignEnforcementZoneModificationType, EnforcementZoneAssignationResult, BootviewUser, OptirotueReadImages } from './mapDashboardStateV2';
import { updateMapState, setError, applyFilter, changePageOnFilter, setFilterSource, updateLiveMapState, updateEnforcementZoneState, updateEnforcementZoneAssignationState, updateBootviewUserState } from './mapOptiRouteDashboardSlice';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import RestClient, { Get, RestClientV2 } from '../../../../services/RestClient';
import { ApplicationState } from '../../../../app/store';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';
import { SceneType } from '../../LiveMap/state/mapDashboardStateV2';

const fetchMapFilterSource = (customerId: string): Promise<MapFilterSource> => {
    return new Promise((resolve, reject) => {
        RestClient.get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/filterSource`,
            params: {
                customerId: customerId,
            },
        }).then((response: any) => {
            resolve(response.data as MapFilterSource);
        }).catch((err) => {
            reject(err);
        });
    });
};

const initializeMapFilterSource = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        const state = getState();
        if (!state.dashboardFilter.customerId) {
            return Promise.resolve();
        }

        return fetchMapFilterSource(getState().dashboardFilter.customerId).then((filterSource: MapFilterSource) => {
            //we need to add the No location option to parking areas after changing customer
            const locations = filterSource.parkingAreas.map((pa) => ({ parkingAreaId: pa.name, name: pa.name }));
            if(filterSource.parkingAreas.find(x=> x.parkingAreaId ==  NoLocationGuid) == null)
            {
                const emptyId = { parkingAreaId: NoLocationGuid, name:  NoLocationName};
                locations.unshift(emptyId);
                filterSource.parkingAreas = locations;
            }
            dispatch(setFilterSource(filterSource));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const fetchMapMarkersFromAPI = (mapFilter: MapFilterState, dashboardFilter: DashboardFilterState): Promise<MapSearchResult> => {
    return new Promise((resolve, reject) => {
        console.log('fetchMapMarkersFromAPI mapFilter: ', mapFilter);

        const dateFrom = dashboardFilter.date.replace(/[0-9]{2}:[0-9]{2}\s[A|P|a|p][M|m]/, mapFilter.fromTime);
        const dateTo = dashboardFilter.dateTo?.replace(/[0-9]{2}:[0-9]{2}\s[A|P|a|p][M|m]/, mapFilter.toTime) ? dashboardFilter.dateTo.replace(/[0-9]{2}:[0-9]{2}\s[A|P|a|p][M|m]/, mapFilter.toTime) : dashboardFilter.dateTo;
        


        const dateFromNewPlates = mapFilter.date;
        const parameters  = dashboardFilter.sceneType == SceneType.NewPlates ? {
                                                                                    customerId: dashboardFilter.customerId,
                                                                                    dateFrom: dateFromNewPlates,
                                                                                    dateFromBase: dateFrom,
                                                                                    plate: mapFilter.plate,
                                                                                    currentPage: mapFilter.currentPage,
                                                                                    pageSize: mapFilter.pageSize
                                                                                }
                                                                                :
                                                                                {
                                                                                    customerId: dashboardFilter.customerId,
                                                                                    dateFrom: dateFrom,
                                                                                    dateTo: dateTo,
                                                                                    plate: mapFilter.plate,
                                                                                    SourceTypes: mapFilter.sourceTypes,
                                                                                    AmtDueRanges: mapFilter.debtRange,
                                                                                    currentPage: mapFilter.currentPage,
                                                                                    pageSize: mapFilter.pageSize,
                                                                                    NumRunAways: mapFilter.runAways,
                                                                                    WeekDay: dashboardFilter.weekDays,
                                                                                    Status: mapFilter.status
                                                                                };

        const uri = dashboardFilter.sceneType == SceneType.NewPlates ? 'dashboards/map/newplates' : 'dashboards/map/optiroute';
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/`+uri,
            data: parameters,
        }).then((response: any) => {
            console.log('fetchMapMarkersFromAPI response: ', response);
            resolve(response as MapSearchResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

const applyFilterAndFetchMarkers = (mapFilter: MapFilterState) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        //mapFilter.currentPage = getState().mapDashboard.filter ? getState().mapDashboard.filter.currentPage : 1;
        console.log('filter before apply', mapFilter);
        dispatch(applyFilter(mapFilter));

        return fetchMapMarkersFromAPI(mapFilter, getState().dashboardFilter).then((mapSearchResult: MapSearchResult) => {
            console.log('mapSearchResult >>', mapSearchResult)
            dispatch(updateMapState({mapSearchResult: mapSearchResult, dashboardFilterState: getState().dashboardFilter}));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const fetchLiveMapMarkersFromAPI = (mapFilter: MapFilterState, dashboardFilter: DashboardFilterState): Promise<MapSearchResult> => {
    return new Promise((resolve, reject) => {
        console.log('fetchMapMarkersFromAPI mapFilter: ', mapFilter);
        //change local date to server so initial time matches
        const dt = new Date();
        dt.setMinutes( dt.getMinutes() - 20 );
        const ETdate = (dt).toLocaleString('en-Us', {timeZone: 'America/New_York'});
        //const ETdate = dashboardFilter.dateTo
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/livesearch`,
            data: {
                customerId: dashboardFilter.customerId,
                dateFrom: ETdate,
                dateTo: ETdate,
                plate: mapFilter.plate,
                agentsIds: mapFilter.selectedAgentsToSearchBy,
                locationsIds: mapFilter.selectedLocationsToSearchBy,
                currentPage: mapFilter.currentPage,
                pageSize: mapFilter.pageSize,
                dataTypes: mapFilter.selectedDataTypes,
                useExactDate: true
            },
        }).then((response: any) => {
            console.log('fetchMapMarkersFromAPI response: ', response);
            resolve(response as MapSearchResult);
        }).catch((err) => {
            reject(err);
        });
    });
};


const fetchEnforcementZone = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        return enforcementZone(getState().dashboardFilter.customerId,'','','', getState().dashboardFilter.customerId, EnforcementZoneModificationType.Select).then((enforcementZoneResult: EnforcementZoneResult) => {
            dispatch(updateEnforcementZoneState(enforcementZoneResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const changeEnforcementZone = (id: string | number, zoneName: string, coordinates: string, points: string,  ModificationType: number) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        return enforcementZone(id, zoneName, coordinates, points, getState().dashboardFilter.customerId, ModificationType).then((enforcementZoneResult: EnforcementZoneResult) => {
            dispatch(updateEnforcementZoneState(enforcementZoneResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};


const enforcementZone = (id: string | number, zoneName: string, coordinates: string, points: string, customerId: string, ModificationType: number): Promise<EnforcementZoneResult> => {
    return new Promise((resolve, reject) => {

        if(id != null && id != '' && customerId != null && customerId != '')
        {
            RestClientV2.post({
                url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/enforcementzone`,
                data: {
                    id: id,
                    zoneName: zoneName,
                    coordinates: coordinates,
                    points: points,
                    customerId: customerId,
                    modificationType: ModificationType
                },
            }).then((response: any) => {
                resolve(response as EnforcementZoneResult);
            }).catch((err) => {
                reject(err);
            });
        }
        else
        {
            resolve({success: true} as EnforcementZoneResult)//if we don't have id or customer id, dont request and assume success to prevent errors.
        }
    });
};

const fetchEnforcementZoneAssignation = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        //first parameter is just a place holder, it's not used on the server on inserts, we autogenerate one on DB insert
        return assignEnforcementZone('', [],[], [], getState().dashboardFilter.customerId, AssignEnforcementZoneModificationType.Select).then((enforcementZoneResult: EnforcementZoneAssignationResult) => {
            dispatch(updateEnforcementZoneAssignationState(enforcementZoneResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const changeEnforcementZoneAssignation = (enforcementZoneAssignationIds: string, enforcementZoneIds: string [], officerIds: string [], WeekDays: string [], ModificationType: number) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        return assignEnforcementZone(enforcementZoneAssignationIds, enforcementZoneIds,officerIds, WeekDays, getState().dashboardFilter.customerId, ModificationType).then((enforcementZoneResult: EnforcementZoneAssignationResult) => {
            dispatch(updateEnforcementZoneAssignationState(enforcementZoneResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const assignEnforcementZone = (enforcementZoneAssignationIds: string, enforcementZoneIds: string [], officerIds: string [], WeekDays: string [], customerId: string, ModificationType: number): Promise<EnforcementZoneAssignationResult> => {
    return new Promise((resolve, reject) => {
        if(customerId != null && customerId != '')
        {
            RestClientV2.post({
                url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/assignenforcementzone`,
                data: {
                    ids: enforcementZoneAssignationIds,
                    enforcementZoneIds: JSON.stringify(enforcementZoneIds),
                    officerIds: JSON.stringify(officerIds),
                    weekDays: JSON.stringify(WeekDays),
                    customerId: customerId,
                    modificationType: ModificationType
                },
            }).then((response: any) => {
                resolve(response as EnforcementZoneAssignationResult);
            }).catch((err) => {
                reject(err);
            });
        }
        else
        {
            resolve({success: true} as EnforcementZoneAssignationResult)//if we don't have id, dont request and assume success to prevent errors.
        }
    });
};

const fetchUsersByCustomer = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        return getUsersByCustomer(getState().dashboardFilter.customerId).then((usersResult: BootviewUser[]) => {
            dispatch(updateBootviewUserState(usersResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};
const getUsersByCustomer = (customerId: string): Promise<BootviewUser[]> => {
    return new Promise((resolve, reject) => {
        Get({
            url: `${process.env.REACT_APP_BOOTVIEW_API_URL}/users`,
            params: {
                CustomerId: customerId,
                isActive: 1,
                IsInternal: 1,
                GroupId: 'F7C79DC4-E744-4EF2-BE4D-8ECAC513BC8A',//Mobile user group
            },
        }).then((response: BootviewUser[]) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

const applyFilterAndFetchLiveMarkers = (mapFilter: MapFilterState) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        mapFilter.currentPage = getState().mapDashboard.filter ? getState().mapDashboard.filter.currentPage : 1;
        console.log('filter before apply', mapFilter);
        //dispatch(applyFilter(mapFilter));

        return fetchLiveMapMarkersFromAPI(mapFilter, getState().dashboardFilter).then((mapSearchResult: MapSearchResult) => {
            console.log('mapSearchResult >>', mapSearchResult)
            dispatch(updateLiveMapState(mapSearchResult));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const changePageAndFetchMarkers = (page: number) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): void => {
        dispatch(changePageOnFilter(page));

        // const state = getState();

        // return fetchMapMarkersFromAPI(state.mapDashboard.filter, state.dashboardFilter).then((mapSearchResult: MapSearchResult) => {
        //     dispatch(updateMapState(mapSearchResult));
        // }).catch((err: string) => {
        //     dispatch(setError(err));
        // });
    };
};

const toggleMarkerStatus = (marker: MapMarker, status: number): Promise<SuccessResult> => {
    return new Promise((resolve, reject) => {
        console.log('toggleMarkerStatus: ', marker);
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/ToggleOptiRouteStatus`,
            data: {
                hotlistid: marker.hotlistSrcLocIf,
                status: status
            },
        }).then((response: any) => {
            console.log('toggleMarkerStatus changed: ', response);
            resolve(response as SuccessResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

/* eslint-disable @typescript-eslint/camelcase */
const getAssociatedImages = (lprDataId: string, dateFrom: string, dateTo: string, timeFrom: string, timeTo: string, domain: string): Promise<OptirotueReadImages> => {
    let fixedDateFrom = dateFrom.replace(/[0-9]{2}:[0-9]{2}\s[A|P|a|p][M|m]/, timeFrom);
    fixedDateFrom = fixedDateFrom.replaceAll('/', '-');
    let fixedDateTo = dateTo.replace(/[0-9]{2}:[0-9]{2}\s[A|P|a|p][M|m]/, timeTo);
    fixedDateTo = fixedDateTo.replaceAll('/', '-');
    return new Promise((resolve, reject) => {
        RestClientV2.postNoHeaders({
            url: `https://${domain}.myparkinginfo.com/cws/admin/lprread/media/related/${lprDataId}`,
            data: {
                start_date: fixedDateFrom,
                end_date: fixedDateTo
            },
        }).then((response: any) => {
            //for some reason parkingview is returnig out of date range reads so make sure we filter them out
            const responseObject = response as OptirotueReadImages;
            const transitionObject: OptirotueReadImages = {
                success: responseObject.success,
                list: []
            };
            if(responseObject.list != null)
            {
                for(let i = 0; i<responseObject.list.length; i++)
                {
                    if(responseObject.list[i].read != null)
                    {
                        const fromDate = new Date(fixedDateFrom);
                        const toDate = new Date(fixedDateTo);
                        const elementDate = new Date(responseObject.list[i].read.read_date);
                        
                        if(elementDate > fromDate && elementDate < toDate)
                        {
                            transitionObject.list.push(responseObject.list[i]);
                        }
                    }
                }
            }

            resolve(transitionObject as OptirotueReadImages);
        }).catch((err) => {
            reject(err);
        });
    });
};

export { initializeMapFilterSource, applyFilterAndFetchMarkers, changePageAndFetchMarkers, applyFilterAndFetchLiveMarkers, getAssociatedImages, toggleMarkerStatus, changeEnforcementZone, fetchEnforcementZone, changeEnforcementZoneAssignation, fetchEnforcementZoneAssignation, fetchUsersByCustomer };
    

