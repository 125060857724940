/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { GPSDeviceAlert, GPSDeviceMessage, MapMarker } from '../../state/mapDashboardStateV2';
import { Typography, Paper, Select as ComboSelect, Grid, Link, Snackbar,  Tabs, Tab, CircularProgress, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { DriveEta as CarIcon, CameraAlt as ImageIcon, Label } from '@material-ui/icons';
import { useStyles } from './markerDetailStyles';
import moment from 'moment';
import OSM from 'ol/source/OSM';
import OLMap from 'ol/Map';
import { Tile as TileLayer, Vector, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Geometry, Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import readsLayerStyle, { getSelectedStyle }  from '../Map/readsLayerStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable'; 
import { getDeviceAlertsList, getDeviceMessagesList, sendBehavior } from '../../state/mapDashboardThunks';
import { Alert } from '@material-ui/lab';
import { Select } from 'ol/interaction';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import { pointerMove } from 'ol/events/condition';
import { AgGridReact, ChangeDetectionStrategyType } from 'ag-grid-react';
import { ColDef, GridReadyEvent, RowClickedEvent, RowDoubleClickedEvent, RowGroupingDisplayType, ValueFormatterParams } from 'ag-grid-community';

type MarkerDetailProps = {
    openModal: boolean;
    marker?: MapMarker | null;
    onClose: () => void;
}

export const MarkerDetail = (props: MarkerDetailProps): JSX.Element => {
    const { openModal, onClose } = props;
    const classes = useStyles();

    const [marker, setMarker] = useState(props.marker ? props.marker : {} as MapMarker);
    const [moveFreq, setMoveFreq] = useState(60);
    const [restFreq, setRestFreq] = useState(24);
    const [openSuccessToast, setOpenSuccessToast] = useState(false);
    const [openFailedToast, setOpenFailedToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [messageItems, setMessageItems] = React.useState<GPSDeviceMessage[]>([]);
    const [map, setMap] = React.useState<OLMap>();
    const [messageDays, setMessageDays] = React.useState(10);
    const [alertItems, setAlertItems] = React.useState<GPSDeviceAlert[]>([]);
    const [alertDays, setAlertDays] = React.useState(10);
    

    useEffect(()=>{
        if(props.marker != null)
        setMarker(props.marker);
    },[props.marker]);

    const deviceName = marker.name;
    const googleLink = 'https://www.google.com/maps/dir/'+marker.deviceStatus?.latitude+','+marker.deviceStatus?.longitude+'/';

    let percentageColor = classes.black;
    if(+marker.deviceStatus?.batteryPercentage <= 25 )
    {
        percentageColor  = classes.red;
    }
    else if(+marker.deviceStatus?.batteryPercentage > 25 && +marker.deviceStatus?.batteryPercentage < 50)
    {
        percentageColor  = classes.orange;
    }
    else if(+marker.deviceStatus?.batteryPercentage >= 50)
    {
        percentageColor  = classes.green;
    }
    

    const changeSelectedRestFreqHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void =>{
        const newRestFreqValue = event.target.value as unknown as number;
        setRestFreq(newRestFreqValue);
    };

    const changeSelectedMoveFreqHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void =>{
        const newMoveFreqValue = event.target.value as unknown as number;
        setMoveFreq(newMoveFreqValue);
    };

    const showMarkerOnmap = () => {
        if(marker.deviceStatus != null)
        {
            const featureMarker = new Feature({
                geometry: new Point([marker.deviceStatus.longitude, marker.deviceStatus.latitude]),
                location_uuid: marker.id,
                ol_uid: marker.id
            });
    
            featureMarker.setStyle(readsLayerStyle(marker));
            //map with marker
            drawMap([featureMarker], 17)
        }
    };

    const showMarkerHistoryOnmap = () => {
        setIsLoading(true);
        getDeviceMessagesList(marker.imei, messageDays).then((result)=>{
            if(result != null && result.success)
            {
                
                const features: Feature<Geometry>[] = [];
                const messages: GPSDeviceMessage[] = [];
                result.result.items.forEach((point) =>{
                    if(point.type == 'GTFRI')//GTFRI are message types with valid coordinates
                    {
                        const featureMarker = new Feature({
                            geometry: new Point([point.longitude, point.latitude]),
                            location_uuid: point.message_id,
                            ol_uid: point.message_id
                        });
                
                        featureMarker.setStyle(readsLayerStyle(marker));
                        featureMarker.set('currentPoint', point);
                        featureMarker.set('id', point.message_id);
    
                        features.push(featureMarker);
                        messages.push(point);
                    }
                });
                setMessageItems(messages);
                drawMap(features, 10);
            }
            else
            {
                setOpenFailedToast(true);
            }
            setIsLoading(false);
        });
    };

    const showMarkerAlertOnmap = () => {
        setIsLoading(true);
        const types = 'LowBattery,Movement';
        getDeviceAlertsList(marker.imei, alertDays, types).then((result)=>{
            if(result != null && result.success)
            {
                const features: Feature<Geometry>[] = [];
                const alerts: GPSDeviceAlert[] = [];
                result.result.items.forEach((point) =>{
                    const featureMarker = new Feature({
                        geometry: new Point([point.coordinates.longitude, point.coordinates.latitude]),
                        location_uuid: point.alertId,
                        ol_uid: point.alertId
                    });
            
                    featureMarker.setStyle(readsLayerStyle(marker));
                    featureMarker.set('currentPoint', point);
                    featureMarker.set('id', point.alertId);

                    features.push(featureMarker);
                    alerts.push(point);
                });
                setAlertItems(alerts);
                drawMap(features, 10);
            }
            else
            {
                setOpenFailedToast(true);
            }
            setIsLoading(false);
        });
    };

    const drawMap= (features: Feature<Geometry>[], zoomLevel: number) =>{
        const mapElemnt = document.getElementById('map-detail-container-id');
        if(mapElemnt != null)
        {
            mapElemnt.innerHTML = '';
        }
        const newMap = new OLMap({
            target: 'map-detail-container-id',
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                
            ],
            view: new View({
                center: [marker.deviceStatus.longitude, marker.deviceStatus.latitude],
                zoom: zoomLevel,
                projection: 'EPSG:4326',
            })
        });

        const vLayer = new VectorLayer({
            source: new VectorSource({
                features:features
            }),
        });
        vLayer.set('name', 'mainLayer');
        newMap.addLayer(vLayer);
        
        //on hover interaction
        const tooltip = document.getElementById('tooltip-id') as HTMLElement;
        const overlay = new Overlay({
                    element: tooltip,
                    positioning: OverlayPositioning.BOTTOM_CENTER,
                    stopEvent: false,
                    offset: [0, -50],
                  });
        

        
        const selectOnHover = new Select({
            condition: pointerMove
        });

        selectOnHover.on('select', function (e) 
        {
            if(e.target.getFeatures().getLength() > 0)
            {
                const feature = e.target.getFeatures().getArray()[0];
                const coordinates = feature.getGeometry().getCoordinates();
                const marker = feature.get('currentPoint') as GPSDeviceMessage;
                if(marker != null && tooltip != null)
                {
                    overlay.setPosition(coordinates);
                    tooltip.innerHTML = moment((new Date(marker.sendTime))).format('YYYY/MM/DD hh:mm:ss a');
                    newMap.addOverlay(overlay);
                }
            }
            else
            {
                newMap.removeOverlay(overlay);
            }
        });    
        newMap.addInteraction(selectOnHover);

        setMap(newMap);
    };

    const  highlightMessageFeature = (selectedFeature: Feature | undefined): void =>{
        if(selectedFeature != null)
        {
            clearStyles();
            selectedFeature.setStyle(getSelectedStyle());
            const currentPoint = selectedFeature.get('currentPoint') as GPSDeviceMessage;
            map?.getView().setCenter([currentPoint.longitude, currentPoint.latitude]);
        }
     };

     const  highlightAlertFeature = (selectedFeature: Feature | undefined): void =>{
        if(selectedFeature != null)
        {
            clearStyles();
            selectedFeature.setStyle(getSelectedStyle());
            const currentPoint = selectedFeature.get('currentPoint') as GPSDeviceAlert;
            map?.getView().setCenter([currentPoint.coordinates.longitude, currentPoint.coordinates.latitude]);
        }
     };

     const clearStyles = (): void =>{
        const mainLayer = map?.getLayers().getArray().find(layer => layer.get('name') == 'mainLayer') as Vector;
        const mesasgeSource = mainLayer?.getSource();
        const sourceFeatures = mesasgeSource?.getFeatures();
        sourceFeatures?.forEach(feature => {
            feature.setStyle(readsLayerStyle(marker));
        });
    };

    const sendFrequBehavior = (): void =>{
        const params: Record<string, any> = {};
        params.rest_frequency = restFreq;
        params.moving_frequency = moveFreq;
        setIsLoading(true);
        sendBehavior('REPORT_FREQ', marker.imei, params).then((result)=>{
            if(result != null && result.success)
            {
                setOpenSuccessToast(true);
                console.log(result.result?.taskId);
            }
            else
            {
                setOpenFailedToast(true);
            }
            setIsLoading(false);
        });
    };

    const closeSuccessToastMessage = (): void =>{
        setOpenSuccessToast(false);
    };

    const closeFailedToastMessage = (): void =>{
        setOpenFailedToast(false);
    };

   const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const handleMessageDaysChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void =>{
        const newMEssageDaysValue = event.target.value as unknown as number;
        setMessageDays(newMEssageDaysValue);
    };

    const handleAlertDaysChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void =>{
        const newAlertDaysValue = event.target.value as unknown as number;
        setAlertDays(newAlertDaysValue);
    };

    const onCloseModal: (ev: any) => void = () => {
        setTabValue(0);//when modal close restart first tab
        onClose();
    };

    useEffect(()=>{
        showMarkerHistoryOnmap();
    }, [messageDays]);

    useEffect(()=>{
        showMarkerAlertOnmap();
    }, [alertDays]);

    useEffect(()=>{
        if(openModal)
        {
            setMessageItems([]);
            setAlertItems([]);
            if(tabValue == 0)
            {
                showMarkerOnmap();   
            }
            else if(tabValue == 1)
            {
                showMarkerHistoryOnmap();
            }
            else if(tabValue == 2)
            {
                showMarkerAlertOnmap();
            }
        }
    },[tabValue, openModal]);

    const [dialogRender, setdialogRender] = useState(() => (props: any) =>{
        return (
             <Draggable
                 handle='#simple-modal-title'
                 cancel={'[class*="MuiDialogContent-root"]'}
                 
             >
                 <Paper {...props} />
             </Draggable>
             );
     });

     const messageColumns: ColDef [] = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            aggFunc: 'first',
        },
        {
            field: 'sendTime',
            headerName: 'Send Time',
            editable: false,
            minWidth: 150,
            sortable: true,
            sort: 'asc',
            valueFormatter: (params: ValueFormatterParams) => {
                return moment((new Date(params.data.sendTime))).format('YYYY/MM/DD hh:mm:ss a');
             },
        },
        {
          field: 'latitude',
          headerName: 'Latitude',
          flex: 0.4,
          editable: false,
          resizable: true,
        },
        {
            field: 'longitude',
            headerName: 'Longitude',
            flex: 1,
            editable: false,
            resizable: true,
        },
      ];

      const alertColumns: ColDef [] = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            aggFunc: 'first',
        },
        {
            field: 'alertType',
            headerName: 'Type',
            flex: 0.4,
            editable: false,
            resizable: true,
          },
        {
            field: 'sendTime',
            headerName: 'Send Time',
            editable: false,
            minWidth: 150,
            sortable: true,
            sort: 'asc',
            valueFormatter: (params: ValueFormatterParams) => {
                return moment((new Date(params.data.sendTime))).format('YYYY/MM/DD hh:mm:ss a');
             },
        },
        {
          field: 'coordinates.latitude',
          headerName: 'Latitude',
          flex: 0.4,
          editable: false,
          resizable: true,
        },
        {
            field: 'coordinates.longitude',
            headerName: 'Longitude',
            flex: 1,
            editable: false,
            resizable: true,
        },
      ];

      const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
          sortable: true,
          minWidth: 45,
          resizable: false,
          headerName: '.',
        };
      }, []);

      const onGridReady = function (event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
     };

     const selectMessageMarkerOnMap = function(event: RowClickedEvent){
        clearStyles();
        const mainLayer = map?.getLayers().getArray().find(layer => layer.get('name') == 'mainLayer') as Vector;
        const mesasgeSource = mainLayer?.getSource();
        const sourceFeatures = mesasgeSource?.getFeatures();
        highlightMessageFeature(sourceFeatures.find(x => x.get('id') == event.data.message_id));
     };

     const selectAlertMarkerOnMap = function(event: RowClickedEvent){
        clearStyles();
        const mainLayer = map?.getLayers().getArray().find(layer => layer.get('name') == 'mainLayer') as Vector;
        const mesasgeSource = mainLayer?.getSource();
        const sourceFeatures = mesasgeSource?.getFeatures();
        highlightAlertFeature(sourceFeatures.find(x => x.get('id') == event.data.alertId));
     };

    return (
        <Grid>
        <Dialog
            open={openModal}
            onClose={onCloseModal}
            onRendered={showMarkerOnmap}
            id='simple-modal-title'
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            PaperComponent={dialogRender}
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}
            hideBackdrop={true}>
            <DialogTitle style={{ cursor: 'move' }} id="simple-modal-title">
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Typography variant="h4">
                        {deviceName}
                    </Typography>
                    <IconButton onClick={onCloseModal}>
                            <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleTabChange} aria-label="tabs" variant='standard'>
                <Tab label="Details" />
                <Tab label="Location history"/>
                <Tab label="Alerts"/>
            </Tabs>
            <Grid>
                {tabValue == 0 && <Paper elevation={2} className={classes.modalContent} >
                    <Grid container direction="row" justify='flex-start'>
                        <Typography variant="h6">
                            Customer: 
                        </Typography>
                        <Typography variant="body1" className={classes.subText}>
                            {marker.description}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justify='flex-start'>
                        <Typography variant="h6">
                            Imei:
                        </Typography>
                        <Typography variant="body1" className={classes.subText}>
                            {marker.imei}
                        </Typography>
                    </Grid>
                    {
                        marker.deviceStatus != null &&
                    <Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Battery Percentage: 
                            </Typography>
                            <Typography variant="body1" className={percentageColor + ' ' + classes.subText}>
                            {marker.deviceStatus.batteryPercentage}%
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Last GPS Movement: 
                            </Typography>
                            <Typography variant="body1" className={classes.subText}>
                                {moment((new Date(marker.deviceStatus.gpsUTCTime.substring(0,4) + '-' + marker.deviceStatus.gpsUTCTime.substring(4,6)  + '-' + marker.deviceStatus.gpsUTCTime.substring(6,8) + ' ' + marker.deviceStatus.gpsUTCTime.substring(8,10) + ':' + marker.deviceStatus.gpsUTCTime.substring(10,12) + ':' + marker.deviceStatus.gpsUTCTime.substring(12,14) + ' UTC')).toString()).format('YYYY/MM/DD hh:mm:ss a')}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Last Report Sent: 
                            </Typography>
                            <Typography variant="body1" className={classes.subText}>
                                {moment((new Date(marker.deviceStatus.sendTime))).format('YYYY/MM/DD hh:mm:ss a')}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Altitude: 
                            </Typography>
                            <Typography variant="body1" className={classes.subText}>
                            {marker.deviceStatus.altitude}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Speed: 
                            </Typography>
                            <Typography variant="body1" className={classes.subText}>
                            {marker.deviceStatus.speed}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Address: 
                            </Typography>
                            <Typography variant="body2" className={classes.subText}>
                            {marker.deviceStatus.address}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Lat/Long: 
                            </Typography>
                            <Typography variant="body1" className={classes.subText}>
                                <Link href={googleLink} target="_blank" rel="noopener">{marker.deviceStatus.latitude},{marker.deviceStatus.longitude}</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    }
                    <Grid container direction="row" justify='flex-start'>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Moving Frequency: 
                            </Typography>
                            <Typography variant="body2" className={classes.subText}>
                                N/A
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justify='flex-start'>
                            <Typography variant="h6">
                                Rest Frequency:
                            </Typography>
                            <Typography variant="body1" className={classes.subText}>
                                N/A
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* <Grid container  direction="row" justify='flex-start' className={classes.controls}>
                        <Grid item xs={4} md="auto">
                            <FormControl className={classes.formControl} >
                                <InputLabel shrink className={classes.label}>Moving Frequency</InputLabel>
                                <Select
                                    displayEmpty
                                    value={moveFreq}
                                    name="movingFrequency"
                                    onChange={changeSelectedMoveFreqHandler}
                                    autoWidth={false}>
                                    <MenuItem value={15}>15 Minutes</MenuItem>
                                    <MenuItem value={60}>60 Minutes</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} md="auto">
                            <FormControl className={classes.formControl} >
                                <InputLabel shrink className={classes.label}>Rest Frequency</InputLabel>
                                <Select
                                    displayEmpty
                                    value={restFreq}
                                    name="restFrequency"
                                    onChange={changeSelectedRestFreqHandler}
                                    autoWidth={false}>
                                    <MenuItem value={1}>1 hour</MenuItem>
                                    <MenuItem value={24}>24 Hours</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} md="auto">
                            <Button
                                className={classes.readButton}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={isLoading}
                                onClick={(event) => {sendFrequBehavior()}}>
                                {isLoading ? 'Sending' : 'Send'}
                            </Button>
                        </Grid>
                    </Grid> */}
                    <Paper >
                        {<div className={classes.map} id='map-detail-container-id' ></div>}
                    </Paper>
                </Paper>}
                {tabValue == 1 && 
                <Paper elevation={2} className={classes.modalContent}>
                    <div className="ag-theme-alpine" style={{ height: '16vh', width: '100%' }}>
                        <AgGridReact
                            //ref={gridRef}
                            gridOptions={{ suppressCellSelection: true }}
                            columnDefs={messageColumns}
                            rowData={messageItems}
                            defaultColDef={{ resizable: true }}
                            //onSelectionChanged={onSelectionChanged}
                            groupDisplayType={RowGroupingDisplayType.SINGLE_COLUMN}
                            autoGroupColumnDef={autoGroupColumnDef}
                            animateRows={true}
                            rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                            onGridReady={onGridReady}
                            onRowClicked={selectMessageMarkerOnMap}
                            /*onRowDoubleClicked={(rowParams: RowDoubleClickedEvent) => {
                                rowParams.event?.preventDefault();
                                if(rowParams.data != null)//for regular selection
                                {
                                    onSelectMarker(rowParams.data.lprDataID);   
                                }
                                else if(rowParams.node != null && rowParams.node.aggData != null && rowParams.node.aggData.lprDataID != null)//for group selection
                                {
                                    onSelectMarker(rowParams.node.aggData.lprDataID);
                                }
                            }}*/
                            onGridColumnsChanged={event => event.api.sizeColumnsToFit()} />
                    </div>
                    <Grid container direction="row" justify='flex-start'>
                        <Typography variant="h6">
                            Vehicle seen: 
                        </Typography>
                        <Typography variant="body1" className={classes.subText}>
                            <Grid item xs={4} md="auto">
                                {messageItems.length} times in last 
                                <FormControl className={classes.formControl} >
                                    <ComboSelect
                                        displayEmpty
                                        value={messageDays}
                                        name="movingFrequency"
                                        onChange={handleMessageDaysChange}
                                        autoWidth={false}>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={60}>60</MenuItem>
                                        <MenuItem value={90}>90</MenuItem>
                                        <MenuItem value={120}>120</MenuItem>
                                    </ComboSelect>
                                </FormControl>
                                days
                            </Grid>
                        </Typography>
                    </Grid>
                    {isLoading && <CircularProgress />}
                    <Paper >
                        {<div className={classes.historyMap} id='map-detail-container-id' ></div>}
                    </Paper>
                    <div id="tooltip-id" className={classes.tooltip}></div>
                </Paper>}
                {tabValue == 2 && 
                <Paper elevation={2} className={classes.modalContent}>
                    <div className="ag-theme-alpine" style={{ height: '16vh', width: '100%' }}>
                        <AgGridReact
                            //ref={gridRef}
                            gridOptions={{ suppressCellSelection: true }}
                            columnDefs={alertColumns}
                            rowData={alertItems}
                            defaultColDef={{ resizable: true }}
                            //onSelectionChanged={onSelectionChanged}
                            groupDisplayType={RowGroupingDisplayType.SINGLE_COLUMN}
                            autoGroupColumnDef={autoGroupColumnDef}
                            animateRows={true}
                            rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                            onGridReady={onGridReady}
                            onRowClicked={selectAlertMarkerOnMap}
                            onGridColumnsChanged={event => event.api.sizeColumnsToFit()} />
                    </div>
                    <Grid container direction="row" justify='flex-start'>
                        <Typography variant="h6">
                            Alerts: 
                        </Typography>
                        <Typography variant="body1" className={classes.subText}>
                            <Grid item xs={4} md="auto">
                                {alertItems.length} in last 
                                <FormControl className={classes.formControl} >
                                    <ComboSelect
                                        displayEmpty
                                        value={alertDays}
                                        onChange={handleAlertDaysChange}
                                        autoWidth={false}>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={60}>60</MenuItem>
                                        <MenuItem value={90}>90</MenuItem>
                                        <MenuItem value={120}>120</MenuItem>
                                    </ComboSelect>
                                </FormControl>
                                days
                            </Grid>
                        </Typography>
                    </Grid>
                    {isLoading && <CircularProgress />}
                    <Paper >
                        {<div className={classes.historyMap} id='map-detail-container-id' ></div>}
                    </Paper>
                    <div id="tooltip-id" className={classes.tooltip}></div>
                </Paper>}
            </Grid>
        </Dialog>
        <Snackbar open={openSuccessToast} autoHideDuration={3000} onClose={closeSuccessToastMessage}>
            <Alert severity="success" >
                Command Sent!
            </Alert>
        </Snackbar>
        <Snackbar open={openFailedToast} autoHideDuration={3000} onClose={closeFailedToastMessage}>
            <Alert severity="error" >
                Command Failed!
            </Alert>
        </Snackbar>
      </Grid>
    );
};
