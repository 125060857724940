import { Get, RestClientV2 } from '../../services/RestClient';
import { ApplicationUser, Customer } from './sessionTypes';
import { Dispatch } from 'react';
import { ApplicationState } from '../store';
import { setCurrentUser, setLoading, setError } from './sessionStateSlice';
import { updateCustomer } from '../../components/dashboards/state/DashboardFilterState';
import { updateFilterActionPayload } from '../../components/dashboards/state/DashboardFilterActions';
import Cookies from 'js-cookie';

const fetchCurrentUserData = (): Promise<ApplicationUser> => {
    return new Promise((resolve, reject) => {
        RestClientV2.getNoHeaders({
            url: `${process.env.REACT_APP_BOOTVIEW_API_URL}/users/me`,
            params: {},
        }).then((response: ApplicationUser) => {
            resolve(response);
        }).catch((err) => {
            reject(err);
        });
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectInitialCustomer = (dispatch: Dispatch<any>, currentUser: ApplicationUser): void => {
    const dashboardFilterCookie: updateFilterActionPayload = (Cookies.getJSON('dashboardFilter') as unknown as updateFilterActionPayload);

    if (currentUser.customers.length > 0) 
    {
        const initialCustomerId = dashboardFilterCookie ? dashboardFilterCookie.customerId : getInitialCustomer(currentUser.customers)?.id;
        if (initialCustomerId != null) 
        {
            dispatch(updateCustomer({ customerId: initialCustomerId}));
        }
    }
};

const getInitialCustomer = (customers: Customer[]): Customer | null => {
    if (customers.length > 0) {
        let index = 0;
        let initialCustomer = customers[index];

        while (initialCustomer.code === 'AYT') {
            initialCustomer = customers[++index];
        }

        return initialCustomer;
    }

    return null;
};

const initializeSession = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        dispatch(setLoading(true));

        return fetchCurrentUserData().then((currentUser) => {
            currentUser.customers = fixCustomerIds(currentUser.customers);
            dispatch(setCurrentUser(currentUser));
            selectInitialCustomer(dispatch, currentUser);
        }).catch((err) => {
            dispatch(setError('Please login to continue.'));
        });
    };
};

const fixCustomerIds = (customers: Customer[]): Customer[] =>
{
    //if does not include dashes we need to add them, BV returns customerIds (GUIDs) without dashes, we have issues when sending them back to BV.
    if (customers.length > 0 && !customers[0].id.includes('-')) {
        customers.forEach(x=> {
            x.id= x.id.replace(/([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,'$1-$2-$3-$4-$5');
        });
    }

    return customers;
};

const getFatureToogle = (userId: any, key: string): Promise<boolean> => {
    return Get({
        url: `${process.env.REACT_APP_BOOTVIEW_API_URL}/users/hasFeatureToggle`,
        params: {
            UserId: userId,
            FeatureToggle: key,
        },
    }).then((response: boolean) => {
        return response;
    }).catch((err) => {
        return false;
    });
};

export { initializeSession, getInitialCustomer, getFatureToogle };
