import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';

export interface MapDashboardStateModel {
    isLoading: boolean;
    error: string | null;
    filter: MapFilterState;
    markers: MapMarker[];
    liveMarkers: MapMarker[];
    paginatedMarkers: MapMarker[];
    filteredMarkers: MapMarker[];
    vehiclesInHotlist: number;
    debtVehiclesInHotlist: number;
    debtVehiclesInHotlistSummary: number;
    pagination: PaginationResult;
    selectedMarker: MapMarker | null | undefined;
    filterSource: MapFilterSource;
    enforcementZones: EnforcementZone[];
    enforcementAssignationZones: EnforcementZoneAssignation[];
    bootviewUsers: BootviewUser[];
    showDebtRange: boolean;
}

export type MapMarker = {
    id: number;//for datagrid columns created manually on mapDashboardActions.tsx
    hotlistSrcLocIf: number;
    clientCode: string;
    licPlate: string;
    licState: string;
    makeModel: string;
    color: string;
    amtDue: number;
    hitStatus: string;
    hitAction: string;
    srcType: string;
    typeDate: Date;
    typeDateDay: string;
    sourceLL: number;
    sourceLLName: string;
    locationName: string;
    latitude: number;
    longitude: number;
    parkingZone: string;
    sourceAddress: number;
    addressNum: number;
    address: string;
    city: string;
    state: string;
    zip: string;
    numRunawaysSinceLastPayment: number;
    currentStatus: string;
    dataSource: string;
    fileDate?: Date;
    locImportSource: number;
    locImportSourceName: string;
    importDate?: Date;
    agentId: string;
    agentFullName: string;
    isLastPosition: boolean;
    bootRecord?: BootRecord;
    lprDataID?: string;
    occurrences: number;
    plateStatus: number;
}

export type BootRecord = {
    violationNumber: string;
    collectionAttemptId: string;
    violationUrl: string;
    filesImagesUrl: string;
    bootRecordStatusId: number;
}

export enum EnforcementDataType {
    Scan = 0,
    Hit = 1,
    Boot = 2
}

export type MapSearchResult = {
    results: MapMarker[];
    vehiclesInHotlist: number;
    debtVehiclesInHotlist: number;
    debtVehiclesInHotlistSummary: number;
    pagination: PaginationResult;
}

export type SuccessResult = {
    success: boolean;
}

export type EnforcementZoneResult = {
    success: boolean;
    message: string;
    results: EnforcementZone[];
}

export type EnforcementZone = {
    enforcementZoneId: string;
    zoneName: string;
    coordinates: string;
    points: string;
    customerId: string;
}

export type MapAddressSearchResult = {
    place_id: string;
    licence: string;
    lat: number;
    lon: number;
}

export type EnforcementZoneAssignationResult = {
    success: boolean;
    message: string;
    results: EnforcementZoneAssignation[];
}

export type BootviewUser = {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    alias: string;
    emailAddress: string;
}

export type EnforcementZoneAssignation = {
    enforcementZoneAssignationId: string;
    enforcementZoneIds: string;
    officerIds: string;
    weekDays: string;
    customerId: string;
}

export type Pagination = {
    currentPage: number;
    pageSize: number;
}

export type PaginationResult = {
    totalPages: number;
    totalItems: number;
} & Pagination;

export type MapFilterSource = {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    customerLongitude: number;
    customerLatitude: number;
}

export type BootingOfficer = {
    bootingOfficerId: string;
    name: string;
}

export type ParkingArea = {
    parkingAreaId: string;
    name: string;
}

export type OptirotueReadImages = {
    success: boolean;
    list: ImageList[];
}


export type ImageList = {
    read: Read;
    contextPhoto: string;
    lprPhoto: string;
}

export type Read = {
    lpr_read_id: number;
    read_date: string;
    license: string;
    state: string;
    lat: number;
    lng: number;
    processed: number;
    hits: number;
    user_session_id: number;
    guid: string;
    user_id: number;
    device_id: number;
    ssrs_id: string;
}

export enum MapFilterSearchBy {
    All = 0,
    Agents = 1,
    Locations = 2
}

export enum MapFilterDataType {
    Scan = 0,
    Hit = 1,
    Boot = 2
}

export enum EnforcementZoneModificationType {
    Insert = 0,
    Update = 1,
    Delete = 2,
    Select = 3
}

export enum AssignEnforcementZoneModificationType {
    Select = 0,
    Insert = 1,
    Delete = 2
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export const NoLocationGuid = '00000000-0000-0000-0000-000000000001';
export const NoLocationName = 'No Location';
export const ClearPlateConstant = '--clear-plate--';
export const PointType = 'point';
export const ZoneType = 'zone';

export enum MapLayerType {
    Reads = 1,
    Heat = 2,
    Route = 3,
}

export enum MapSourceType {
    LPR = 0,
    Ticket = 1
}

export const noDebtRange = '-1';
export const debtRangeOne = '0-100';
export const debtRangeTwo = '101-300';
export const debtRangeThree = '301-500';
export const debtRangeFour = '501-1000';
export const debtRangeFive = '1001-999999999';


export const all = 'All';
export const monday = 'Monday';
export const tuesday = 'Tuesday';
export const wednesday = 'Wednesday';
export const thursday = 'Thursday';
export const friday = 'Friday';
export const saturday = 'Saturday';
export const sunday = 'Sunday';


