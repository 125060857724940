import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionState } from './sessionTypes';
import { setLoadingAction, setErrorAction, setCurrentUserAction } from './sessionStateActions';

const sessionStateSlice = createSlice({
    name: 'sessionState',
    initialState: {
        isLoading: true,
        error: null,
        currentUser: null,
    } as SessionState,
    reducers: {
        setLoading: setLoadingAction,
        setError: setErrorAction,
        setCurrentUser: setCurrentUserAction,
    },
});

export const { reducer } = sessionStateSlice;
export const { setLoading, setError, setCurrentUser } = sessionStateSlice.actions;
