import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    contentGrid: {
        padding: theme.spacing(1),
        marginTop: 24
    },
    content: {
        padding: 0,
    },
    title: {
        color: '#70677b',
    },
    icon: {
        color: '#70677b',
        marginRight: theme.spacing(1),
    },
}));
