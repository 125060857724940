import { Grid, Paper, Typography } from '@material-ui/core';
import { Subject as TitleIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import clone from 'rfdc';

import { Get } from '../../../services/RestClient';
import AverageCard from '../../dashboards/Commons/AverageCard/AverageCard';
import { DashboardFilterState } from '../../dashboards/state/DashboardFilterState';
import FileDateCard from './FileDateCard';
import { useStyles } from './ImportFilesStyles';
import ScofflawSummary from './ImportFilesSummary';

type ImportFilesProps = {
    filter: DashboardFilterState;
}

export default function ImportFiles (props: ImportFilesProps): JSX.Element {
    const classes = useStyles();

    const { filter } = props;

    const [state, setState] = useState(new ScofflawSummary);

    useEffect(() => {
        fetchData(setState, state, filter);
    }, [filter]);

    return (
        <Paper className={classes.root} elevation={1}>
            <Grid container spacing={2}>
                <Grid item container alignContent="center" alignItems="center">
                    <TitleIcon className={classes.icon} />
                    <Typography variant="h6" className={classes.title}>
                        Hotlist
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justify="space-between" alignItems="stretch" alignContent="center">
                <Grid item xs={12} md={3} container direction="row" alignItems="stretch" alignContent="stretch">
                    <AverageCard title="Total Plates"
                        subtitle={filter.averageDescription}
                        total={state.vehiclesImported.total}
                        averagePerPeriod={state.vehiclesImported.averagePerPeriod}
                        isLoading={state.isLoading} />
                </Grid>
                <Grid item md={3} container direction="row" alignItems="stretch" alignContent="stretch">
                    <AverageCard title="New Plates"
                        subtitle={filter.averageDescription}
                        total={state.vehiclesAdded.total}
                        averagePerPeriod={state.vehiclesAdded.averagePerPeriod}
                        isLoading={state.isLoading} />
                </Grid>
                <Grid item md={3} container direction="row" alignItems="stretch" alignContent="stretch">
                    <AverageCard title="Debt Due"
                        subtitle={filter.averageDescription}
                        total={state.dollarsInFile.total}
                        averagePerPeriod={state.dollarsInFile.averagePerPeriod}
                        isLoading={state.isLoading}
                        isCurrency={true} />
                </Grid>
                <Grid item md={3} container direction="row" alignItems="stretch" alignContent="stretch">
                    <FileDateCard title="Updated Last" data={{updatedLastDate: state.fileDate}} loading={state.isLoading} />
                </Grid>
            </Grid>
        </Paper>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchData = (setState: React.Dispatch<any>, state: ScofflawSummary, filter: DashboardFilterState): Promise<void> => {
    if (!filter.customerId) {
        setState({...state, isLoading: true, error: null});
        return Promise.resolve();
    }

    if (!state.isLoading) {
        setState({...state, isLoading: true, error: null});
    }

    return Get({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/hotlist/summary`,
        params: {
            customerId: filter.customerId,
            daysToAverage: filter.daysToAverage,
            date: filter.date,
        },
    }).then((summary: ScofflawSummary) => {
        summary.isLoading = false;
        summary.error = null;
        setState(clone({proto: true})(summary));
    }).catch((err) => {
        setState(new ScofflawSummary(false, err));
    });
};
