import { api as statTrakAPI } from '../../services/apiService/statTrakServiceAPI';
import { deserializeTileJsonData } from '../dashboard-tile-builder/state/tileBuilderThunks';
import { CustomDashboard } from './types';

export const fetchDashboard = (dashboardId: string): Promise<CustomDashboard> => {
    return new Promise((resolve, reject) => {
        if (!dashboardId) {
            return reject('No dashboard id provided.');
        }

        statTrakAPI.get(`stattrak/dashboardBuilder/${dashboardId}`).then((response) => {
            const dashboard = response.data as CustomDashboard;
            if (dashboard.tiles) {
                dashboard.tiles.forEach((tile) => {
                    const tileModel = deserializeTileJsonData(tile.tileModelPoco);
                    tile.tileModel = tileModel;
                })
            }

            return resolve(dashboard);
        }).catch((error) => {
            console.log(`Error while trying to fetch custom dashboard. DashboardId: ${dashboardId}.`);
            return reject(error);
        })
    })
};

export const deleteDashboard = (dashboardId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (!dashboardId) {
            return reject('No dashboard id provided.');
        }

        statTrakAPI.delete(`stattrak/dashboardBuilder/${dashboardId}`).then((response) => {
            return resolve(response.data);
        }).catch((error) => {
            console.log(`Error while trying to delete custom dashboard. DashboardId: ${dashboardId}.`);
            return reject(error);
        })
    });
};
