import AverageData from '../../dashboards/Commons/AverageCard/AverageData';
import { DashboardModel as DashboardModel } from '../../dashboards/Commons/types';

export default class ScofflawSummary implements DashboardModel {
    isLoading = false;
    error: string | null = null;
    vehiclesImported: AverageData = new AverageData();
    vehiclesAdded: AverageData = new AverageData();
    dollarsInFile: AverageData = new AverageData();
    fileDate: Date = new Date();

    constructor (
        isLoading?: boolean,
        error?: string | null,
        vehiclesImported?: AverageData,
        vehiclesAdded?: AverageData,
        dollarsInFile?: AverageData,
        fileDate?: Date) {
        this.isLoading = isLoading ?? false;
        this.error = error ?? null;
        this.vehiclesImported = vehiclesImported ?? new AverageData();
        this.vehiclesAdded = vehiclesAdded ?? new AverageData();
        this.dollarsInFile = dollarsInFile ?? new AverageData();
        this.fileDate = fileDate ?? new Date();
    }
}
