import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AgGridReact } from 'ag-grid-react';
import { GridReadyEvent } from 'ag-grid-community';
import { ContactSupport as TitleIcon } from '@material-ui/icons';
import clone from 'rfdc';
import moment from 'moment';

import { Get } from '../../../../services/RestClient';
import AverageCard from '../../Commons/AverageCard/AverageCard';
import AveragePieCard from '../../Commons/AveragePieCard/AveragePieCard';
import AverageVerticalBarCard from '../../Commons/AverageVerticalBarCard/AverageVerticalBarCard';
import { useStyles } from './HelpCenterStyles';
import HelpCenterSummary from './HelpCenterSummary';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import { DataGridRow, GridHeader, GridItem } from '../../Commons/types';
import { getCellBootedColor, getCellColor, getCellTotalColor } from '../../../../utils/CellColorParser';

type HelpCenterProps = {
    filter: DashboardFilterState;
}

export default function HelpCenter(props: HelpCenterProps): JSX.Element {
    const classes = useStyles();

    const { filter } = props;

    const [state, setState] = useState(new HelpCenterSummary);

    useEffect(() => {
        fetchData(setState, state, filter);
    }, [filter]);

    const onGridReady = function (event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    };

    return (
        <>
            {/* <Paper className={classes.root} elevation={1}>
                <Grid container spacing={2}>
                    <Grid item container alignContent="center" alignItems="center">
                        <TitleIcon className={classes.icon} />
                        <Typography variant="h6" className={classes.title}>
                            Help Center
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row" justify="flex-start" alignItems="stretch" alignContent="stretch">
                    <Grid item xs={12} md={4} lg={3}>
                        <AveragePieCard title="Avg. Call Total Time (minutes)"
                            subtitle={filter.averageDescription}
                            value={state.callsTotalTime.total}
                            averageValue={state.callsTotalTime.averagePerPeriod}
                            isLoading={state.isLoading}
                            moreIsBetter={false} />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <AverageVerticalBarCard title="Avg. Call Talk Time (minutes)"
                            subtitle={filter.averageDescription}
                            value={state.callsTalkTime.total}
                            averageValue={state.callsTalkTime.averagePerPeriod}
                            isLoading={state.isLoading} />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <AverageVerticalBarCard title="Calls Taken"
                            subtitle={filter.averageDescription}
                            value={state.callsTaken.total}
                            averageValue={state.callsTaken.averagePerPeriod}
                            isLoading={state.isLoading} />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <AveragePieCard title="Amount Collected (dollars)"
                            subtitle={filter.averageDescription}
                            value={state.amountCollected.total}
                            averageValue={state.amountCollected.averagePerPeriod}
                            isLoading={state.isLoading} />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <AveragePieCard title="Avg. Call Wrap-Up Time (minutes)"
                            subtitle={filter.averageDescription}
                            value={state.callsWrapUpTime.total}
                            averageValue={state.callsWrapUpTime.averagePerPeriod}
                            isLoading={state.isLoading}
                            moreIsBetter={false} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AverageVerticalBarCard title="Avg. Call Hold Time (minutes)"
                            subtitle={filter.averageDescription}
                            value={state.callsHoldTime.total}
                            averageValue={state.callsHoldTime.averagePerPeriod}
                            isLoading={state.isLoading}
                            moreIsBetter={false} />
                    </Grid>

                    // <Grid item xs={12} md={3}>
                    //     <AverageVerticalBarCard title="Speed to Answer"
                    //         subtitle={filter.averageDescription}
                    //         value={state.data.speedToAnswer.value}
                    //         averageValue={state.data.speedToAnswer.averageValue}
                    //         isLoading={state.isLoading} />
                    // </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <AverageCard title="Payments Taken"
                            subtitle={filter.averageDescription}
                            total={state.paymentsTaken.total}
                            averagePerPeriod={state.paymentsTaken.averagePerPeriod}
                            isLoading={state.isLoading} />
                    </Grid>
                </Grid>
            </Paper> */}
            <Paper className={classes.root} elevation={1}>
                <Typography variant="button" color="primary">
                 {`Help Center Data for ${ moment(filter.date).format('dddd')}, ${moment(filter.date).format('MM/DD/YYYY')}`}
                </Typography>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ height: '213px', width: '100%' }}>
                        {
                            state.isLoading
                                ? <HelpCenterGridSkeleton />
                                : <AgGridReact
                                    overlayNoRowsTemplate={'<span>Help Center data not available for this client</span>'}
                                    gridOptions={{ suppressCellSelection: true }}
                                    columnDefs={state.columnDefinitions}
                                    rowData={state.rows}
                                    defaultColDef={{ resizable: true }}
                                    onGridReady={onGridReady}
                                    onGridColumnsChanged={event => event.api.sizeColumnsToFit()} />
                        }
                    </div>
                </Grid>
            </Paper>
        </>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchData = (setState: React.Dispatch<any>, state: HelpCenterSummary, filter: DashboardFilterState): Promise<void> => {
    if (!filter.customerId) {
        setState({ ...state, isLoading: true, error: null });
        return Promise.resolve();
    }

    if (!state.isLoading) {
        setState({ ...state, isLoading: true, error: null });
    }

    return Get({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/help-center/summary`,
        params: {
            customerId: filter.customerId,
            date: filter.date,
            daysToAverage: filter.daysToAverage,
        },
    }).then((summary: HelpCenterSummary) => {
        const columnDefinitions = getColumnDefinitions(filter.daysToAverage);
        const rows = getRows(summary);

        summary.isLoading = false;
        summary.error = null;

        summary.columnDefinitions = columnDefinitions;
        summary.rows = rows;

        setState(clone({ proto: true })(summary));
    }).catch((err) => {
        setState(new HelpCenterSummary(false, err));
    });
};

const getColumnDefinitions = (daysToAverage: number): Array<{ headerName: string; field: string, pinned?: string }> => {
    const columnDefinitions: any = [
        { headerName: 'Description', field: 'dataType', headerClass: 'text-center', minWidth: 96, pinned: 'left', cellStyle: { textAlign: 'center',  fontSize:12 }, suppressMovable: true },
        { headerName: (+daysToAverage/7) + ' Wk Avg', headerClass: 'text-center', field: 'wkAvg', minWidth: 96, pinned: 'left', cellStyle: { textAlign: 'center',  fontSize:12 }, suppressMovable: true },
        { 
            headerName: 'Total',
            headerClass: 'text-center',
            field: 'total', 
            minWidth: 69, 
            pinned: 'left', 
            cellStyle: (params: any) => { 
                const colorIndicator = getCellTotalColor(params.data.total, params.data.wkAvg);
                return { ...colorIndicator, textAlign: 'center', fontSize:12 };
            },
            suppressMovable: true
        }
    ];
    
    headers().forEach((header) => {
        columnDefinitions.push({
            headerName: header.name,
            field: 'column-data-' + (header.ordinal as unknown as string),
            minWidth: 51,
            cellStyle: (params: any) => { 
                let colorIndicator = null;
                
                if(params.data.dataType === 'Booted' && params.data[`hit-${params.colDef.field}`] && params.data[`hit-${params.colDef.field}`] > 0){
                    colorIndicator = getCellBootedColor(params.data[`hit-${params.colDef.field}`], params.value);
                } else {
                    colorIndicator = getCellColor(params.data.min, params.data.max, params.data.totalIndicator, params.value);
                }

                return { ...colorIndicator, textAlign: 'center', fontSize:12};
            },
            suppressMovable: true
        });
    });

    return columnDefinitions;
};

const getRows = (summary: HelpCenterSummary): Array<DataGridRow> => {
    const rows = [];
    rows.push(dataRow('Received', summary.receivedDetails, summary.receivedAvg, summary.receivedTotal));
    rows.push(dataRow('Answered', summary.answeredDetails, summary.answeredAvg, summary.answeredTotal));
    rows.push(dataRow('Abandoned', summary.abandonedDetails, summary.abandonedAvg, summary.abandonedTotal));

    return rows;
};

function HelpCenterGridSkeleton() {
    return (
        <Box style={{ height: '100%' }}>
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
        </Box>
    );
}

function headers (): GridHeader[] {
        const headers = [];

        for (let i = 0; i <= 23; i++) {
            if(i === 0){
                headers.push(new GridHeader('12AM', i));
            } else if ((i >= 1 && i <= 11)){
                headers.push(new GridHeader(`${i}AM`, i));
            } else {
                headers.push(new GridHeader(`${i > 12 ? (i-12) : i}PM`, i));
            }
        }

        return headers;
}

function dataRow (dataType: string, data: Array<{ timeDuration: number; numberOfEvents: number }>, wkAverage: number, dataTotal: number) {
    const row = new DataGridRow();
    const min = Math.min.apply(null, data.filter((df) => df.numberOfEvents > 0).map((dm) => dm.numberOfEvents));
    const max = Math.max.apply(null,  data.filter((df) => df.numberOfEvents > 0).map((dm) => dm.numberOfEvents));

    row.dataType = dataType;
    row['wkAvg'] = wkAverage;
    row['total'] = dataTotal;
    row['min'] = min;
    row['max'] = max;
    row['totalIndicator'] = (max - min);

    data.forEach((item) => {
        row[`column-data-${(item.timeDuration as unknown as string)}`] = item.numberOfEvents;
    });

    return row;
}
