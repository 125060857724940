import { Box, AppBar, Toolbar, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import Iframe from 'react-iframe';
import { DashboardFilterBar } from '../dashboards/Commons';
import { ColorScheme } from '../dashboards/Main';
import theme from '../themes/base';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import { useStyles } from './SsrsStyles';

export default function Ssrs () {
    const classes = useStyles();
    const appBarPosition = useMediaQuery(theme.breakpoints.up('md')) ? 'fixed' : 'relative';

    return (
        <Box style={{height: '100%'}}>
            <AppBar position={appBarPosition} className={classes.appBar} elevation={1}>
                <Toolbar>
                    <Grid container spacing={3} justify="space-between" alignItems="center" alignContent="center">
                        <Grid item xs={12} md={3} container alignItems="center" alignContent="center">
                            <DashboardIcon className={classes.icon} />
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Dashboard
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} container justify="flex-end" alignItems="center" alignContent="center">
                            <Grid item xs={12} md={3}>
                            </Grid>
                            <Grid item xs={12} md={9}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {
                useMediaQuery(theme.breakpoints.up('md')) && <Toolbar className={classes.emptyToolbar} />
            }
            <Box className={classes.root}>
                <Grid container direction="row" justify="space-between" alignItems="stretch" alignContent="stretch" style={{height: '100%'}}>
                    <Grid item xs={12} style={{height: '100%'}}>
                        <Iframe
                            url="http://reporting.paylock.com/portal/browse/"
                            width="100%"
                            height="100%"
                            id="myId"
                            className="myClassname"
                            display="inline"
                            position="relative" />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
