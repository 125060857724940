import React, { useEffect, useReducer } from 'react';
import { Grid, Button, Select, MenuItem, Input, InputLabel, Paper, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { BootingOfficer, ParkingArea, MapFilterSearchBy, MapFilterDataType, MapLayerType, MapDashboardStateModel, Scenario } from '../../state/mapDashboardStateV2';
import { useStyles } from './mapFilterBarStyles';
import { MultipleSelect } from '../../../Commons/MultipleSelect/MultipleSelect';
import { MapFilterState, mapFilterStateReducer, mapFilterStateInit, ACTION_TYPES } from './mapFilterState';
import { ApplicationState } from '../../../../../app/store';
import { SessionState } from '../../../../../app/session/sessionTypes';
import { useDispatch, useSelector } from 'react-redux';
import * as DashboardFilterState from '../../../state/DashboardFilterState';
import { savePreviousSearch } from '../../state/mapScenariosDashboardSlice';

type MapFilterBarProps = {
    isLoading: boolean;
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    onApply: (filter: MapFilterState) => void;
    onClearMap: () => void;
    filterBy?: string | null;
    filterQ?: string | null;
    onFilterMapZone: () => void;
}

export default function MapFilterBar (props: MapFilterBarProps): JSX.Element {
    const { isLoading, bootingOfficers, parkingAreas, onApply, onClearMap, filterBy, filterQ, onFilterMapZone } = props;
    const classes = useStyles();

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mapScenariosDashboard as MapDashboardStateModel,
            session: state.session as SessionState,
        };
    };
    const globalState = useSelector(selector);

    //determine scanrio selected
    const  isRegularScenario = (globalState.dashboardFilter.scenario == Scenario.Boots || globalState.dashboardFilter.scenario == Scenario.Hits || globalState.dashboardFilter.scenario == Scenario.Scans);
    const  isTowEligibleScenario = (globalState.dashboardFilter.scenario == Scenario.TowEligible);
    
    const [state, dispatch] = useReducer(mapFilterStateReducer, { bootingOfficers, parkingAreas }, mapFilterStateInit);
    const dispatchDashboard = useDispatch();

    useEffect(() => {
        dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_BY, payload: { searchBy: state.searchBy, bootingOfficers, parkingAreas, filterBy, filterQ } });
    }, [bootingOfficers, parkingAreas]);

    const changePlateHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const plate = event.target.value as unknown as string;
        dispatch({ type: ACTION_TYPES.UPDATE_PLATE, payload: plate });
    };

    const changeEligibleHours = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const eligibleHours = (event.target.value != null && event.target.value != '') ? (+event.target.value as number) : 0 as number;
        dispatch({ type: ACTION_TYPES.UPDATE_ELIGIBLE_HOURS, payload: eligibleHours });
    };

    const changeSearchByHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const newSearchByValue = event.target.value as unknown as number;
        dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_BY, payload: { searchBy: newSearchByValue, bootingOfficers, parkingAreas } });
    };

    const changeSelectedAgentsHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_AGENTS, payload: selected });
    };

    const changeSelectedLocationsHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LOCATIONS, payload: selected });
    };

    const changeSelectedDataTypesHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_DATA_TYPES, payload: selected });
    };

    const changeSelectedLayerTypeHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const selected = event.target.value as number;
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LAYER_TYPE, payload: selected });
    };

    const changeSavePreviousSearchBox = (selected: boolean): void => {
        dispatchDashboard(savePreviousSearch(selected));
    };

    return (
        <Paper elevation={1} className={classes.root}>
            <Grid  container spacing={3} alignItems="flex-end">
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Plate</InputLabel>
                        <Input
                            fullWidth
                            value={state.plate}
                            onChange={changePlateHandler}
                            name="plate"
                            placeholder="Plate" />
                    </FormControl>
                </Grid>
                {
                    isRegularScenario &&
                    <Grid item xs={12} md="auto">
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel shrink className={classes.label}>Agents</InputLabel>
                            <MultipleSelect
                                id="searchByAgents"
                                disabled={state.searchBy !== MapFilterSearchBy.All && state.searchBy !== MapFilterSearchBy.Agents}
                                includeSelectAll={true}
                                currentSelected={state.selectedAgentsToSearchBy}
                                type="checkbox"
                                onChange={changeSelectedAgentsHandler}
                                selectableItems={bootingOfficers.map((bo) => ({ id: bo.bootingOfficerId, name: bo.name }))}
                            />
                        </FormControl>
                    </Grid>
                }
                {
                    isRegularScenario &&
                    <Grid item xs={12} md="auto" className={classes.hideComponent}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel shrink className={classes.label}>Search by</InputLabel>
                            <Select
                                displayEmpty
                                value={state.searchBy}
                                onChange={changeSearchByHandler}
                                name="searchBy"
                                autoWidth={false}>
                                <MenuItem value={MapFilterSearchBy.All}>All</MenuItem>
                                <MenuItem value={MapFilterSearchBy.Agents}>Agents</MenuItem>
                                <MenuItem value={MapFilterSearchBy.Locations}>Locations</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {
                    isRegularScenario &&
                    <Grid item xs={12} md="auto">
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel shrink className={classes.label}>Locations</InputLabel>
                            <MultipleSelect
                                id="searchByLocations"
                                disabled={state.searchBy !== MapFilterSearchBy.All && state.searchBy !== MapFilterSearchBy.Locations}
                                includeSelectAll={true}
                                currentSelected={state.selectedLocationsToSearchBy}
                                type="checkbox"
                                onChange={changeSelectedLocationsHandler}
                                selectableItems={parkingAreas.map((pa) => ({ id: pa.name, name: pa.name }))}
                            />
                        </FormControl>
                    </Grid>
                }
                {/* <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Type</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.selectedDataTypes}
                            type="checkbox"
                            onChange={changeSelectedDataTypesHandler}
                            selectableItems={[
                                { id: MapFilterDataType.Scan as unknown as string, name: 'Scans' },
                                { id: MapFilterDataType.Hit as unknown as string, name: 'Hits' },
                                { id: MapFilterDataType.Booted as unknown as string, name: 'Boots' },
                            ]}
                            renderAllSelected={true}
                        />
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} md="auto">
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Show as</InputLabel>
                        <Select
                            displayEmpty
                            value={state.layerType}
                            name="showAs"
                            onChange={changeSelectedLayerTypeHandler}
                            autoWidth={false}>
                            <MenuItem value={MapLayerType.Reads}>Reads</MenuItem>
                            <MenuItem value={MapLayerType.Heat}>Heat</MenuItem>
                            {/*<MenuItem value={MapLayerType.Route}>Route</MenuItem>*/}
                        </Select>
                    </FormControl>
                </Grid>
                {
                isTowEligibleScenario &&
                <Grid item xs={4} md="auto">
                    <FormControl className={classes.formControl + ' '+ classes.InputEligibleHours} fullWidth>
                        <InputLabel shrink className={classes.label}>Eligible In (Hours)</InputLabel>
                        <Input
                            fullWidth
                            value={state.towEligibleHours}
                            onChange={changeEligibleHours}
                            name="eligibleHours"
                            type='number'
                            placeholder="Hours" />
                    </FormControl>
                </Grid>
                }
                <Grid item xs={12} md="auto">
                    <Button
                        variant="contained"
                        className={classes.searchButton}
                        startIcon={<SearchIcon />}
                        onClick={() => onApply(state)}
                        disabled={isLoading}>
                        {
                            !isLoading ? 'Search' : 'Searching...'
                        }
                    </Button>
                </Grid>
                <Grid item xs={12} md="auto">
                    <FormControlLabel
                        className={classes.formControl}
                        control={<Checkbox checked={globalState.data.savePreviousSearch} onChange={(event) => changeSavePreviousSearchBox(event.target.checked)} name="chkSavePS" />}
                        label="Show Previous Search"
                    />
                    {
                        globalState.data.savePreviousSearch &&
                        <Button
                        variant="contained"
                        className={classes.searchButton}
                        onClick={() => onClearMap()}
                        disabled={isLoading}>
                        {
                            'Clear Previous Search'
                        }
                    </Button>
                    }
                </Grid>
                <Grid item xs={12} md="auto">
                    <Button
                        variant="contained"
                        className={classes.searchButton}
                        onClick={onFilterMapZone}
                        disabled={isLoading}>
                        {
                            !isLoading ? 'Zone Filter' : 'Searching...'
                        }
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
