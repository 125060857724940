import { BaseState, ReducerAction, ReducerActionType } from '../BaseState';
import InformationOverTimeSummary, { TableType, DataGridRow } from './InformationOverTimeSummary';

export default class InformationOverTimeState implements BaseState<InformationOverTimeSummary> {
    isLoading: boolean;
    isError: string | null;
    filter: {
        tableType: TableType;
        from: Date;
        to: Date;
    };
    summary: InformationOverTimeSummary;
    columnDefinitions: Array<{ headerName: string; field: string }>;
    rows: Array<DataGridRow>;

    constructor (
        isLoading?: boolean,
        filter?: any,
        summary?: InformationOverTimeSummary,
        columnDefinitions?: Array<{ headerName: string; field: string }>,
        rows?: Array<DataGridRow>) {

        if (!filter) {
            filter = {
                tableType: TableType.Consolidated,
                from: (new Date().setHours(8, 0, 0)),
                to: (new Date().setHours(20, 0, 0)),
            };
        }

        this.filter = filter;
        this.isLoading = isLoading ? isLoading : false;
        this.isError = null;
        this.summary = summary ? summary : new InformationOverTimeSummary();
        this.columnDefinitions = columnDefinitions ? columnDefinitions : new Array<{ headerName: string; field: string }>();
        this.rows = rows ? rows : new Array<DataGridRow>();
    }
}

export const reducer = (state: InformationOverTimeState, action: ReducerAction): InformationOverTimeState => {
    switch (action.type) {
        case ReducerActionType.IsLoading:
            return { ...state, isLoading: action.data, isError: null };
        case ReducerActionType.IsError:
            return { ...state, isLoading: false, isError: action.error ? action.error : null };
        case ReducerActionType.FilterUpdate:
            return { ...state, isLoading: false, isError: null, filter: action.data };
        case ReducerActionType.SummaryUpdate:
            return {
                ...state,
                isLoading: false,
                isError: null,
                summary: action.data.summary,
                columnDefinitions: action.data.columnDefinitions,
                rows: action.data.rows,
            };
        default:
            return new InformationOverTimeState();
    }
};
