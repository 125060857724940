import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    content: {
        padding: 0,
    },
    infoColor: {
        color: theme.palette.info.main,
    },
    infoBgColor: {
        backgroundColor: theme.palette.info.main,
    },
    cardContent: {
        padding: theme.spacing(1),
        '&:last-child': {
          paddingBottom: theme.spacing(1),
        },
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        },
      },
      mainLabels: {
          fontWeight: 500,
      },
      secondaryLabels: {
        color: theme.palette.grey[700],
      },
      divider: {
        height: '1px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
}));
