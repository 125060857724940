import { ChartType, SqlResult } from '../tileBuilderTypes';

export interface TileControllerState {
    Component: React.LazyExoticComponent<React.ComponentType<any>>;
}

export interface BaseSettingsProps {
    availableColumns: AvailableColumn[];
    fieldsColumns: FieldColumn[];
    onFieldColumnChanged: (columns: FieldColumn[]) => void;
}

export interface BaseChartProps {
    tileName: string;
    tileSubname?: string;
    isLoading?: boolean;
    // fieldColumnValues: FieldColumnValue[];
    sqlResult: SqlResult;
    fieldsColumns: FieldColumn[];
    mode: TileEditMode;
}

export enum TileEditMode {
    None,
    TileBuilder,
    DashboardBuilder
}

export type ChartValue = {
    [x in string]: any;
}

export interface AvailableColumn {
    name: string;
    type: string;
    displayName: string;
}

export interface FieldColumn {
    column: string;
    field: string;
}

export interface FieldColumnValue extends FieldColumn {
    value: any;
}

export const findFieldColumn = (field: string, fieldsColumns: FieldColumn[]) => {
    return fieldsColumns.find(fc => fc.field === field)
}

export interface TileUserSettings {
    ChartType: ChartType;
}