import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { lazy, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../app/store';
import SqlSettingsForm from './SqlSettingsForm';
import { setFieldsColumns, setTileSettingsChartType, setTileSettingsName } from './state/tileBuilderReducer';
import { deleteTileOnDatabase, saveTileOnDatabase } from './state/tileBuilderThunks';
import { FieldColumn } from './templates/types';
import { ChartType, ColumnViewModel, getChartTypeName, SqlResult, TileSettingsModel } from './tileBuilderTypes';
import { useStyles } from './TileSettings.style';

interface ChartSettingsState {
    Component: React.LazyExoticComponent<React.ComponentType<any>>;
}

const TileSettings = (): JSX.Element => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const tileSettings = useSelector((state: ApplicationState) => state.dashboardTileBuilder.tileSettings as TileSettingsModel);
    const sqlResult = useSelector((state: ApplicationState) => state.dashboardTileBuilder.sqlResult as SqlResult);

    const importSettingsComponent = (name: string) => lazy(() => import('./templates/' + name + '/' + name + 'Settings').then(module => ({ default: module.default })));

    const [chartSettingsState, setChartSettingsState] = useState({
        Component: importSettingsComponent('EmptyChart'),
    } as ChartSettingsState)

    useEffect(() => {
        const chartTypeName = getChartTypeName(tileSettings.chartType);

        const newValue: ChartSettingsState = {
            Component: importSettingsComponent('EmptyChart'),
        };

        try {
            newValue.Component = importSettingsComponent(chartTypeName);
        } catch (error) {
            console.log('Unexpected error while trying to load chart settings: ', error);
        }

        setChartSettingsState(newValue);
    }, [tileSettings.chartType]);

    const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(setTileSettingsName(event.target.value as string));
    const chartTypeChangeHandler = (event: React.ChangeEvent<any>) => dispatch(setTileSettingsChartType(parseInt(event.target.value as string)));
    const fieldColumnsChangeHandler = (columns: FieldColumn[]) => dispatch(setFieldsColumns(columns));
    const saveTileHandler = () => dispatch(saveTileOnDatabase());
    const deleteTileHandler = () => dispatch(deleteTileOnDatabase(tileSettings.tileId || ''));

    const availableColumns = useMemo(() => mapToColumnViewModels(sqlResult), [sqlResult]);

    return (
        <Box id="tileSettingsContainer" style={{ maxWidth: '500px' }}>
            <Paper elevation={1} style={{ padding: '16px' }}>
                <Grid container spacing={1} direction="row" justify="center" alignItems="stretch" alignContent="stretch" style={{ height: '100%' }}>
                    <Grid item xs={12} md={12} >
                        <Typography variant="h6" style={{ color: '#70677b' }}>
                            Tile Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <FormControl fullWidth>
                            <InputLabel shrink={tileSettings.chartType != null}>Chart Type</InputLabel>
                            <Select
                                value={tileSettings.chartType}
                                onChange={chartTypeChangeHandler}
                                displayEmpty={false}
                                inputProps={{ 'aria-label': 'Without label' }}
                                autoWidth={true}>
                                {/* <MenuItem value={ChartType.Number}>Number</MenuItem> */}
                                {/* <MenuItem value={ChartType.Bar}>Bar</MenuItem>
                <MenuItem value={ChartType.Pie}>Pie</MenuItem>
                <MenuItem value={ChartType.Line}>Line</MenuItem> */}
                                {/* <MenuItem value={ChartType.BarAverage}>Bar - Average</MenuItem>
                <MenuItem value={ChartType.Donut}>Donut</MenuItem>
                <MenuItem value={ChartType.DonutAverage}>Donut - Average</MenuItem> */}
                                {/* <MenuItem value={ChartType.Table}>Table</MenuItem> */}
                                <MenuItem value={ChartType.Grid}>Grid</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                label="Title"
                                variant="standard"
                                style={{ marginTop: '12px' }}
                                fullWidth
                                margin="normal"
                                placeholder="My Custom Tile"
                                value={tileSettings.name}
                                onChange={nameChangeHandler} />
                        </FormControl>
                    </Grid>
                    <SqlSettingsForm />
                    <Grid item xs={12} md={12} style={{ marginTop: '12px' }}>
                        <Typography variant="body1" style={{ color: '#70677b' }}>
                            Chart Settings
                        </Typography>
                        <React.Suspense fallback={<Alert severity="info">Loading chart settings, please wait...</Alert>}>
                            <chartSettingsState.Component
                                availableColumns={availableColumns}
                                onFieldColumnChanged={fieldColumnsChangeHandler}
                                fieldsColumns={tileSettings.fieldsColumns}
                            />
                        </React.Suspense>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginTop: '12px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.saveButton}
                            onClick={saveTileHandler}>
                            Save
                        </Button>
                        {
                            tileSettings.tileId &&
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.deleteButton}
                                onClick={deleteTileHandler}>
                                Delete
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

const mapToColumnViewModels = (sqlResult: SqlResult): ColumnViewModel[] => {
    if (!sqlResult || !sqlResult.tables) {
        return [];
    }

    const columns: ColumnViewModel[] = [];

    for (let i = 0; i < sqlResult.tables.length; i++) {
        const table = sqlResult.tables[i];

        const prefix = sqlResult.tables.length > 1 ? `Resultset - ${i} - Column: ` : '';

        for (let j = 0; j < table.columns.length; j++) {
            const column = table.columns[j];

            columns.push({
                displayName: prefix + column.name,
                name: column.name,
                type: column.type,
            });
        }
    }

    return columns;
};

export default TileSettings;
