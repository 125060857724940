
export function getCellColor (min: number, max: number, total: number, itemValue: number): any {
    const precent75 = (total * .75) + min;
    const precent25 = (total * .25) + min;

    if(itemValue > 0 && itemValue >= precent75) {
        return { backgroundColor: '#28A745', color: 'white' };
    } else if(itemValue > 0 && itemValue < precent75 && itemValue > precent25){
        return { backgroundColor: '#FFC107', color: 'black' };
    } else if(itemValue > 0 && itemValue <= precent25){
        return { backgroundColor: '#DC3545', color: 'white' };
    } else {
        return { backgroundColor: 'white', color: 'black' };
    }
}

export function getCellBootedColor (hitValue: number, bootValue: number): any {
    if(hitValue > 0 && bootValue > 0){
        const precentValue = ((bootValue/hitValue) * 100);

        if(precentValue >= 50) {
            return { backgroundColor: '#28A745', color: 'white' };
        } else if(precentValue < 50 && precentValue > 15){
            return { backgroundColor: '#FFC107', color: 'black' };
        } else if(precentValue <= 15){
            return { backgroundColor: '#DC3545', color: 'white' };
        }
    }

    return { backgroundColor: 'white', color: 'black' };

}

export function getCellTotalColor (total: number, averagePerPeriod: number) {
    if (total === 0 && averagePerPeriod === 0)
        return { backgroundColor: 'white', color: 'black' };
    else if (total >= averagePerPeriod)
        return { backgroundColor: '#28A745', color: 'white' };
    else if (total > (averagePerPeriod / 2))
        return { backgroundColor: '#FFC107', color: 'black' };

    return { backgroundColor: '#DC3545', color: 'white' };

}