import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        height: '100%',
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#1976d2',
            color: '#eae9ef',
        },
    },
    gridAssignBase: {
        display: 'flex'
    },
    deleteMessage:{
        color: theme.palette.error.dark,
        marginTop: 10
    },
    contentWrapper: {
        marginTop: theme.spacing(1),
    },
    openLayersMapWrapper: {
        position: 'relative',
        boxSizing: 'border-box',
    },
    label: {
        color: theme.palette.primary.dark,
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
    },
    markersListWrapper: {
        flexGrow: 1,
    },
    rightColumn:{
        height: '100%'
    },
    formControl: {
      marginRight: theme.spacing(2),
      maxWidth:110,
    },
    weekSelect: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    buttonAssign: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 16,
        width: 120,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    button: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 10,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    buttonRed: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 10,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.error.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    deleteButton: {
        padding: 5,
        margin: 5,
        backgroundColor: theme.palette.error.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.error.dark,
            outline: 0,
        },
        minWidth: 30,
        color: 'white'
    },
    buttonGroup: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 10,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    table: {
        width:'100%',
        height: 300,
        overflow: 'auto',
    },
    bottoAssigControls:{
        marginTop: theme.spacing(5),
    },
    modalContent: {
        background: 'white',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            width: '310px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '320px',
        },
        top: '45%',
        left: '45%',
        transform: 'translate(-50%, -50%)',
        padding: '10px',
        '&:focus': {
            outline: 'none',
        },
        '& > .MuiTypography-root': {
            marginBottom: theme.spacing(.5),
        },
        '& > .MuiButtonBase-root': {
            marginRight: theme.spacing(1),
        },
    },
    enforcementZoneDetailWrapper:{
        display: 'block'
    },
    enforcementZoneAssignationDetailWrapper:{
        display: 'block'
    },
    buttonGroupContainer:{
        zIndex: 1000
    }
}));
