import React, { useEffect, useMemo } from 'react';
import { Route } from 'react-router';
import LeftNavigationMenu from './components/layout/LeftNavigationMenu';
import BuilderLeftNavigationMenu from './components/layout/BuilderLeftNavigationMenu';
import MainDashboard from './components/dashboards/Main/MainDashboard';
import EnforcementDashboard from './components/dashboards/Enforcement/EnforcementDashboard';
import MapDashboard from './components/dashboards/Map/MapDashboard';
import LiveMapDashboard from './components/dashboards/LiveMap/LiveMapDashboard';
import GPSDevicesMapDashboard from './components/dashboards/GPSDevices/GPSDevicesMapDashboard';
import DashboardBuilder from './components/dashboard-builder/DashboardBuilder';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './components/themes/base';
import { Box } from '@material-ui/core';
import { useStyles } from './AppStyles';
import './components/themes/base.css';

import { useDispatch, useSelector } from 'react-redux';

import { SessionState } from './app/session/sessionTypes';
import { initializeSession } from './app/session/sessionThunks';
import { ApplicationState } from './app/store';
import LoadingModal from './components/dashboards/Commons/LoadingModal/LoadingModal';
import Ssrs from './components/ssrs/Ssrs';
import { useLocation } from 'react-router-dom';
import OptiRouteMapDashboard from './components/dashboards/OptiRoute/OptiRouteMapDashboard';
import ScenariosMapDashboard from './components/dashboards/Scenarios/ScenariosMapDashboard';
import TileBuilderLeftNavigationMenu from './components/layout/TileBuilderLeftNavigationMenu';
import DashboardTileBuilder from './components/dashboard-tile-builder/DashboardTileBuilder';
import { hasAccessToBuilders } from './app/session/utils';
import DashboardViewer from './components/dashboard-viewer/DashboardViewer';

export default function App(): JSX.Element {
    const classes = useStyles();

    const session = useSelector((state: ApplicationState) => state.session as SessionState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initializeSession());
    }, []);

    useEffect(() => {
        if (session.error) {
            const urlifiedLocation = window.location.href.trim().replace(/\s/g, '%20').replace(/\//g, '%2F');
            const loginUrl = `${process.env.REACT_APP_BOOTVIEW_URL}/Login.aspx?ReturnUrl=${urlifiedLocation}`;
            window.location.href = loginUrl;
        }
    }, [session.error]);

    const location = useLocation();

    const showBuilders: boolean = useMemo(() => hasAccessToBuilders(session.currentUser), [session.currentUser])

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.layoutContainer} style={{ height: '100%' }}>
                {
                    session.currentUser &&
                    <React.Fragment>
                        <div id="leftNavigationMenu" className={classes.leftNavigationMenu}>
                            {(!location.pathname.includes('/customer-dashboards/builder') && !location.pathname.includes('/customer-dashboards/tile-builder')) && <LeftNavigationMenu />}
                            {location.pathname.includes('/customer-dashboards/builder') && <BuilderLeftNavigationMenu />}
                            {location.pathname.includes('/customer-dashboards/tile-builder') && <TileBuilderLeftNavigationMenu />}
                        </div>
                        <Box id="mainContainer" className={classes.mainContainer} style={{ height: '100%' }}>
                            <React.Fragment>
                                <Route exact path='/' component={MainDashboard} />
                                <Route path='/customer-dashboards/map' component={MapDashboard} />
                                <Route path='/customer-dashboards/live-map' component={LiveMapDashboard} />
                                {
                                    showBuilders &&
                                    <>
                                        <Route path='/customer-dashboards/builder/:dashboardId?' component={DashboardBuilder} />
                                        <Route path='/customer-dashboards/tile-builder/:tileId?' component={DashboardTileBuilder} />
                                    </>
                                }
                                <Route path='/customer-dashboards/custom/:dashboardId' component={DashboardViewer} />
                                <Route path='/customer-dashboards/optiroute' component={OptiRouteMapDashboard} />
                                <Route path='/customer-dashboards/scenarios' component={ScenariosMapDashboard} />
                                <Route path='/customer-dashboards/gpsDevices' component={GPSDevicesMapDashboard} />
                                <Route path='/ssrs' component={Ssrs} />
                            </React.Fragment>
                        </Box>
                    </React.Fragment>
                }
                {
                    session.isLoading &&
                    <LoadingModal
                        isOpen={session.isLoading}
                        container={(() => document.getElementsByTagName('body')[0])()}
                        message="Loading, please wait..."
                    />
                }
            </Box>
        </ThemeProvider>
    );
}
