import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#332643',
    contrastText: '#eae9ef',
  },
  secondary: {
    main: '#ff6633',
    contrastText: '#000000',
  },
  submit: {
    main: '#2196f3',
    darker: '#1565c0',
  },
  success: {
    main: '#4caf50',
    darker: '#2e7d32',
  },
  warning: {
    main: '#FFC107',
    darker: '#fbc02d',
  },
  primaryBlue: {
    main: '#1976D2',
    darker: '#115293',
  },
};

// const themeName = 'PayLock BootView Base';
let theme = createMuiTheme({ palette: palette });
theme = responsiveFontSizes(theme);
export default theme;
