import OverTimeData, { OverTimeColumn, OverTimeRow } from './OverTimeModels';

export function MapOverTimeFromDTO (rowsDTO: any): OverTimeData {
    const otd = new OverTimeData(0, 'Consolidated');

    if (rowsDTO === null) {
        return otd;
    }

    rowsDTO.items.forEach((r: any) => {
        const columns: OverTimeColumn[] = r.items.map((dr: any) => {
            return new OverTimeColumn(dr.timeDuration, dr.numberOfEvents);
        });

        otd.rows.push(new OverTimeRow(r.name, r.ordinal, columns));
    });

    return otd;
}

export function MergeOverTimeData (...otdsToMerge: { type: string; otd: OverTimeData }[]): OverTimeData {
    if (otdsToMerge.length === 0) {
        return new OverTimeData;
    }

    const mergedOtd = new OverTimeData(otdsToMerge[0].otd.type, otdsToMerge[0].otd.typeName);

    otdsToMerge.forEach((item: { type: string; otd: OverTimeData }) => {
        item.otd.rows.forEach((row: OverTimeRow) => {
            const newRow = { ...row };
            newRow.columns = [ ...row.columns ];
            newRow.type = item.type;
            mergedOtd.rows.push(newRow);
        });

    });

    return mergedOtd;
}
