import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../../app/store';
import { Get } from '../../../../services/RestClient';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import { ReducerAction, ReducerActionType } from '../BaseState';
import InformationOverTimeState, { reducer } from './InformationOverTimeState';
import { useStyles } from './InformationOverTimeStyles';
import InformationOverTimeSummary, {
    DataGridRow,
    OverTimeHeader,
    OverTimeItem,
    TableType,
} from './InformationOverTimeSummary';
import { getCellColor, getCellBootedColor, getCellTotalColor } from '../../../../utils/CellColorParser';
import moment from 'moment';

type InformationOverTimeProps = {
    filter: DashboardFilterState;
}

export default function InformationOverTime (props: InformationOverTimeProps): JSX.Element {
    const classes = useStyles();

    const { filter } = props;

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState,
            isLoading: state.mainDashboard ? state.mainDashboard.isLoading : false,
        };
    };

    const dashboardState = useSelector(selector);

    const [state, dispatch] = React.useReducer(
        reducer,
        new InformationOverTimeState(dashboardState.isLoading, undefined, new InformationOverTimeSummary, getColumnDefinitions(TableType.Consolidated, filter.daysToAverage), getRows(new InformationOverTimeSummary))
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleTableTypeChange = function (event: any) {
        const filter = { tableType: event.target.value, from: state.filter.from, to: state.filter.to };
        dispatch(new ReducerAction(ReducerActionType.FilterUpdate, filter));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFromChange = function (event: any) {
        const filter = { tableType: state.filter.tableType, from: new Date(event), to: state.filter.to };
        dispatch(new ReducerAction(ReducerActionType.FilterUpdate, filter));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleToChange = function (event: any) {
        const filter = { tableType: state.filter.tableType, from: state.filter.from, to: new Date(event) };
        dispatch(new ReducerAction(ReducerActionType.FilterUpdate, filter));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnClickRefreshButton = function (event: any) {
        fetchData(dispatch, state, props.filter);
    };

    // useEffect(() => {
    //     if (dashboardState.isLoading) {
    //         dispatch(new ReducerAction(ReducerActionType.IsLoading, dashboardState.isLoading));
    //     } else {
    //         const filter = { tableType: TableType.Consolidated, from: state.filter.from, to: state.filter.to };
    //         dispatch(new ReducerAction(ReducerActionType.FilterUpdate, filter));

    //         const columnDefinitions = getColumnDefinitions(state.filter.tableType);
    //         const rows = getRows(dashboardState.data);
    //         dispatch(new ReducerAction(ReducerActionType.SummaryUpdate, {
    //             summary: dashboardState.data,
    //             rows: rows,
    //             columnDefinitions: columnDefinitions,
    //         }));
    //     }
    // }, [dashboardState.isLoading]);

    useEffect(() => {
        fetchData(dispatch, state, filter);
    }, [filter]);

    const onGridReady = function (event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    };

    return (
        <Paper className={classes.root} elevation={1}>
            <Typography variant="button" color="primary">
                {`Enforcement Data for ${ moment(filter.date).format('dddd')}, ${moment(filter.date).format('MM/DD/YYYY')}`}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{display: 'none'}}>
                    <Box display="flex" alignItems="center">
                        <FormControl className={classes.formControl}>
                            <InputLabel>Format</InputLabel>
                            <Select
                                displayEmpty
                                value={state.filter.tableType}
                                onChange={handleTableTypeChange}
                                autoWidth={false}
                                className={classes.select}
                                disabled={state.isLoading}>
                                <MenuItem value={TableType.Consolidated}>Consolidated</MenuItem>
                                <MenuItem value={TableType.AM}>AM</MenuItem>
                                <MenuItem value={TableType.PM}>PM</MenuItem>
                                <MenuItem value={TableType.Range}>Range</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            state.filter.tableType !== TableType.Range
                                ? ''
                                : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        label="From"
                                        placeholder="08:00 AM"
                                        mask="__:__ _M"
                                        value={state.filter.from}
                                        onChange={handleFromChange}
                                        className={classes.formControl} />
                                    <KeyboardTimePicker
                                        label="To"
                                        placeholder="08:00 PM"
                                        mask="__:__ _M"
                                        value={state.filter.to}
                                        onChange={handleToChange}
                                        className={classes.formControl} />
                                </MuiPickersUtilsProvider>
                        }
                        <Button
                            variant="contained"
                            color="default"
                            startIcon={<RefreshIcon />}
                            disabled={state.isLoading}
                            onClick={handleOnClickRefreshButton}>
                            Refresh
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <div className="ag-theme-alpine" style={{ height: '252px', width: '100%' }}>
                        {
                            state.isLoading
                                ? <InformationOverTimeGridSkeleton />
                                : <AgGridReact
                                    gridOptions={{ suppressCellSelection: true }}
                                    columnDefs={state.columnDefinitions}
                                    rowData={state.rows}
                                    defaultColDef={{ resizable: true }}
                                    onGridReady={onGridReady}
                                    onGridColumnsChanged={event => event.api.sizeColumnsToFit()} />
                        }
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchData = (dispatch: React.Dispatch<any>, state: InformationOverTimeState, filter: DashboardFilterState): Promise<void> => {
    if (!filter.customerId) {
        dispatch(new ReducerAction(ReducerActionType.IsLoading, true));
        return Promise.resolve();
    }

    if (!state.isLoading) {
        dispatch(new ReducerAction(ReducerActionType.IsLoading, true));
    }

    let date: Date | string;
    let dateTo: Date | string | null;
    if (state.filter.tableType === TableType.Range) {
        date = state.filter.from;
        dateTo = state.filter.to;
    } else {
        date = filter.date;
        dateTo = filter.dateTo;
    }

    return Get({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/data-over-time/summary`,
        params: {
            customerId: filter.customerId,
            daysToAverage: filter.daysToAverage,
            dateFrom: date,
            dateTo: dateTo,
            dataGrouping: state.filter.tableType,
        },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((response: any) => {
        const summary = MapInformationOverTime(response);
        const columnDefinitions = getColumnDefinitions(state.filter.tableType, filter.daysToAverage);
        const rows = getRows(summary);

        dispatch(new ReducerAction(ReducerActionType.SummaryUpdate, {
            summary: summary,
            rows: rows,
            columnDefinitions: columnDefinitions,
        }));
    }).catch((err) => {
        dispatch(new ReducerAction(ReducerActionType.IsError, undefined, err));
    });
};

const getColumnDefinitions = (tableType: TableType, daysToAverage: number): Array<{ headerName: string; field: string, pinned?: string }> => {
    const columnDefinitions: any = [
        { headerName: 'Description', field: 'dataType', headerClass: 'text-center', minWidth: 96, pinned: 'left', cellStyle: { textAlign: 'center',  fontSize:12 }, suppressMovable: true },
        { headerName: (+daysToAverage/7) + ' Wk Avg', headerClass: 'text-center', field: 'wkAvg', minWidth: 96, pinned: 'left', cellStyle: { textAlign: 'center',  fontSize:12 }, suppressMovable: true },
        { 
            headerName: 'Total',
            headerClass: 'text-center',
            field: 'total', 
            minWidth: 69, 
            pinned: 'left', 
            cellStyle: (params: any) => { 
                const colorIndicator = getCellTotalColor(params.data.total, params.data.wkAvg);
                return { ...colorIndicator, textAlign: 'center', fontSize:12 };
            },
            suppressMovable: true
        }
    ];
    
    headers(tableType).forEach((header) => {
        columnDefinitions.push({
            headerName: header.name,
            field: 'column-data-' + (header.ordinal as unknown as string),
            minWidth: 51,
            cellStyle: (params: any) => { 
                let colorIndicator = null;
                
                if(params.data.dataType === 'Booted' && params.data[`hit-${params.colDef.field}`] && params.data[`hit-${params.colDef.field}`] > 0){
                    colorIndicator = getCellBootedColor(params.data[`hit-${params.colDef.field}`], params.value);
                } else {
                    colorIndicator = getCellColor(params.data.min, params.data.max, params.data.totalIndicator, params.value);
                }

                return { ...colorIndicator, textAlign: 'center', fontSize:12};
            },
            suppressMovable: true
        });
    });

    return columnDefinitions;
};

const getRows = (summary: InformationOverTimeSummary): Array<DataGridRow> => {
    const rows = [];
    rows.push(dataRow('Scans', summary.scans, summary.scansAverage, summary.scansTotal));
    rows.push(dataRow('Hits', summary.hits, summary.hitsAverage, summary.hitsTotal));
    rows.push(dataRow('Booted', summary.bootedVehicles, summary.bootsAverage, summary.bootsTotal, summary.hits));
    rows.push(dataRow('Towed', summary.towedVehicles, summary.towsAverage, summary.towsTotal));

    return rows;
};

export function MapInformationOverTime (data: any): InformationOverTimeSummary {
    const information = new InformationOverTimeSummary(0, 'Consolidated');
    if (data === null) {
        return information;
    }

    // scans
    information.scans = getItems('scans');
    information.scansAverage = data['scansAverage'];
    information.scansTotal = data['scansTotal'];

    // hits
    information.hits = getItems('hits');
    information.hitsAverage = data['hitsAverage'];
    information.hitsTotal = data['hitsTotal'];

    // boots
    information.bootedVehicles = getItems('boots');
    information.bootsAverage = data['bootsAverage'];
    information.bootsTotal = data['bootsTotal'];

    // tows
    information.towedVehicles = getItems('tows');
    information.towsAverage = data['towsAverage'];
    information.towsTotal = data['towsTotal'];

    return information;

    function getItems (type: string): OverTimeItem[] {
        if (data[type] === null || typeof (data[type]) === 'undefined') { return new Array<OverTimeItem>(); }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const items = data[type].map((item: any) => {
            return new OverTimeItem(item.timeDuration, item.numberOfEvents);
        });

        return items;
    }
}

function InformationOverTimeGridSkeleton () {
    return (
        <Box style={{ height: '100%' }}>
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
            <Skeleton height="25px" animation="wave" />
            <Skeleton height="25px" />
        </Box>
    );
}

function headers (tableType: number): OverTimeHeader[] {
    if (tableType === TableType.Consolidated) {
        const headers = [];

        for (let i = 0; i <= 23; i++) {
            if(i === 0){
                headers.push(new OverTimeHeader('12AM', i));
            } else if ((i >= 1 && i <= 11)){
                headers.push(new OverTimeHeader(`${i}AM`, i));
            } else {
                headers.push(new OverTimeHeader(`${i > 12 ? (i-12) : i}PM`, i));
            }
        }

        return headers;
    }

    if (tableType === TableType.AM) {
        const headers = [];
        let nextHour = 0;
        for (let i = 0; i < 12; i++) {
            headers.push(new OverTimeHeader(`${nextHour} AM`, i));
            ++nextHour;
        }

        return headers;
    }

    if (tableType === TableType.PM) {
        const headers = [];
        let nextHour = 12;
        for (let i = 12; i < 24; i++) {
            headers.push(new OverTimeHeader(`${nextHour} PM`, i));
            ++nextHour;
            if (nextHour === 13) { nextHour = 1; }
        }

        return headers;
    }

    return new Array<OverTimeHeader>();
}

function dataRow (dataType: string, data: Array<OverTimeItem>, wkAverage: number, dataTotal: number, hitsItems?: OverTimeItem[]) {
    const row = new DataGridRow();
    const min = Math.min.apply(null, data.filter((df) => df.totalEvents > 0).map((dm) => dm.totalEvents));
    const max = Math.max.apply(null,  data.filter((df) => df.totalEvents > 0).map((dm) => dm.totalEvents));

    row.dataType = dataType;
    row['wkAvg'] = wkAverage;
    row['total'] = dataTotal;
    row['min'] = min;
    row['max'] = max;
    row['totalIndicator'] = (max - min);

    data.forEach((item) => {
        row[`column-data-${(item.ordinal as unknown as string)}`] = item.totalEvents;
        if(hitsItems && hitsItems[item.ordinal]){
            row[`hit-column-data-${(item.ordinal as unknown as string)}`] = hitsItems[item.ordinal]?.totalEvents;
        }
    });

    return row;
}
