import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { SqlResult, TileSettingsModel } from '../tileBuilderTypes';
import {
    setFieldsColumnsAction,
    setTileSettingsChartTypeAction,
    setTileSettingsCategoryIdAction,
    setTileSettingsDatabaseIdAction,
    setTileSettingsNameAction,
    setTileIdAction,
    setTileSettingsSqlQueryAction,
    setTileSettingsTestDataAction,
    setSqlQueryResultAction,
    updateTileSettingsAction,
    setCustomTilesListAction,
    setTileSizeAction
} from './tileBuilderActions';
import { DashboardTileBuilderState } from './tileBuilderState';

const initialState = {
    tileSettings: {
        name: '',
        chartType: null,
        categoryId: null,
        databaseId: '',
        sqlQuery: '',
        testValues: {
            customerId: '8560B992-BF0E-4407-B263-7D4D731DA7E2',
            date: moment(new Date()).format('YYYY/MM/DD hh:mm a'),
            dateTo: moment(new Date()).format('YYYY/MM/DD hh:mm a'),
        },
        includedParams: [],
        fieldsColumns: [],
        positionX: 0,
        positionY: 0,
        width: 300,
        height: 150
    } as TileSettingsModel,
    sqlResult: {} as SqlResult,
    availableColumns: [],
    fieldsColumns: [],
    customTilesList: []
} as DashboardTileBuilderState;

const tileBuilderSlice = createSlice({
    name: 'tileBuilder',
    initialState: initialState,
    reducers: {
        updateTileSettings: updateTileSettingsAction,
        setTileId: setTileIdAction,
        setTileSettingsName: setTileSettingsNameAction,
        setTileSettingsChartType: setTileSettingsChartTypeAction,
        setTileSettingsCategoryId: setTileSettingsCategoryIdAction,
        setTileSettingsDatabaseId: setTileSettingsDatabaseIdAction,
        setTileSettingsSqlQuery: setTileSettingsSqlQueryAction,
        setTileSettingsTestData: setTileSettingsTestDataAction,
        setSqlQueryResult: setSqlQueryResultAction,
        setFieldsColumns: setFieldsColumnsAction,
        setCustomTilesList: setCustomTilesListAction,
        setTileSize: setTileSizeAction,
    },
});

export const { reducer } = tileBuilderSlice;
export const {
    updateTileSettings,
    setTileId,
    setTileSettingsName,
    setTileSettingsChartType,
    setTileSettingsCategoryId,
    setTileSettingsDatabaseId,
    setTileSettingsSqlQuery,
    setTileSettingsTestData,
    setSqlQueryResult,
    setFieldsColumns,
    setCustomTilesList,
    setTileSize
} = tileBuilderSlice.actions;
