export interface BaseState<T> {
    isLoading: boolean;
    isError: string | null;
    filter?: any,
    summary: T
}

export class ReducerAction {
    type: ReducerActionType | string = ReducerActionType.None;
    data: any | undefined;
    error: string | null | undefined;

    constructor(type: ReducerActionType | string, data?: any, error?: string) {
        this.type = type;
        this.data = data;
        this.error = error;
    }
}

export enum ReducerActionType {
    None,
    IsLoading,
    IsError,
    FilterUpdate,
    SummaryUpdate
}
