import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  saveButton: {
    margin: 0,
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.info.dark,
      outline: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 0,
      marginBottom: 0,
    },
  },
  deleteButton: {
    margin: 0,
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.warning.dark,
      outline: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));