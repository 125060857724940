import { makeStyles, Theme } from '@material-ui/core';
import { BorderLinearProgressProps } from './BorderLinearProgress';

export const useStyles = makeStyles<Theme, BorderLinearProgressProps>((theme: Theme) => ({
    root: {
        height: props => props.height,
        borderRadius: 2,
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
    },
    bar: {
        borderRadius: 2,
        backgroundColor: props => {
            // return props.value === 100 ? '#00c853' : props.value > 50 ? '#ffd600' : '#dd2c00';
            return props.value === 100 ? theme.palette.success.main : props.value > 50 ? theme.palette.warning.main : theme.palette.error.main;
        },
    },
}));
