import {
    AppBar,
    Box,
    Collapse,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from '@material-ui/core';
import {
    AddBox as AddIcon,
    Dashboard as DashboardIcon,
    DonutSmall as DonutChartIcon,
    ExitToApp as LogoutIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    InsertChart as BarChartIcon,
    Menu as MenuIcon,
    WebAsset as GenericIcon,
    Widgets as ChartIcon,
} from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../app/store';
import { fetchTilesFromServer } from '../dashboard-tile-builder/state/tileBuilderThunks';
import { ChartType, TileModel, TileModelCategory, TileModelCategoryPoco } from '../dashboard-tile-builder/tileBuilderTypes';
import { useStyles } from './LeftNavigationMenuStyles';
import { ListItemLink } from './ListItemLink';

export default function TileBuilderLeftNavigationMenu(): JSX.Element {
    const classes = useStyles();

    const dispatch = useDispatch();
    const availableTiles = useSelector((state: ApplicationState) => state.dashboardTileBuilder.customTilesList as TileModel[]);

    const [selectedCustomerCode, setSelectedCustomerCode] = useState('paylock');

    const [bootViewMenuOpen, setBootViewMenuOpen] = useState(false);
    const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
    const [myDashboardsMenuOpen, setMyDashboardsMenuOpen] = useState(true);

    const handleLogoError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const images = document.getElementsByClassName('main-logo');
        for (let i = 0; i < images.length; i++) {
            images[i].setAttribute('src', '/img/customers/paylock.jpeg');
        }
    };

    useEffect(() => {
        dispatch(fetchTilesFromServer());
    }, [])

    const [tileCategoriesMenus, setTileCategoriesMenus] = useState({
        [TileModelCategory.Booting]: false,
        [TileModelCategory.EasyLPR]: false,
        [TileModelCategory.HelpCenter]: false,
    })

    const toggleTileCategoryMenu = (categoryId: TileModelCategory) => {
        tileCategoriesMenus[categoryId] = !tileCategoriesMenus[categoryId];
        setTileCategoriesMenus(tileCategoriesMenus);
    }

    const tileCategories = useMemo(() => {
        return [
            {
                categoryId: TileModelCategory.Booting,
                name: 'Booting'
            },
            {
                categoryId: TileModelCategory.EasyLPR,
                name: 'Easy LPR'
            },
            {
                categoryId: TileModelCategory.HelpCenter,
                name: 'Help Center'
            }
        ] as TileModelCategoryPoco[]
    }, [availableTiles]);

    const TileMenuIcon = (props: { chartType: ChartType | null }) => {
        const { chartType } = props;

        if (chartType === ChartType.Bar || chartType === ChartType.BarAverage) {
            return <BarChartIcon />;
        }

        if (chartType === ChartType.Donut || chartType === ChartType.DonutAverage) {
            return <DonutChartIcon />;
        }

        return <GenericIcon />
    }

    return (
        <Box>
            <Hidden mdUp>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    <Collapse in={hamburgerMenuOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                        <MenuItems />
                    </Collapse>
                </AppBar>
                <List component="div" disablePadding className={classes.logoWrapper}>
                    <ListItem className={classes.logo}>
                        <img className="main-logo" src={`/img/customers/${selectedCustomerCode}.jpeg`} onError={handleLogoError} />
                    </ListItem>
                </List>
            </Hidden>
            <Hidden smDown>
                <Box boxShadow={3}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left">
                        <List component="div" disablePadding className={classes.logoWrapper}>
                            <ListItem className={classes.logo}>
                                <img className="main-logo" src={`/img/customers/${selectedCustomerCode}.jpeg`} onError={handleLogoError} />
                            </ListItem>
                        </List>
                        <Divider />
                        <MenuItems />
                    </Drawer>
                </Box>
            </Hidden>
        </Box>
    );

    function MenuItems(): JSX.Element {
        return (
            <React.Fragment>
                <List component="div" disablePadding>
                    <ListItemLink primary="Add Tile" to="/customer-dashboards/tile-builder" icon={<AddIcon />} openTab={true} />
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => setMyDashboardsMenuOpen(!myDashboardsMenuOpen)} className={classes.menuElement}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tiles" />
                        {myDashboardsMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={myDashboardsMenuOpen} timeout="auto" unmountOnExit>
                        {
                            tileCategories.map((tileCategory: TileModelCategoryPoco) =>
                                <List component="div" disablePadding key={tileCategory.categoryId}>
                                    <Divider />
                                    <ListItem button onClick={() => toggleTileCategoryMenu(tileCategory.categoryId)} className={classes.menuElement}>
                                        <ListItemIcon>
                                            <ChartIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={tileCategory.name} />
                                        {tileCategoriesMenus[tileCategory.categoryId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItem>
                                    <Collapse in={true} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {
                                                availableTiles?.filter((t) => t.categoryId === tileCategory.categoryId).map((tile: TileModel) =>
                                                    <ListItemLink
                                                        icon={<TileMenuIcon chartType={tile.chartType} />}
                                                        key={tile.tileId}
                                                        primary={tile.name}
                                                        to={`/customer-dashboards/tile-builder/${tile.tileId}`}
                                                        openTab={true} />
                                                )
                                            }
                                        </List>
                                    </Collapse>
                                </List>
                            )
                        }

                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItemLink primary="Exit" to="/" icon={<LogoutIcon />} />
                </List>
            </React.Fragment>
        );
    }
}
