import { AppBar, Box, Grid, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Position, ResizableDelta } from 'react-rnd';
import { useParams } from 'react-router-dom';

import { ApplicationState } from '../../app/store';
import theme from '../themes/base';
import { useStyles } from './DashboardTileBuilderStyles';
import { setTileSize } from './state/tileBuilderReducer';
import { fetchTileFromServer } from './state/tileBuilderThunks';
import TileController from './templates/TileController';
import { TileEditMode } from './templates/types';
import { TileModel, TileSettingsModel } from './tileBuilderTypes';
import TileSettingsDrawer from './TileSettingsDrawer';

export const DashboardTileBuilder = (): JSX.Element => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const tileSettings = useSelector((state: ApplicationState) => state.dashboardTileBuilder.tileSettings as TileSettingsModel);

    const {
        tileId = null
    } = useParams<{ tileId: string }>();

    useEffect(() => {
        if (tileId) {
            dispatch(fetchTileFromServer(tileId));
        }
    }, [tileId])

    const onResizeTile = useCallback((_e: any, _dir: any, ref: HTMLElement, _delta: ResizableDelta, _position: Position) => {
        dispatch(setTileSize({ width: ref.clientWidth, height: ref.clientHeight }));
    }, [])

    return (
        <Box style={{ height: '100%' }} id="tileBuilderContainer">
            <AppBar position={useMediaQuery(theme.breakpoints.up('md')) ? 'fixed' : 'relative'} className={classes.appBar} elevation={1}>
                <Toolbar>
                    <Grid container spacing={3} justify="space-between" alignItems="center" alignContent="center">
                        <Grid item xs={12} md={3} container alignItems="center" alignContent="center">
                            <DashboardIcon className={classes.iconDashboard} />
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Tile Builder
                                <Typography variant="h6" color="inherit" className={classes.subTitle}>
                                    by Stat-Trak
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} container justify="flex-end" alignItems="center" alignContent="center">
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {
                useMediaQuery(theme.breakpoints.up('md')) && <Toolbar className={classes.emptyToolbar} />
            }
            <Box className={classes.root} style={{ padding: 0 }}>
                <Grid container spacing={0} direction="row" alignItems="stretch" alignContent="stretch" style={{ height: '100%' }} id="builderContent">
                    <Grid item xs={12} style={{ padding: '16px', minHeight: '100px' }}>
                        <Typography variant="h6" style={{ color: '#70677b' }}>
                            Tile Preview
                        </Typography>
                        <br />
                        <Box className={classes.tileControllerWrapper}>
                            <TileController
                                tileModel={tileSettings as TileModel}
                                paramValues={tileSettings.testValues}
                                mode={TileEditMode.TileBuilder}
                                onResizeTile={onResizeTile} />
                        </Box>
                        <TileSettingsDrawer />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default DashboardTileBuilder;
