import React, { useState, useEffect } from 'react';
import { Select, Input, MenuItem, Checkbox, ListItemText, Divider } from '@material-ui/core';
import { EmptyGuid } from '../../Map/state/mapDashboardStateV2';

type MultipleSelectProps = {
    id?: string;
    includeSelectAll: boolean;
    type: 'checkbox';
    currentSelected: string[];
    onChange: (newSelected: string[]) => void;
    selectableItems: selectableItem[];
    disabled?: boolean;
    renderAllSelected?: boolean;
}

type selectableItem = {
    id: string;
    name: string; 
}

const selectAllItem: selectableItem = { id: EmptyGuid, name: 'All' };

export const MultipleSelect = (props: MultipleSelectProps): JSX.Element => {
    const { id, includeSelectAll, currentSelected, onChange: onValueChange, selectableItems, disabled = false, renderAllSelected = false } = props;

    const getLocalCurrentSelected = (selected: string[], availables: selectableItem[]): string[] => {
        const lcs = [...selected];
        if (selected.length === availables.length) {
            lcs.push(selectAllItem.id);
        }

        return lcs;
    };

    const [localCurrentSelected, setLocalCurrentSelected] = useState(getLocalCurrentSelected(currentSelected, selectableItems));

    useEffect(() => {
        setLocalCurrentSelected(getLocalCurrentSelected(currentSelected, selectableItems));
    }, [currentSelected, selectableItems]);

    useEffect(() => {
        if (disabled) {
            changeValue([]);
        }
    }, [disabled]);

    const renderValue = (selected: unknown): React.ReactNode => {
       const sv = selected as string[];
        if (sv.length >= selectableItems.length || sv.includes(selectAllItem.id)) {
            return <div>All</div>;
        }

        if (sv.length === 0) {
            return <div>None</div>;
        }

        if(renderAllSelected)
        {
            const valueJoin: string[] = [];
            (selected as string[]).forEach(selectedItem => {
                const currentSelected = selectableItems.find(item => item.id === selectedItem);
                if(currentSelected != null)
                {
                    valueJoin.push(currentSelected.name);
                }
            });
            return valueJoin.join(', ');
        }

        return `${selectableItems.find((item) => item.id === (selected as string[])[0])?.name}...`;
    };

    const isChecked = (id: string): boolean => {
        return localCurrentSelected.some((selectedId) => selectedId === id);
    };

    const changeValueHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const newSelected = event.target.value as string[];
        changeValue(newSelected);
    };

    const changeValue = (newSelected: string[]) => {
        let newSelectedIds: string[] = [];

        // If contains select All then check if it should add all or if it should unmark "All" checkbox
        if (newSelected.includes(selectAllItem.id)) {
            // If previously already selected All but unselected one item then unmark "All" checkbox and add only the selected
            if (localCurrentSelected.length - 1 === selectableItems.length) {
                newSelectedIds = selectableItems.filter((si) => newSelected.includes(si.id)).map((si) => si.id);
            } else {
                newSelectedIds = selectableItems.map((si) => si.id);
            }

            onValueChange(newSelectedIds);
            return;
        }

        // If unselect All then clear all
        if (localCurrentSelected.includes(selectAllItem.id)) {
            onValueChange([]);
            return;
        }

        newSelectedIds = selectableItems.filter((si) => newSelected.includes(si.id)).map((si) => si.id);
        onValueChange(newSelectedIds);
    };

    return (
        <Select
            id={id}
            displayEmpty
            multiple
            value={localCurrentSelected}
            onChange={changeValueHandler}
            input={<Input />}
            renderValue={renderValue}
            autoWidth={false}
            disabled={disabled}>
            {
                includeSelectAll &&
                <MenuItem value={selectAllItem.id} key={selectAllItem.id}>
                    <Checkbox checked={isChecked(selectAllItem.id)} />
                    <ListItemText primary={'All'} />
                    <Divider />
                </MenuItem>
            }
            {
                selectableItems.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                        <Checkbox checked={isChecked(item.id)} />
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))
            }
        </Select>
    );
};
