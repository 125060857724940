/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react';
import { MapSearchResult, MapFilterSource, EnforcementDataType, BootExtraMapSearchResult } from './mapDashboardStateV2';
import { updateMapState, setError, applyFilter, changePageOnFilter, setFilterSource, setListLoading, setBootExtraInfo } from './mapLiveDashboardSlice';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import RestClient, { RestClientV2 } from '../../../../services/RestClient';
import { ApplicationState } from '../../../../app/store';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';


const fetchMapFilterSource = (customerId: string): Promise<MapFilterSource> => {
    return new Promise((resolve, reject) => {
        RestClient.get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/filterSource`,
            params: {
                customerId: customerId,
            },
        }).then((response: any) => {
            resolve(response.data as MapFilterSource);
        }).catch((err) => {
            reject(err);
        });
    });
};

const initializeMapFilterSource = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        const state = getState();
        if (!state.dashboardFilter.customerId) {
            return Promise.resolve();
        }

        return fetchMapFilterSource(getState().dashboardFilter.customerId).then((filterSource: MapFilterSource) => {
            dispatch(setFilterSource(filterSource));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const fetchMapMarkersFromAPI = (mapFilter: MapFilterState, dashboardFilter: DashboardFilterState): Promise<MapSearchResult> => {
    return new Promise((resolve, reject) => {
        console.log('fetchMapMarkersFromAPI mapFilter: ', mapFilter);
        //change local date to server so initial time matches
        const dt = new Date(dashboardFilter.date);
        dt.setSeconds( dt.getSeconds() - 180 );
        const ETdate = (dt).toLocaleString('en-Us', {timeZone: 'America/New_York'});
        //const ETdate = dashboardFilter.dateTo
        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/livesearch`,
            data: {
                customerId: dashboardFilter.customerId,
                dateFrom: ETdate,
                dateTo: ETdate,
                plate: mapFilter.plate,
                agentsIds: mapFilter.selectedAgentsToSearchBy,
                locationsIds: mapFilter.selectedLocationsToSearchBy,
                currentPage: mapFilter.currentPage,
                pageSize: mapFilter.pageSize,
                dataTypes: mapFilter.selectedDataTypes,
                useExactDate: true
            },
        }).then((response: any) => {
            console.log('fetchMapMarkersFromAPI response: ', response);
            resolve(response as MapSearchResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

const applyFilterAndFetchMarkers = (mapFilter: MapFilterState) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        mapFilter.currentPage = getState().mapDashboard.filter ? getState().mapDashboard.filter.currentPage : 1;
        console.log('filter before apply', mapFilter);
        dispatch(applyFilter(mapFilter));

        const dashboardFilter = getState().dashboardFilter;
        //dashboardFilter.date = Date.now.toString();

        return fetchMapMarkersFromAPI(mapFilter, getState().dashboardFilter).then((mapSearchResult: MapSearchResult) => {
            console.log('mapSearchResult >>', mapSearchResult)
            dispatch(updateMapState(mapSearchResult));
            getBootRecordExtraInformation(dispatch, mapSearchResult);
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const getBootRecordExtraInformation = (dispatch: Dispatch<any>, mapSearchResult: MapSearchResult) =>{
    
    dispatch(setListLoading(true));
    //get vio numbers
    const vioNumberArray: Array<string> = [];
    mapSearchResult.results.forEach(x=>{
        if(x.type == EnforcementDataType.Boot)
        {
            if(x.bootRecord != null)
            {
                vioNumberArray.push(x.bootRecord.violationNumber);
            }
        }
    });

    //get boot record extra information
    RestClientV2.post({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/bootRecordExtra`,
        data: {
            vioNumberList: vioNumberArray
        },
    }).then((response: any) => {
        console.log('Bootrecord response: ', response);
        const resp = response as BootExtraMapSearchResult;
        dispatch(setBootExtraInfo(resp.results));
        dispatch(setListLoading(false));
    }).catch((err) => {
        console.log('Bootrecord Error: ', err);
        dispatch(setListLoading(false));
    });
};

const changePageAndFetchMarkers = (page: number) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): void => {
        dispatch(changePageOnFilter(page));

        // const state = getState();

        // return fetchMapMarkersFromAPI(state.mapDashboard.filter, state.dashboardFilter).then((mapSearchResult: MapSearchResult) => {
        //     dispatch(updateMapState(mapSearchResult));
        // }).catch((err: string) => {
        //     dispatch(setError(err));
        // });
    };
};

export { initializeMapFilterSource, applyFilterAndFetchMarkers, changePageAndFetchMarkers, getBootRecordExtraInformation };
