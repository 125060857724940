import React from 'react';
import { LinearProgress, Grid } from '@material-ui/core';
import { useStyles } from './BorderLinearProgressStyles';

export type BorderLinearProgressProps = {
    value: number;
    height?: number;
} & typeof defaultProps;

const defaultProps = {
    height: 12,
};

export default function BorderLinearProgress (props: BorderLinearProgressProps): JSX.Element {
    const { value } = props;
    const classes = useStyles(props);

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <LinearProgress classes={classes} variant="determinate" value={value} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


