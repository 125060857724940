/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { useState, useEffect, useRef } from 'react';
import 'ol/ol.css';
import Feature, { FeatureLike } from 'ol/Feature';
import OLMap from 'ol/Map';
import View from 'ol/View';
import { Point, LineString, Geometry, Polygon } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer, Heatmap as HeatmapLayer, Layer, Vector } from 'ol/layer';
import {defaults as controlDeafaults, Control} from 'ol/control'
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import { unByKey } from 'ol/Observable';
import { click, pointerMove } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import {defaults as interactionDefaults, Draw, Modify, Snap} from 'ol/interaction';
import { MapMarker, MapLayerType, MapDashboardStateModel, MapFilterDataType, PointType, ZoneType, EnforcementZoneModificationType, EnforcementZone, EnforcementZoneAssignation, all, MapAddressSearchResult } from '../../state/mapDashboardStateV2';
import { Paper } from '@material-ui/core';
import routeLayerStyles from './routeLayerStyles';
import readsLayerStyle, { onHoverReadLayerStyle, getSelectedStyle, getMapZoneDrawingStyle } from './readsLayerStyles';
import { MapFilterState } from '../FilterBar/mapFilterState';
import { MapBrowserEvent, Overlay } from 'ol';
import OverlayPositioning from 'ol/OverlayPositioning';
import { useStyles } from './mapStyles';
import {MouseWheelZoom} from 'ol/interaction';
import GeometryType from 'ol/geom/GeometryType';
import { truncate } from 'fs';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import { v4 as uuidv4 } from 'uuid';
import { Dictionary } from '@reduxjs/toolkit';
import BaseEvent from 'ol/events/Event';
import {  changeEnforcementZone } from '../../state/mapDashboardThunks';
import { _ } from 'ag-grid-community';
import { Coordinate } from 'ol/coordinate';
import { useDispatch } from 'react-redux';
import { polygonDivide } from '../../../../../utils/MapHelper';
import { getCenter } from 'ol/extent';
import { RestClientV2 } from '../../../../../services/RestClient';



type MapProps = {
    layerType: MapLayerType;
    markers: MapMarker[];
    liveMarkers: MapMarker[];
    zoom?: number;
    onSelectedMarker: (selectedMarkerId: string) => void;
    selectedMarker?: MapMarker | null;
    onDrawEnd: () => void;
    center: Array<number>;
    customerId: string;
    mapFilter?: MapFilterState;
    drawActive?: boolean;
    drawType: string;
    modifyActive?: boolean;
    zoneName: string;
    showZoneLayer: boolean;
    isDeleteActive: boolean;
    enforcementZones: EnforcementZone[];
    enforcementAssignationZones: EnforcementZoneAssignation[];
    weekDay: string;
    hotspotNumber: number;
    onGenerateHotspotsEnd: () => void;
    hotspotAddress: string;
    onGenerateHotspotAddressEnd: () => void;
};

type MapState = {
    map: OLMap;
    layers: {
        vector: VectorLayer;
        live: VectorLayer;
        heat: HeatmapLayer;
        zones: VectorLayer;
        route: VectorLayer;
    };
    zoom: number;
    center: Array<number>;
    customerId: string;
}


type ZoomControlAttributes = {
    id: string;
    zoom: number;
};

// const defaultCenter = [-100.964709, 39.259336];



const Map = (props: MapProps): JSX.Element => {
    const { layerType, markers, liveMarkers, zoom = 13, center, customerId, mapFilter, onSelectedMarker, onDrawEnd, selectedMarker, drawActive, modifyActive, drawType, showZoneLayer, zoneName, isDeleteActive, enforcementZones, enforcementAssignationZones, weekDay, hotspotNumber, onGenerateHotspotsEnd, hotspotAddress, onGenerateHotspotAddressEnd } = props;

    const mapHeight = window.innerWidth >= 992 ? window.innerHeight - 178 : 400;

    const isScans = mapFilter != null ? mapFilter.selectedDataTypes.includes(MapFilterDataType.Scan as unknown as string) : false;

    const classes = useStyles();

    const dispatch = useDispatch();

    const zoomControlList: Array<ZoomControlAttributes> = [{id:'zoom-1', zoom:16}, {id:'zoom-2', zoom: 14.5}, {id:'zoom-3', zoom: 14}, {id:'zoom-4', zoom: 13.5}, {id:'zoom-5', zoom: 13},
                                                            {id:'zoom-6', zoom: 12.5}, {id:'zoom-7', zoom: 12}, {id:'zoom-8', zoom: 11.5}, {id:'zoom-9', zoom: 11}, {id:'zoom-10', zoom: 10}];
    let zoomControlSelected: ZoomControlAttributes;
    zoomControlSelected= zoomControlList[4];

    const onWindowResizeHandler = (): void => {
        const height = window.innerWidth >= 992 ? window.innerHeight - 178 : 400;
        const map = document.getElementById('map');
        if (map) map.style.height = `${height}`;
    };

    const [state, setState] = useState({
        map: new OLMap({
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: center,
                zoom: zoom,
                projection: 'EPSG:4326',
            }),
            controls: controlDeafaults({
                zoom: false
            }),
            interactions: interactionDefaults({mouseWheelZoom:false})
        }),
        layers: {
            vector: new VectorLayer({}),
            live: new VectorLayer({}),
            heat: new HeatmapLayer({}),
            zones: new VectorLayer({/*renderBuffer:100000*/source: new VectorSource({}), 
                style: getMapZoneDrawingStyle(null),
            }),
            route: new VectorLayer({}),
        },
        zoom: zoom,
        center: center,
        customerId: customerId,
    } as MapState);


    const addLayersToMap = (): void => {
        state.layers.vector.set('source', new VectorSource({}), false);
        state.layers.vector.set('name', 'reads');
        state.map.addLayer(state.layers.vector);

        state.layers.live.set('source', new VectorSource({}), false);
        state.layers.heat.set('name', 'heat');
        state.map.addLayer(state.layers.live);

        state.layers.heat.set('source', new VectorSource({}), false);
        state.layers.route.set('name', 'reads');
        state.layers.heat.setOpacity(0.4);
        state.map.addLayer(state.layers.heat);

        state.layers.route.set('source', new VectorSource({}), false);
        state.map.addLayer(state.layers.route);

        //state.layers.zones.set('source', new VectorSource({}), false);already set on map initialization
        state.map.addLayer(state.layers.zones);
        
    };

    //custom control
    const setCustomContols = (): void =>{
         
        const zoomElementList: Array<HTMLElement> = [];
        
        //zoom in label
        const zoomInElement = document.getElementById('zoom-in') as HTMLElement;
        zoomInElement.addEventListener('click', function add(): void {handleZoomStep('DOWN')}, false);
        const zoomInControl = new Control({element:zoomInElement});
        state.map.addControl(zoomInControl);

        //zoom controls
        zoomControlList.forEach((zoomControl)=>{
            const zoomElement = document.getElementById(zoomControl.id) as HTMLElement;
            zoomElement.addEventListener('click', function add(): void {handleZoomChanged(zoomControl, zoomElement)}, false);
            const zoomControlElement = new Control({element:zoomElement});
            state.map.addControl(zoomControlElement);
            zoomElementList.push(zoomElement);
        });

        //zoom out label
        const zoomOutElement = document.getElementById('zoom-out') as HTMLElement;
        zoomOutElement.addEventListener('click', function add(): void {handleZoomStep('UP')}, false);
        const zoomOutControl = new Control({element:zoomOutElement});
        state.map.addControl(zoomOutControl);


        //handle zoom changed
        const handleZoomChanged = (zoomControl: ZoomControlAttributes, currentZoomElement: HTMLElement): void => {
            state.map.getView().setZoom(zoomControl.zoom);
            zoomControlSelected = zoomControl;

            zoomElementList.forEach((zoomElement) =>{
                if(zoomElement.id  == currentZoomElement.id)
                {
                    zoomElement.classList.add('selectedFixedZoom');
                }
                else
                {
                    zoomElement.classList.remove('selectedFixedZoom');
                }
            });
        };

        //handle zoom step
        const handleZoomStep = (direction: string): void => {
            if(direction== 'UP')
            {
                const selectedIndex = zoomControlList.indexOf(zoomControlSelected);
                if(selectedIndex != zoomControlList.length -1)//only increase if we are not at the top already
                {
                    const newZoomControlSelected = zoomControlList[selectedIndex+1];
                    const zoomElement = document.getElementById(newZoomControlSelected.id) as HTMLElement;
                    handleZoomChanged(newZoomControlSelected, zoomElement);
                }
            }
            else
            {
                const selectedIndex = zoomControlList.indexOf(zoomControlSelected);
                if(selectedIndex != 0)//only decrease if we are not at the top already
                {
                    const newZoomControlSelected = zoomControlList[selectedIndex-1];
                    const zoomElement = document.getElementById(newZoomControlSelected.id) as HTMLElement;
                    handleZoomChanged(newZoomControlSelected, zoomElement);
                }
            }
        };

    }

    const addEventListenersToMap = (): void => {
        // select interaction working on "click"
        const selectClick = new Select({
            style: getSelectedStyle(),
            condition: click,
        });

        selectClick.on('select', (event) => {
            if (event.selected && event.selected.length > 0) {
                onSelectedMarker((event.selected[0] as any).values_.ol_uid);
            }
        });
      

        // select interaction working on "pointermove"
        const selectPointerMove = new Select({
            condition: pointerMove,
            style: getSelectedStyle(),
        });

        state.map.addInteraction(selectClick);
        state.map.addInteraction(selectPointerMove);

        state.map.on('moveend', (event) => {
            //nothign to do here yet
        });

        state.map.on('click', (event: MapBrowserEvent) => {
            
            const features = state.map.getFeaturesAtPixel(event.pixel);

            //first check if delete is active if so, delete feature
            if(features && features.length >= 1 &&  currentIsDeleteActiveRef.current)
            {

                let zoneAssignation = false;
                let elementToDelete: any = null;

                for (let i = 0; i < features.length; i++) {
                    const feature = features[i];
                    if(feature.get('type') != null)//we need to search for a feture with a type to know that was created from us
                    {
                        elementToDelete = feature;
                        currentEnforcementAssignationZonesRef.current?.forEach(assignedZone => {
                            const assignedZones = JSON.parse(assignedZone.enforcementZoneIds);
                            assignedZones.forEach((zoneAssigned: string) => {
                                if(zoneAssigned == elementToDelete.getId())
                                {
                                    zoneAssignation = true;
                                }
                            });
                            
                        });
                        break;//if it has type then we found the element to delete, stop searching.
                    }
                }
                
                if(zoneAssignation)
                {
                    alert('Cannot delete, this zone is currently assigned.');
                }
                else if(elementToDelete != null)
                {
                    deleteFeatures(elementToDelete);
                }
            }
        });

        state.map.getView().on('change:center', () => {
           //nothign to do here yet
        });
    };


    const clearStyles = (): void =>{
        const readLayer = state.map.getLayers().getArray().find(layer => layer.get('name') == 'reads') as Vector;
        const readSource = readLayer?.getSource();
        const sourceFeatures = readSource?.getFeatures();
        sourceFeatures?.forEach(feature => {
            feature.setStyle(readsLayerStyle(feature.get('marker') as MapMarker, isScans, mapFilter));
        });
    }
    

    const  highlightFeature = (selectedFeature: Feature): void =>{
        if(selectedFeature != null)
        {
            clearStyles();
            selectedFeature.setStyle(getSelectedStyle());
            //selectClick.getFeatures().clear();
            //selectClick.getFeatures().push(selectedFeature);
            //selectClick.dispatchEvent('select');
        }
     }

    

    //Runs once on page load
    useEffect(() => {
        window.addEventListener('resize', onWindowResizeHandler);

        state.map.set('target', document.getElementById('map'), false);
        addLayersToMap();
        
        addEventListenersToMap();

        //on hover interaction
        const tooltip = document.getElementById('tooltip-id') as HTMLElement;
        const overlay = new Overlay({
                    element: tooltip,
                    positioning: OverlayPositioning.BOTTOM_CENTER,
                    stopEvent: false,
                    offset: [0, -50],
                  });
        

        
        const selectOnHover = new Select({
            condition: pointerMove
        });

        selectOnHover.on('select', function (e) 
        {
            if(e.target.getFeatures().getLength() > 0)
            {
                const feature = e.target.getFeatures().getArray()[0];
                const coordinates = feature.getGeometry().getCoordinates();
                const marker = feature.get('marker') as MapMarker;
                overlay.setPosition(coordinates);
                if(marker != null)
                {
                    tooltip.innerHTML = marker.licPlate != null ? marker.licPlate : marker.agentFullName;
                    state.map.addOverlay(overlay);
                }
            }
            else
            {
                state.map.removeOverlay(overlay);
            }
        });    
        state.map.addInteraction(selectOnHover);
        mapDraw.init();//initialize map drawing
        mapDraw.setActive(false, true, '');//initialize zone modifications on the map from the beggining
    }, []);

    useEffect(() => {
        const readLayer = state.map.getLayers().getArray().find(layer => layer.get('name') == 'reads') as Vector;
        const readSource = readLayer?.getSource();
        const sourceFeatures = readSource?.getFeatures();
        const feature = sourceFeatures?.find(feature => { return feature.getId() == selectedMarker?.lprDataID}) as Feature;

        highlightFeature(feature);
    }, [selectedMarker]);


    const [currentCustomerId, _setCurrentCustomerId] = React.useState('');
    const currentCustomerIdRef = React.useRef(currentCustomerId);
    const setCurrentCustomerId = (data: string) => {
        currentCustomerIdRef.current = data;
      _setCurrentCustomerId(data);
    };
    useEffect(() => {
        setCurrentCustomerId(customerId);
    }, [customerId]);


    //center map 
    useEffect(() => {
        state.map.getView().setCenter(center);
    }, [center[0]]);


    //update info when customer id change
    useEffect(() => {
        const newState = {
            map: state.map,
            layers: state.layers,
            zoom: state.zoom,
            center: state.center,
            customerId: state.customerId,
        } as MapState;


        // If customer changed then reset zoom.
        if (state.customerId !== customerId) {
            state.map.getView().setZoom(zoom);
            newState.zoom = zoom;
        }

        if (state.customerId !== customerId && customerId) {
            newState.customerId = customerId;
        }

        setState(newState);

        setCustomContols();
        //if client changed restart zoom
        zoomControlSelected = zoomControlList[4];
        zoomControlList.forEach((zoomControl)=>{
            const zoomElement = document.getElementById(zoomControl.id) as HTMLElement;
            if(zoomControl.id  == zoomControlSelected.id)
            {
                zoomElement.classList.add('selectedFixedZoom');
            }
            else
            {
                zoomElement.classList.remove('selectedFixedZoom');
            }
            
        });

    }, [customerId]);

    
    //when marker changed render layers
    useEffect(() => {
        clearLayers();

        switch (layerType) {
            case MapLayerType.Reads:
                renderReadsLayer();
                break;
            case MapLayerType.Heat:
                renderHeatLayer();
                break;
            case MapLayerType.Route:
                renderRouteLayer();
                break;
        }
    }, [markers]);

    useEffect(() => {
        renderLiveReadsLayer();
    }, [liveMarkers]);

    useEffect(() => {
        renderEnforcementZoneLayer();
    }, [enforcementZones, enforcementAssignationZones]);

    

    

    //methods to show points on differnet layers
    //////////////////////////
    const renderReadsLayer = (): void => {
        markers.forEach((marker: MapMarker) => {
            const featureMarker = new Feature({
                geometry: new Point([marker.longitude, marker.latitude]),
                location_uuid: marker.lprDataID,
                ol_uid: marker.lprDataID,
            });

            featureMarker.setId(marker.lprDataID);
            featureMarker.set('dataType', marker.srcType);
            featureMarker.set('marker', marker);
            featureMarker.setStyle(readsLayerStyle(marker, isScans, mapFilter));
            state.layers.vector.getSource().addFeature(featureMarker);
        });
    };

    const renderLiveReadsLayer = (): void => {
        state.layers.live.getSource().clear();

        liveMarkers?.forEach((marker: MapMarker) => {
            const featureMarker = new Feature({
                geometry: new Point([marker.longitude, marker.latitude]),
                location_uuid: marker.lprDataID,
                ol_uid: marker.lprDataID,
            });

            featureMarker.setId(marker.lprDataID);
            featureMarker.set('dataType', marker.srcType);
            featureMarker.set('marker', marker);
            featureMarker.setStyle(readsLayerStyle(marker, isScans, mapFilter));
            state.layers.live.getSource().addFeature(featureMarker);
        });
    };

    const renderEnforcementZoneLayer = (): void => {
        state.layers.zones.getSource().clear();

        enforcementZones?.forEach((zone: EnforcementZone) => {
            const zoneCoordinates = JSON.parse(zone.coordinates);
            const featureMarker = new Feature({
                geometry: new Polygon(zoneCoordinates)//Point([marker.longitude, marker.latitude]),
            });

            const weedDays = getWeekDay(zone);

            featureMarker.setId(zone.enforcementZoneId);
            featureMarker.set('type', ZoneType);
            featureMarker.set('weekDays', weedDays);
            featureMarker.set('zoneName', zone.zoneName);
            featureMarker.setStyle(getMapZoneDrawingStyle(zone.zoneName));

            state.layers.zones.getSource().addFeature(featureMarker);
            
            let pointsCoordinates = [];
            if(zone.points != null && zone.points != '')
            {
                pointsCoordinates = JSON.parse(zone.points);
            }

            pointsCoordinates?.forEach((point: Coordinate) => {
                const pointMarker = new Feature({
                    geometry: new Point(point),
                });
    
                pointMarker.setId(uuidv4());
                pointMarker.set(ZoneType, zone.enforcementZoneId);
                pointMarker.set('type', PointType);
    
                state.layers.zones.getSource().addFeature(pointMarker);
            });
        });
        showHideZones(currentEnforcementWeekDaysRef.current);
    };

    const getWeekDay = (zone: EnforcementZone): string =>{
        let weekDays = '';
        enforcementAssignationZones?.forEach((zoneAssignation: EnforcementZoneAssignation)=>{
            if(zoneAssignation.enforcementZoneIds.includes(zone.enforcementZoneId))
            {
                weekDays += ' '+zoneAssignation.weekDays;
            }
        });  
        return weekDays;
    };

    const renderHeatLayer = (): void => {
        markers.forEach((marker) => {
            const featureMarker = new Feature({
                geometry: new Point([marker.longitude, marker.latitude]),
                location_uuid: marker.lprDataID,
                ol_uid: marker.lprDataID,
            });

            featureMarker.setId(marker.lprDataID);
            featureMarker.set('dataType', marker.srcType);
            featureMarker.setStyle(readsLayerStyle(marker));
            state.layers.heat.getSource().addFeature(featureMarker);
        });
    };
    

    let routeGeoMarker: Feature = new Feature({});
    let routeMarkerIndex = 0;
    let routeDatetime: number;
    let routePostRenderEventKey: any;

    const moveFeature = (event: any): void => {
        const frameState = event.frameState;
        const pathSpeed = 1000;
        const elapsedTime = frameState.time - (routeDatetime);

        if (elapsedTime > pathSpeed) {
            routeDatetime = new Date().getTime();
            //move marker
            routeGeoMarker.set('geometry', new Point([markers[routeMarkerIndex].longitude, markers[routeMarkerIndex].latitude]));

            routeMarkerIndex = routeMarkerIndex + 1;
            if (routeMarkerIndex >= markers.length) {
                routeMarkerIndex = 0;
            }
        }

        state.map.render();
    };

    const renderRouteLayer = (): void => {
        routeMarkerIndex = 1;

        const locations = markers.map((marker) => [marker.longitude, marker.latitude]);
        const route = new LineString(locations);

        const routeFeature = new Feature({
            type: 'route',
            geometry: route,
        });
        routeFeature.setStyle(routeLayerStyles('route'));
        state.layers.route.getSource().addFeature(routeFeature);

        const startMarker = new Feature({
            type: 'startpoint',
            geometry: new Point(route.getCoordinates()[0]),
        });
        startMarker.setStyle(routeLayerStyles('startpoint'));
        state.layers.route.getSource().addFeature(startMarker);

        const endMarker = new Feature({
            type: 'endpoint',
            geometry: new Point(route.getCoordinates()[route.getCoordinates().length - 1]),
        });
        endMarker.setStyle(routeLayerStyles('endpoint'));
        state.layers.route.getSource().addFeature(endMarker);

        //huh?
        routeGeoMarker = new Feature({
            type: 'geoMarker',
            geometry: new Point(route.getCoordinates()[1]),
        });
        routeGeoMarker.setStyle(routeLayerStyles('geoMarker'));
        state.layers.route.getSource().addFeature(routeGeoMarker);

        routeDatetime = new Date().getTime();

        routePostRenderEventKey = state.layers.route.on('postrender', moveFeature);
        state.map.render();
    };
    //End methods to show points on differnet layers 
    //////////////////////////

    const clearLayers = (): void => {
        unByKey(routePostRenderEventKey);
        state.layers.vector.getSource().clear();
        state.layers.heat.getSource().clear();
        state.layers.route.getSource().clear();
    };



    //drawawing hooks
    ///////////////
    useEffect(() => {
        const isDrawActive = drawActive != null ? drawActive : false;
        const isModifyActive = modifyActive != null ? modifyActive : false;
        mapDraw.setActive(isDrawActive, isModifyActive, drawType);
    }, [drawActive, modifyActive]);


    useEffect(() => {
        state.layers.zones.setVisible(showZoneLayer);
    }, [showZoneLayer]);

    const [currentZoneName, _setCurrentZoneName] = React.useState('');
    const currentZoneNameRef = React.useRef(currentZoneName);
    const setCurrentZoneName = (data: string) => {
        currentZoneNameRef.current = data;
        _setCurrentZoneName(data);
    };
    useEffect(() => {
        setCurrentZoneName(zoneName);
    }, [zoneName]);

    const [currentIsDeleteActive, _setCurrentIsDeleteActive] = React.useState(false);
    const currentIsDeleteActiveRef = React.useRef(currentIsDeleteActive);
    const setCurrentIsDeleteActive = (data: boolean) => {
      currentIsDeleteActiveRef.current = data;
      _setCurrentIsDeleteActive(data);
    };
    useEffect(() => {
        setCurrentIsDeleteActive(isDeleteActive);
    }, [isDeleteActive]);

    const [currentEnforcementAssignationZones, _setCurrentEnforcementAssignationZones] = React.useState(enforcementAssignationZones);
    const currentEnforcementAssignationZonesRef = React.useRef(currentEnforcementAssignationZones);
    const setCurrentEnforcementAssignationZones = (data: EnforcementZoneAssignation[]) => {
        currentEnforcementAssignationZonesRef.current = data;
      _setCurrentEnforcementAssignationZones(data);
    };
    useEffect(() => {
        setCurrentEnforcementAssignationZones(enforcementAssignationZones);
    }, [enforcementAssignationZones]);
    

    const [currentEnforcementWeekDays, _setCurrentEnforcementWeekDays] = React.useState(weekDay);
    const currentEnforcementWeekDaysRef = React.useRef(currentEnforcementWeekDays);
    const setCurrentEnforcementWeekDays = (data: string) => {
        currentEnforcementWeekDaysRef.current = data;
        _setCurrentEnforcementWeekDays(data);
    };
    useEffect(() => {
        setCurrentEnforcementWeekDays(weekDay);
        showHideZones(currentEnforcementWeekDaysRef.current);
    }, [weekDay]);

    useEffect(() => {
        if(hotspotNumber > 0)
        {
            onGenerateHotspots();
        }
    }, [hotspotNumber]);

    useEffect(() => {
        if(hotspotAddress != '')
        {
            onGenerateHotspotByAddress();
        }
    }, [hotspotAddress]);
    
    //end drawawing hooks
    ///////////////

    //map drawing base structure
    ////////////////////////////////
    const [mapDraw, setMapDraw] = React.useState<any>({
        init: function (){
            //initialize events
            this.Polygon.on('drawend', (event: DrawEvent) =>{
                onDrawZoneEnd(event)
            });
            this.Point.on('drawend', (event: DrawEvent) =>{
                onDrawPointEnd(event);
            });
            this.Modify.on('modifyend', (event: ModifyEvent) =>{
                onModifyEnd(event);
            });
        },
        Polygon: new Draw({
          source: state.layers.zones.getSource(),
          type: GeometryType.POLYGON,
          
        }),
        Point: new Draw({
            source: state.layers.zones.getSource(),
            type: GeometryType.POINT
        }),
        Snap: new Snap({
            source: state.layers.zones.getSource()
        }),
        Modify: new Modify({
            source: state.layers.zones.getSource(),
        }),
        setActive: function (isDrawActive: boolean, isModifyActive: boolean, type: string) {
            //draw
            const draw = type == 'zone' ? this.Polygon : this.Point;
            if(isDrawActive)
            {
                state.map.addInteraction(draw);
                if(type == 'zone')//draw zone
                {
                    this.Polygon.setActive(true);
                }
                else//draw a point
                {
                    this.Point.setActive(true);
                }
                
                state.map.addInteraction(this.Snap);
            }
            else
            {
                //disable drawing
                this.Polygon.setActive(false);
                this.Point.setActive(false);
                state.map.removeInteraction(this.Snap);
                state.map.removeInteraction(draw);
            }

            //modify
            if(isModifyActive)
            {
                state.map.addInteraction(this.Modify);
            }
            /*else  leave modify always active, not working anyways
            {
                state.map.removeInteraction(this.modify);
            }*/
        }
    });
    //end map drawing base structure
    ////////////////////////////////

    //drawing event handlers
    /////////////////////////
    const onDrawZoneEnd = (event: DrawEvent): void => {
        event.feature.setId(uuidv4());
        event.feature.set('type', ZoneType);
        event.feature.setStyle(getMapZoneDrawingStyle(currentZoneNameRef.current));
        
        
        const zone: Polygon = event.feature.getGeometry() as Polygon;
        const currentZoneName = currentZoneNameRef.current;
        const zoneId = event.feature.getId();
        const zoneCoordinates= JSON.stringify(zone.getCoordinates());
        
        //TODO: Might need to update to add points inside zone when automatically created

        dispatch(changeEnforcementZone(zoneId, currentZoneName, zoneCoordinates, '',  EnforcementZoneModificationType.Insert));
       onDrawEnd();//callback to clear zone name and end drawing
    };

    const onDrawPointEnd = (event: DrawEvent): void => {
        const selected = [];
        event.feature.setId(uuidv4());
        event.feature.set('type', PointType);
        state.layers.zones.getSource().forEachFeatureIntersectingExtent(event.feature.getGeometry().getExtent(),function(zone){
            if (zone.get('type') == ZoneType) //only if point belongs to a zone we save it to server
            {
                //assign point to zone
                event.feature.set(ZoneType, zone.getId());
                //get all points inside zone
                const pointInZone: Array<Coordinate[][]> = [];  
                pointInZone.push((event.feature.getGeometry() as Polygon).getCoordinates());
                state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                    if (possiblePoint.get('type') == PointType) {
                        pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                    }
                });

                const currentZoneName = currentZoneNameRef.current;
                const zoneId = zone.getId();
                const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
                const points = JSON.stringify(pointInZone);

                dispatch(changeEnforcementZone(zoneId, currentZoneName, zoneCoordinates, points, EnforcementZoneModificationType.Update));
            }
        });
    };

    const onModifyEnd = (event: ModifyEvent): void => {
        //get the zones
       
        event.features.forEach(feature =>{
            if(feature.get('type') == ZoneType && !currentIsDeleteActiveRef.current)//we don't want to send modify requst when deleting
            {
                const zone = feature;
                const pointInZone: Array<Coordinate[][]> = [];  
                //pointInZone.push((zone.getGeometry() as Polygon).getCoordinates());
                state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                    if (possiblePoint.get('type') == PointType) {
                        pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                    }
                });

                const currentZoneName = currentZoneNameRef.current;
                const zoneId = zone.getId();
                const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
                const points = JSON.stringify(pointInZone);

                dispatch(changeEnforcementZone(zoneId, currentZoneName, zoneCoordinates, points, EnforcementZoneModificationType.Update));
            }
        });
    };

    const deleteFeatures = (feature: FeatureLike): void => {
        if(feature.get('type') == ZoneType)
        {
            const zone = feature;
            //if its a zone remove zone
            state.layers.zones.getSource().removeFeature(state.layers.zones.getSource().getFeatureById(zone.getId()));
            //remove points
            state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                if (possiblePoint.get('type') == PointType) {
                    state.layers.zones.getSource().removeFeature(possiblePoint);
                }
            });

            const currentZoneName = currentZoneNameRef.current;
            const zoneId = zone.getId();

            dispatch(changeEnforcementZone(zoneId, currentZoneName, '', '', EnforcementZoneModificationType.Delete));
        }
        else if(feature.get('type') == PointType)
        {
            const point = feature;
            //remove the point from map
            state.layers.zones.getSource().removeFeature(state.layers.zones.getSource().getFeatureById(point.getId()));
            //if its a point first get the zone that belongs to
            state.layers.zones.getSource().forEachFeatureIntersectingExtent(point.getGeometry().getExtent(),function(zone){
                if (zone.get('type') == ZoneType) //if feature is a zone
                {
                    //then we get all points inside that zone
                    const pointInZone: Array<Coordinate[][]> = [];  
                    
                    state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                        if (possiblePoint.get('type') == PointType) {
                            pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                        }
                    });
                    
                    const currentZoneName = currentZoneNameRef.current;
                    const zoneId = zone.getId();
                    const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
                    const points = JSON.stringify(pointInZone);

                    dispatch(changeEnforcementZone(zoneId, currentZoneName, zoneCoordinates, points,  EnforcementZoneModificationType.Update));
                }
            });
        }
    };

    const onGenerateHotspots = (): void => {
        if(state?.layers?.zones != null)
        {
            if(state.layers.zones.getSource().getFeatures().length > 0)
            {
                state.layers.zones.getSource().forEachFeature (function(feature)
                {
                    //get the Enforcement zone
                    if (feature.get('type') == ZoneType) 
                    {
                        const divisionWithPointsNumber: Array<{zone: Feature<Geometry>, readPointNumber: number}> = [];
                        //divide the enforcement zone
                        const polygonParts = polygonDivide(feature, 50);
                        polygonParts.forEach(zoneDivision=>{
                            //then we get all read points inside that zone division
                            const pointsInZone: Array<Coordinate[][]> = [];  
                            
                            state.layers.vector.getSource().forEachFeatureIntersectingExtent(zoneDivision.getGeometry().getExtent(),function(possiblePoint){
                                pointsInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                            });
                            const numberOfPointsOnZone = pointsInZone.length;

                            //create dictionary with the zone divisions and its contained read points
                            divisionWithPointsNumber.push({zone: zoneDivision, readPointNumber: numberOfPointsOnZone});

                            //draw the zone division on the map
                            //zoneDivision.setStyle(getMapZoneDrawingStyle(numberOfPointsOnZone.toString()));
                            //state.layers.zones.getSource().addFeature(zoneDivision);
                        });

                        //order the array by number of read points desc
                        const sortedZoneDivisions = divisionWithPointsNumber.sort((p1, p2) => (p1.readPointNumber < p2.readPointNumber) ? 1 : (p1.readPointNumber > p2.readPointNumber) ? -1 : 0);
                        console.log(hotspotNumber);

                        //draw the generated hotspots
                        for(let i=0; i< hotspotNumber; i++)
                        {
                            const currentZoneExtend = sortedZoneDivisions[i].zone.getGeometry().getExtent();
                            const currentZoneCenter = getCenter(currentZoneExtend);
                            const hotspotPoint = new Feature({
                                geometry: new Point(currentZoneCenter)
                            });
                
                            hotspotPoint.setId(uuidv4());
                            hotspotPoint.set(ZoneType, feature.getId());
                            hotspotPoint.set('type', PointType);
                
                            state.layers.zones.getSource().addFeature(hotspotPoint);
                        }

                        //save the generated hotspots
                        saveDrawnHotspotsToAZone(feature);
                    }
                });
            }
            else
            {
                alert('Please create a zone first');
            }
        }
        onGenerateHotspotsEnd();
    };

    const onGenerateHotspotByAddress = (): void => {
        //
        RestClientV2.getR({
            url: 'https://nominatim.openstreetmap.org/search?q='+hotspotAddress+'&format=json',
            params: {},
        }).then((response: MapAddressSearchResult[]) => {
            if(response.length > 0)
            {
                if(state?.layers?.zones != null && state.layers.zones.getSource().getFeatures().length > 0)
                {
                    state.layers.zones.getSource().forEachFeature (function(feature)
                    {
                        
                        const lat = response[0].lat;
                        const lon = response[0].lon;
                        if(feature.getGeometry().intersectsCoordinate([lon,lat]))
                        {
                            const hotspotPoint = new Feature({
                                geometry: new Point([lon,lat])
                            });
    
                            hotspotPoint.setId(uuidv4());
                            hotspotPoint.set(ZoneType, feature.getId());
                            hotspotPoint.set('type', PointType);
    
                            state.layers.zones.getSource().addFeature(hotspotPoint);
    
                            //save the generated hotspots
                            saveDrawnHotspotsToAZone(feature);
                        }
                    });
                }
                else
                {
                    alert('Please create a zone first');
                }
            }
            else
            {
                alert('No address found.');
            }
        }).catch((err) => {
            alert('Could not found an addres. Detail:'+err);
        });

         //callback on MapSearch
         onGenerateHotspotAddressEnd();
    };

    const saveDrawnHotspotsToAZone = (zone: Feature<Geometry>): void =>{
        const pointInZone: Array<Coordinate[][]> = [];  
        state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
            if (possiblePoint.get('type') == PointType) {
                pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
            }
        });

        const currentZoneName = currentZoneNameRef.current;
        const zoneId = zone.getId();
        const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
        const points = JSON.stringify(pointInZone);

        dispatch(changeEnforcementZone(zoneId, currentZoneName, zoneCoordinates, points, EnforcementZoneModificationType.Update));
    };

    const showHideZones = (weekDayC: string): void =>{
        state.layers.zones.getSource().forEachFeature (function(feature){
            if (feature.get('type') == ZoneType) 
            {
                let hidden = false;
                //zone
                const zone = feature;
                if(weekDayC != all && !zone.get('weekDays')?.includes(weekDayC))
                {
                    zone.setStyle(new Style({}));
                    hidden = true;
                }
                else
                {
                    zone.setStyle(getMapZoneDrawingStyle(zone.get('zoneName')));
                    hidden = false;
                }

                //points
                state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                    if (possiblePoint.get('type') == PointType) {
                        if(hidden)
                        {
                            possiblePoint.setStyle(new Style({}));
                        }
                        else
                        {
                            possiblePoint.setStyle(null);
                        }
                    }
                });
            }
        });
    };
    //end drawing event handlers
    /////////////////////////
    

    return (
        <Paper elevation={1} style={{ borderRadius: 2 }}>
            <style  dangerouslySetInnerHTML={{__html: `
            .selectedFixedZoom{
                background-color: #ffa433;
            }
            `}} />
            <div id="map" style={{
                width: '100%',
                height: mapHeight,
                backgroundColor: '#fff',
                position: 'relative',
                boxSizing: 'border-box',
            }}>
                <div id="zoom-in" className={classes.zoom+' '+classes.zoomIn}>Zoom In</div>
                <div id="zoom-1" className={classes.zoom+' '+classes.zoomOne}>1</div>
                <div id="zoom-2" className={classes.zoom+' '+classes.zoomTwo}>2</div>
                <div id="zoom-3" className={classes.zoom+' '+classes.zoomThree}>3</div>
                <div id="zoom-4" className={classes.zoom+' '+classes.zoomFourth}>4</div>
                <div id="zoom-5" className={classes.zoom+' '+classes.zoomFifth}>5</div>
                <div id="zoom-6" className={classes.zoom+' '+classes.zoomSixth}>6</div>
                <div id="zoom-7" className={classes.zoom+' '+classes.zoomSeventh}>7</div>
                <div id="zoom-8" className={classes.zoom+' '+classes.zoomEighth}>8</div>
                <div id="zoom-9" className={classes.zoom+' '+classes.zoomNineth}>9</div>
                <div id="zoom-10" className={classes.zoom+' '+classes.zoomTenth}>10</div>
                <div id="zoom-out" className={classes.zoom+' '+classes.zoomOut}>Zoom Out</div>
            </div>
            <div id="tooltip-id" className={classes.tooltip}></div>
        </Paper>
        
    );
};

export default Map;
