import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { useStyles } from './ErrorStyles';

type ErrorProps = {
    label: string;
    children?: React.ReactNode;
}

export default function Error (props: ErrorProps): JSX.Element {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} container direction="column" alignContent="center" alignItems="center" justify="center">
                <ErrorIcon />
                <Typography variant="body1">
                    {props.label}
                </Typography>
                {
                    props.children &&
                    <React.Fragment>
                        {props.children}
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    );
}
