import { ThunkAction, Action, combineReducers, createStore, compose, applyMiddleware, CombinedState, Store, AnyAction } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { History } from 'history';
import * as DashboardFilter from '../components/dashboards/state/DashboardFilterState';
import * as MainDashboard from '../components/dashboards/Main/state/MainDashboardState';
import * as EnforcementDashboard from '../components/dashboards/Enforcement/state/EnforcementDashboardState';
import * as MapDashboard from '../components/dashboards/Map/state/mapDashboardStateV2';
import * as MapLiveDashboard from '../components/dashboards/LiveMap/state/mapDashboardStateV2';
import * as MapGPSDevicesDashboard from '../components/dashboards/GPSDevices/state/mapDashboardStateV2';
import * as MapOptiRouteDashboard from '../components/dashboards/OptiRoute/state/mapDashboardStateV2';
import * as MapScenariosDashboard from '../components/dashboards/Scenarios/state/mapDashboardStateV2';
import { reducer as MapDashboardReducer } from '../components/dashboards/Map/state/mapDashboardSlice';
import { reducer as MapLiveDashboardReducer } from '../components/dashboards/LiveMap/state/mapLiveDashboardSlice';
import { reducer as MapGPSDevicesDashboardReducer } from '../components/dashboards/GPSDevices/state/mapGPSDevicesMapDashboardcDashboardSlice';
import { reducer as MapOptiRouteDashboardReducer } from '../components/dashboards/OptiRoute/state/mapOptiRouteDashboardSlice';
import { reducer as MapScenariosDashboardReducer } from '../components/dashboards/Scenarios/state/mapScenariosDashboardSlice';
import { SessionState } from './session/sessionTypes';
import { DashboardBuilderState } from '../components/dashboard-builder/state/dashboardBuilderState';

import { reducer as SessionStateReducer } from './session/sessionStateSlice';
import { reducer as DashboardBuilderStateReducer } from '../components/dashboard-builder/state/dashboardBuilderStateSlice';
import * as DashboardTileBuilder from '../components/dashboard-tile-builder/state/tileBuilderState';
import { reducer as TileBuilderStateReducer } from '../components/dashboard-tile-builder/state/tileBuilderReducer';

export interface ApplicationState {
    session: SessionState;
    dashboardFilter: DashboardFilter.DashboardFilterState;
    mainDashboard: MainDashboard.MainDashboardState;
    enforcementDashboard: EnforcementDashboard.EnforcementDashboardState;
    mapDashboard: MapDashboard.MapDashboardStateModel;
    mapLiveDashboard: MapLiveDashboard.MapDashboardStateModel;
    dashboardBuilder: DashboardBuilderState;
    mapOptiRouteDashboard: MapOptiRouteDashboard.MapDashboardStateModel;
    dashboardTileBuilder: DashboardTileBuilder.DashboardTileBuilderState;
    mapScenariosDashboard: MapScenariosDashboard.MapDashboardStateModel;
    mapGPSDevicesDashboard: MapGPSDevicesDashboard.MapDashboardStateModel;
}

export const reducers = {
    session: SessionStateReducer,
    dashboardFilter: DashboardFilter.reducer,
    mainDashboard: MainDashboard.reducer,
    enforcementDashboard: EnforcementDashboard.reducer,
    mapDashboard: MapDashboardReducer,
    mapLiveDashboard: MapLiveDashboardReducer,
    dashboardBuilder: DashboardBuilderStateReducer,
    mapOptiRouteDashboard: MapOptiRouteDashboardReducer,
    dashboardTileBuilder: TileBuilderStateReducer,
    mapScenariosDashboard: MapScenariosDashboardReducer,
    mapGPSDevicesDashboard: MapGPSDevicesDashboardReducer,
};

let applicationStore: Store<CombinedState<{
    router: RouterState<History.PoorMansUnknown>;
    session: SessionState;
    dashboardFilter: DashboardFilter.DashboardFilterState;
    mainDashboard: MainDashboard.MainDashboardState;
    enforcementDashboard: EnforcementDashboard.EnforcementDashboardState;
    mapDashboard: MapDashboard.MapDashboardStateModel;
    mapLiveDashboard: MapLiveDashboard.MapDashboardStateModel;
    dashboardBuilder: DashboardBuilderState;
    mapOptiRouteDashboard: MapOptiRouteDashboard.MapDashboardStateModel;
    dashboardTileBuilder: DashboardTileBuilder.DashboardTileBuilderState;
    mapScenariosDashboard: MapScenariosDashboard.MapDashboardStateModel;
    mapGPSDevicesDashboard: MapGPSDevicesDashboard.MapDashboardStateModel;
}>, AnyAction>;

export function configureStore (history: History, initialState?: ApplicationState): typeof applicationStore {
    const middleware = [
        thunk,
        routerMiddleware(history),
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const enhancers = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}

export type RootState = ReturnType<typeof applicationStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
