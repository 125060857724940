import moment from 'moment';
import { Dispatch } from 'react';

import { ApplicationState } from '../../../app/store';
import { Delete, Get, Post } from '../../../services/RestClient';
import {
    availableParams,
    SqlQueryParam as SqlQueryParams,
    SqlResult,
    TileModel,
    TileModelPoco,
    TileSettingsModel,
    TileSettingsTestValues,
} from '../tileBuilderTypes';
import {
    setCustomTilesList,
    setSqlQueryResult,
    setTileSettingsDatabaseId,
    setTileSettingsSqlQuery,
    setTileSettingsTestData,
    updateTileSettings,
} from './tileBuilderReducer';
import { removeTimeFromDate } from './utils';

const setDatabaseIdAndExecuteSqlQuery = (databaseId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        dispatch(setTileSettingsDatabaseId(databaseId));

        if (!databaseId) {
            return Promise.resolve();
        }

        return getSqlResultFromStateData(getState).then((sqlResult: SqlResult) => {
            updateSqlQueryResult(dispatch, getState, sqlResult);
        });
    };
};

const setSqlQueryAndExecute = (sqlQuery: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        dispatch(setTileSettingsSqlQuery(sqlQuery));

        if (!sqlQuery) {
            return Promise.resolve();
        }

        return getSqlResultFromStateData(getState).then((sqlResult: SqlResult) => {
            console.log('setSqlQueryAndExecute', sqlResult);
            updateSqlQueryResult(dispatch, getState, sqlResult);
        });
    };
};

const getSqlResultFromStateData = (
    getState: () => ApplicationState
): Promise<SqlResult> => {
    return new Promise<SqlResult>((resolve, reject) => {
        const state = getState();

        const sqlQuery = state.dashboardTileBuilder?.tileSettings?.sqlQuery;
        if (!sqlQuery || sqlQuery === '') {
            return Promise.resolve();
        }

        const databaseId = state.dashboardTileBuilder?.tileSettings?.databaseId;
        if (!databaseId) {
            return Promise.resolve();
        }

        const testValues = state.dashboardTileBuilder?.tileSettings?.testValues;
        if (queryIncludesParams(sqlQuery) && !testValues) {
            return Promise.resolve();
        }

        const params: SqlQueryParams = {
            CustomerId: testValues.customerId,
            Date: testValues.date,
            DateTo: testValues.dateTo,
        };

        executeSqlQueryOnServer(databaseId, sqlQuery, params)
            .then((response) => {
                console.log('executeSqlQueryOnServer', response);

                return resolve(response);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

// TODO: Add local cache or validate to prevent calling if parameters haven't change.
const executeSqlQueryOnServer = (
    databaseId: string,
    sqlQuery: string,
    parameters: SqlQueryParams
): Promise<SqlResult> => {
    return new Promise<SqlResult>((resolve, reject) => {
        if (!databaseId || !sqlQuery) {
            reject('DatabaseId or sqlQuery not available.');
        }

        const query = sanitizeSqlQuery(sqlQuery);

        // TODO: Dynamically detect and add parameters like customer code, startDate, etc.
        Post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/tile-builder/query`,
            data: {
                databaseId: databaseId,
                query: query,
                parameters: parameters,
            },
        })
            .then((response: SqlResult) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const updateSqlQueryResult = (
    dispatch: Dispatch<any>,
    getState: () => ApplicationState,
    sqlResult: SqlResult
) => {
    dispatch(setSqlQueryResult(sqlResult));
};

const queryIncludesParams = (sqlQuery: string): boolean => {
    return availableParams.some((param) => sqlQuery.includes(param.name));
};

const setTestDataAndExecuteSqlQuery = (data: TileSettingsTestValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        dispatch(setTileSettingsTestData(data));

        return getSqlResultFromStateData(getState).then((sqlResult: SqlResult) => {
            updateSqlQueryResult(dispatch, getState, sqlResult);
        });
    };
};

const sanitizeSqlQuery = (sqlQuery: string) => {
    // TODO: Sanitize queries, prevent INSERT, UPDATE, etc. Improve.
    // if (sqlQuery.includes('INSERT') || sqlQuery.includes('UPDATE') || sqlQuery.includes('DELETE') || sqlQuery.includes('DROP')){
    //     return '';
    // }

    const sanitizedSqlQuery = sqlQuery
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');

    return sanitizedSqlQuery;
};

const saveTileOnDatabase = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        const state = getState();

        console.log('saveTileOnDatabase', state.dashboardTileBuilder.tileSettings);

        const tileModel = state.dashboardTileBuilder.tileSettings;
        const data = {
            name: tileModel.name,
            chartType: tileModel.chartType,
            categoryId: tileModel.categoryId,
            databaseId: tileModel.databaseId,
            sqlQuery: tileModel.sqlQuery,
            includedParams: { ...tileModel.includedParams },
            fieldsColumns: [...tileModel.fieldsColumns],
            positionX: tileModel.positionX,
            positionY: tileModel.positionY,
            width: tileModel.width,
            height: tileModel.height,
        };

        return new Promise<void>((resolve, reject) => {
            Post({
                url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/tile-builder/tile`,
                data: {
                    tileId: tileModel.tileId,
                    name: tileModel.name,
                    chartType: tileModel.chartType,
                    categoryId: tileModel.categoryId,
                    data: JSON.stringify(data),
                },
            })
                .then((tileId: string) => {
                    resolve();
                    window.location.href = `/customer-dashboards/tile-builder/${tileId}`;
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

const fetchTilesFromServer = () => {
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        return Get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/tile-builder/tile/all`,
        })
            .then((tilesResponse) => {
                const tiles = deserializeTilesJsonData(tilesResponse);
                dispatch(setCustomTilesList(tiles));
            })
            .catch((err) => {
                console.log('Error fetching tiles from server: ', err);
            });
    };
};

export const deserializeTilesJsonData = (
    tilesList: TileModelPoco[]
): TileModel[] => {
    const tiles = tilesList.map((t) => {
        return deserializeTileJsonData(t);
    });

    return tiles;
};

const fetchTileFromServer = (tileId: string) => {
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        if (!tileId) {
            Promise.resolve();
        }

        return Get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/tile-builder/tile/${tileId}`,
        })
            .then((tilesResponse) => {
                const tile = deserializeTileJsonData(tilesResponse);
                const tileSettings = {
                    tileId: tile.tileId,
                    name: tile.name,
                    chartType: tile.chartType,
                    categoryId: tile.categoryId,
                    databaseId: tile.databaseId,
                    sqlQuery: tile.sqlQuery,
                    includedParams: tile.includedParams,
                    fieldsColumns: tile.fieldsColumns,
                    // TODO: Move to common
                    testValues: {
                        customerId: '8560B992-BF0E-4407-B263-7D4D731DA7E2',
                        date: removeTimeFromDate(
                            moment(new Date()).format('YYYY/MM/DD hh:mm a')
                        ),
                        dateTo: removeTimeFromDate(
                            moment(new Date()).format('YYYY/MM/DD hh:mm a')
                        ),
                    },
                    positionX: tile.positionX,
                    positionY: tile.positionY,
                    width: tile.width,
                    height: tile.height,
                } as TileSettingsModel;

                console.log('deserializeTileJsonData', tileSettings);
                dispatch(updateTileSettings(tileSettings));
                dispatch(setSqlQueryAndExecute(tileSettings.sqlQuery));
            })
            .catch((err) => {
                console.log('Error fetching tiles from server: ', err);
            });
    };
};

export const deserializeTileJsonData = (poco?: TileModelPoco): TileModel => {
    if (!poco) {
        throw "Tile Model Poco can't be null or undefined.";
    }

    const d = JSON.parse(poco.data);

    return {
        tileId: poco.tileId,
        name: poco.name,
        chartType: d.chartType,
        categoryId: d.categoryId,
        databaseId: d.databaseId,
        sqlQuery: d.sqlQuery,
        includedParams: d.includedParams,
        fieldsColumns: d.fieldsColumns,
        positionX: d.positionX,
        positionY: d.positionY,
        width: d.width,
        height: d.height,
    } as TileModel;
};

const deleteTileOnDatabase = (tileId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
        dispatch: Dispatch<any>,
        getState: () => ApplicationState
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (!tileId) {
                reject();
            }

            Delete({
                url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/tile-builder/tile/${tileId}`,
            })
                .then((success: boolean) => {
                    window.location.href = '/customer-dashboards/tile-builder';
                    resolve();
                })
                .catch((error: any) => {
                    console.log('delete catch >>>', error);
                    reject(error);
                });
        });
    };
};

export {
    setDatabaseIdAndExecuteSqlQuery,
    setSqlQueryAndExecute,
    setTestDataAndExecuteSqlQuery,
    executeSqlQueryOnServer,
    saveTileOnDatabase,
    fetchTilesFromServer,
    fetchTileFromServer,
    deleteTileOnDatabase,
};
