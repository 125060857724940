import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  interfaceTypeButton: {
    width: '96%',
    height: 120,
    borderRadius: 0,
  },
}));

export default useStyles;
