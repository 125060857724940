import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './AverageCardStyles';

export default function AverageCardSkeleton (): JSX.Element {
    const classes = useStyles();

    return (
        <Box className={classes.cardContent} style={{ minHeight: '84px' }} justifyContent="space-between">
            <Grid container justify="center" alignItems="center" alignContent="center">
                <Grid item xs={6}>
                    <Skeleton width="50%" variant="text" animation={false} />
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Skeleton width="40%" variant="text" animation={false} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton height="10px" variant="rect" animation="wave" />
                </Grid>
                <Grid item xs={6}>
                    <Skeleton width="40%" animation={false} />
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Skeleton width="25%" animation={false} />
                </Grid>
            </Grid>
        </Box>
    );
}
