import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: '100%',
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 240,
        },
        backgroundColor: 'white',
        color: theme.palette.primary.main,
    },
    emptyToolbar: {
        height: 0,
    },
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    iconDashboard: {
        marginTop: '-19px',
        marginRight: theme.spacing(1),
    },
    title: {
        marginTop: '8px',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    subTitle: {
        marginTop: '8px',
        textAlign: 'end',
        fontSize: '12px',
        fontWeight: 400,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    previewButton: {
        marginBottom: theme.spacing(2),
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        width: '100%',
    },
    saveButton: {
        margin: 0,
        width: '100%',
    },
    builderTile: {
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    },
    builderTileTitle: {
        display: 'inline',
    },
    tileCategoryTitle: {
        display: 'inline',
    },
    modalContent: {
        background: 'white',
        position: 'relative',
        width: '300px',
        top: '45%',
        left: '45%',
        transform: 'translate(-50%, -50%)',
        padding: '10px',
        '&:focus': {
            outline: 'none',
        },
        '& > .MuiTypography-root': {
            marginBottom: theme.spacing(.5),
        },
        '& > .MuiButtonBase-root': {
            marginRight: theme.spacing(1),
        },
        textAlign: 'center',
    },
    acceptButton: {
        marginTop: theme.spacing(1),
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
    },
    tileControllerWrapper: {
        height: '100%',
        width: '100%',
        position: 'relative',
    }
}));
