export default class AverageData {
    total: number;
    averagePerPeriod: number;

    constructor (total = 0, averagePerPeriod = 0) {
        this.total = total;
        this.averagePerPeriod = averagePerPeriod;
    }
}

export class MultipleAverageData extends AverageData {
    averagesCollection: Array<[string, number]>;

    constructor (value = 0, mainAverageValue = 0, averagesCollection: Array<[string, number]> = new Array<[string, number]>()) {
        super(value, mainAverageValue);
        this.averagesCollection = averagesCollection;
    }
}

export class TopPerformerAverageData extends AverageData {
    name: string;
    hourlyAverage: number;

    constructor (name = '', value = 0, averageValue = 0, hourlyAverageValue = 0) {
        super(value, averageValue);
        this.name = name;
        this.hourlyAverage = hourlyAverageValue;
    }
}
