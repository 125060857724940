import { LocalStatePayloadAction } from '../../../../../app/types';
import { BootingOfficer, MapFilterSearchBy, ParkingArea, MapFilterDataType, MapLayerType } from '../../state/mapDashboardStateV2';
import clone from 'rfdc';
import Cookies from 'js-cookie';

export type MapFilterState = {
    plate: string;
    searchBy: number;
    selectedAgentsToSearchBy: string[];
    selectedLocationsToSearchBy: string[];
    selectedAgentsColors: string[];
    selectedDataTypes: string[];
    layerType: MapLayerType;
    currentPage: number;
    pageSize: number;
    isArchiveQuery: boolean;
}

const ACTION_TYPES = {
    UPDATE_PLATE: 'UPDATE_PLATE',
    UPDATE_SEARCH_BY: 'UPDATE_SEARCH_BY',
    UPDATE_SELECTED_AGENTS: 'UPDATE_SELECTED_AGENTS',
    UPDATE_SELECTED_LOCATIONS: 'UPDATE_SELECTED_LOCATIONS',
    UPDATE_SELECTED_DATA_TYPES: 'UPDATE_SELECTED_DATA_TYPES',
    UPDATE_SELECTED_LAYER_TYPE: 'UPDATE_SELECTED_LAYER_TYPE',
};

const cloneFilterState = (state: MapFilterState): MapFilterState => {
    return clone({ proto: true })(state);
};

const UpdatePlate = (state: MapFilterState, payload: string): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.plate = payload;
    return newState;
};

const UpdateSearchBy = (state: MapFilterState, payload: {
    searchBy: number;
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    filterBy?: string;
    filterQ?: string;
}): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.searchBy = payload.searchBy;

    const agents = payload.searchBy === MapFilterSearchBy.All || payload.searchBy === MapFilterSearchBy.Agents ? payload.bootingOfficers : [];
    const locations = payload.searchBy === MapFilterSearchBy.All || payload.searchBy === MapFilterSearchBy.Locations ? payload.parkingAreas : [];

    newState.selectedAgentsColors = agents.map(officer =>getRandomColor());

    console.log('filterBy', payload.filterBy, payload.filterQ);
    if (!payload.filterBy || !payload.filterQ) {
        newState.selectedAgentsToSearchBy = agents.map((ag) => ag.bootingOfficerId);
        newState.selectedLocationsToSearchBy = locations.map((lo) => lo.name);
    } else {
        newState.selectedAgentsToSearchBy = agents.filter((ag) => ag.name === payload.filterQ).map((ag) => ag.bootingOfficerId);
        newState.selectedLocationsToSearchBy = locations.map((lo) => lo.name);
    }

    return newState;
};

const getRandomColor = () =>{
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

const UpdateSelectedAgents = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.selectedAgentsToSearchBy = [...selected];
    return newState;
};

const UpdateSelectedLocations = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.selectedLocationsToSearchBy = [...selected];
    return newState;
};

const UpdateSelectedDataTypes = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.selectedDataTypes = [...selected];
    return newState;
};

const UpdateSelectedLayerType = (state: MapFilterState, selected: MapLayerType): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.layerType = selected;
    return newState;
};

const mapFilterStateReducer = (state: MapFilterState, action: LocalStatePayloadAction): MapFilterState => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_PLATE:
            return UpdatePlate(state, action.payload as string);
        case ACTION_TYPES.UPDATE_SEARCH_BY:
            return UpdateSearchBy(state, action.payload);
        case ACTION_TYPES.UPDATE_SELECTED_AGENTS:
            return UpdateSelectedAgents(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_LOCATIONS:
            return UpdateSelectedLocations(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_DATA_TYPES:
            return UpdateSelectedDataTypes(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_LAYER_TYPE:
            return UpdateSelectedLayerType(state, action.payload as MapLayerType);
        default:
            return state;
    }
};

const mapFilterStateInit = (params: {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
}): MapFilterState => {
    const { bootingOfficers = [], parkingAreas = [] } = params;
    
    const mapFilterCookie: MapFilterState = (Cookies.getJSON('mapFilter') as unknown as MapFilterState);

    const agentColors = [''];
    bootingOfficers.forEach(officer =>{
        const randomColor = getRandomColor();
    });
    
    if(mapFilterCookie) { 
        return mapFilterCookie;
    } else {
        return {
            plate: '',
            searchBy: 0,
            selectedAgentsToSearchBy: bootingOfficers.map((bo) => bo.bootingOfficerId),
            selectedLocationsToSearchBy: parkingAreas.map((lo) => lo.parkingAreaId),
            selectedAgentsColors: agentColors,
            selectedDataTypes: [
                //MapFilterDataType.Scan as unknown as string,
                MapFilterDataType.Hit as unknown as string,
                MapFilterDataType.Booted as unknown as string,
            ],
            layerType: MapLayerType.Reads,
            currentPage: 1,
            pageSize: 10,
            isArchiveQuery: false,
        };
    }

    
};

export { ACTION_TYPES, mapFilterStateReducer, mapFilterStateInit };
