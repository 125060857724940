import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch',
        justifyContent: 'center',
    },
    grid: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
    },
    titleContainer: {
        flexGrow: 0,
        // maxHeight: 33,
    },
    titleIcon: {
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(1.5),
    },
    listTitle: {
        fontWeight: 'bold',
        fontSize: '13px',
        paddingLeft: theme.spacing(1.5),
    },
    listContainer: {
        flexGrow: 1,
        // maxHeight: 759,
    },
    listItem: {
        padding: theme.spacing(2),
    },
    startSearchMessage: {
        padding: theme.spacing(2),
    },
    loadingSearchMessage: {
        padding: theme.spacing(2),
    },
    noResultsMessage: {
        padding: theme.spacing(2),
    },
    paginationContainer: {
        flexGrow: 0,
        alignSelf: 'flex-end',
        marginTop: theme.spacing(1),
    },
    pagination: {
        padding: theme.spacing(1),
    },
    scenarioListlabel: {
        color: theme.palette.primary.dark,
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        marginTop: '5px',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    searchButton: {
        margin: 15,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: 0,
            marginBottom: 0,
        },
    },
}));
