import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '8px',
        marginTop: '8px',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    formControl: {
        margin: 0,
        minWidth: 120,
        color: theme.palette.primary.dark,
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: 0,
            marginBottom: 0,
        },
    },
    label: {
        color: theme.palette.primary.dark,
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
    },
    scenarioListlabel: {
        color: theme.palette.primary.dark,
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        marginTop: '5px',
    },
    select: {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
        minWidth: 160,
        color: theme.palette.primary.dark,
        '&:before, &:after': {
            borderBottom: '2px solid ' + theme.palette.primary.dark,
        },
        '&:hover:before, &:active:before, &:focus:before, &:hover:after, &:active:after, &:focus:after': {
            borderBottom: '2px solid ' + theme.palette.secondary.main + ' !important',
        },
        '& .MuiIconButton-label': {
            color: theme.palette.primary.dark,
        },
    },
    selectIcon: {
        color: theme.palette.primary.dark,
    },
    applyButton: {
        margin: 0,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: 0,
            marginBottom: 0,
        },
    },
}));
