import React from 'react';
import { ButtonBase, Card, CardContent, Typography, Grid, Divider } from '@material-ui/core';
import { useStyles } from './MarkerItemCardStyles';
import { MapDashboardStateModel, MapFilterDataType, MapMarker } from '../../state/mapDashboardStateV2';
import moment from 'moment';

export type MarkerItemCard = {
    id: number;
    date: string;
    plate: string;
    plateState: string;
    mapState?:  MapDashboardStateModel;
    marker:  MapMarker;
    onClick: (selectedId: number) => void;
}

const MarkerItemCard = (props: MarkerItemCard): JSX.Element => {
    const { id, date: dateRecord, plate, plateState,  onClick, mapState, marker } = props;
    const classes = useStyles();

    let percentageColor = classes.black;
    if(+marker.deviceStatus?.batteryPercentage <= 25 )
    {
        percentageColor  = classes.red;
    }
    else if(+marker.deviceStatus?.batteryPercentage > 25 && +marker.deviceStatus?.batteryPercentage < 50)
    {
        percentageColor  = classes.orange;
    }
    else if(+marker.deviceStatus?.batteryPercentage >= 50)
    {
        percentageColor  = classes.green;
    }

    return (
        <ButtonBase
            key={id}
            className={classes.root}
            focusRipple
            onClick={(e) => onClick(id)}>
            <Card className={classes.card} variant="outlined">
                <CardContent className={classes.cardContent} style={{ paddingBottom: '8px' }}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="body1" className={classes.plate}>
                                {plate}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} container alignContent="flex-start" alignItems="flex-start" justify="flex-end">
                            <Typography variant="body2">
                                {dateRecord}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} container alignContent="center" alignItems="center" justify="flex-end">
                            <Typography variant="body2" className={percentageColor}>
                                {marker.deviceStatus?.batteryPercentage}%
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                           <Grid item xs={4}>
                                <Typography variant="subtitle1" className={classes.subText}>
                                    {plateState}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" className={classes.subText}>
                                    
                                </Typography>
                            </Grid>
                            <Grid item xs={2} container alignContent="center" alignItems="center" justify="flex-end" >
                                <Typography variant="subtitle1" component="h2" className={classes.subText}>
                                   
                                </Typography>
                            </Grid>
                        </Grid>
                </CardContent>
            </Card>
        </ButtonBase>
    );
};

export default MarkerItemCard;
