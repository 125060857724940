import { LocalStatePayloadAction } from '../../../../../app/types';
import { BootingOfficer, MapFilterSearchBy, ParkingArea, MapFilterDataType, MapLayerType, MapSourceType, noDebtRange, debtRangeOne, debtRangeTwo, debtRangeThree, debtRangeFour, debtRangeFive } from '../../state/mapDashboardStateV2';
import clone from 'rfdc';
import Cookies from 'js-cookie';
import moment from 'moment';

export type MapFilterState = {
    plate: string;
    selectedAgentsToSearchBy: string[];
    selectedLocationsToSearchBy: string[];
    selectedAgentsColors: string[];
    selectedDataTypes: string[];
    layerType: MapLayerType;
    sourceTypes: string[];
    fromTime: string;
    toTime: string;
    debtRange: string[];
    runAways: string[];
    currentPage: number;
    pageSize: number;
    status: number;
    date: string;
}

const ACTION_TYPES = {
    UPDATE_PLATE: 'UPDATE_PLATE',
    UPDATE_AGENTS_LOCATIONS: 'UPDATE_AGENTS_LOCATIONS',
    UPDATE_SELECTED_AGENTS: 'UPDATE_SELECTED_AGENTS',
    UPDATE_SELECTED_LOCATIONS: 'UPDATE_SELECTED_LOCATIONS',
    UPDATE_SELECTED_DATA_TYPES: 'UPDATE_SELECTED_DATA_TYPES',
    UPDATE_SELECTED_LAYER_TYPE: 'UPDATE_SELECTED_LAYER_TYPE',
    UPDATE_SELECTED_STATUS: 'UPDATE_SELECTED_STATUS',
    UPDATE_SELECTED_SOURCE_TYPE: 'UPDATE_SELECTED_SOURCE_TYPE',
    UPDATE_SELECTED_FROMTIME_TYPE: 'UPDATE_SELECTED_FROMTIME_TYPE',
    UPDATE_SELECTED_TOTIME_TYPE: 'UPDATE_SELECTED_TOTIME_TYPE',
    UPDATE_SELECTED_DEBT_TYPE: 'UPDATE_SELECTED_DEBT_TYPE',
    UPDATE_SELECTED_RUNAWAYS: 'UPDATE_SELECTED_RUNAWAYS',
    UPDATE_SELECTED_DATE_FROM: 'UPDATE_SELECTED_DATE_FROM'
};

const cloneFilterState = (state: MapFilterState): MapFilterState => {
    return clone({ proto: true })(state);
};

const UpdatePlate = (state: MapFilterState, payload: string): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.plate = payload;
    return newState;
};


const getRandomColor = () =>{
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const UpdateAgentLocatons = (state: MapFilterState, payload: {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    filterBy?: string;
    filterQ?: string;
    }): MapFilterState => {
        const newState = cloneFilterState(state);

        const agents = payload.bootingOfficers;
        const locations = payload.parkingAreas;

        newState.selectedAgentsColors = agents.map(officer =>getRandomColor());
        console.log('filterBy', payload.filterBy, payload.filterQ);
        if (!payload.filterBy || !payload.filterQ) {
            newState.selectedAgentsToSearchBy = agents.map((ag) => ag.bootingOfficerId);
            newState.selectedLocationsToSearchBy = locations.map((lo) => lo.name);
        } else {
            newState.selectedAgentsToSearchBy = agents.filter((ag) => ag.name === payload.filterQ).map((ag) => ag.bootingOfficerId);
            newState.selectedLocationsToSearchBy = locations.map((lo) => lo.name);
        }

        return newState;
    };

const UpdateSelectedAgents = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.selectedAgentsToSearchBy = [...selected];
    return newState;
};

const UpdateSelectedLocations = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.selectedLocationsToSearchBy = [...selected];
    return newState;
};

const UpdateSelectedDataTypes = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.selectedDataTypes = [...selected];
    return newState;
};

const UpdateSelectedLayerType = (state: MapFilterState, selected: MapLayerType): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.layerType = selected;
    return newState;
};

const UpdateSelectedSourceType = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.sourceTypes = selected;
    return newState;
};

const UpdateSelectedStatus = (state: MapFilterState, status: number): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.status = status;
    return newState;
};

const UpdateSelectedFromTime = (state: MapFilterState, selected: string): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.fromTime = selected;
    return newState;
};

const UpdateSelectedToTime = (state: MapFilterState, selected: string): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.toTime = selected;
    return newState;
};

const UpdateSelectedDebt = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.debtRange = selected;
    return newState;
};

const UpdateSelectedRunAways = (state: MapFilterState, selected: string[]): MapFilterState => {
    const newState = cloneFilterState(state);
    newState.runAways = selected;
    return newState;
};

const updateDate = (state: MapFilterState, date: Date) => {
    const newState = cloneFilterState(state);
    const formatteDate = moment(date).format('YYYY/MM/DD hh:mm a');
    newState.date = formatteDate;
    return newState;
};

const mapFilterStateReducer = (state: MapFilterState, action: LocalStatePayloadAction): MapFilterState => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_AGENTS_LOCATIONS:
            return UpdateAgentLocatons(state, action.payload);
        case ACTION_TYPES.UPDATE_PLATE:
            return UpdatePlate(state, action.payload as string);
        case ACTION_TYPES.UPDATE_SELECTED_AGENTS:
            return UpdateSelectedAgents(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_LOCATIONS:
            return UpdateSelectedLocations(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_DATA_TYPES:
            return UpdateSelectedDataTypes(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_LAYER_TYPE:
            return UpdateSelectedLayerType(state, action.payload as MapLayerType);
        case ACTION_TYPES.UPDATE_SELECTED_STATUS:
            return UpdateSelectedStatus(state, action.payload as number);
        case ACTION_TYPES.UPDATE_SELECTED_SOURCE_TYPE:
            return UpdateSelectedSourceType(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_FROMTIME_TYPE:
            return UpdateSelectedFromTime(state, action.payload as string);
        case ACTION_TYPES.UPDATE_SELECTED_TOTIME_TYPE:
            return UpdateSelectedToTime(state, action.payload as string);
        case ACTION_TYPES.UPDATE_SELECTED_DEBT_TYPE:
            return UpdateSelectedDebt(state, action.payload as string[]);
        case ACTION_TYPES.UPDATE_SELECTED_RUNAWAYS:
            return UpdateSelectedRunAways(state, action.payload as string []);
        case ACTION_TYPES.UPDATE_SELECTED_DATE_FROM:
            return updateDate(state, action.payload as Date);
        default:
            return state;
    }
};

const mapFilterStateInit = (params: {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
}): MapFilterState => {
    const { bootingOfficers = [], parkingAreas = [] } = params;
    
    const mapFilterCookie: MapFilterState = (Cookies.getJSON('mapOptiRouteFilter') as unknown as MapFilterState);

    const agentColors = [''];
    bootingOfficers.forEach(officer =>{
        const randomColor = getRandomColor();
    });
    
    if(mapFilterCookie) { 
        mapFilterCookie.status = 1;//default status to active
        return mapFilterCookie;
    } else {
        return {
            plate: '',
            selectedAgentsToSearchBy: bootingOfficers.map((bo) => bo.bootingOfficerId),
            selectedLocationsToSearchBy: parkingAreas.map((lo) => lo.parkingAreaId),
            selectedAgentsColors: agentColors,
            sourceTypes: [
                MapSourceType.LPR as unknown as string,
                //MapSourceType.Ticket as unknown as string 
            ],
            status: 1,
            fromTime: '12:00 AM',
            toTime: '11:59:59 PM',
            debtRange: [
                noDebtRange,
                debtRangeOne,
                debtRangeTwo,
                debtRangeThree,
                debtRangeFour,
                debtRangeFive
            ],
            runAways: ['0','1','2','3','4','5','6'],
            selectedDataTypes: [
                //MapFilterDataType.Scan as unknown as string,
                MapFilterDataType.Hit as unknown as string,
                MapFilterDataType.Boot as unknown as string,
            ],
            layerType: MapLayerType.Reads,
            currentPage: 1,
            pageSize: 10,
            date: moment(new Date()).format('YYYY/MM/DD hh:mm a'),
        };
    }

    
};

export { ACTION_TYPES, mapFilterStateReducer, mapFilterStateInit };
