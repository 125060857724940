import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((them: Theme) => ({
    root: {
        borderRadius: 2,
    },
    map: {
        width: '100%',
        // height: this.state.height,
        backgroundColor: '#fff',
        position: 'relative',
        boxSizing: 'border-box',
    },
    tooltip:{
        //width: '130px',
        height: '50px',
        padding: '5px',
        textAlign: 'center',
        backgroundColor: '#fff',
        position: 'relative',
        boxSizing: 'border-box',
    },
    zoom:{
        position: 'relative',
        left: '.5em',
        zIndex: them.zIndex.modal,
        display: 'block',
        margin: '1px',
        padding: '0',
        color: 'white',
        fontSize: '1em',
        fontWeight: 'bold',
        textAlign: 'center',
        background: 'rgba(0,60,136,0.5)',
        border: 'none',
        width: '25px',
        height: '22px',
        borderRadius: '5px',
        '&:hover': {
            background: 'rgba(0,60,136,0.7)',
         },
    },
    zoomIn:{
        top: '0.5em',
        width: '60px',
        fontSize: '0.8em',
        height: '19px'
    },
    zoomOne:{
        top: '1em',
    },
    zoomTwo:{
        top: '1.5em',
    },
    zoomThree:{
        top: '2em',
    },
    zoomFourth:{
        top: '2.5em',
    },
    zoomFifth:{
        top: '3em',
    },
    zoomSixth:{
        top: '3.5em',
    },
    zoomSeventh:{
        top: '4em',
    },
    zoomEighth:{
        top: '4.5em',
    },
    zoomNineth:{
        top: '5em',
    },
    zoomTenth:{
        top: '5.5em',
    },
    zoomOut:{
        top: '7.5em',
        width: '60px',
        fontSize: '0.8em',
        height: '19px'
    }
}));
