import { Grid, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import clone from 'rfdc';

import { Get } from '../../../../services/RestClient';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import { BootsStatsSummary } from '../Enforcement/EnforcementSummary';
import { useStyles } from './BootsStyles';

type BootsProps = {
    filter: DashboardFilterState;
}

export default function Boots (props: BootsProps): JSX.Element {
    const classes = useStyles();

    const { filter } = props;

    const [state, setState] = useState(new BootsStatsSummary);

    useEffect(() => {
        fetchData(setState, state, filter);
    }, [filter]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper className={classes.root} elevation={1}>
                    {
                        state.isLoading
                            ? <Skeleton height="10px" variant="rect" animation="wave" />
                            : <Typography variant="h4" className={`${classes.successColor}`} align="center">{state.onCar}</Typography>
                    }
                    <Typography variant="button" className={classes.mainLabels} align="center" color="primary">
                        Boots On A Car
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper className={classes.root} elevation={1}>
                    {
                        state.isLoading
                            ? <Skeleton height="10px" variant="rect" animation="wave" />
                            : <Typography variant="h4" className={`${classes.infoColor}`} align="center">{state.available}</Typography>
                    }
                    <Typography variant="button" className={classes.mainLabels} align="center" color="primary">
                        Boots Inventory
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchData = (setState: React.Dispatch<any>, state: BootsStatsSummary, filter: DashboardFilterState): Promise<void> => {
    if (!filter.customerId) {
        setState({...state, isLoading: true, error: null});
        return Promise.resolve();
    }

    if (!state.isLoading) {
        setState({...state, isLoading: true, error: null});
    }

    return Get({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/boots/summary`,
        params: {
            customerId: filter.customerId,
            daysToAverage: filter.daysToAverage,
            date: filter.date,
        },
    }).then((summary: BootsStatsSummary) => {
        summary.isLoading = false;
        summary.error = null;
        setState(clone({proto: true})(summary));
    }).catch((err) => {
        setState(new BootsStatsSummary(false, err));
    });
};
