import DateFnsUtils from '@date-io/date-fns';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../app/store';
import { setTestDataAndExecuteSqlQuery } from './state/tileBuilderThunks';

import { AllAvailableCustomers, TileSettingsTestValues } from './tileBuilderTypes';

const TestValuesSettingsForm = (): JSX.Element => {
  
  const dispatch = useDispatch();
  const testValues = useSelector((state: ApplicationState) => state.dashboardTileBuilder.tileSettings.testValues as TileSettingsTestValues);

  const customerIdChangeHandler = (event: React.ChangeEvent<any>) => dispatch(setTestDataAndExecuteSqlQuery({...testValues, customerId: event.target.value as string}));
  const dateChangeHandler = (date: any) => dispatch(setTestDataAndExecuteSqlQuery({...testValues, date: date as Date}));
  const dateToChangeHandler = (date: any) => dispatch(setTestDataAndExecuteSqlQuery({...testValues, dateTo: date as Date}));

  return (
    <Grid item xs={12} md={12} style={{ marginTop: '12px' }}>
      <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={0}>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" style={{ color: '#70677b' }}>
            Test Values
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth={true}>
            <InputLabel>Customer</InputLabel>
            <Select
              value={testValues.customerId}
              onChange={(event) => customerIdChangeHandler(event)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              autoWidth={false}>
              {
                AllAvailableCustomers.map((customer) =>
                  <MenuItem value={customer.id} key={customer.id}>{customer.name}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} style={{ marginLeft: '12px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date From"
              value={testValues.date}
              onChange={dateChangeHandler}
              // onChange={d => dispatch(updateDate({ date: d as Date }))}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              autoOk={true}
              maxDate={new Date()}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3} style={{ marginLeft: '12px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date To"
              value={testValues.dateTo ?? testValues.date}
              //onChange={d => console.log('d', d)}
              onChange={dateToChangeHandler}
              // onChange={d => dispatch(updateDateTo({ date: d as Date }))}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              autoOk={true}
              maxDate={new Date()}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TestValuesSettingsForm;
