import React from 'react';
import { Modal, Paper, Typography, LinearProgress } from '@material-ui/core';
import { useStyles } from './loadingModalStyles';

type LoadingModalProps = {
    isOpen: boolean;
    container: HTMLElement | null;
    message: string;
}

const LoadingModal = (props: LoadingModalProps): JSX.Element => {
    const { isOpen, container, message } = props;
    const classes = useStyles();

    return (
        <Modal
            open={isOpen}
            aria-labelledby="loading-modal"
            aria-describedby="loading-please-wait"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            disablePortal={true}
            container={container}
            style={{ position: 'absolute' }}
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
            <Paper elevation={2} className={classes.content}>
                <Typography variant="body1" className={classes.message}>
                    {message}
                </Typography>
                <LinearProgress />
            </Paper>
        </Modal>
    );
};

export default LoadingModal;
