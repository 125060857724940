import { Dispatch } from 'react';

import { ApplicationState } from '../../../../app/store';
import { isLoading, updateAll } from './MainDashboardState';

const loadMainDashboardData = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        const state = getState();

        if (!state.dashboardFilter.customerId) {
            return Promise.resolve();
        }

        if (!state.mainDashboard.isLoading) {
            dispatch(isLoading(true));
        }

        return Promise.all([
        ]).then((values) => {
            dispatch(updateAll({
                isLoading: false,
                error: null,
                initialized: true,
            }));
        });
    };
};

export { loadMainDashboardData };
