import { createSlice } from '@reduxjs/toolkit';
import { MapMarker, MapDashboardStateModel, MapFilterSource } from './mapDashboardStateV2';
import { updateMapStateAction, setLoadingAction, setListLoadingAction, selectMarkerAction, unselectMarkerAction, setBootExtraInfoAction, setErrorAction,  applyFilterAction, changePageOnFilterAction, setFilterSourceAction } from './mapDashboardActions';

const mapDashboardSlice = createSlice({
    name: 'mapDashboard',
    initialState: {
        isLoading: false,
        listIsLoading: false,
        error: null,
        markers: Array<MapMarker>(),
        pagination: {
            currentPage: 1,
            totalPages: 0,
        },
        selectedMarker: undefined,
        filterSource: {
            bootingOfficers: [],
            parkingAreas: [],
            customerLongitude: -100.964709,
            customerLatitude: 39.259336,
        } as MapFilterSource,
    } as MapDashboardStateModel,
    reducers: {
        setLoading: setLoadingAction,
        setListLoading: setListLoadingAction,
        setBootExtraInfo: setBootExtraInfoAction,
        setError: setErrorAction,
        setFilterSource: setFilterSourceAction,
        applyFilter: applyFilterAction,
        changePageOnFilter: changePageOnFilterAction,
        updateMapState: updateMapStateAction,
        selectMarker: selectMarkerAction,
        unselectMarker: unselectMarkerAction,
    },
});

export const { reducer } = mapDashboardSlice;
export const { setLoading, setListLoading, setBootExtraInfo, setError, setFilterSource, updateMapState, applyFilter, changePageOnFilter, selectMarker, unselectMarker } = mapDashboardSlice.actions;
