import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        // margin: theme.spacing(2),
        padding: theme.spacing(2),
        height: '100%',
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 240,
        },
        backgroundColor: 'white',
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
        // color: 'rgba(0, 0, 0, 0.2)',
        color: theme.palette.primary.main,
    },
    emptyToolbar: {
        height: 0,
    },
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    iconDashboard: {
        marginTop:'-19px',
        marginRight: theme.spacing(1),
    },
    title: {
        marginTop: '8px',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    subTitle: {
        marginTop: '8px',
        textAlign: 'end',
        fontSize: '12px',
        fontWeight: 400,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    labelGrid:{
        [theme.breakpoints.up('lg')]: {
            maxWidth: '20%'
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '20%'
        },
    },
    filterBar:{
        [theme.breakpoints.up('md')]: {
            minWidth: '300px'
        },
    }
}));
