export interface DashboardModel {
    isLoading: boolean;
    error: string | null;
}

export class DataGridRow {
    dataType: string | undefined;
    [key: string]: any;
}

export class GridHeader {
    name: string;
    ordinal: number;

    constructor (name: string, ordinal: number) {
        this.name = name;
        this.ordinal = ordinal;
    }
}

export class GridItem {
    ordinal: number;
    totalEvents: number;

    constructor (ordinal: number, totalEvents: number) {
        this.ordinal = ordinal;
        this.totalEvents = totalEvents;
    }
}
