import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LprSummary from '../Lpr/LprSummary';
import { TopPerformerAverageData } from '../../Main';
import OverTimeData from '../OverTimeTable/OverTimeModels';
import { updateAllAction } from './EnforcementDashboardActions';

export interface EnforcementDashboardState {
    isLoading: boolean;
    error: string | null;
    lpr: LprSummary;
    overTimeData: OverTimeData;
    breakHistory: any;
    activeTab: 'agents' | 'locations';
    scans: {
        topPerformers: TopPerformerAverageData[];
        topPerformersOTD: OverTimeData;
        topZones: TopPerformerAverageData[];
        topZonesOTD: OverTimeData;
    };
    hits: {
        topPerformers: TopPerformerAverageData[];
        topPerformersOTD: OverTimeData;
        topZones: TopPerformerAverageData[];
        topZonesOTD: OverTimeData;
    };
    boots: {
        topPerformers: TopPerformerAverageData[];
        topPerformersOTD: OverTimeData;topZones: TopPerformerAverageData[];
        topZonesOTD: OverTimeData;
    };
    topPerformersOTD?: OverTimeData;
    topZonesOTD?: OverTimeData;
}

const enforcementDashboardSlice = createSlice({
    name: 'enforcementDashboard',
    initialState: {
        isLoading: false,
        error: null,
        lpr: new LprSummary(),
        overTimeData: new OverTimeData(),
        activeTab: 'agents',
        scans: {
            topPerformers: new Array<TopPerformerAverageData>(),
            topPerformersOTD: new OverTimeData(),
            topZones: new Array<TopPerformerAverageData>(),
            topZonesOTD: new OverTimeData(),
        },
        hits: {
            topPerformers: new Array<TopPerformerAverageData>(),
            topPerformersOTD: new OverTimeData(),
            topZones: new Array<TopPerformerAverageData>(),
            topZonesOTD: new OverTimeData(),
        },
        boots: {
            topPerformers: new Array<TopPerformerAverageData>(),
            topPerformersOTD: new OverTimeData(),
            topZones: new Array<TopPerformerAverageData>(),
            topZonesOTD: new OverTimeData(),
        },
        topPerformersOTD: new OverTimeData,
    } as EnforcementDashboardState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            return { ...state, isLoading: action.payload, error: null };
        },
        isError: (state, action: PayloadAction<string | null>) => {
            return { ...state, isLoading: false, error: action.payload };
        },
        updateAll: updateAllAction,
        updateOverTimeData: (state, action: PayloadAction<OverTimeData>) => {
            return { ...state, informationOverTime: action.payload };
        },
        updateBreakHistory: (state, breakHistory: any) => {
            return { ...state, breakHistory: breakHistory };
        },
        setActiveTab: (state, action: PayloadAction<'agents' | 'locations'>) => {
            return {
                ...state,
                activeTab: action.payload ? action.payload : state.activeTab,
            };
        },
    },
});

export const { reducer } = enforcementDashboardSlice;
export const { isLoading, isError, updateAll, updateOverTimeData, setActiveTab, updateBreakHistory } = enforcementDashboardSlice.actions;
