import React, {useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { Grid, Paper, Typography, Divider,  Box, Link, Button } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { MapMarker, PaginationResult, MapFilterDataType, MapDashboardStateModel, ClearPlateConstant } from '../../state/mapDashboardStateV2';
import { MarkerDetail } from '../MarkerDetail/MarkerDetail';
import { DataDetail } from '../DataDetail/DataDetail';
import { useStyles } from './markerListStyles';
import moment from 'moment';
import MarkerItemCard from './MarkerItemCard';
import ClearIcon from '@material-ui/icons/Clear';
//import { DataGrid, GridCellParams, GridColDef, GridRowParams, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams, GridValueGetterParams } from '@material-ui/data-grid';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { CellClickedEvent, ColDef, ColGroupDef,  GridApi, GridReadyEvent, RowDoubleClickedEvent, RowGroupingDisplayType, ValueFormatterParams } from 'ag-grid-community';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import { HelpPopup } from './HelpPopup/HelpPopup';

type MarkersListProps = {
    isLoading: boolean;
    markers: MapMarker[];
    selectedMarker?: MapMarker | null;
    mapState:  MapDashboardStateModel;
    pagination?: PaginationResult;
    onSelectedMarker: (selectedMarkerId: string) => void;
    onClearSelectedMarker: () => void;
    onPageChanged?: (newPage: number) => void;
    onSelectedPlate: (licensePlate: string) => void;
    onSelectedPlateNoSearch: (licensePlateList: string) => void;
    selectedCustomerSubDomain?: string;
};

type MarkersListState = {
    openModal: boolean;
    openDataModal: boolean;
    selectedMarker: MapMarker;
};


export const MarkersList = (props: MarkersListProps): JSX.Element => {
    const { markers = [], selectedMarker, pagination, onSelectedMarker: onSelectMarker, onClearSelectedMarker, onPageChanged, mapState, selectedCustomerSubDomain, onSelectedPlate, onSelectedPlateNoSearch } = props;
    const classes = useStyles();

    const [state, setState] = useState({
        openModal: selectedMarker ? true : false,
    } as MarkersListState);

    const [modalHelp, setModalHelp] = useState(false);

    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const pageChangeHandler = (event: React.ChangeEvent<unknown>, page: number) => {
        if(onPageChanged)
        {
            onPageChanged(page);
        }
    };
    const gridRef = useRef<AgGridReact>(null);

    const [plateList, _setPlateList] = React.useState('');
    const plateListRef = React.useRef('');
    const setPlateList = (data: string) => {
        _setPlateList(data);
        plateListRef.current = data;
    };

    
    const onDataDetailedPressed = (): void =>{
        const newState = {openDataModal:  true} as  MarkersListState;
        setState(newState);
    };

    const onClearPlateFilterPressed = (): void =>{
        onSelectedPlate(ClearPlateConstant);
        setPlateList('');
    };

    const onSelectionChanged = useCallback((event) => {
        const selectedRows = gridRef.current!.api.getSelectedRows();
        //add group selections
        const selectedNodes =gridRef.current!.api.getSelectedNodes();
            selectedNodes.forEach((node) =>{
                if(node.aggData != null)
                {
                    selectedRows.push(node.aggData);
                }
        });
        if(selectedRows)
        {
            let tempPlate = plateListRef.current;
            selectedRows.forEach(row =>{
                if(!plateListRef.current.includes(row.licPlate))
                {
                    if(plateListRef.current.length > 0)
                    {
                        tempPlate = plateListRef.current+','+row.licPlate;
                    }
                    else
                    {
                        tempPlate = row.licPlate;
                    }
                }
            });
            setPlateList(tempPlate);
        }
    }, []);

    useEffect(() => {
        onSelectedPlateNoSearch(plateList);
    }, [plateList]);

    const onCloseDataDetail = (): void =>{
        const newState = {openDataModal:  false} as  MarkersListState;
        setState(newState);
    };

    const onCloseHelp = (): void =>{
        setModalHelp(false);
    };

    useEffect(() => {
        setState({ ...state, openModal: selectedMarker ? true : false });
    }, [selectedMarker]);
    

    const columns: ColDef [] = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            aggFunc: 'first',
        },
        {
            field: 'lprDataID',
            headerName: 'lprDataID',
            hide: true,
            aggFunc: 'first',
        },
        {
            field: 'licPlate',
            headerName: 'License',
            minWidth: 100,
            flex: 1.4,
            editable: false,
            sortable: true,
            resizable: true,
            cellClass: 'license-theme--cell',
            rowGroup: true,
            rowGroupIndex: 0,
            aggFunc: 'first',
            checkboxSelection: true,
            valueFormatter: (params: ValueFormatterParams) => {
                let fullPlate = params.value != null ? params.value : params.node != null ? params.node.key : '';//params.node.key for grouping column
                if(params.data != null)
                {
                    const licState = params.data.licState;
                    if (licState) 
                    {
                        fullPlate += '-'+licState;
                    }
                }
                
                return fullPlate;
             },
             
        },
        {
          field: 'occurrences',
          headerName: '#',
          flex: 0.4,
          minWidth: 55,
          editable: false,
          sortable: true,
          resizable: true,
          aggFunc: 'first',
        },
        {
            field: 'srcType',
            headerName: 'Type',
            flex: 1,
            minWidth: 60,
            editable: false,
            sortable: true,
            resizable: true,
            aggFunc: 'first',
        },
        {
          field: 'typeDate',
          headerName: 'Date',
          flex: 2.5,
          sortable: true,
          minWidth: 150,
          editable: false,
          resizable: true,
          aggFunc: 'first',
          valueFormatter: (params: ValueFormatterParams ) => {
            return params.value != null ? moment(params.value).format('YYYY/MM/DD hh:mm a') : '';
          },
        },
      ];

      const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
          sortable: true,
          minWidth: 45,
          resizable: false,
          headerName: '.',
        };
      }, []);

    // useEffect(() => {
    //     console.log('markers in list', markers);
    // }, [markers]);

    const onGridReady = function (event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
        setGridApi(event.api);
        const defaultSortModel = [
            {
              colId: 'occurrences',
              sort: 'desc',
              sortIndex: 0,
            },
            {
              colId: 'licPlate',
              sort: 'desc',
              sortIndex: 1,
            },
            {
                colId: 'typeDate',
                sort: 'desc',
                sortIndex: 2,
            },
          ];
          event.columnApi.applyColumnState({ state: defaultSortModel });
    };

    const onBtExport = () => {
        if(gridApi != null)
        {
            gridApi.exportDataAsExcel();    
        }
        
    };

    const onBtHelp = () => {
        setModalHelp(true);
    };


    return (
        <Paper elevation={1} className={classes.root}>
            <Grid container spacing={1} className={classes.grid}>
                <Grid item xs={12} md={12} className={classes.titleContainer}>
                    <Typography variant="h6" color="inherit" className={classes.title}>
                        # of Times Vehicles were seen [{markers.length}] 
                    </Typography>
                    <Box display="flex" flexDirection="column">
                        <Link onClick={onDataDetailedPressed} className={classes.details}>Click for more Detail</Link>
                        {
                            (mapState.filter != null && mapState.filter.plate != null && mapState.filter.plate != '' ) &&
                            <Button variant="contained" className={classes.clearButton} startIcon={<ClearIcon  />} onClick={() => onClearPlateFilterPressed()}>Clear plate</Button>
                        }
                    </Box>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={12} className={classes.listContainer}>
                    <Grid container>
                        {
                            (
                                markers.length === 0) &&
                            <Grid item xs={12} md={12}>
                                <Typography variant="body1" className={classes.startSearchMessage}>
                                    No results found, please try with different search parameters.
                                </Typography>
                            </Grid>
                        }
                        
                        {
                            (
                                markers.length > 0) && 
                                <Box style={{maxHeight: '66vh', overflow: 'auto', width: '100%'}} className={classes.gridStyles} >
                                    <div className="container">
                                        {<div>
                                            <button
                                                onClick={() => onBtExport()}
                                                style={{ marginBottom: '5px', fontWeight: 'bold' }}
                                            >
                                                Export to Excel
                                            </button>
                                            <button
                                                onClick={() => onBtHelp()}
                                                style={{ marginLeft: '5px', marginBottom: '5px', fontWeight: 'bold' }}
                                            >
                                                ?
                                            </button>
                                        </div>}
                                        <div className="opti-route-list" >
                                            <div className="ag-theme-alpine" style={{ height: '66vh', width: '100%' }}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    gridOptions={{ suppressCellSelection: true }}
                                                    columnDefs={columns}
                                                    rowData={markers}
                                                    defaultColDef={{ resizable: true }}
                                                    multiSortKey={'ctrl'}
                                                    rowSelection={'multiple'}
                                                    onSelectionChanged={onSelectionChanged}
                                                    groupDisplayType={RowGroupingDisplayType.SINGLE_COLUMN}
                                                    autoGroupColumnDef={autoGroupColumnDef}
                                                    animateRows={true}
                                                    rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                                                    onGridReady={onGridReady}
                                                    onRowDoubleClicked={(rowParams: RowDoubleClickedEvent) => {
                                                        rowParams.event?.preventDefault();
                                                        if(rowParams.data != null)//for regular selection
                                                        {
                                                            onSelectMarker(rowParams.data.lprDataID);   
                                                        }
                                                        else if(rowParams.node != null && rowParams.node.aggData != null && rowParams.node.aggData.lprDataID != null)//for group selection
                                                        {
                                                            onSelectMarker(rowParams.node.aggData.lprDataID);
                                                        }
                                                    }}
                                                    onCellClicked={(params: CellClickedEvent ) => {
                                                        if(params.colDef.field == 'licPlate')
                                                        {
                                                            const plate = params.data != null ? params.data.licPlate : params.node.key;//params.node.key for group selection
                                                            onSelectedPlate(plate);
                                                        }
                                                    }}
                                                    onGridColumnsChanged={event => event.api.sizeColumnsToFit()} />

                                            </div>
                                        </div>
                                    </div>
                                </Box>
                        }
                    </Grid>
                </Grid>
                
            </Grid>
            <MarkerDetail marker={selectedMarker} openModal={state.openModal} onClose={onClearSelectedMarker} selectedCustomerSubDomain={selectedCustomerSubDomain} onSelectedPlate={onSelectedPlate} mapState={mapState} markers={markers}/>
            <DataDetail markers={markers} openModal={state != null ? state.openDataModal : false} onClose={onCloseDataDetail} mapState={mapState}  />
            <HelpPopup  openModal={modalHelp} onClose={onCloseHelp}  />
        </Paper>
    );
};
