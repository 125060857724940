import { Button, Fade, Grid, Modal, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';

import { useStylesSqlQueryEditorModal } from './SqlQueryEditor.styles';

interface SqlQueryEditorModalProps {
  isOpen: boolean;
  sqlQuery: string;
  onSqlQueryChange: (sqlQuery: string) => void;
  isValidSqlQuery: boolean;
  onCloseModal: () => void;
}

const SqlQueryEditorModal = (props: SqlQueryEditorModalProps): JSX.Element => {
  const classes = useStylesSqlQueryEditorModal();

  const {
    isOpen,
    sqlQuery,
    onSqlQueryChange,
    isValidSqlQuery,
    onCloseModal,
  } = props;

  const initialSqlQuery = useMemo(() =>  isOpen === true ? sqlQuery : null, [isOpen]);

  const onCancelButtonClicked = useCallback(() => {
    if (initialSqlQuery != null && initialSqlQuery !== sqlQuery) {
      onSqlQueryChange(initialSqlQuery);
    }

    onCloseModal();
  }, [onCloseModal, initialSqlQuery])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {/* TODO: Replace this field font family for 'monospace' to make it look like a code editor */}
              <TextField
                label="SQL Query Editor"
                multiline
                rows={30}
                onChange={(event) => onSqlQueryChange(event.target.value as string)}
                variant="outlined"
                style={{ width: '100%', marginTop: '6px', minWidth: '600px' }}
                value={sqlQuery}
                type="text"
                inputProps={{style: { fontFamily: 'monospace' }}}
              />
              {
                isValidSqlQuery === false &&
                <Typography variant="caption" style={{ color: 'red' }}>
                  Invalid query, please verify it.
                </Typography>
              }
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onCloseModal()}>
                Accept
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={() => onCancelButtonClicked()}
                className={classes.cancelButton}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

export default SqlQueryEditorModal;