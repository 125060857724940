import { createSlice } from '@reduxjs/toolkit';
import { MapMarker, MapDashboardStateModel, MapFilterSource } from './mapDashboardStateV2';
import { updateMapStateAction, clearMarkersAction, setLoadingAction, setListLoadingAction, selectMarkerAction, unselectMarkerAction, setBootExtraInfoAction, setErrorAction,  applyFilterAction, changePageOnFilterAction, setFilterSourceAction, savePreviousSearchAction, updateMarkersByZoneAction } from './mapDashboardActions';

const mapScenariosDashboardSlice = createSlice({
    name: 'mapScenariosDashboard',
    initialState: {
        isLoading: false,
        listIsLoading: false,
        error: null,
        markers: Array<MapMarker>(),
        pagination: {
            currentPage: 1,
            totalPages: 0,
        },
        selectedMarker: undefined,
        filterSource: {
            bootingOfficers: [],
            parkingAreas: [],
            customerLongitude: -100.964709,
            customerLatitude: 39.259336,
        } as MapFilterSource,
    } as MapDashboardStateModel,
    reducers: {
        setLoading: setLoadingAction,
        setListLoading: setListLoadingAction,
        setBootExtraInfo: setBootExtraInfoAction,
        setError: setErrorAction,
        setFilterSource: setFilterSourceAction,
        applyFilter: applyFilterAction,
        changePageOnFilter: changePageOnFilterAction,
        updateMapState: updateMapStateAction,
        updateMarkersByZone: updateMarkersByZoneAction,
        clearMarkers: clearMarkersAction,
        selectMarker: selectMarkerAction,
        unselectMarker: unselectMarkerAction,
        savePreviousSearch: savePreviousSearchAction,
    },
});

export const { reducer } = mapScenariosDashboardSlice;
export const { setLoading, clearMarkers, setListLoading, setBootExtraInfo, setError, setFilterSource, updateMapState, applyFilter, changePageOnFilter, selectMarker, unselectMarker, savePreviousSearch, updateMarkersByZone } = mapScenariosDashboardSlice.actions;
