import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        marginTop: theme.spacing(2),
        color: theme.palette.error.main,
        '& .MuiSvgIcon-root': {
            marginBottom: theme.spacing(1),
        },
    },
}));
