import { MapDashboardStateModel, MapSearchResult, MapFilterSource, MapMarker, EnforcementDataType, BootRecordExtra } from './mapDashboardStateV2';
import { current, PayloadAction } from '@reduxjs/toolkit';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';
import { DashboardFilterState } from '../../state/DashboardFilterState';

const setLoadingAction = (state: MapDashboardStateModel, action: PayloadAction<boolean>): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setBootExtraInfoAction = (state: MapDashboardStateModel, action: PayloadAction<BootRecordExtra[]>): MapDashboardStateModel => {
    const booExtraInfo = [...action.payload];

    if(state.bootExtraInfo != null && state.savePreviousSearch)
    {
        state.bootExtraInfo.forEach((extraInfo: BootRecordExtra)=>{
            const temp = booExtraInfo.find(x=> x.boot_Serial_Number == extraInfo.boot_Serial_Number);
            if(temp == null)//only add it if not already there
            {
                booExtraInfo.push(extraInfo);
            }
        });
    }

    return {
        ...state,
        bootExtraInfo: booExtraInfo,
    };
};

const savePreviousSearchAction = (state: MapDashboardStateModel, action: PayloadAction<boolean>): MapDashboardStateModel => {
    return {
        ...state,
        savePreviousSearch: action.payload,
    };
};

const setListLoadingAction = (state: MapDashboardStateModel, action: PayloadAction<boolean>): MapDashboardStateModel => {
    return {
        ...state,
        listIsLoading: action.payload,
    };
};

const setErrorAction = (state: MapDashboardStateModel, action: PayloadAction<string | null>): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
};

const selectMarkerAction = (state: MapDashboardStateModel, action: PayloadAction<string>): MapDashboardStateModel => {
    const selectedMarker = state.markers.find(a => a.id == action.payload);

    return {
        ...state,
        selectedMarker: selectedMarker,
    };
};

const unselectMarkerAction = (state: MapDashboardStateModel, action: PayloadAction<void>): MapDashboardStateModel => {
    return {
        ...state,
        selectedMarker: undefined,
    };
};

const setFilterSourceAction = (state: MapDashboardStateModel, action: PayloadAction<MapFilterSource>): MapDashboardStateModel => {
    return {
        ...state,
        filterSource: {
            ...action.payload,
            bootingOfficers: [...action.payload.bootingOfficers],
            parkingAreas: [...action.payload.parkingAreas],
        },
    };
};

const applyFilterAction = (state: MapDashboardStateModel, action: PayloadAction<MapFilterState>): MapDashboardStateModel => {
    const filter: MapFilterState = {
        ...action.payload,
        selectedAgentsToSearchBy: [...action.payload.selectedAgentsToSearchBy],
        selectedDataTypes: [...action.payload.selectedDataTypes],
    };

    return {
        ...state,
        isLoading: true,
        filter: filter,
    };
};

const changePageOnFilterAction = (state: MapDashboardStateModel, action: PayloadAction<number>): MapDashboardStateModel => {
    const filter: MapFilterState = {
        ...state.filter,
        currentPage: action.payload,
    };

    let paginatedMarkers = [...state.markers];
    if (state.markers.length > filter.pageSize) {
        const start = (filter.currentPage - 1) * filter.pageSize;
        paginatedMarkers = [...state.markers].slice(start, (start + filter.pageSize));
    }

    return {
        ...state,
        // isLoading: true,
        filter,
        paginatedMarkers: paginatedMarkers,
    };
};

const clearMarkersAction = (state: MapDashboardStateModel): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: false,
        markers: [],
        latestMarkers: [],
        paginatedMarkers: [],
        filteredMarkers: [],
    };
};

const updateMarkersByZoneAction = (state: MapDashboardStateModel, action: PayloadAction<Array<MapMarker>>): MapDashboardStateModel => {

    const markers = [...action.payload];//will contain all the markers the one from previous search and current.
    //list operations
    //we only want hits and boots on the right list so we filter
    let filteredMarkers = [...markers];
    filteredMarkers = filteredMarkers.filter(item => item.type == EnforcementDataType.Boot || item.type == EnforcementDataType.Hit);
    
    //sort the array and add them again, we want the list to be oredered
    const sortedArray = filteredMarkers.sort(function(a, b){return (new Date(a.dateRecord)).getTime() - (new Date(b.dateRecord)).getTime()});


    return {
        ...state,
        isLoading: false,
        error: null,
        markers: markers,
        latestMarkers: markers,
        filteredMarkers: sortedArray,
    };
};

const updateMapStateAction = (state: MapDashboardStateModel, action: PayloadAction<any>): MapDashboardStateModel => {
    const mapSearchResult = action.payload.mapSearchResult as MapSearchResult;
    const dashboardState  = action.payload.dashboardState as DashboardFilterState;
    const pagination = mapSearchResult.pagination;
    const markers = [...mapSearchResult.results];//will contain all the markers the one from previous search and current.
    const latestMarkers = [...mapSearchResult.results];//will contain only markers from current search for increamentals add to map

    //assing scenario type
    markers.forEach((marker)=>{
        marker.scenarioType = dashboardState.scenario;
        marker.id += dashboardState.scenario;//this will help to distinguish between same point on different scenarios
    });

    //mark the last read of each officer
    state.filterSource.bootingOfficers.forEach(officer=>{
        const markerList = markers.filter(x => x.agentId == officer.bootingOfficerId);
        if(markerList.length>0)
        {
            markerList[0].isLastPosition = true
        }
    });

    //if we want to save previous search add the state markes to the new received reads
    if(state.savePreviousSearch)
    {
        state.markers.map((marker)=>{
            const temp = markers.find(x=> x.id == marker.id);
            if(temp == null)//only add it if not already there
            {
                markers.push(current(marker));
            }
        });
    }

    //list operations
    //we only want hits and boots on the right list so we filter
    let filteredMarkers = [...markers];
    filteredMarkers = filteredMarkers.filter(item => item.type == EnforcementDataType.Boot || item.type == EnforcementDataType.Hit);
    
    //sort the array and add them again, we want the list to be oredered
    const sortedArray = filteredMarkers.sort(function(a, b){return (new Date(a.dateRecord)).getTime() - (new Date(b.dateRecord)).getTime()});

    //paginated logic for the list
    let paginatedMarkers = [...sortedArray];
    if (sortedArray.length > pagination.pageSize) {
        const start = (pagination.currentPage - 1) * pagination.pageSize;
        paginatedMarkers = [...sortedArray].slice(start, (start + pagination.pageSize));
    }

    return {
        ...state,
        isLoading: false,
        error: null,
        markers: markers,
        latestMarkers: latestMarkers,
        paginatedMarkers: paginatedMarkers,
        filteredMarkers: sortedArray,
        pagination: mapSearchResult.pagination,
    };
};


export { setLoadingAction, clearMarkersAction, setErrorAction, updateMapStateAction, selectMarkerAction, unselectMarkerAction,  applyFilterAction, changePageOnFilterAction, setFilterSourceAction, setListLoadingAction, setBootExtraInfoAction, savePreviousSearchAction, updateMarkersByZoneAction };
