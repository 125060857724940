import { Icon, Style, Stroke, Fill } from 'ol/style';
import IconStyle from 'ol/style/Icon';
import CircleStyle from 'ol/style/Circle';
import { EnforcementDataType, MapMarker } from '../../state/mapDashboardStateV2';
import { MapFilterState } from '../FilterBar/mapFilterState';


/*const getMarkerIconColor = (marker: MapMarker): string => {
    switch (marker.type) {
        case EnforcementDataType.Scan:
            return '#473b55';
        case EnforcementDataType.Hit:
            return '#FF6633';
        case EnforcementDataType.Boot:
        default:
            return 'red';
    }
};*/

const getReadIconStyle = (marker: MapMarker, mapFilter?: MapFilterState ): any => {
    if(marker.isLastPosition)
    {
        switch (marker.type) {
            case EnforcementDataType.Boot:
                return new Style({
                    image: new Icon({
                        color: '#BADA55',
                        crossOrigin: 'anonymous',
                        // For Internet Explorer 11
                        imgSize: [40, 40],
                        src: '/img/green-star-boot.png',
                    }),
                    zIndex: 150,
                });

            case EnforcementDataType.Hit:
                return new Style({
                    image: new Icon({
                        color: '#BADA55',
                        crossOrigin: 'anonymous',
                        // For Internet Explorer 11
                        imgSize: [40, 40],
                        src: '/img/green-star-hit.png',
                    }),
                    zIndex: 150,
                });
            case EnforcementDataType.Scan:
            default:
                return new Style({
                    image: new Icon({
                        color: '#BADA55',
                        crossOrigin: 'anonymous',
                        // For Internet Explorer 11
                        imgSize: [40, 40],
                        src: '/img/green-star.png',
                    }),
                    zIndex: 150,
                });
        }
    }
    else
    {
        switch (marker.type) {
            case EnforcementDataType.Scan:
                return new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: mapFilter != null ? (mapFilter.selectedAgentsColors[mapFilter.selectedAgentsToSearchBy.indexOf(marker.agentId)]) : '#473b55',
                        }),
                        stroke: new Stroke({
                            color: 'Black',
                            width: 1,
                        }),
                    }),
                    zIndex: 0,
                });

            case EnforcementDataType.Hit:
                return new Style({
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#FF6633',
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 50,
                });
            case EnforcementDataType.Boot:
            default:
                return new Style({
                    image: new CircleStyle({
                        radius: 9,
                        fill: new Fill({
                            color: '#C0382A',
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 100,
                });
        }
    }  
}

const readsLayerStyle = (marker: MapMarker, mapFilter?: MapFilterState ): Style => {
    //const markerIconColor = getMarkerIconColor(marker);

    return getReadIconStyle(marker, mapFilter);

};

const onHoverReadLayerStyle = (marker: MapMarker): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = getReadIconStyle(marker);
    //style.image.radius += 2;
    return style;

};

const getSelectedStyle = (): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = new Style({
        
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#FBD337',
            }),
            stroke: new Stroke({
                color: 'black',
                width: 1,
            }),
        }),
        zIndex: 1000,
    });
    //style.image.radius += 2;
    return style;

};

export default readsLayerStyle;
export { onHoverReadLayerStyle, getSelectedStyle };
