import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch',
        justifyContent: 'center',
    },
    grid: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
    },
    titleContainer: {
        flexGrow: 0,
        // maxHeight: 33,
    },
    titleIcon: {
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(1.5),
    },
    listTitle: {
        fontWeight: 'bold',
        fontSize: '13px',
        paddingLeft: theme.spacing(1.5),
    },
    listContainer: {
        flexGrow: 1,
        // maxHeight: 759,
    },
    listItem: {
        padding: theme.spacing(2),
    },
    startSearchMessage: {
        padding: theme.spacing(2),
    },
    loadingSearchMessage: {
        padding: theme.spacing(2),
    },
    noResultsMessage: {
        padding: theme.spacing(2),
    },
    paginationContainer: {
        flexGrow: 0,
        alignSelf: 'flex-end',
        marginTop: theme.spacing(1),
    },
    pagination: {
        padding: theme.spacing(1),
    },
}));
