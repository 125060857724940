import { SessionState, ApplicationUser } from './sessionTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import clone from 'rfdc';

const setLoadingAction = (state: SessionState, action: PayloadAction<boolean>): SessionState => {
  return {
      ...state,
      isLoading: action.payload,
  };
};

const setErrorAction = (state: SessionState, action: PayloadAction<string | null>): SessionState => {
  return {
      ...state,
      isLoading: false,
      error: action.payload,
  };
};

const setCurrentUserAction = (state: SessionState, action: PayloadAction<ApplicationUser | null>): SessionState => {
  const newCurrentUser = clone({ proto: true })(action.payload);

  return {
    ...state,
    isLoading: false,
    error: null,
    currentUser: newCurrentUser,
  };
};

export { setLoadingAction, setErrorAction, setCurrentUserAction };
