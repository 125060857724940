/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getDefaultHeaders } from './apiService/utils';

const commonHeaders = getDefaultHeaders();

export function RestClient(): { get: (request: any) => Promise<any> } {
    function get(request: { url: string; params: any }) {
        return new Promise((resolve, reject) => {
            return axios.get(request.url, {
                params: request.params,
                headers: commonHeaders,
                withCredentials: true,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    return {
        get: get,
    };
}

export default RestClient();

export type PostRequest = {
    url: string;
    data: any;
    config?: any;
}

const Post = (request: PostRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.post(
            request.url,
            request.data,
            {
                headers: commonHeaders,
                withCredentials: true,
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const PostNoHeaders = (request: PostRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.post(
            request.url,
            request.data,
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export type PutRequest = {
    url: string;
    data: any;
    config?: any;
}

const Put = (request: PutRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.put(
            request.url,
            request.data,
            {
                headers: commonHeaders,
                withCredentials: true,
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export type GetRequest = {
    url: string;
    params?: any;
}

const Get = (request: GetRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.get(request.url, {
            params: request.params,
            headers: commonHeaders,
            withCredentials: true,
        }).then((response) => {
            if (response.request.responseURL.includes('/Login.aspx')) {
                reject('Login required.');
                return;
            }

            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const GetNoHeaders = (request: GetRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.get(request.url, {
            params: request.params,
            withCredentials: true,
        }).then((response) => {
            if (response.request.responseURL.includes('/Login.aspx')) {
                reject('Login required.');
                return;
            }

            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const RegularGet = (request: GetRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.get(request.url, {
            params: request.params,
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export type DeleteRequest = {
    url: string;
    params?: any;
}

const Delete = (request: DeleteRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        return axios.delete(request.url, {
            params: request.params,
            headers: commonHeaders,
            withCredentials: true,
        }).then((response) => {
            if (response.request.responseURL.includes('/Login.aspx')) {
                reject('Login required.');
                return;
            }

            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const RestClientV2 = {
    post: Post,
    postNoHeaders: PostNoHeaders,
    get: Get,
    getNoHeaders: GetNoHeaders,
    getR: RegularGet
};

export { Get, Post, Put, Delete };

