/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react';
import { MapSearchResult, MapFilterSource, NoLocationGuid, NoLocationName, EnforcementDataType, BootExtraMapSearchResult } from './mapDashboardStateV2';
import { updateMapState, setError, applyFilter, changePageOnFilter, setFilterSource, setListLoading, setBootExtraInfo } from './mapScenariosDashboardSlice';
import { DashboardFilterState } from '../../state/DashboardFilterState';
import RestClient, { RestClientV2 } from '../../../../services/RestClient';
import { ApplicationState } from '../../../../app/store';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';
import { setLoading } from './mapScenariosDashboardSlice';
import { toggleModal } from '../../Commons/ConfirmDialog/ConfirmDialog';

const fetchMapFilterSource = (customerId: string): Promise<MapFilterSource> => {
    return new Promise((resolve, reject) => {
        RestClient.get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/filterSource`,
            params: {
                customerId: customerId,
            },
        }).then((response: any) => {
            resolve(response.data as MapFilterSource);
        }).catch((err) => {
            reject(err);
        });
    });
};

const initializeMapFilterSource = () => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        const state = getState();
        if (!state.dashboardFilter.customerId) {
            return Promise.resolve();
        }

        return fetchMapFilterSource(getState().dashboardFilter.customerId).then((filterSource: MapFilterSource) => {
            //we need to add the No location option to parking areas after changing customer
            const locations = filterSource.parkingAreas.map((pa) => ({ parkingAreaId: pa.name, name: pa.name }));
            if(filterSource.parkingAreas.find(x=> x.parkingAreaId ==  NoLocationGuid) == null)
            {
                const emptyId = { parkingAreaId: NoLocationGuid, name:  NoLocationName};
                locations.unshift(emptyId);
                filterSource.parkingAreas = locations;
            }
            dispatch(setFilterSource(filterSource));
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const fetchMapMarkersFromAPI = (mapFilter: MapFilterState, dashboardFilter: DashboardFilterState): Promise<MapSearchResult> => {
    return new Promise((resolve, reject) => {
        console.log('fetchMapMarkersFromAPI mapFilter: ', mapFilter);

        const datatype: number[] = [dashboardFilter.scenario];//assigned the selected scenario

        RestClientV2.post({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/scenarios`,
            data: {
                customerId: dashboardFilter.customerId,
                dateFrom: dashboardFilter.date,
                dateTo: dashboardFilter.dateTo,
                plate: mapFilter.plate,
                agentsIds: mapFilter.selectedAgentsToSearchBy,
                locationsIds: mapFilter.selectedLocationsToSearchBy,
                currentPage: mapFilter.currentPage,
                pageSize: mapFilter.pageSize,
                dataTypes: datatype,
                towEligibleHours: mapFilter.towEligibleHours,
                isArchiveQuery: mapFilter.isArchiveQuery,
            },
        }).then((response: any) => {
            console.log('fetchMapMarkersFromAPI response: ', response);
            resolve(response as MapSearchResult);
        }).catch((err) => {
            reject(err);
        });
    });
};

const applyFilterAndFetchMarkers = (mapFilter: MapFilterState) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
        mapFilter.currentPage = getState().mapDashboard.filter ? getState().mapDashboard.filter.currentPage : 1;
        console.log('filter before apply', mapFilter);
        dispatch(applyFilter(mapFilter));

        return fetchMapMarkersFromAPI(mapFilter, getState().dashboardFilter).then((mapSearchResult: MapSearchResult) => {
            console.log('mapSearchResult >>', mapSearchResult)
            const itemsNumber = mapSearchResult.results.length;
            if(itemsNumber > 8000)
            {
                toggleModal(true, 'Note: there are more than 8000 records from this search, this can result on slow or unresponsive user interface. Do you still want to continue?', (): void =>{
                        toggleModal(false);
                        dispatch(updateMapState({mapSearchResult: mapSearchResult, dashboardState: getState().dashboardFilter}));
                        getBootRecordExtraInformation(dispatch, mapSearchResult);
                    }, 
                    (): void =>{
                        toggleModal(false);
                        dispatch(setLoading(false));
                    }
                );
            }
            else
            {
                dispatch(updateMapState({mapSearchResult: mapSearchResult, dashboardState: getState().dashboardFilter}));
                getBootRecordExtraInformation(dispatch, mapSearchResult);
            }
        }).catch((err: string) => {
            dispatch(setError(err));
        });
    };
};

const getBootRecordExtraInformation = (dispatch: Dispatch<any>, mapSearchResult: MapSearchResult) =>{
    
    dispatch(setListLoading(true));
    //get vio numbers
    const vioNumberArray: Array<string> = [];
    mapSearchResult.results.forEach(x=>{
        if(x.type == EnforcementDataType.Boot)
        {
            if(x.bootRecord != null)
            {
                vioNumberArray.push(x.bootRecord.violationNumber);
            }
        }
    });

    //get boot record extra information
    RestClientV2.post({
        url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/dashboards/map/bootRecordExtra`,
        data: {
            vioNumberList: vioNumberArray
        },
    }).then((response: any) => {
        console.log('Bootrecord response: ', response);
        const resp = response as BootExtraMapSearchResult;
        dispatch(setBootExtraInfo(resp.results));
        dispatch(setListLoading(false));
    }).catch((err) => {
        console.log('Bootrecord Error: ', err);
        dispatch(setListLoading(false));
    });
};

const changePageAndFetchMarkers = (page: number) => {
    return (dispatch: Dispatch<any>, getState: () => ApplicationState): void => {
        dispatch(changePageOnFilter(page));

        // const state = getState();

        // return fetchMapMarkersFromAPI(state.mapDashboard.filter, state.dashboardFilter).then((mapSearchResult: MapSearchResult) => {
        //     dispatch(updateMapState(mapSearchResult));
        // }).catch((err: string) => {
        //     dispatch(setError(err));
        // });
    };
};

export { initializeMapFilterSource, applyFilterAndFetchMarkers, changePageAndFetchMarkers };
