import { Icon, Style, Stroke, Fill, Text } from 'ol/style';
import IconStyle from 'ol/style/Icon';
import CircleStyle from 'ol/style/Circle';
import { EnforcementDataType, MapMarker, MapSourceType } from '../../state/mapDashboardStateV2';
import { MapFilterState } from '../FilterBar/mapFilterState';

/*const getMarkerIconColor = (marker: MapMarker): string => {
    switch (marker.type) {
        case EnforcementDataType.Scan:
            return '#473b55';
        case EnforcementDataType.Hit:
            return '#FF6633';
        case EnforcementDataType.Boot:
        default:
            return 'red';
    }
};*/

const getReadIconStyle = (marker: MapMarker, isScans = false, mapFilter?: MapFilterState): any => {
    if(marker.isLastPosition)
    {
        return new Style({
            image: new Icon({
                color: '#BADA55',
                crossOrigin: 'anonymous',
                // For Internet Explorer 11
                imgSize: [40, 40],
                src: '/img/green-star.png',
            }),
            zIndex: 150,
        });
    }
    else
    {
        switch (marker.srcType) {
            case MapSourceType[MapSourceType.LPR]:
                return new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: '#0399FE',
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 0,
                });
    
            case MapSourceType[MapSourceType.Ticket]:
                return new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: '#FF6633',
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 50,
                });
            default:
                return new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: new Fill({
                            color: 'black',
                        }),
                        stroke: new Stroke({
                            color: 'white',
                            width: 2,
                        }),
                    }),
                    zIndex: 100,
                });
        } 
    }
}

const readsLayerStyle = (marker: MapMarker, isScan = false, mapFilter?: MapFilterState ): Style => {
    //const markerIconColor = getMarkerIconColor(marker);

    return getReadIconStyle(marker, isScan, mapFilter);

};

const onHoverReadLayerStyle = (marker: MapMarker): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = getReadIconStyle(marker);
    //style.image.radius += 2;
    return style;

};

const getSelectedStyle = (): Style => {
    //const markerIconColor = getMarkerIconColor(type);

    const style = new Style({
        
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#FBD337',
            }),
            stroke: new Stroke({
                color: 'black',
                width: 1,
            }),
        }),
        zIndex: 1000,
    });
    //style.image.radius += 2;
    return style;

};

const getMapZoneDrawingStyle = (newZoneName: string | null): Style => {

    const style = new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color: '#8C0000',
            width: 2,
        }),
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#8C0000',
            }),
        }),
        text: newZoneName != null ? createTextStyle(newZoneName) : undefined
    })
    return style;

};

const createTextStyle = function (newZoneName: string): Text {
    return new Text({
      textAlign: 'center',
      textBaseline: 'middle',
      font: '14px sans-serif',
      text: newZoneName,
      fill: new Fill({color: '#8C0000'}),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
      }),
      offsetX: 0,
      offsetY: 15,
      placement: 'line',
      maxAngle: 360,
      overflow: true,
      rotation: 0,//45 = 0.785398164, 90 = 1.570796327
    });
  };

export default readsLayerStyle;
export { onHoverReadLayerStyle, getSelectedStyle, getMapZoneDrawingStyle };
