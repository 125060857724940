import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        height: '100%',
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: '#1976d2',
            color: '#eae9ef',
        },
    },
    contentWrapper: {
        marginTop: theme.spacing(1),
    },
    openLayersMapWrapper: {
        position: 'relative',
        boxSizing: 'border-box',
    },
    markersListWrapper: {
        flexGrow: 1,
    },
    rightColumn:{
        height: '100%'
    },
    modalContent: {
        background: 'white',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            width: '310px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '420px',
        },
        padding: '10px',
        '&:focus': {
            outline: 'none',
        },
        '& > .MuiTypography-root': {
            marginBottom: theme.spacing(.5),
        },
        '& > .MuiButtonBase-root': {
            marginRight: theme.spacing(1),
        },
    },
    enforcementZoneDetailWrapper:{
        display: 'block'
    },
    gridAssignBase: {
        display: 'flex'
    },
    buttonGroup: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 10,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    button: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 10,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.info.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    deleteMessage:{
        color: theme.palette.error.dark,
        marginTop: 10
    },
    buttonRed: {
        marginTop: 18,
        maxHeight: 30,
        fontSize: 10,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover, &:active, &:focus': {
            backgroundColor: theme.palette.error.dark,
            outline: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            marginTop: 18,
            marginBottom: 0,
        },
    },
    buttonGroupContainer:{
        zIndex: 1000
    }
}));
