import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import moment from 'moment';
import FileData from './FileData';
import FileDateCardSkeleton from './FileDateCardSkeleton';
import { useStyles } from './FileDataStyles';
import { Alert } from '@material-ui/lab';

type FileDateCardProps = {
    title: string;
    data: FileData;
    loading?: boolean | undefined;
};

export default function FileDateCard (props: FileDateCardProps): JSX.Element {
    const { title, data, loading } = props;
    const classes = useStyles();

    const hoursDifference = moment().diff(moment(data.updatedLastDate), 'hours');

    let alertMessage: string;
    if (hoursDifference >= 24) {
        alertMessage = `Warning: ${Math.round(hoursDifference / 24)} day(s) old`;
    } else {
        alertMessage = 'File is up to date';
    }

    return (
        <Card variant="outlined" square className={`${classes.root} ${hoursDifference < 24 ? 'success' : 'error'}`}>
            { loading ? <FileDateCardSkeleton /> : <FileDateCardContent /> }
        </Card>
    );

    function FileDateCardContent () {
        return (
            <CardContent className={classes.cardContent}>
                <div className={classes.mainLabels}>
                    <Typography variant="button">{title}</Typography>
                    <Typography variant="h5" className={'value'}>
                        {moment(data.updatedLastDate).format('YYYY/MM/DD hh:mm a')}
                    </Typography>
                </div>
                <Alert severity={hoursDifference >= 24 ? 'error' : 'success'} className={classes.alert}>{alertMessage}</Alert>
            </CardContent>
        );
    }
}
