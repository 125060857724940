import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardBuilderState } from './dashboardBuilderState';
import { setBuilderIsLoadingAction, updateBuilderAction, updateDashboardTileAction } from './dashboardBuilderStateActions';

const dashboardBuilderSlice = createSlice({
    name: 'dashboardBuilderState',
    initialState: {
        id: 0,
        name: '',
        description: '',
        customerId: '',
        isDefault: false,
        isHomePage: false,
        searchTile: '',
        selectedTiles: [],
        availableTiles: [],
        isLoading: false,
        // availableTiles: [
        //     {
        //         categoryId: 'booting',
        //         name: 'Booting',
        //         tiles: [
        //             {
        //                 id: 'Scofflaw',
        //                 name: 'Scofflaw',
        //                 selected: false
        //             },
        //             {
        //                 id: 'Boots',
        //                 name: 'Boots',
        //                 selected: false
        //             },
        //             {
        //                 id: 'Notices',
        //                 name: 'Notices',
        //                 selected: false
        //             },
        //             {
        //                 id: 'Tows',
        //                 name: 'Tows',
        //                 selected: false
        //             },
        //             {
        //                 id: 'DetailOverTime',
        //                 name: 'Detail Over Time',
        //                 selected: false
        //             }
        //         ]
        //     },
        //     {
        //         categoryId: 'easyLpr',
        //         name: 'Easy LPR',
        //         tiles: [
        //             {
        //                 id: 'Enforcement',
        //                 name: 'Enforcement',
        //                 selected: false
        //             },
        //             {
        //                 id: 'scans',
        //                 name: 'Scans',
        //                 selected: false
        //             },
        //             {
        //                 id: 'hits',
        //                 name: 'Hits',
        //                 selected: false
        //             }
        //         ]
        //     },
        //     {
        //         categoryId: 'helpCenter',
        //         name: 'Help Center',
        //         tiles: [
        //             {
        //                 id: 'helpCenter',
        //                 name: 'Help Center Summary',
        //                 selected: false
        //             }
        //         ]
        //     }
        // ],
    } as DashboardBuilderState,
    reducers: {
        setBuilderIsLoading: setBuilderIsLoadingAction,
        updateBuilder: updateBuilderAction,        
        updateDashboardTile: updateDashboardTileAction,
    },
});

export const { reducer } = dashboardBuilderSlice;
export const { updateBuilder, setBuilderIsLoading, updateDashboardTile } = dashboardBuilderSlice.actions;
