export default class InformationOverTimeSummary {
    type: number;
    typeName: string;
    scans: Array<OverTimeItem> = new Array<OverTimeItem>();
    scansAverage = 0;
    scansTotal = 0;
    hits: Array<OverTimeItem> = new Array<OverTimeItem>();
    hitsAverage = 0;
    hitsTotal = 0;
    bootedVehicles: Array<OverTimeItem> = new Array<OverTimeItem>();
    bootsAverage = 0;
    bootsTotal = 0;
    noticedVehicles: Array<OverTimeItem> = new Array<OverTimeItem>();
    noticesAverage = 0;
    noticesTotal = 0;
    towedVehicles: Array<OverTimeItem> = new Array<OverTimeItem>();
    towsAverage = 0;
    towsTotal = 0;
    payments: Array<OverTimeItem> = new Array<OverTimeItem>();
    paymentsAverage = 0;
    paymentsTotal = 0;

    constructor (type = 0, typeName = 'Consolidated') {
        this.type = type;
        this.typeName = typeName;
    }
}

export class OverTimeHeader {
    name: string;
    ordinal: number;

    constructor (name: string, ordinal: number) {
        this.name = name;
        this.ordinal = ordinal;
    }
}

export class OverTimeItem {
    ordinal: number;
    totalEvents: number;

    constructor (ordinal: number, totalEvents: number) {
        this.ordinal = ordinal;
        this.totalEvents = totalEvents;
    }
}

export class DataGridRow {
    dataType: string | undefined;
    [key: string]: any;
}

export enum TableType {
    Consolidated = 0,
    AM = 1,
    PM = 2,
    Range = 3
}
