import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Divider,  Box, CircularProgress, InputLabel, Button } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { MapMarker, PaginationResult, MapFilterDataType, MapDashboardStateModel, EnforcementDataType, Scenario, MarkerExport } from '../../state/mapDashboardStateV2';
import { MarkerDetail } from '../MarkerDetail/MarkerDetail';
import { useStyles } from './markerListStyles';
import moment from 'moment';
import MarkerItemCard from './MarkerItemCard';
import { getScenarioColor } from '../Map/readsLayerStyles';
import {FiberManualRecord as CircleIcon} from '@material-ui/icons';
import { read, utils, writeFile } from 'xlsx';

type MarkersListProps = {
    isLoading: boolean;
    filteredMarkers: MapMarker[];
    markers: MapMarker[];
    //latestMarkers?: MapMarker[];
    selectedMarker?: MapMarker | null;
    mapState:  MapDashboardStateModel;
    pagination?: PaginationResult;
    onSelectedMarker: (selectedMarkerId: string) => void;
    onClearSelectedMarker: () => void;
    selectedCustomerSubDomain?: string;
    onPageChanged?: (newPage: number) => void;
};

type MarkersListState = {
    openModal: boolean;
    selectedMarker: MapMarker;
    displayList: MapMarker[];
};

export const MarkersList = (props: MarkersListProps): JSX.Element => {
    const { filteredMarkers = [], markers = [],  selectedMarker, pagination, onSelectedMarker: onSelectMarker, selectedCustomerSubDomain, onClearSelectedMarker, onPageChanged, mapState } = props;
    const classes = useStyles();


    const bootMarkers = filteredMarkers.filter(x=> x.scenarioType == Scenario.Boots);
    const hitMarkers = filteredMarkers.filter(x=> x.scenarioType == Scenario.Hits);
    const scansMarkers = markers.filter(x=> x.scenarioType == Scenario.Scans);
    const runAwayMarkers = filteredMarkers.filter(x=> x.scenarioType == Scenario.Runaways);
    const towEligibleMarkers = filteredMarkers.filter(x=> x.scenarioType == Scenario.TowEligible);

    const [state, setState] = useState({
        openModal: selectedMarker ? true : false,
    } as MarkersListState);

    const pageChangeHandler = (event: React.ChangeEvent<unknown>, page: number) => {
        if(onPageChanged)
        {
            onPageChanged(page);
        }
    };

    const setScenarioResultList = (scenario: Scenario): void =>{
        let resultArray: MapMarker[] = [];
        resultArray =  filteredMarkers.filter(x => x.scenarioType == scenario);
        setState({ ...state, displayList: resultArray});
    };

    useEffect(() => {
        setState({ ...state, openModal: selectedMarker ? true : false });
    }, [selectedMarker]);

    useEffect(() => {
        setState({ ...state, displayList: []});//if markers changed clear list
    }, [markers]);


    const onClearMap = (): void=>{
        const exportList: MarkerExport[] = [];
        //map the list to an exportable object
        state.displayList.forEach(marker =>{
            let bootExtraInfo = null;
            if(mapState.bootExtraInfo != null)
            {
                 bootExtraInfo = mapState.bootExtraInfo.find(x => x.sticker_Id == marker.bootRecord?.violationNumber);
            }
            const markerExport: MarkerExport =
            {
                plate: marker.plate,
                plateState:  marker.plateState,
                violationNumber: marker.bootRecord != null ? marker.bootRecord.violationNumber: '',
                bootSerialNumber: bootExtraInfo != null ? bootExtraInfo.boot_Serial_Number : '',
                bootRecordStatusLabel: bootExtraInfo != null ? bootExtraInfo.boot_Record_Status_Label: '',
                latitude: marker.latitude,
                longitude: marker.longitude,
                dateRecord: marker.dateRecord,
                customerCode: marker.customerCode,
                agentFullName: marker.agentFullName,
                locationName: marker.locationName,
                rejectionReason: marker.rejectionReason,
                numberRunAways: bootExtraInfo != null ? bootExtraInfo.number_RunAways: 0,
            }
            if(bootExtraInfo != null)
            {
                markerExport.towEligibleDate = bootExtraInfo.tow_Eligible_Date
            }
            exportList.push(markerExport);
        });
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(exportList);
        utils.book_append_sheet(wb,ws,'Stattrack_Export');
        writeFile(wb, 'Stattrack_Export.xlsx');
    };

    return (
        <Paper elevation={1} className={classes.root}>
            <Grid container spacing={1} className={classes.grid}>
                <Grid item xs={12} md={12} className={classes.titleContainer}>
                    <Typography variant="h6" color="inherit" className={classes.title}>
                        <Grid container alignItems="flex-start" direction='column' spacing={1}>
                            {scansMarkers.length > 0 && <Grid container direction="row" item><div style={{color: getScenarioColor(Scenario.Scans), background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)', borderRadius: '25px', width:'15px', height: '15px', margin: '5px'}}/><InputLabel className={classes.scenarioListlabel} onClick={() => {setScenarioResultList(Scenario.Scans);}}>Reads[{scansMarkers.length}]</InputLabel></Grid>}
                            {hitMarkers.length > 0 && <Grid container direction="row" item><CircleIcon style={{color: getScenarioColor(Scenario.Hits)}}/><InputLabel className={classes.scenarioListlabel} onClick={() => {setScenarioResultList(Scenario.Hits);}}>Hits[{hitMarkers.length}]</InputLabel></Grid>}
                            {bootMarkers.length > 0 && <Grid container direction="row" item><CircleIcon style={{color: getScenarioColor(Scenario.Boots)}}/><InputLabel className={classes.scenarioListlabel} onClick={() => {setScenarioResultList(Scenario.Boots);}}>Boots[{bootMarkers.length}]</InputLabel></Grid>}
                            {towEligibleMarkers.length > 0 && <Grid container direction="row"><CircleIcon style={{color: getScenarioColor(Scenario.TowEligible)}}/><InputLabel className={classes.scenarioListlabel} onClick={() => {setScenarioResultList(Scenario.TowEligible);}}>Tow Eligible[{towEligibleMarkers.length}]</InputLabel></Grid>}
                            {runAwayMarkers.length > 0 && <Grid container direction="row" item><CircleIcon style={{color: getScenarioColor(Scenario.Runaways)}}/><InputLabel className={classes.scenarioListlabel} onClick={() => {setScenarioResultList(Scenario.Runaways);}}>Runaways[{runAwayMarkers.length}]</InputLabel></Grid>}
                        </Grid>
                        <Grid container alignItems="flex-start" direction='row' spacing={1}>
                            {state.displayList?.length > 0 && <Button variant="contained" className={classes.searchButton} onClick={() => onClearMap()}>Export</Button>}
                        </Grid>
                    </Typography>
                    <Divider />
                    {mapState.listIsLoading && <CircularProgress />}
                </Grid>
                <Grid item xs={12} md={12} className={classes.listContainer}>
                    <Grid container>
                        {
                            (
                                markers.length === 0) &&
                            <Grid item xs={12} md={12}>
                                <Typography variant="body1" className={classes.startSearchMessage}>
                                    No results found, please try with different search parameters.
                                </Typography>
                            </Grid>
                        }
                        {
                            state.displayList?.length > 0 &&
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        Plate-State
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        /Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        Read Type
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        /Device id
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} justify="center">
                                    <Typography variant="subtitle1"  className={classes.listTitle}>
                                        DT Vehicle Seen
                                    </Typography>
                                    <Typography variant="subtitle1"  className={classes.listTitle}>
                                        /DT Tow Eligible
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {
                            pagination == null &&
                            <Box style={{maxHeight: '75vh', overflow: 'auto', width: '100%'}} >
                            {
                                state.displayList?.map((marker: MapMarker) =>
                                    <React.Fragment key={marker.id}>
                                        <Grid item xs={12} md={12} container alignContent="stretch" alignItems="stretch">
                                            <MarkerItemCard
                                                id={marker.id}
                                                date={moment(marker.dateRecord).format('YYYY/MM/DD hh:mm a')}
                                                plate={marker.plate}
                                                plateState={marker.plateState}
                                                type={MapFilterDataType[marker.type]}
                                                marker={marker}
                                                mapState={mapState}
                                                //numRunawaysSinceLastPayment={marker.numRunawaysSinceLastPayment}
                                                //vioDeviceID={marker.vioDeviceID}
                                                onClick={onSelectMarker} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            </Box>
                        }
                        {
                            pagination != null &&
                            
                            state.displayList?.map((marker: MapMarker) =>
                                <React.Fragment key={marker.id}>
                                    <Grid item xs={12} md={12}  container alignContent="stretch" alignItems="stretch">
                                        <MarkerItemCard
                                            id={marker.id}
                                            date={moment(marker.dateRecord).format('YYYY/MM/DD hh:mm a')}
                                            plate={marker.plate}
                                            plateState={marker.plateState}
                                            type={MapFilterDataType[marker.type]}
                                            marker={marker}
                                            mapState={mapState}
                                            onClick={onSelectMarker} />
                                    </Grid>
                                </React.Fragment>
                            )
                            
                        }
                    </Grid>
                </Grid>
                {
                    (pagination != null && filteredMarkers.length > 0 && pagination.totalPages > 1) &&
                    <Grid item xs={12} md={12} className={classes.paginationContainer} container justify="center">
                        <Divider />
                        <Pagination
                            count={pagination.totalPages}
                            color="primary"
                            onChange={pageChangeHandler}
                            className={classes.pagination}
                            shape="rounded"
                            variant="outlined" />
                    </Grid>
                }
            </Grid>
            <MarkerDetail marker={selectedMarker} openModal={state.openModal} selectedCustomerSubDomain={selectedCustomerSubDomain} onClose={onClearSelectedMarker} />
        </Paper>
    );
};
