import AverageData from '../../Commons/AverageCard/AverageData';
import { DashboardModel, DataGridRow } from '../../Commons/types';

export default class HelpCenterSummary implements DashboardModel {
    isLoading: boolean;
    error: string | null;
    callsTaken: AverageData;
    callsTotalTime: AverageData;
    callsTalkTime: AverageData;
    callsWrapUpTime: AverageData;
    callsHoldTime: AverageData;
    // speedToAnswer: AverageData;
    amountCollected: AverageData;
    paymentsTaken: AverageData;
    columnDefinitions: Array<{ headerName: string; field: string }>;
    rows: Array<DataGridRow>;
    receivedDetails: Array<{ timeDuration: number; numberOfEvents: number }>;
    receivedTotal: number;
    receivedAvg: number;
    answeredDetails: Array<{ timeDuration: number; numberOfEvents: number }>;
    answeredTotal: number;
    answeredAvg: number;
    abandonedDetails: Array<{ timeDuration: number; numberOfEvents: number }>;
    abandonedTotal: number;
    abandonedAvg: number;

    constructor (
        isLoading?: boolean,
        error?: string | null,
        callsTaken?: AverageData,
        callsTotalTime?: AverageData,
        callsTalkTime?: AverageData,
        callsWrapUpTime?: AverageData,
        callsHoldTime?: AverageData,
        amountCollected?: AverageData,
        paymentsTaken?: AverageData,
        columnDefinitions?: Array<{ headerName: string; field: string }> | [],
        rows?: Array<DataGridRow> | [],
        receivedDetails?: Array<{ timeDuration: number; numberOfEvents: number }> | [],
        receivedTotal?: number,
        receivedAvg?: number,
        answeredDetails?: Array<{ timeDuration: number; numberOfEvents: number }> | [],
        answeredTotal?: number,
        answeredAvg?: number,
        abandonedDetails?: Array<{ timeDuration: number; numberOfEvents: number }> | [],
        abandonedTotal?: number,
        abandonedAvg?: number
        
        ) {
            this.isLoading = isLoading ?? false;
            this.error = error ?? null;
            this.callsTaken = callsTaken ?? new AverageData;
            this.callsTotalTime = callsTotalTime ?? new AverageData;
            this.callsTalkTime = callsTalkTime ?? new AverageData;
            this.callsWrapUpTime = callsWrapUpTime ?? new AverageData;
            this.callsHoldTime = callsHoldTime ?? new AverageData;
            this.amountCollected = amountCollected ?? new AverageData;
            this.paymentsTaken = paymentsTaken ?? new AverageData;
            this.columnDefinitions = columnDefinitions ?? [];
            this.rows = rows ?? [];
            this.receivedDetails = receivedDetails ?? [];
            this.receivedTotal = receivedTotal ?? 0;
            this.receivedAvg = receivedAvg ?? 0;
            this.answeredDetails = answeredDetails ?? [];
            this.answeredTotal = answeredTotal ?? 0;
            this.answeredAvg = answeredAvg ?? 0;
            this.abandonedDetails = abandonedDetails ?? [];
            this.abandonedTotal = abandonedTotal ?? 0;
            this.abandonedAvg = abandonedAvg ?? 0;
    }
}
