import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    modalContent: {
        background: 'white',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            width: '310px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '420px',
        },
        padding: '10px',
        '&:focus': {
            outline: 'none',
        },
        '& > .MuiTypography-root': {
            marginBottom: theme.spacing(.5),
        },
        '& > .MuiButtonBase-root': {
            marginRight: theme.spacing(1),
        },
    },
    hideComponent:{
        display: 'none'
    },
    map:{
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            height: '300px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '350px',
        },
        backgroundColor: '#fff',
        position: 'relative',
        boxSizing: 'border-box',
    }
}));
