import React from 'react';
import { Chip, Box, Grid } from '@material-ui/core';
import { useStyles } from './ColorSchemeStyles';

export default function ColorScheme(): JSX.Element {
    const classes = useStyles();

    return (
        <Grid className={classes.colorCodes} container justify="space-between" alignItems="center" alignContent="center">
            <Grid item xs={4} md={4} lg={12}>
                <Chip label="Good" size="small" className={classes.successCode} />
            </Grid>
            <Grid item xs={4} md={4} lg={12}>
                <Chip label="Warning" size="small" className={classes.warningCode} />
            </Grid>
            <Grid item xs={4} md={4} lg={12}>
                <Chip label="Requires Attention" size="small" className={classes.dangerCode} />
            </Grid>
        </Grid>
    );
}
