import { MapDashboardStateModel, MapSearchResult, MapFilterSource, MapMarker, EnforcementDataType, EnforcementZoneResult, EnforcementZoneAssignationResult, BootviewUser } from './mapDashboardStateV2';
import { PayloadAction } from '@reduxjs/toolkit';
import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';
import { DashboardFilterState } from '../../state/DashboardFilterState';

const setLoadingAction = (state: MapDashboardStateModel, action: PayloadAction<boolean>): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setErrorAction = (state: MapDashboardStateModel, action: PayloadAction<string | null>): MapDashboardStateModel => {
    return {
        ...state,
        isLoading: false,
        error: action.payload,
    };
};

const setFilterSourceAction = (state: MapDashboardStateModel, action: PayloadAction<MapFilterSource>): MapDashboardStateModel => {
    return {
        ...state,
        filterSource: {
            ...action.payload,
            bootingOfficers: [...action.payload.bootingOfficers],
            parkingAreas: [...action.payload.parkingAreas],
        },
    };
};

const applyFilterAction = (state: MapDashboardStateModel, action: PayloadAction<MapFilterState>): MapDashboardStateModel => {
    const filter: MapFilterState = {
        ...action.payload,
        selectedAgentsToSearchBy: [...action.payload.selectedAgentsToSearchBy],
        selectedDataTypes: [...action.payload.selectedDataTypes],
    };

    return {
        ...state,
        isLoading: true,
        filter: filter,
    };
};

const changePageOnFilterAction = (state: MapDashboardStateModel, action: PayloadAction<number>): MapDashboardStateModel => {
    const filter: MapFilterState = {
        ...state.filter,
        currentPage: action.payload,
    };

    let paginatedMarkers = [...state.markers];
    if (state.markers.length > filter.pageSize) {
        const start = (filter.currentPage - 1) * filter.pageSize;
        paginatedMarkers = [...state.markers].slice(start, (start + filter.pageSize));
    }

    return {
        ...state,
        // isLoading: true,
        filter,
        paginatedMarkers: paginatedMarkers,
    };
};

const selectMarkerAction = (state: MapDashboardStateModel, action: PayloadAction<string>): MapDashboardStateModel => {
    const selectedMarker = state.markers.find(a => a.lprDataID != null && a.lprDataID.toString() == action.payload);

    return {
        ...state,
        selectedMarker: selectedMarker,
    };
};

const unselectMarkerAction = (state: MapDashboardStateModel, action: PayloadAction<void>): MapDashboardStateModel => {
    return {
        ...state,
        selectedMarker: undefined,
    };
};

const updateMapStateAction = (state: MapDashboardStateModel, action: PayloadAction<({mapSearchResult: MapSearchResult, dashboardFilterState: DashboardFilterState})>): MapDashboardStateModel => {
    const pagination = action.payload.mapSearchResult.pagination;
    const markers = [...action.payload.mapSearchResult.results];

    //mark the last read of each officer
    /*state.filterSource.bootingOfficers.forEach(officer=>{
        const markerList = markers.filter(x => x.agentId == officer.bootingOfficerId);
        if(markerList.length>0)
        {
            markerList[0].isLastPosition = true
        }
    });*/

    let paginatedMarkers = [...markers];
    if (markers.length > pagination.pageSize) {
        const start = (pagination.currentPage - 1) * pagination.pageSize;
        paginatedMarkers = [...markers].slice(start, (start + pagination.pageSize));
    }

    let showDebtRange = false;

    const markersResult: MapMarker[] = [];

    const filteredMarkers = [...markers];
    let identifier = 1;
    filteredMarkers.forEach(marker => {
        if(marker.bootRecord?.bootRecordStatusId == 1)//1 indicates booted, so we don't want that on optiroute
        {
            return;
        }

        marker.id = identifier;
        marker.occurrences = filteredMarkers.filter(mark =>{return (mark.licPlate == marker.licPlate)}).length;
        identifier++;
        if(marker.amtDue != null)
        {
            showDebtRange = true;
        }

         action.payload.dashboardFilterState.occurrences.forEach(occurrenceOption =>{
            if(occurrenceOption == '1' && ((marker.occurrences > 0 && marker.occurrences <= 5) || marker.occurrences == null))
            {
                markersResult.push(marker);
            }
            if(occurrenceOption == '2' && marker.occurrences >= 6 && marker.occurrences <= 15)
            {
                markersResult.push(marker);
            }
            if(occurrenceOption == '3' && marker.occurrences >= 16)
            {
                markersResult.push(marker);
            }
        });

    });
    //filteredMarkers = filteredMarkers.filter(item => item.type == EnforcementDataType.Boot || item.type == EnforcementDataType.Hit);

    return {
        ...state,
        isLoading: false,
        error: null,
        markers: markersResult,
        vehiclesInHotlist: action.payload.mapSearchResult.vehiclesInHotlist,
        debtVehiclesInHotlist: action.payload.mapSearchResult.debtVehiclesInHotlist,
        debtVehiclesInHotlistSummary: action.payload.mapSearchResult.debtVehiclesInHotlistSummary,
        paginatedMarkers: paginatedMarkers,
        filteredMarkers: markersResult,
        pagination: action.payload.mapSearchResult.pagination,
        showDebtRange: showDebtRange
    };
};

const updateLiveMapStateAction = (state: MapDashboardStateModel, action: PayloadAction<MapSearchResult>): MapDashboardStateModel => {
    const pagination = action.payload.pagination;
    const markers = [...action.payload.results];
    const liveMarkers: Array<MapMarker> = [];

    //mark the last read of each officer
    state.filterSource.bootingOfficers.forEach(officer=>{
        const markerList = markers.filter(x => x.agentId == officer.bootingOfficerId);
        if(markerList.length>0)
        {
            markerList[0].isLastPosition = true;
            liveMarkers.push(markerList[0]);
        }
    });

    

    return {
        ...state,
        isLoading: false,
        error: null,
        liveMarkers: liveMarkers
    };
};

const updateEnforcementZonesAction = (state: MapDashboardStateModel, action: PayloadAction<EnforcementZoneResult>): MapDashboardStateModel => {
    const zones = [...action.payload.results];

    return {
        ...state,
        isLoading: false,
        error: null,
        enforcementZones: zones
    };
};

const updateEnforcementZonesAssignationAction = (state: MapDashboardStateModel, action: PayloadAction<EnforcementZoneAssignationResult>): MapDashboardStateModel => {
    const zones = [...action.payload.results];

    return {
        ...state,
        isLoading: false,
        error: null,
        enforcementAssignationZones: zones
    };
};

const updateBootviewUserAction = (state: MapDashboardStateModel, action: PayloadAction<BootviewUser[]>): MapDashboardStateModel => {
    const users = [...action.payload];

    //bootview seems to be sending bootview users ids with out dashes some times, we make sure that ids always have them.
    if (users.length > 0 && !users[0].id.includes('-')) {
        users.forEach(x=> {
            x.id= x.id.replace(/([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,'$1-$2-$3-$4-$5');
        });
    }

    return {
        ...state,
        isLoading: false,
        error: null,
        bootviewUsers: users
    };
};

export { setLoadingAction, setErrorAction, updateMapStateAction, selectMarkerAction, unselectMarkerAction, updateLiveMapStateAction,  applyFilterAction, changePageOnFilterAction, setFilterSourceAction, updateEnforcementZonesAction, updateEnforcementZonesAssignationAction, updateBootviewUserAction };