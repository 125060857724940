import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '&.success': {
            '& .MuiDivider-root': {
                // backgroundColor: theme.palette.success.main,
                backgroundColor: '#00c853',
            },
            '& .value, .file-date-message': {
                color: theme.palette.success.main,
                // color: '#00c853',
            },
        },
        '&.error': {
            '& .MuiDivider-root': {
                // backgroundColor: theme.palette.error.main,
                backgroundColor: '#dd2c00',
            },
            '& .value, .file-date-message': {
                color: theme.palette.error.main,
                // color: '#dd2c00',
            },
        },
    },
    cardContent: {
        padding: theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
        },
    },
    mainLabels: {
        '& .MuiTypography-root': {
            fontWeight: '500',
        },
    },
    alert: {
        marginTop: theme.spacing(1),
    },
}));
