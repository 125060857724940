import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';

export interface MapDashboardStateModel {
    isLoading: boolean;
    error: string | null;
    filter: MapFilterState;
    markers: MapMarker[];
    paginatedMarkers: MapMarker[];
    filteredMarkers: MapMarker[];
    latestMarkers: MapMarker[];
    pagination: PaginationResult;
    selectedMarker: MapMarker | null | undefined;
    filterSource: MapFilterSource;
    listIsLoading: boolean;
    bootExtraInfo: BootRecordExtra[];
    savePreviousSearch: boolean;
}

export type MapMarker = {
    id: string;
    plate: string;
    plateState: string;
    type: EnforcementDataType;
    latitude: number;
    longitude: number;
    importSource: string;
    dateRecord: Date;
    customerId: string;
    customerCode: string;
    customerFullName?: string;
    agentId: string;
    agentFullName: string;
    locationId: string;
    locationName: string;
    rejectionReason: string;
    bootRecord?: BootRecord;
    isLastPosition: boolean;
    scenarioType: Scenario;
}

export type MarkerExport = {
    plate: string;
    plateState: string;
    violationNumber: string;
    bootSerialNumber: string;
    bootRecordStatusLabel: string;
    latitude: number;
    longitude: number;
    dateRecord: Date;
    customerCode: string;
    agentFullName: string;
    locationName: string;
    rejectionReason: string;
    towEligibleDate?: Date;
    numberRunAways: number;
}

export type BootRecord = {
    violationNumber: string;
    collectionAttemptId: string;
    violationUrl: string;
    filesImagesUrl: string;
}

export type BootRecordExtra = {
    sticker_Id: string;
    boot_Serial_Number: string;
    boot_Record_Status_Label: string;
    number_RunAways: number;
    tow_Eligible_Date: Date;
}

export type BootExtraMapSearchResult = {
    results: BootRecordExtra[];
}

export enum EnforcementDataType {
    Scan = 0,
    Hit = 1,
    Boot = 2
}

export type MapSearchResult = {
    results: MapMarker[];
    pagination: PaginationResult;
}

export type Pagination = {
    currentPage: number;
    pageSize: number;
}

export type PaginationResult = {
    totalPages: number;
    totalItems: number;
} & Pagination;

export type MapFilterSource = {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    customerLongitude: number;
    customerLatitude: number;
}

export type BootingOfficer = {
    bootingOfficerId: string;
    name: string;
}

export type ParkingArea = {
    parkingAreaId: string;
    name: string;
}

export enum MapFilterSearchBy {
    All = 0,
    Agents = 1,
    Locations = 2
}

export enum MapFilterDataType {
    Scan = 0,
    Hit = 1,
    Booted = 2
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export const NoLocationGuid = '00000000-0000-0000-0000-000000000001';
export const NoLocationName = 'No Location';

export enum MapLayerType {
    Reads = 1,
    Heat = 2,
    Route = 3,
}

export enum Scenario {
    Scans = 0,
    Hits = 1,
    Boots = 2,
    Runaways = 3,
    QueueTow = 4,
    QueueRelease = 5,
    TowEligible = 6,
}

export type EnforcementZone = {
    enforcementZoneId: string;
    zoneName: string;
    coordinates: string;
    points: string;
    customerId: string;
}

export type EnforcementZoneAssignationResult = {
    success: boolean;
    message: string;
    results: EnforcementZoneAssignation[];
}

export type EnforcementZoneAssignation = {
    enforcementZoneAssignationId: string;
    enforcementZoneIds: string;
    officerIds: string;
    weekDays: string;
    customerId: string;
}

export enum EnforcementZoneModificationType {
    Insert = 0,
    Update = 1,
    Delete = 2,
    Select = 3
}

export enum AssignEnforcementZoneModificationType {
    Select = 0,
    Insert = 1,
    Delete = 2
}

export const PointType = 'point';
export const ZoneType = 'zone';
export const all = 'All';