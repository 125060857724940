import React from 'react';
import { ButtonBase, Card, CardContent, Typography, Grid, Divider } from '@material-ui/core';
import { useStyles } from './MarkerItemCardStyles';
import { MapDashboardStateModel, MapFilterDataType, MapMarker } from '../../state/mapDashboardStateV2';
import moment from 'moment';

export type MarkerItemCard = {
    id: string;
    date: string;
    plate: string;
    plateState: string;
    mapState?:  MapDashboardStateModel;
    marker:  MapMarker;
    type: string;
    onClick: (selectedId: string) => void;
}

const MarkerItemCard = (props: MarkerItemCard): JSX.Element => {
    const { id, date: dateRecord, plate, plateState, type, onClick, mapState, marker } = props;
    const classes = useStyles();

    let vioDeviceID  = '';
    let brStatus  = '';
    let numbRunaways  = '';
    let towEligibleDate  = '';

    if(type == MapFilterDataType[MapFilterDataType.Booted] && marker.bootRecord != null)
    {
        const bootExtraInfoList = mapState?.bootExtraInfo;
        if(bootExtraInfoList != null)
        {
            const bootExtraInfo = bootExtraInfoList.find(x => x.sticker_Id == marker.bootRecord?.violationNumber);
            if(bootExtraInfo != null)
            { 
                vioDeviceID = bootExtraInfo.boot_Serial_Number;
                brStatus = bootExtraInfo.boot_Record_Status_Label;
                numbRunaways = bootExtraInfo.number_RunAways.toString();
                towEligibleDate = bootExtraInfo.tow_Eligible_Date != null ? moment(bootExtraInfo.tow_Eligible_Date).format('YYYY/MM/DD hh:mm a') : '';
            }
        }
    }

    let fullPlate = plate;
    if (plateState) {
        fullPlate += `-${plateState}`;
    }

    return (
        <ButtonBase
            key={id}
            className={classes.root}
            focusRipple
            onClick={(e) => onClick(id)}>
            <Card className={classes.card} variant="outlined">
                <CardContent className={classes.cardContent} style={{ paddingBottom: '8px' }}>
                    <Grid container>
                        <Grid item xs={5}>
                            <Typography variant="body1" className={classes.plate}>
                                {fullPlate}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" component="h2" className={type == MapFilterDataType[0] ? classes.scan : (MapFilterDataType[MapFilterDataType.Hit] ? classes.hit : classes.boot)} >
                                {type}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} container alignContent="center" alignItems="center" justify="flex-end">
                            <Typography variant="body2">
                                {dateRecord}
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        type == MapFilterDataType[MapFilterDataType.Booted] && 
                        <Grid container>
                           <Grid item xs={5}>
                                <Typography variant="subtitle1" className={classes.subText}>
                                    {brStatus}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" className={classes.subText}>
                                    {vioDeviceID}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} container alignContent="center" alignItems="center" justify="flex-end" >
                                <Typography variant="subtitle1" component="h2" className={classes.subText}>
                                    {towEligibleDate}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                        type == MapFilterDataType[MapFilterDataType.Booted] && 
                        <Grid container>
                           <Grid item xs={5}>
                                <Typography variant="subtitle1" className={classes.subText}>
                                    {numbRunaways != '0'  ? 'Prior Runaway, ' +numbRunaways  : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </CardContent>
            </Card>
        </ButtonBase>
    );
};

export default MarkerItemCard;
