import { AppBar, Box, Button, Grid, Modal, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import { Dashboard as DashboardIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import React, { lazy, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ApplicationState } from '../../app/store';
import { removeTimeFromDate } from '../dashboard-tile-builder/state/utils';
import TileController from '../dashboard-tile-builder/templates/TileController';
import { TileEditMode } from '../dashboard-tile-builder/templates/types';
import { ChartType, TileModel, TileParamaterValues, TileSettingsTestValues } from '../dashboard-tile-builder/tileBuilderTypes';
import { DashboardFilterBar } from '../dashboards/Commons';
import LoadingModal from '../dashboards/Commons/LoadingModal/LoadingModal';
import { DashboardFilterState } from '../dashboards/state/DashboardFilterState';
import theme from '../themes/base';
import { deleteDashboard, fetchDashboard } from './dashboardViever.API';
import { useStyles } from './dashboardViewer.styles';
import { CustomDashboard } from './types';


export const DashboardViewer = (): JSX.Element => {
    const classes = useStyles();

    const dashboardFilter = useSelector((state: ApplicationState) => state.dashboardFilter as DashboardFilterState);

    const routeParams = useParams() as { dashboardId: string };
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [customDashboard, setCustomDashboard] = useState({} as CustomDashboard);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    
    const mapToParamValues = (dashboardFilter: DashboardFilterState): TileParamaterValues => {
        return {
            customerId: dashboardFilter.customerId,
            date: removeTimeFromDate(dashboardFilter.date),
            dateTo: removeTimeFromDate(dashboardFilter.dateTo ?? dashboardFilter.date)
        };
    };

    const [paramValues, setParamValues] = useState<TileParamaterValues>(mapToParamValues(dashboardFilter));

    const importTile = (name: string) => lazy(() => import('../dashboard-tiles/' + name + '/' + name).then(module => ({ default: module.default })));

    useEffect(() => {
        setIsLoading(true);

        fetchDashboard(routeParams.dashboardId).then((dashboard: CustomDashboard) => {
            importCustomDashboardTiles(dashboard);
            setCustomDashboard({ ...dashboard });
        }).finally(() => setIsLoading(false))
    }, [routeParams.dashboardId]);

    const importCustomDashboardTiles = (dashboard: CustomDashboard) => {
        if (dashboard.tiles) {
            dashboard.tiles.forEach((tile) => {
                tile.component = importTile(tile.name);
            })
        }
    }

    const editButtonClickHandler = () => {
        history.push(`/customer-dashboards/builder/${routeParams.dashboardId}`);
    };

    const deleteButtonClickHandler = () => {
        setIsLoading(true);

        deleteDashboard(routeParams.dashboardId).then((success) => {
            history.push('/');
        }).finally(() => setIsLoading(false));
    };
    
    return (
        <div style={{ height: '100%' }} id="dashboardViewerWrapper" >
            <AppBar position={useMediaQuery(theme.breakpoints.up('md')) ? 'fixed' : 'relative'} className={classes.appBar} elevation={1}>
                <Toolbar>
                    <Grid container spacing={3} justify="space-between" alignItems="center" alignContent="center">
                        <Grid item xs={12} md={3} container alignItems="center" alignContent="center">
                            <DashboardIcon className={classes.iconDashboard} />
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                {customDashboard.name ? customDashboard.name : 'Loading...'}
                                <Typography variant="h6" color="inherit" className={classes.subTitle}>
                                    by Stat-Trak
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} container justify="flex-end" alignItems="center" alignContent="center">
                            <Grid item xs={12}>
                                <DashboardFilterBar
                                    onApply={() => setParamValues(mapToParamValues(dashboardFilter))}
                                    applyEnabled={!isLoading}
                                    excludeAverage={true}
                                    filterType={'range'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {
                useMediaQuery(theme.breakpoints.up('md')) && <Toolbar className={classes.emptyToolbar} />
            }
            <Box className={classes.root} style={{ padding: 0 }}>
                <Grid container spacing={0} direction="row" justify="center" alignItems="stretch" alignContent="stretch" style={{ height: '100%' }} id="builderContent">
                    <Grid item xs={12} md={12} style={{ padding: '16px' }}>
                        <Box id="draggableContainer" style={{ height: '100%', width: '100%', position: 'relative' }}>
                            {
                                customDashboard.tiles && customDashboard.tiles.map(tile => {
                                    // console.log('Tile on dashboard viewer >>>', tile)
                                    return (
                                        <TileController
                                            key={tile.tileId}
                                            tileModel={{
                                                ...(tile.tileModel ?? ({} as TileModel)),
                                                positionX: tile.positionX,
                                                positionY: tile.positionY,
                                                height: tile.height != '-1' && tile.height != 'auto' ? parseInt(tile.height) : (tile.tileModel?.height || 0),
                                                width: tile.width != '-1' && tile.width != 'auto' ? parseInt(tile.width) : (tile.tileModel?.width || 0),
                                            }}
                                            chartType={tile.chartType}
                                            mode={TileEditMode.None}
                                            paramValues={paramValues}
                                            onChartTypeSelected={(chartType: ChartType) => console.log('do nothing')}
                                        />
                                    )
                                })
                            }
                        </Box>
                        {/* TODO: SHOW ONLY IF ENOUGH PERMISSIONS, MAKE BUTTONS ALSO FLOATING TRANSPARENT */}
                        <Box style={{ marginTop: '16px', marginBottom: '16px', paddingBottom: '16px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.saveButton}
                                startIcon={<EditIcon />}
                                onClick={() => editButtonClickHandler()}>
                                Edit
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.deleteButton}
                                startIcon={<DeleteIcon />}
                                onClick={() => setShowDeleteModal(true)}>
                                Delete
                            </Button>
                        </Box>
                        <Modal
                            open={showDeleteModal}
                            onClose={() => setShowDeleteModal(false)}
                            className={classes.deleteModal}
                            aria-labelledby="delete-modal-title"
                            aria-describedby="delete-modal-description"
                        >
                            <div className={classes.paperModal}>
                                <Grid container justify="center" alignItems="center" alignContent="center">
                                    <Grid item xs={12}>
                                        <Typography gutterBottom>
                                            Are you sure you want to DELETE this dashboard?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.deleteButton}
                                            onClick={() => deleteButtonClickHandler()}>
                                            DELETE
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setShowDeleteModal(false)}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                        {
                            isLoading &&
                            <LoadingModal
                                isOpen={isLoading}
                                container={(() => document.getElementById('customDashboardContainer'))()}
                                message="Loading, please wait..."
                            />
                        }
                    </Grid>
                </Grid>
            </Box>
        </div >
    );
};

export default DashboardViewer;
