/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import 'ol/ol.css';
import Feature, { FeatureLike } from 'ol/Feature';
import OLMap from 'ol/Map';
import View from 'ol/View';
import { Point, LineString, Geometry, Polygon } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer, Heatmap as HeatmapLayer, VectorImage } from 'ol/layer';
import {defaults as controlDeafaults, Control} from 'ol/control'
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import { unByKey } from 'ol/Observable';
import { click, pointerMove } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import {defaults as interactionDefaults, Modify, Snap} from 'ol/interaction';
import { MapMarker, MapLayerType, MapDashboardStateModel, MapFilterDataType, EnforcementZoneAssignation, ZoneType, PointType, all, EnforcementZone } from '../../state/mapDashboardStateV2';
import { Paper } from '@material-ui/core';
import routeLayerStyles from './routeLayerStyles';
import readsLayerStyle, { getMapZoneDrawingStyle, getSelectedStyle } from './readsLayerStyles';
import { MapFilterState } from '../FilterBar/mapFilterState';
import { Overlay } from 'ol';
import OverlayPositioning from 'ol/OverlayPositioning';
import { useStyles } from './mapStyles';
import {MouseWheelZoom} from 'ol/interaction';
import { Style } from 'ol/style';
import { StyleLike } from 'ol/style/Style';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { v4 as uuidv4 } from 'uuid';
import GeometryType from 'ol/geom/GeometryType';
import { Coordinate } from 'ol/coordinate';
import { ModifyEvent } from 'ol/interaction/Modify';
import { useDispatch } from 'react-redux';
import { updateMarkersByZone } from '../../state/mapScenariosDashboardSlice';


type MapProps = {
    layerType: MapLayerType;
    markers: MapMarker[];
    latestMarkers: MapMarker[];
    zoom?: number;
    onSelectedMarker: (selectedMarkerId: string) => void;
    selectedMarker?: MapMarker | null;
    onDrawEnd: () => void;
    center: Array<number>;
    customerId: string;
    mapFilter?: MapFilterState;
    mapFilterDashboard?: MapDashboardStateModel;
    drawActive?: boolean;
    drawType: string;
    modifyActive?: boolean;
    zoneName: string;
    showZoneLayer: boolean;
    isDeleteActive: boolean;
    enforcementZones: EnforcementZone[];
    weekDay: string;
};

type MapState = {
    map: OLMap;
    layers: {
        vector: VectorImage;
        heat: HeatmapLayer;
        route: VectorLayer;
        zones: VectorLayer;
    };
    zoom: number;
    center: Array<number>;
    customerId: string;
}


type ZoomControlAttributes = {
    id: string;
    zoom: number;
};

// const defaultCenter = [-100.964709, 39.259336];



const Map = forwardRef((props: MapProps): JSX.Element => {
    //markers hold previous and current search
    //latestMarkers holds only  current search
    const { layerType, markers, latestMarkers, zoom = 13, center, customerId, mapFilter, onSelectedMarker, selectedMarker, mapFilterDashboard, onDrawEnd, drawActive, drawType, modifyActive, zoneName, showZoneLayer, isDeleteActive, enforcementZones, weekDay} = props;

    const mapHeight = window.innerWidth >= 992 ? window.innerHeight - 178 : 400;

    const isScans = mapFilter != null ? mapFilter.selectedDataTypes.includes(MapFilterDataType.Scan as unknown as string) : false;

    const SELECTE_FEATURE = 'isSelectedFeature';

    const classes = useStyles();
    const dispatch = useDispatch();

    const zoomControlList: Array<ZoomControlAttributes> = [{id:'zoom-1', zoom:16}, {id:'zoom-2', zoom: 14.5}, {id:'zoom-3', zoom: 14}, {id:'zoom-4', zoom: 13.5}, {id:'zoom-5', zoom: 13},
                                                            {id:'zoom-6', zoom: 12.5}, {id:'zoom-7', zoom: 12}, {id:'zoom-8', zoom: 11.5}, {id:'zoom-9', zoom: 11}, {id:'zoom-10', zoom: 10}];
    let zoomControlSelected: ZoomControlAttributes;
    zoomControlSelected= zoomControlList[4];


    const [state, setState] = useState({
        map: new OLMap({
            layers: [
                new TileLayer({
                    source: new OSM({})
                    /*source: new XYZ({
                        attributions:
                          'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                          'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
                        url:
                          'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                          'World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
                    })*/
                }),
            ],
            view: new View({
                center: center,
                zoom: zoom,
                projection: 'EPSG:4326',
            }),
            controls: controlDeafaults({
                zoom: false
            }),
            interactions: interactionDefaults({mouseWheelZoom:false})
        }),
        layers: {
            vector: new VectorImage({}),
            heat: new HeatmapLayer({}),
            route: new VectorLayer({}),
            zones: new VectorLayer({/*renderBuffer:100000*/source: new VectorSource({}), 
                style: getMapZoneDrawingStyle(null),
            }),
        },
        zoom: zoom,
        center: center,
        customerId: customerId,
    } as MapState);

  

    const onWindowResizeHandler = (): void => {
        const height = window.innerWidth >= 992 ? window.innerHeight - 178 : 400;
        const map = document.getElementById('map');
        if (map) map.style.height = `${height}`;
    };

    const addLayersToMap = (): void => {
        state.layers.vector.set('source', new VectorSource({}), false);
        state.layers.vector.set('name', 'reads');
        state.map.addLayer(state.layers.vector);

        state.layers.heat.set('source', new VectorSource({}), false);
        state.layers.heat.set('name', 'heat');
        state.layers.heat.setOpacity(0.4);
        state.map.addLayer(state.layers.heat);

        state.layers.route.set('source', new VectorSource({}), false);
        state.layers.route.set('name', 'reads');
        state.map.addLayer(state.layers.route);

        state.map.addLayer(state.layers.zones);
    };

    //custom control
    const setCustomContols = (): void =>{
         
        const zoomElementList: Array<HTMLElement> = [];
        
        //zoom in label
        const zoomInElement = document.getElementById('zoom-in') as HTMLElement;
        zoomInElement.addEventListener('click', function add(): void {handleZoomStep('DOWN')}, false);
        const zoomInControl = new Control({element:zoomInElement});
        state.map.addControl(zoomInControl);

        //zoom controls
        zoomControlList.forEach((zoomControl)=>{
            const zoomElement = document.getElementById(zoomControl.id) as HTMLElement;
            zoomElement.addEventListener('click', function add(): void {handleZoomChanged(zoomControl, zoomElement)}, false);
            const zoomControlElement = new Control({element:zoomElement});
            state.map.addControl(zoomControlElement);
            zoomElementList.push(zoomElement);
        });

        //zoom out label
        const zoomOutElement = document.getElementById('zoom-out') as HTMLElement;
        zoomOutElement.addEventListener('click', function add(): void {handleZoomStep('UP')}, false);
        const zoomOutControl = new Control({element:zoomOutElement});
        state.map.addControl(zoomOutControl);


        //handle zoom changed
        const handleZoomChanged = (zoomControl: ZoomControlAttributes, currentZoomElement: HTMLElement): void => {
            state.map.getView().setZoom(zoomControl.zoom);
            zoomControlSelected = zoomControl;

            zoomElementList.forEach((zoomElement) =>{
                if(zoomElement.id  == currentZoomElement.id)
                {
                    zoomElement.classList.add('selectedFixedZoom');
                }
                else
                {
                    zoomElement.classList.remove('selectedFixedZoom');
                }
            });
        };

        //handle zoom step
        const handleZoomStep = (direction: string): void => {
            if(direction== 'UP')
            {
                const selectedIndex = zoomControlList.indexOf(zoomControlSelected);
                if(selectedIndex != zoomControlList.length -1)//only increase if we are not at the top already
                {
                    const newZoomControlSelected = zoomControlList[selectedIndex+1];
                    const zoomElement = document.getElementById(newZoomControlSelected.id) as HTMLElement;
                    handleZoomChanged(newZoomControlSelected, zoomElement);
                }
            }
            else
            {
                const selectedIndex = zoomControlList.indexOf(zoomControlSelected);
                if(selectedIndex != 0)//only decrease if we are not at the top already
                {
                    const newZoomControlSelected = zoomControlList[selectedIndex-1];
                    const zoomElement = document.getElementById(newZoomControlSelected.id) as HTMLElement;
                    handleZoomChanged(newZoomControlSelected, zoomElement);
                }
            }
        };

    }

    useEffect(() => {
        const readLayer = state.map.getLayers().getArray().find(layer => layer.get('name') == 'reads') as VectorImage;
        const readSource = readLayer?.getSource() as VectorSource<Geometry>;
        const sourceFeatures = readSource?.getFeatures();
        const feature = sourceFeatures?.find(feature => { return feature.getId() == selectedMarker?.id}) as Feature;

        highlightFeature(feature);
    }, [selectedMarker]);


    

    const clearSelectedStyle = (): void =>{
        const readLayer = state.map.getLayers().getArray().find(layer => layer.get('name') == 'reads') as VectorImage;
        const readSource = readLayer?.getSource() as VectorSource<Geometry>;
        const sourceFeatures = readSource?.getFeatures();
        const selectedFeature = sourceFeatures.find(x => x.get(SELECTE_FEATURE));
        if(selectedFeature != null)
        {
            selectedFeature.setStyle(readsLayerStyle(selectedFeature.get('marker') as MapMarker, mapFilter));
            selectedFeature.set(SELECTE_FEATURE, false);
        }
    }
    

    const  highlightFeature = (selectedFeature: Feature): void =>{
        if(selectedFeature != null)
        {
            clearSelectedStyle();
            selectedFeature.setStyle(getSelectedStyle());
            selectedFeature.set(SELECTE_FEATURE, true);
        }
     }

    const addEventListenersToMap = (): void => {
        // select interaction working on "click"
        const selectClick = new Select({
            style: getSelectedStyle(),
            condition: click,
        });

        selectClick.on('select', (event) => {
            if (event.selected && event.selected.length > 0) {
                onSelectedMarker((event.selected[0] as any).values_.ol_uid);
            }
        });

        

        // select interaction working on "pointermove"
        const selectPointerMove = new Select({
            condition: pointerMove,
            style: getSelectedStyle(),
        });

        state.map.addInteraction(selectClick);
        state.map.addInteraction(selectPointerMove);

        state.map.on('moveend', (event) => {
            //nothign to do here yet
        });

        state.map.getView().on('change:center', () => {
           //nothign to do here yet
        });
    
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResizeHandler);

        state.map.set('target', document.getElementById('map'), false);
        addLayersToMap();
        
        addEventListenersToMap();

        //on hover interaction
        const tooltip = document.getElementById('tooltip-id') as HTMLElement;
        const overlay = new Overlay({
                    element: tooltip,
                    positioning: OverlayPositioning.BOTTOM_CENTER,
                    stopEvent: false,
                    offset: [0, -50],
                  });
        

        
        const selectOnHover = new Select({
            condition: pointerMove
        });

        selectOnHover.on('select', function (e) 
        {
            if(e.target.getFeatures().getLength() > 0)
            {
                const feature = e.target.getFeatures().getArray()[0];
                const coordinates = feature.getGeometry().getCoordinates();
                const marker = feature.get('marker') as MapMarker;
                if(marker != null)
                {
                    overlay.setPosition(coordinates);
                    tooltip.innerHTML = marker.agentFullName  +'<br>'+ marker.plate;
                    state.map.addOverlay(overlay);
                }
            }
            else
            {
                state.map.removeOverlay(overlay);
            }
        });    
        state.map.addInteraction(selectOnHover);
        mapDraw.init();//initialize map drawing
        mapDraw.setActive(false, true, '');//initialize zone modifications on the map from the beggining
    }, []);


    useEffect(() => {
        state.map.getView().setCenter(center);
    }, [center[0]]);

    useEffect(() => {
        const newState = {
            map: state.map,
            layers: state.layers,
            zoom: state.zoom,
            center: state.center,
            customerId: state.customerId,
        } as MapState;


        // If customer changed then reset zoom.
        if (state.customerId !== customerId) {
            state.map.getView().setZoom(zoom);
            newState.zoom = zoom;
        }

        if (state.customerId !== customerId && customerId) {
            newState.customerId = customerId;
        }

        setState(newState);

        setCustomContols();
        //if client changed restart zoom
        zoomControlSelected = zoomControlList[4];
        zoomControlList.forEach((zoomControl)=>{
            const zoomElement = document.getElementById(zoomControl.id) as HTMLElement;
            if(zoomControl.id  == zoomControlSelected.id)
            {
                zoomElement.classList.add('selectedFixedZoom');
            }
            else
            {
                zoomElement.classList.remove('selectedFixedZoom');
            }
            
        });

    }, [customerId]);

    
    const renderReadsLayer = (): void => {
        latestMarkers?.forEach((marker: MapMarker) => {
            const featureMarker = new Feature({
                geometry: new Point([marker.longitude, marker.latitude]),
                location_uuid: marker.id,
                ol_uid: marker.id,
            });

            featureMarker.setId(marker.id);
            featureMarker.set('dataType', marker.type);
            featureMarker.set('marker', marker);
            featureMarker.setStyle(readsLayerStyle(marker, mapFilter));
            const source = state.layers.vector.getSource() as VectorSource<Geometry>;
            source.addFeature(featureMarker);
        });
    };

    const renderHeatLayer = (): void => {
        markers.forEach((marker) => {
            const featureMarker = new Feature({
                geometry: new Point([marker.longitude, marker.latitude]),
                location_uuid: marker.id,
                ol_uid: marker.id,
            });

            featureMarker.setId(marker.id);
            featureMarker.set('dataType', marker.type);
            state.layers.heat.getSource().addFeature(featureMarker);
        });
    };

    const renderEnforcementZoneLayer = (): void => {
        state.layers.zones.getSource().clear();

        enforcementZones?.forEach((zone: EnforcementZone) => {
            const zoneCoordinates = JSON.parse(zone.coordinates);
            const featureMarker = new Feature({
                geometry: new Polygon(zoneCoordinates)//Point([marker.longitude, marker.latitude]),
            });

            featureMarker.setId(zone.enforcementZoneId);
            featureMarker.set('type', ZoneType);
            featureMarker.set('zoneName', zone.zoneName);
            featureMarker.setStyle(getMapZoneDrawingStyle(zone.zoneName));

            state.layers.zones.getSource().addFeature(featureMarker);
            
            let pointsCoordinates = [];
            if(zone.points != null && zone.points != '')
            {
                pointsCoordinates = JSON.parse(zone.points);
            }

            pointsCoordinates?.forEach((point: Coordinate) => {
                const pointMarker = new Feature({
                    geometry: new Point(point),
                });
    
                pointMarker.setId(uuidv4());
                pointMarker.set(ZoneType, zone.enforcementZoneId);
                pointMarker.set('type', PointType);
    
                state.layers.zones.getSource().addFeature(pointMarker);
            });
        });
        showHideZones(currentEnforcementWeekDaysRef.current);
    };


    let routeGeoMarker: Feature = new Feature({});
    let routeMarkerIndex = 0;
    let routeDatetime: number;
    let routePostRenderEventKey: any;

    const moveFeature = (event: any): void => {
        const frameState = event.frameState;
        const pathSpeed = 1000;
        const elapsedTime = frameState.time - (routeDatetime);

        if (elapsedTime > pathSpeed) {
            routeDatetime = new Date().getTime();
            //move marker
            routeGeoMarker.set('geometry', new Point([markers[routeMarkerIndex].longitude, markers[routeMarkerIndex].latitude]));

            routeMarkerIndex = routeMarkerIndex + 1;
            if (routeMarkerIndex >= markers.length) {
                routeMarkerIndex = 0;
            }
        }

        state.map.render();
    };

    const renderRouteLayer = (): void => {
        routeMarkerIndex = 1;

        const locations = latestMarkers.map((marker) => [marker.longitude, marker.latitude]);
        const route = new LineString(locations);

        const routeFeature = new Feature({
            type: 'route',
            geometry: route,
        });
        routeFeature.setStyle(routeLayerStyles('route'));
        state.layers.route.getSource().addFeature(routeFeature);

        const startMarker = new Feature({
            type: 'startpoint',
            geometry: new Point(route.getCoordinates()[0]),
        });
        startMarker.setStyle(routeLayerStyles('startpoint'));
        state.layers.route.getSource().addFeature(startMarker);

        const endMarker = new Feature({
            type: 'endpoint',
            geometry: new Point(route.getCoordinates()[route.getCoordinates().length - 1]),
        });
        endMarker.setStyle(routeLayerStyles('endpoint'));
        state.layers.route.getSource().addFeature(endMarker);

        //huh?
        routeGeoMarker = new Feature({
            type: 'geoMarker',
            geometry: new Point(route.getCoordinates()[1]),
        });
        routeGeoMarker.setStyle(routeLayerStyles('geoMarker'));
        state.layers.route.getSource().addFeature(routeGeoMarker);

        routeDatetime = new Date().getTime();

        routePostRenderEventKey = state.layers.route.on('postrender', moveFeature);
        state.map.render();
    };

    const clearLayers = (): void => {
        unByKey(routePostRenderEventKey);
        if(state.layers.vector.getSource() != null)
        {
            state.layers.vector.getSource().clear();
        }
        if(state.layers.heat.getSource() != null)
        {
            state.layers.heat.getSource().clear();
        }
        if(state.layers.route.getSource() != null)
        {
            state.layers.route.getSource().clear();
        }
    };


    useEffect(() => {
        if(!mapFilterDashboard?.savePreviousSearch || markers.length == 0)
        {
            clearLayers();
        }

        switch (layerType) {
            case MapLayerType.Reads:
                renderReadsLayer();
                break;
            case MapLayerType.Heat:
                renderHeatLayer();
                break;
            case MapLayerType.Route:
                renderRouteLayer();
                break;
        }
    }, [latestMarkers]);

    useEffect(() => {
        renderEnforcementZoneLayer();
    }, [enforcementZones]);


    //drawawing hooks
    ///////////////
    useEffect(() => {
        const isDrawActive = drawActive != null ? drawActive : false;
        const isModifyActive = modifyActive != null ? modifyActive : false;
        mapDraw.setActive(isDrawActive, isModifyActive, drawType);
    }, [drawActive, modifyActive]);


    useEffect(() => {
        state.layers.zones.setVisible(showZoneLayer);
    }, [showZoneLayer]);

    const [currentZoneName, _setCurrentZoneName] = React.useState('');
    const currentZoneNameRef = React.useRef(currentZoneName);
    const setCurrentZoneName = (data: string) => {
        currentZoneNameRef.current = data;
        _setCurrentZoneName(data);
    };
    useEffect(() => {
        setCurrentZoneName(zoneName);
    }, [zoneName]);

    const [currentIsDeleteActive, _setCurrentIsDeleteActive] = React.useState(false);
    const currentIsDeleteActiveRef = React.useRef(currentIsDeleteActive);
    const setCurrentIsDeleteActive = (data: boolean) => {
      currentIsDeleteActiveRef.current = data;
      _setCurrentIsDeleteActive(data);
    };
    useEffect(() => {
        setCurrentIsDeleteActive(isDeleteActive);
    }, [isDeleteActive]);

    

    const [currentEnforcementWeekDays, _setCurrentEnforcementWeekDays] = React.useState(weekDay);
    const currentEnforcementWeekDaysRef = React.useRef(currentEnforcementWeekDays);
    const setCurrentEnforcementWeekDays = (data: string) => {
        currentEnforcementWeekDaysRef.current = data;
        _setCurrentEnforcementWeekDays(data);
    };
    useEffect(() => {
        setCurrentEnforcementWeekDays(weekDay);
        showHideZones(currentEnforcementWeekDaysRef.current);
    }, [weekDay]);
    //end drawawing hooks
    ///////////////

    //map drawing base structure
    ////////////////////////////////
    const [mapDraw, setMapDraw] = React.useState<any>({
        init: function (){
            //initialize events
            this.Polygon.on('drawend', (event: DrawEvent) =>{
                onDrawZoneEnd(event)
            });
            this.Point.on('drawend', (event: DrawEvent) =>{
                onDrawPointEnd(event);
            });
            this.Modify.on('modifyend', (event: ModifyEvent) =>{
                onModifyEnd(event);
            });
        },
        Polygon: new Draw({
          source: state.layers.zones.getSource(),
          type: GeometryType.POLYGON,
          
        }),
        Point: new Draw({
            source: state.layers.zones.getSource(),
            type: GeometryType.POINT
        }),
        Snap: new Snap({
            source: state.layers.zones.getSource()
        }),
        Modify: new Modify({
            source: state.layers.zones.getSource(),
        }),
        setActive: function (isDrawActive: boolean, isModifyActive: boolean, type: string) {
            //draw
            const draw = type == 'zone' ? this.Polygon : this.Point;
            if(isDrawActive)
            {
                state.map.addInteraction(draw);
                if(type == 'zone')//draw zone
                {
                    this.Polygon.setActive(true);
                }
                else//draw a point
                {
                    this.Point.setActive(true);
                }
                
                state.map.addInteraction(this.Snap);
            }
            else
            {
                //disable drawing
                this.Polygon.setActive(false);
                this.Point.setActive(false);
                state.map.removeInteraction(this.Snap);
                state.map.removeInteraction(draw);
            }

            //modify
            if(isModifyActive)
            {
                state.map.addInteraction(this.Modify);
            }
            /*else  leave modify always active, not working anyways
            {
                state.map.removeInteraction(this.modify);
            }*/
        }
    });
    //end map drawing base structure
    ////////////////////////////////

    //drawing event handlers
    /////////////////////////
    const onDrawZoneEnd = (event: DrawEvent): void => {
        event.feature.setId(uuidv4());
        event.feature.set('type', ZoneType);
        event.feature.setStyle(getMapZoneDrawingStyle(currentZoneNameRef.current));
        
        const zone: Feature = event.feature;
        if (zone.get('type') == ZoneType) //only if point belongs to a zone we save it to server
        {
            //assign point to zone
            event.feature.set(ZoneType, zone.getId());
            //get all points inside zone
            const markersInZone: Array<MapMarker> = [];  
            //pointInZone.push((event.feature.getGeometry() as Polygon).getCoordinates());
            (state.layers.vector.getSource() as VectorSource<Geometry>).forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                markersInZone.push((possiblePoint.get('marker') as MapMarker));
            });

            dispatch(updateMarkersByZone(markersInZone));
        }

       onDrawEnd();//callback to clear zone name and end drawing
    };

    const onDrawPointEnd = (event: DrawEvent): void => {
        const selected = [];
        event.feature.setId(uuidv4());
        event.feature.set('type', PointType);
        state.layers.zones.getSource().forEachFeatureIntersectingExtent(event.feature.getGeometry().getExtent(),function(zone){
            if (zone.get('type') == ZoneType) //only if point belongs to a zone we save it to server
            {
                //assign point to zone
                event.feature.set(ZoneType, zone.getId());
                //get all points inside zone
                const pointInZone: Array<Coordinate[][]> = [];  
                pointInZone.push((event.feature.getGeometry() as Polygon).getCoordinates());
                state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                    if (possiblePoint.get('type') == PointType) {
                        pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                    }
                });

                const currentZoneName = currentZoneNameRef.current;
                const zoneId = zone.getId();
                const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
                const points = JSON.stringify(pointInZone);

                
            }
        });
    };

    const onModifyEnd = (event: ModifyEvent): void => {
        //get the zones
       
        event.features.forEach(feature =>{
            if(feature.get('type') == ZoneType && !currentIsDeleteActiveRef.current)//we don't want to send modify requst when deleting
            {
                const zone = feature;
                const pointInZone: Array<Coordinate[][]> = [];  
                //pointInZone.push((zone.getGeometry() as Polygon).getCoordinates());
                state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                    if (possiblePoint.get('type') == PointType) {
                        pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                    }
                });

                const currentZoneName = currentZoneNameRef.current;
                const zoneId = zone.getId();
                const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
                const points = JSON.stringify(pointInZone);

            }
        });
    };

    const deleteFeatures = (feature: FeatureLike): void => {
        if(feature.get('type') == ZoneType)
        {
            const zone = feature;
            //if its a zone remove zone
            state.layers.zones.getSource().removeFeature(state.layers.zones.getSource().getFeatureById(zone.getId()));
            //remove points
            state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                if (possiblePoint.get('type') == PointType) {
                    state.layers.zones.getSource().removeFeature(possiblePoint);
                }
            });

            const currentZoneName = currentZoneNameRef.current;
            const zoneId = zone.getId();


        }
        else if(feature.get('type') == PointType)
        {
            const point = feature;
            //remove the point from map
            state.layers.zones.getSource().removeFeature(state.layers.zones.getSource().getFeatureById(point.getId()));
            //if its a point first get the zone that belongs to
            state.layers.zones.getSource().forEachFeatureIntersectingExtent(point.getGeometry().getExtent(),function(zone){
                if (zone.get('type') == ZoneType) //if feature is a zone
                {
                    //then we get all points inside that zone
                    const pointInZone: Array<Coordinate[][]> = [];  
                    
                    state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                        if (possiblePoint.get('type') == PointType) {
                            pointInZone.push((possiblePoint.getGeometry() as Polygon).getCoordinates());
                        }
                    });
                    
                    const currentZoneName = currentZoneNameRef.current;
                    const zoneId = zone.getId();
                    const zoneCoordinates= JSON.stringify((zone.getGeometry() as Polygon).getCoordinates());
                    const points = JSON.stringify(pointInZone);

                    
                }
            });
        }
    };

    const showHideZones = (weekDayC: string): void =>{
        state.layers.zones.getSource().forEachFeature (function(feature){
            if (feature.get('type') == ZoneType) 
            {
                let hidden = false;
                //zone
                const zone = feature;
                if(weekDayC != all && !zone.get('weekDays')?.includes(weekDayC))
                {
                    zone.setStyle(new Style({}));
                    hidden = true;
                }
                else
                {
                    zone.setStyle(getMapZoneDrawingStyle(zone.get('zoneName')));
                    hidden = false;
                }

                //points
                state.layers.zones.getSource().forEachFeatureIntersectingExtent(zone.getGeometry().getExtent(),function(possiblePoint){
                    if (possiblePoint.get('type') == PointType) {
                        if(hidden)
                        {
                            possiblePoint.setStyle(new Style({}));
                        }
                        else
                        {
                            possiblePoint.setStyle(null);
                        }
                    }
                });
            }
        });
    };
    //end drawing event handlers
    /////////////////////////

    return (
        <Paper elevation={1} style={{ borderRadius: 2 }}>
            <style  dangerouslySetInnerHTML={{__html: `
            .selectedFixedZoom{
                background-color: #ffa433;
            }
            `}} />
            <div id="map" style={{
                width: '100%',
                height: mapHeight,
                backgroundColor: '#fff',
                position: 'relative',
                boxSizing: 'border-box',
            }}>
                <div id="zoom-in" className={classes.zoom+' '+classes.zoomIn}>Zoom In</div>
                <div id="zoom-1" className={classes.zoom+' '+classes.zoomOne}>1</div>
                <div id="zoom-2" className={classes.zoom+' '+classes.zoomTwo}>2</div>
                <div id="zoom-3" className={classes.zoom+' '+classes.zoomThree}>3</div>
                <div id="zoom-4" className={classes.zoom+' '+classes.zoomFourth}>4</div>
                <div id="zoom-5" className={classes.zoom+' '+classes.zoomFifth}>5</div>
                <div id="zoom-6" className={classes.zoom+' '+classes.zoomSixth}>6</div>
                <div id="zoom-7" className={classes.zoom+' '+classes.zoomSeventh}>7</div>
                <div id="zoom-8" className={classes.zoom+' '+classes.zoomEighth}>8</div>
                <div id="zoom-9" className={classes.zoom+' '+classes.zoomNineth}>9</div>
                <div id="zoom-10" className={classes.zoom+' '+classes.zoomTenth}>10</div>
                <div id="zoom-out" className={classes.zoom+' '+classes.zoomOut}>Zoom Out</div>
            </div>
            <div id="tooltip-id" className={classes.tooltip}></div>
        </Paper>
        
    );
});

export default Map;
