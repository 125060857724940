import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Divider,  Box, CircularProgress } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { MapMarker, PaginationResult, MapFilterDataType, MapDashboardStateModel, EnforcementDataType } from '../../state/mapDashboardStateV2';
import { MarkerDetail } from '../MarkerDetail/MarkerDetail';
import { useStyles } from './markerListStyles';
import moment from 'moment';
import MarkerItemCard from './MarkerItemCard';

type MarkersListProps = {
    isLoading: boolean;
    markers: MapMarker[];
    selectedMarker?: MapMarker | null;
    mapState:  MapDashboardStateModel;
    pagination?: PaginationResult;
    onSelectedMarker: (selectedMarkerId: string) => void;
    onClearSelectedMarker: () => void;
    selectedCustomerSubDomain?: string;
    onPageChanged?: (newPage: number) => void;
};

type MarkersListState = {
    openModal: boolean;
    selectedMarker: MapMarker;
};

export const MarkersList = (props: MarkersListProps): JSX.Element => {
    const { markers = [], selectedMarker, pagination, onSelectedMarker: onSelectMarker, selectedCustomerSubDomain, onClearSelectedMarker, onPageChanged, mapState } = props;
    const classes = useStyles();

    const bootMarkers = markers.filter(x=> x.type == EnforcementDataType.Boot);
    const hitMarkers = markers.filter(x=> x.type == EnforcementDataType.Hit);

    const [state, setState] = useState({
        openModal: selectedMarker ? true : false,
    } as MarkersListState);

    const pageChangeHandler = (event: React.ChangeEvent<unknown>, page: number) => {
        if(onPageChanged)
        {
            onPageChanged(page);
        }
    };

    useEffect(() => {
        setState({ ...state, openModal: selectedMarker ? true : false });
    }, [selectedMarker]);

    // useEffect(() => {
    //     console.log('markers in list', markers);
    // }, [markers]);

    return (
        <Paper elevation={1} className={classes.root}>
            <Grid container spacing={1} className={classes.grid}>
                <Grid item xs={12} md={12} className={classes.titleContainer}>
                    <Typography variant="h6" color="inherit" className={classes.title}>
                        Hits [{hitMarkers.length}] and Boots [{bootMarkers.length}]
                    </Typography>
                    <Divider />
                    {mapState.listIsLoading && <CircularProgress />}
                </Grid>
                <Grid item xs={12} md={12} className={classes.listContainer}>
                    <Grid container>
                        {
                            (
                                markers.length === 0) &&
                            <Grid item xs={12} md={12}>
                                <Typography variant="body1" className={classes.startSearchMessage}>
                                    No results found, please try with different search parameters.
                                </Typography>
                            </Grid>
                        }
                        {
                            markers.length > 0 &&
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        Plate-State
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        /Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        Read Type
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.listTitle}>
                                        /Device id
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} justify="center">
                                    <Typography variant="subtitle1"  className={classes.listTitle}>
                                        DT Vehicle Seen
                                    </Typography>
                                    <Typography variant="subtitle1"  className={classes.listTitle}>
                                        /DT Tow Eligible
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {
                            pagination == null &&
                            <Box style={{maxHeight: '75vh', overflow: 'auto', width: '100%'}} >
                            {
                                markers.map((marker: MapMarker) =>
                                    <React.Fragment key={marker.id}>
                                        <Grid item xs={12} md={12} container alignContent="stretch" alignItems="stretch">
                                            <MarkerItemCard
                                                id={marker.id}
                                                date={moment(marker.dateRecord).format('YYYY/MM/DD hh:mm a')}
                                                plate={marker.plate}
                                                plateState={marker.plateState}
                                                type={MapFilterDataType[marker.type]}
                                                marker={marker}
                                                mapState={mapState}
                                                //numRunawaysSinceLastPayment={marker.numRunawaysSinceLastPayment}
                                                //vioDeviceID={marker.vioDeviceID}
                                                onClick={onSelectMarker} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            </Box>
                        }
                        {
                            pagination != null &&
                            
                            markers.map((marker: MapMarker) =>
                                <React.Fragment key={marker.id}>
                                    <Grid item xs={12} md={12}  container alignContent="stretch" alignItems="stretch">
                                        <MarkerItemCard
                                            id={marker.id}
                                            date={moment(marker.dateRecord).format('YYYY/MM/DD hh:mm a')}
                                            plate={marker.plate}
                                            plateState={marker.plateState}
                                            type={MapFilterDataType[marker.type]}
                                            marker={marker}
                                            mapState={mapState}
                                            onClick={onSelectMarker} />
                                    </Grid>
                                </React.Fragment>
                            )
                            
                        }
                    </Grid>
                </Grid>
                {
                    (pagination != null && markers.length > 0 && pagination.totalPages > 1) &&
                    <Grid item xs={12} md={12} className={classes.paginationContainer} container justify="center">
                        <Divider />
                        <Pagination
                            count={pagination.totalPages}
                            color="primary"
                            onChange={pageChangeHandler}
                            className={classes.pagination}
                            shape="rounded"
                            variant="outlined" />
                    </Grid>
                }
            </Grid>
            <MarkerDetail marker={selectedMarker} openModal={state.openModal} selectedCustomerSubDomain={selectedCustomerSubDomain} onClose={onClearSelectedMarker} />
        </Paper>
    );
};
