import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useStyles } from '../AverageCard/AverageCardProgressBarStyles';

type ProgressLabelsProps = {
    value: number;
    averageValue: number;
};

export interface ProgressLabels {
    indicatorLabel: string;
    valueLabel: string;
    barPercentage: number;
}

const minPercentage = 10;

export function ProgressLabels (props: ProgressLabelsProps): JSX.Element {
    const { value, averageValue } = props;
    const classes = useStyles();

    const [state, setState] = useState<ProgressLabels>({
        indicatorLabel: '',
        valueLabel: '',
        barPercentage: 0,
    });

    useEffect(() => {
        const progress = getProgressLabels(value, averageValue);
        setState(progress);
    }, [value, averageValue]);

    return (
        <Grid container justify="space-between">
            <Typography variant="caption" className={classes.indicatorLabel}>{state.indicatorLabel}</Typography>
            <Typography variant="body2" className="value">{state.valueLabel}</Typography>
        </Grid>
    );
}

export function getProgressLabels (value: number, averageValue: number): ProgressLabels {
    if (value === 0 && averageValue === 0) {
        return {
            indicatorLabel: 'No change',
            valueLabel: '0%',
            barPercentage: minPercentage,
        };
    }

    if (value === 0) {
        return {
            indicatorLabel: 'Decreased by',
            valueLabel: '-100%',
            barPercentage: minPercentage,
        };
    }

    if (averageValue === 0) {
        return {
            indicatorLabel: 'Increase by',
            valueLabel: '+100%',
            barPercentage: 100,
        };
    }

    const differencePercentage = (((value * 100) / averageValue) - 100);
    const barPercentage = getProgressBarPercentage(value, averageValue);

    return {
        indicatorLabel: differencePercentage === 0 ? 'No change' : differencePercentage < 0 ? 'Decreased by' : 'Increased by',
        valueLabel: `${differencePercentage < 0 ? '' : '+'}${differencePercentage.toFixed(2)}%`,
        barPercentage: barPercentage,
    };
}

export function getProgressBarPercentage (value: number, averageValue: number): number {
    averageValue = Math.round(averageValue);
    if (value === 0) {
        return minPercentage;
    }

    if (averageValue === 0) {
        return 100;
    }

    let percentage = (value * 100) / averageValue;
    if (percentage > 100) {
        percentage = 100;
    }

    return percentage;
}
