import {
  AppBar,
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  VisibilityOff as ClosePreviewIcon
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { lazy, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Rnd, ResizableDelta, Position } from 'react-rnd';
import { useParams } from 'react-router-dom';
import { DashboardBuilderState } from './state/dashboardBuilderState';
import { SessionState } from '../../app/session/sessionTypes';
import { ApplicationState } from '../../app/store';
import { Get } from '../../services/RestClient';
import LoadingModal from '../dashboards/Commons/LoadingModal/LoadingModal';
import { updateBuilderState, fetchDashboardForBuilder, updateTile } from './state/dashboardBuilderThunks';

import theme from '../themes/base';
import { useStyles } from './DashboardBuilderStyles';
import { BuilderTilesRndRefs, DashboardSetup, tilesListTileName, CustomDashboardTile } from './types';
import TileController from '../dashboard-tile-builder/templates/TileController';
import { ChartType, TileModel, TileSettingsTestValues } from '../dashboard-tile-builder/tileBuilderTypes';
import { fetchTilesFromServer } from '../dashboard-tile-builder/state/tileBuilderThunks';
import { TileEditMode } from '../dashboard-tile-builder/templates/types';
import moment from 'moment';
import { setTileSettingsChartType } from '../dashboard-tile-builder/state/tileBuilderReducer';
import clone from 'rfdc';
import { timeEnd } from 'console';
import { DashboardFilterBar } from '../dashboards/Commons';
import { removeTimeFromDate } from '../dashboard-tile-builder/state/utils';
import { DashboardFilterState } from '../dashboards/state/DashboardFilterState';

const DashboardBuilder = (): JSX.Element => {
  const classes = useStyles();

  const selector = (state: ApplicationState) => {
    return {
      session: state.session as SessionState,
      dashboardBuilder: state.dashboardBuilder as DashboardBuilderState,
      availableTiles: state.dashboardTileBuilder.customTilesList as TileModel[]
    };
  };

  const state = useSelector(selector);
  const dashboardFilter = useSelector((state: ApplicationState) => state.dashboardFilter as DashboardFilterState);
  const dispatch = useDispatch();

  const builderTilesRndRefs = {} as BuilderTilesRndRefs;

  const setBuilderTilesInitialPosition = () => {
    const builderContentElement = document.getElementById('builderContent');
    if (builderContentElement) {
      builderTilesRndRefs[tilesListTileName]?.updatePosition({ x: builderContentElement.offsetWidth - 76, y: 82 });
    }
  };

  useEffect(() => {
    setBuilderTilesInitialPosition();
    dispatch(fetchTilesFromServer())
  }, []);

  const handleTileDragStop = (e: any, data: any) => {
    e.preventDefault();
    e.stopPropagation();

    console.log('handleTileDragStop', e, data);
    if (e.currentTarget.activeElement.localName === 'button') {
      e.currentTarget.activeElement.click();
    }
    else {
      const dashboardBuilderData = Object.assign({}, { ...state.dashboardBuilder });
      const selectedTilesData = Object.assign([], [...dashboardBuilderData.selectedTiles]);

      if (data.node.dataset.id && data.node.dataset.name) {
        let singleTile: any = selectedTilesData.find((t: CustomDashboardTile) => t.name === data.node.dataset.name);

        if (singleTile) {
          singleTile = {
            ...singleTile,
            positionX: Math.round(data.x),
            positionY: Math.round(data.y),
          };

          const sIndex = selectedTilesData.findIndex((e: any) => e.id === singleTile.id && e.name === singleTile.name);
          selectedTilesData.splice(sIndex, 1, singleTile);
          dashboardBuilderData.selectedTiles = selectedTilesData;
          dispatch(updateBuilderState(dashboardBuilderData));
        }
      }
    }
  };

  const handleTileResizeStop = (e: any, dir: any, ref: HTMLElement, delta: ResizableDelta, position: Position) => {
    const dashboardBuilderData = Object.assign({}, { ...state.dashboardBuilder });
    const selectedTilesData = Object.assign([], [...dashboardBuilderData.selectedTiles]);

    if (ref.dataset.id && ref.dataset.name) {
      let singleTile: any = selectedTilesData.find((t: CustomDashboardTile) => t.name === ref.dataset.name);

      if (singleTile) {
        singleTile = {
          ...singleTile,
          width: ref.clientWidth,
          height: ref.clientHeight,
        };


        const sIndex = selectedTilesData.findIndex((e: any) => e.id === singleTile.id && e.name === singleTile.name);
        selectedTilesData.splice(sIndex, 1, singleTile);
        dashboardBuilderData.selectedTiles = selectedTilesData;
        console.log('after all stuff', selectedTilesData);
        dispatch(updateBuilderState(dashboardBuilderData));
      }
    }
  };

  const [isEdit, setIsEdit] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const handleClosePreview = () => {
    setIsEdit(true);
  };

  const [builderMessage, setBuilderMessage] = useState('');

  const routeParams = useParams() as { dashboardId: string };

  useEffect(() => {
    if (routeParams.dashboardId) {
      dispatch(fetchDashboardForBuilder(routeParams.dashboardId));
    }
  }, [routeParams.dashboardId]);

  const [settingsModal, setSettingsModal] = useState(false);

  const handleOpen = () => {
    console.log('handleOpen');
    setSettingsModal(true);
  };

  const handleClose = () => {
    setSettingsModal(false);
  };

  const onChartUserSettingsChange = useCallback((tileId: string, chartType: ChartType) => {
    const tile = state.dashboardBuilder.selectedTiles.find(st => st.tileId === tileId);

    console.log('onChartTypeSelected: ', tileId, state.dashboardBuilder.selectedTiles);

    if (!tile) {
      return;
    }

    // const newVal = Object.assign({}, tile);
    const newVal = clone({ proto: true })(tile);
    newVal.chartType = chartType;

    if (newVal.model) {
      newVal.model.chartType = chartType;
    }

    console.log('onChartTypeSelectedNewVal', newVal);
    dispatch(updateTile(newVal));
  }, [state.dashboardBuilder.selectedTiles])


  return (
    <Box style={{ height: '100%' }} id="builderContainer">
      <AppBar position={useMediaQuery(theme.breakpoints.up('md')) ? 'fixed' : 'relative'} className={classes.appBar} elevation={1}>
        <Toolbar>
          <Grid container spacing={3} justify="space-between" alignItems="center" alignContent="center">
            <Grid item xs={12} md={3} container alignItems="center" alignContent="center">
              <DashboardIcon className={classes.iconDashboard} />
              <Typography variant="h6" color="inherit" className={classes.title}>
                {state.dashboardBuilder.name ? state.dashboardBuilder.name : 'Dashboard Builder'}
                <Typography variant="h6" color="inherit" className={classes.subTitle}>
                  by Stat-Trak
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} container justify="flex-end" alignItems="center" alignContent="center">
              {
                !isEdit &&
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ClosePreviewIcon />}
                  onClick={() => handleClosePreview()}>
                  Close Preview
                </Button>
              }
              <Grid item xs={12}>
                {/* TODO: Move props to builder settings
                TODO: FIX DELETE SELECTED TILES */}
                <DashboardFilterBar onApply={() => console.log('clicked')} applyEnabled={true} excludeAverage={true} filterType={'range'} />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {
        useMediaQuery(theme.breakpoints.up('md')) && <Toolbar className={classes.emptyToolbar} />
      }
      <Box className={classes.root} style={{ padding: 0 }}>
        <Grid container spacing={0} direction="row" justify="center" alignItems="stretch" alignContent="stretch" style={{ height: '100%' }} id="builderContent">
          <Grid item xs={12} md={12} style={{ padding: '16px' }}>
            <Box id="draggableContainer" style={{ height: '100%', width: '100%', position: 'relative' }}>
              {
                state?.dashboardBuilder?.selectedTiles.filter(i => !i.deleted).map(tile => {
                  console.log('rendering >>>', tile)
                  return (
                    <TileController
                      tileModel={{
                        ...(tile.model ?? ({} as TileModel)),
                        positionX: tile.positionX,
                        positionY: tile.positionY,
                        height: tile.height != '-1' && tile.height != 'auto' ? parseInt(tile.height) : (tile.model?.height || 0),
                        width: tile.width != '-1' && tile.width != 'auto' ? parseInt(tile.width) : (tile.model?.width || 0),
                      }}
                      chartType={tile.chartType}
                      mode={TileEditMode.DashboardBuilder}
                      paramValues={{
                        customerId: dashboardFilter.customerId,
                        date: removeTimeFromDate(dashboardFilter.date),
                        dateTo: removeTimeFromDate(dashboardFilter.dateTo ?? dashboardFilter.date)
                      } as TileSettingsTestValues}
                      onChartTypeSelected={(chartType: ChartType) => {
                        onChartUserSettingsChange(tile.tileId, chartType);
                      }}
                      onDragTile={handleTileDragStop}
                      onResizeTile={handleTileResizeStop}
                    />
                  )
                })
              }
            </Box>
            {
              state.dashboardBuilder.isLoading &&
              <LoadingModal
                isOpen={state.dashboardBuilder.isLoading}
                container={(() => document.getElementById('builderContainer'))()}
                message="Loading, please wait..."
              />
            }
            <Modal
              id="messagesModal"
              open={builderMessage ? true : false}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
              <Paper elevation={2} className={classes.modalContent}>
                <Typography variant="body1">
                  <Alert severity="success">{builderMessage}</Alert>
                </Typography>
                <Button
                  className={classes.acceptButton}
                  variant="contained"
                  color="primary"
                  onClick={() => setBuilderMessage('')}>
                  Accept
                </Button>
              </Paper>
            </Modal>

          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardBuilder;
