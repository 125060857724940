import { ApplicationUser } from './sessionTypes';

export const hasAccessToBuilders = (user: ApplicationUser | null): boolean => {
    if (process.env.NODE_ENV !== 'production') {
        return true;
    }

    if (user === null) {
        return false;
    }

    const groupId = user.groupId.toLowerCase();

    return groupId === '9a372ff1-2f74-4851-925e-e0bf497b646b' || // Engineers
        groupId === '9a372ff12f744851925ee0bf497b646b' ||
        groupId === '2ba9ab42-0549-46b5-99df-bbc6f68b5c34' || // Admin
        groupId === '2ba9ab42054946b599dfbbc6f68b5c34' ||
        groupId === '22c6b5ad-1883-455b-a789-9f8da271fd9e' || // Admin With FieldOps
        groupId === '22c6b5ad1883455ba7899f8da271fd9e';
}