import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { useStyles } from './DashboardFilterStyles';
import { getCookie } from '../../../../app/cookieServices';
import useInterval from '../../../../app/useInterval';
import moment from 'moment';

type autoReloadProps = {
    actionToExecute: () => void;
    timeOptions?: [{ name: string; value: number }];
    resetTimerFlag?: boolean;
    onTimerReseted?: () => void;
}

const defaultTimeOptions = [
    { value: 0, name: 'None' },
    { value: 10, name: '10 Minutes' },
    { value: 30, name: '30 Minutes' },
    { value: 60, name: '60 Minutes' },
];

const AutoReload = (props: autoReloadProps): JSX.Element => {
    const classes = useStyles();

    const { timeOptions = defaultTimeOptions, actionToExecute, resetTimerFlag = false, onTimerReseted } = props;

    const [selectedMinutes, setSelectedMinutes] = useState(0);

    const autoReloadCookie = AutoReloadCookieManager();

    useEffect(() => {
        let minutesValue = autoReloadCookie.get();
        if (minutesValue == null) {
            minutesValue = defaultTimeOptions[1].value;
        }

        setSelectedMinutes(minutesValue);
    }, []);

    useEffect(() => {
        setTimeLeftTimer(selectedMinutes);
    }, [selectedMinutes]);

    useEffect(() => {
        if (resetTimerFlag === true) {
            setSelectedMinutes(() => {
                setTimeLeftTimer(selectedMinutes);
                return selectedMinutes;
            });
            if (onTimerReseted) { onTimerReseted(); }
        }
    }, [resetTimerFlag]);

    useInterval(() => {
        console.log('Auto-reloading...');
        setTimeLeftTimer(selectedMinutes);
        actionToExecute();
    }, selectedMinutes * 60000);

    const convertSecondsToTimeLabel = (secondsLeft: number | null): string => {
        if (secondsLeft == null) {
            return '';
        }

        const time = moment().startOf('day').seconds(secondsLeft).format('mm:ss');
        return `(${time} till reload)`;
    };

    const setTimeLeftTimer = (minutes: number) => {
        const timeLeftLabel = document.getElementById('timeLeftLabel');
        if (!timeLeftLabel) {
            return;
        }

        if (minutes && minutes > 0) {
            const secondsLeft = minutes * 60;
            timeLeftLabel.setAttribute('data-sl', secondsLeft.toString());
        }
    };

    useInterval(() => {
        const timeLeftLabel = document.getElementById('timeLeftLabel');
        if (!timeLeftLabel) {
            return;
        }

        if (!selectedMinutes || selectedMinutes <= 0) {
            timeLeftLabel.removeAttribute('data-sl');
            return;
        }

        const secondsLeftStr = timeLeftLabel.getAttribute('data-sl');
        let secondsLeft = 0;
        if (!secondsLeftStr || secondsLeftStr === '0') {
            secondsLeft = selectedMinutes * 60;
        } else {
            secondsLeft = parseInt(secondsLeftStr);
        }

        secondsLeft = secondsLeft - 1;
        const iTimeLabel = convertSecondsToTimeLabel(secondsLeft);
        timeLeftLabel.innerHTML = secondsLeft > 0 ? iTimeLabel : '';
        timeLeftLabel.setAttribute('data-sl', secondsLeft.toString());
    }, 1000);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const autoReloadChangeHandler = (minutes: number) => {
        setSelectedMinutes(minutes);
        autoReloadCookie.set(minutes);
    };

    return (
        <FormControl className={classes.formControl} fullWidth>
            <InputLabel className={classes.label}>Auto-Reload <span id="timeLeftLabel" data-sl="0"></span> </InputLabel>
            <Select
                value={selectedMinutes}
                onChange={e => autoReloadChangeHandler(e.target.value as number)}
                displayEmpty
                className={classes.select}
                classes={{ icon: classes.selectIcon }}
                inputProps={{ 'aria-label': 'Without label' }}
                autoWidth={false}>
                {
                    timeOptions.map(option => <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
};

const AutoReloadCookieManager = () => {
    const name = 'autoReloadDashboard';

    const set = (minutes: number) => {
        const d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + encodeURIComponent(minutes) + '; ' + expires + '; path=/';
    };

    const get = (): number | null => {
        const value = getCookie(name);
        if (value) {
            return value as number;
        }

        return null;
    };

    return {
        set,
        get,
    };
};

export default AutoReload;
