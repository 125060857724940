import { Get } from '../../../services/RestClient';
import { DashboardBuilderState } from './dashboardBuilderState';
import { Dispatch } from 'react';
import { setBuilderIsLoading, updateBuilder, updateDashboardTile } from './dashboardBuilderStateSlice';
import { ApplicationState } from '../../../app/store';
import { DashboardSetup, CustomDashboardTile } from '../types';
import { TileModel } from '../../dashboard-tile-builder/tileBuilderTypes';
import { deserializeTileJsonData } from '../../dashboard-tile-builder/state/tileBuilderThunks';

// const fetchBuilderTiles = (): Promise<ApplicationUser> => {
//     return new Promise((resolve, reject) => {
//         Get({
//             url: `${process.env.REACT_APP_BOOTVIEW_API_URL}/users/me`,
//             params: {},
//         }).then((response: ApplicationUser) => {
//             resolve(response);
//         }).catch((err) => {
//             reject(err);
//         });
//     });
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateBuilderState = (currentBuilderState: DashboardBuilderState) => {
  return (dispatch: Dispatch<any>): void => {
    dispatch(updateBuilder(currentBuilderState));
  };
};

const updateTile = (dashboardTile: CustomDashboardTile) => {
  return (dispatch: Dispatch<any>): void => {
    dispatch(updateDashboardTile(dashboardTile));
  };
};

const fetchDashboardForBuilder = (dashboardId: string) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState): Promise<void> => {
    dispatch(setBuilderIsLoading(true));

    return Get({
      url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/dashboardBuilder/${dashboardId}`,
    }).then((response: DashboardSetup) => {
      console.log('fetchDashboardForBuilder', response);
      if (!response || response.id <= 0) {
        return;
      }

      const state = getState();

      let tiles: CustomDashboardTile[] = [];
      if (response.tiles) {
        tiles = response.tiles?.map(t => {
          t.model = deserializeTileJsonData(t.tileModelPoco);
          return t;
        })
      }

      const dashboard = {
        id: response.id,
        name: response.name,
        description: response.description,
        customerId: response.customerId,
        isDefault: response.isDefault || false,
        isHomePage: response.isHomePage || false,
        selectedTiles: tiles,
        searchTile: state.dashboardBuilder.searchTile,
        availableTiles: [],
        isLoading: false
      };

      dispatch(updateBuilderState(dashboard));
    });
  }
};

export { fetchDashboardForBuilder, updateBuilderState, updateTile };