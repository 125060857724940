import React, { useEffect, useReducer } from 'react';
import { Grid, Button, Select, MenuItem, Input, InputLabel, Paper, FormControl } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { BootingOfficer, ParkingArea, MapFilterSearchBy, MapFilterDataType, MapLayerType } from '../../state/mapDashboardStateV2';
import { applyFilterAndFetchMarkers} from '../../state/mapDashboardThunks';
import { useStyles } from './mapFilterBarStyles';
import { MultipleSelect } from '../../../Commons/MultipleSelect/MultipleSelect';
import { MapFilterState, mapFilterStateReducer, mapFilterStateInit, ACTION_TYPES } from './mapFilterState';
import { useDispatch } from 'react-redux';

type MapFilterBarProps = {
    isLoading: boolean;
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    onApply: (filter: MapFilterState) => void;
    filterBy?: string | null;
    filterQ?: string | null;
}

export default function MapFilterBar (props: MapFilterBarProps): JSX.Element {
    const { isLoading, bootingOfficers, parkingAreas, onApply, filterBy, filterQ } = props;
    const classes = useStyles();

    const [state, dispatch] = useReducer(mapFilterStateReducer, { bootingOfficers, parkingAreas }, mapFilterStateInit);

    const mapDispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_BY, payload: { searchBy: state.searchBy, bootingOfficers, parkingAreas, filterBy, filterQ } });
    }, [bootingOfficers, parkingAreas]);

    const changePlateHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const plate = event.target.value as unknown as string;
        dispatch({ type: ACTION_TYPES.UPDATE_PLATE, payload: plate });
    };

    const changeSearchByHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const newSearchByValue = event.target.value as unknown as number;
        dispatch({ type: ACTION_TYPES.UPDATE_SEARCH_BY, payload: { searchBy: newSearchByValue, bootingOfficers, parkingAreas } });
    };

    const changeSelectedAgentsHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_AGENTS, payload: selected });
    };

    const changeSelectedLocationsHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LOCATIONS, payload: selected });
    };

    const changeSelectedDataTypesHandler = (selected: string[]): void => {
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_DATA_TYPES, payload: selected });
    };

    const changeSelectedLayerTypeHandler = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        const selected = event.target.value as number;
        dispatch({ type: ACTION_TYPES.UPDATE_SELECTED_LAYER_TYPE, payload: selected });
    };

    //run a query to get the devices after load
    useEffect(() => {
        const seconds = 2;
        setTimeout(autoRefreshMap, seconds*1000);
     },[]);
       
    const autoRefreshMap = (): void => {
        mapDispatch(applyFilterAndFetchMarkers(state));
    };
   

    return (
        <Paper elevation={1} className={classes.root}>
            <Grid  container spacing={3} alignItems="flex-end">
                <Grid item xs={12} md="auto" className={classes.hideComponent}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Plate</InputLabel>
                        <Input
                            fullWidth
                            value={state.plate}
                            onChange={changePlateHandler}
                            name="plate"
                            placeholder="Plate" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md="auto" className={classes.hideComponent}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Search by</InputLabel>
                        <Select
                            displayEmpty
                            value={state.searchBy}
                            onChange={changeSearchByHandler}
                            name="searchBy"
                            autoWidth={false}>
                            <MenuItem value={MapFilterSearchBy.All}>All</MenuItem>
                            <MenuItem value={MapFilterSearchBy.Agents}>Agents</MenuItem>
                            <MenuItem value={MapFilterSearchBy.Locations}>Locations</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12} md="auto" className={classes.hideComponent}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Locations</InputLabel>
                        <MultipleSelect
                            id="searchByLocations"
                            disabled={state.searchBy !== MapFilterSearchBy.All && state.searchBy !== MapFilterSearchBy.Locations}
                            includeSelectAll={true}
                            currentSelected={state.selectedLocationsToSearchBy}
                            type="checkbox"
                            onChange={changeSelectedLocationsHandler}
                            selectableItems={parkingAreas.map((pa) => ({ id: pa.name, name: pa.name }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md="auto" className={classes.hideComponent}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Type</InputLabel>
                        <MultipleSelect
                            includeSelectAll={true}
                            currentSelected={state.selectedDataTypes}
                            type="checkbox"
                            onChange={changeSelectedDataTypesHandler}
                            selectableItems={[
                                { id: MapFilterDataType.Scan as unknown as string, name: 'Scans' },
                                { id: MapFilterDataType.Hit as unknown as string, name: 'Hits' },
                                { id: MapFilterDataType.Booted as unknown as string, name: 'Boots' },
                            ]}
                            renderAllSelected={true}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md="auto" className={classes.hideComponent}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink className={classes.label}>Show as</InputLabel>
                        <Select
                            displayEmpty
                            value={state.layerType}
                            name="showAs"
                            onChange={changeSelectedLayerTypeHandler}
                            autoWidth={false}>
                            <MenuItem value={MapLayerType.Reads}>Reads</MenuItem>
                            <MenuItem value={MapLayerType.Heat}>Heat</MenuItem>
                            {/*<MenuItem value={MapLayerType.Route}>Route</MenuItem>*/}
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} md="auto">
                    <Button variant="contained" fullWidth>
                        More Settings
                    </Button>
                </Grid> */}
                <Grid item xs={12} md="auto">
                    <Button
                        variant="contained"
                        className={classes.searchButton}
                        startIcon={<SearchIcon />}
                        onClick={() => onApply(state)}
                        disabled={isLoading}>
                        {
                            !isLoading ? 'Search' : 'Searching...'
                        }
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
