import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 240,
        },
        backgroundColor: 'white',
        color: theme.palette.primary.main,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    title: {
        // flexGrow: 1,
        marginTop: '8px',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    emptyToolbar: {
        height: 0,
    },
}));
