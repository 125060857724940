import React, { useEffect } from 'react';
import { Box, Toolbar, Grid } from '@material-ui/core';
import MapFilterBar from './FilterBar/MapFilterBar';
import Map from './Map/Map';
import { ApplicationState } from '../../../../app/store';
import * as DashboardFilterState from '../../state/DashboardFilterState';
import { MapDashboardStateModel, MapLayerType } from '../state/mapDashboardStateV2';
import { useSelector, useDispatch } from 'react-redux';
import { applyFilterAndFetchMarkers, changePageAndFetchMarkers, initializeMapFilterSource } from '../state/mapDashboardThunks';
import { useStyles } from './mapSearchStyles';
import { MarkersList } from './MarkerList/MarkersList';
import { MapFilterState } from './FilterBar/mapFilterState';
import LoadingModal from '../../Commons/LoadingModal/LoadingModal';
import { useLocation } from 'react-router-dom';
import { selectMarker, unselectMarker } from '../state/mapLiveDashboardSlice';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const MapSearch = (): JSX.Element => {
    const classes = useStyles();
    const queryParameters = useQuery();

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mapLiveDashboard as MapDashboardStateModel,
        };
    };

    const state = useSelector(selector);
    const dispatch = useDispatch();

    // TODO: Validate dashboard filter state in a better way
    useEffect(() => {
        dispatch(initializeMapFilterSource());
    }, []);

    useEffect(() => {
        dispatch(initializeMapFilterSource());
    }, [state.dashboardFilter.customerId]);

    const searchMapMarkers = (filter: MapFilterState): void => {
        dispatch(applyFilterAndFetchMarkers(filter));
    };

    
   

    useEffect(() => {
        // fetchEnforcementData();
    }, [state.dashboardFilter]);

    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <MapFilterBar
                        isLoading={state.data.isLoading}
                        bootingOfficers={state.data.filterSource.bootingOfficers}
                        parkingAreas={state.data.filterSource.parkingAreas}
                        onApply={searchMapMarkers} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.contentWrapper} alignContent="stretch" alignItems="stretch" id="mapSearchContent" style={{ position: 'relative' }}>
                {/*<LoadingModal we really don't need this for live map
                    isOpen={state.data.isLoading}
                    container={(() => document.getElementById('mapSearchContent'))()}
                message="Loading LPR reads, please wait..." />*/}
                <Grid item xs={12}  lg={9} className={classes.openLayersMapWrapper}>
                    <Map
                        center={[state.data.filterSource.customerLongitude, state.data.filterSource.customerLatitude]}
                        markers={state.data.markers}
                        layerType={state.data.filter ? state.data.filter.layerType : MapLayerType.Reads}
                        customerId={state.dashboardFilter.customerId ?? ''}
                        mapFilter={state.data.filter}
                        selectedMarker={state.data.selectedMarker}
                        onSelectedMarker={(selectedMarkerId: string) => dispatch(selectMarker(selectedMarkerId))}
                    />
                </Grid>
                <Grid item xs={12}  lg={3} container className={classes.markersListWrapper} alignContent="stretch" alignItems="stretch" justify="center">
                    <MarkersList
                        isLoading={state.data.isLoading}
                        markers={state.data.filteredMarkers}
                        mapState={state.data}
                        selectedMarker={state.data.selectedMarker}
                        onSelectedMarker={(selectedMarkerId: string) => dispatch(selectMarker(selectedMarkerId))}
                        onClearSelectedMarker={() => dispatch(unselectMarker())}
                        //pagination={state.data.pagination}
                        //onPageChanged={(page: number) => dispatch(changePageAndFetchMarkers(page))}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default MapSearch;
