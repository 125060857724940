/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { Fragment, useState } from 'react';
import { MapMarker, EnforcementDataType } from '../../state/mapDashboardStateV2';
import { Typography, Paper, Button, Grid } from '@material-ui/core';
import { DriveEta as CarIcon, CameraAlt as ImageIcon } from '@material-ui/icons';
import { useStyles } from './markerDetailStyles';
import moment from 'moment';
import OSM from 'ol/source/OSM';
import OLMap from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import readsLayerStyle  from '../Map/readsLayerStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable'; 

type MarkerDetailProps = {
    openModal: boolean;
    marker?: MapMarker | null;
    onClose: () => void;
}


export const MarkerDetail = (props: MarkerDetailProps): JSX.Element => {
    const { openModal, onClose } = props;
    const classes = useStyles();

    const marker = props.marker ? props.marker : {} as MapMarker;

    let fullPlate = marker.plate;
    if (marker.plateState) fullPlate += `-${marker.plateState}`;

    const onClickViewViolationHandler = (violationUrl: string) => {
        window.open(violationUrl, '_blank');
    };

    const onClickViewFilesImagesHandler = (filesImagesUrl: string) => {
        const left = (screen.width/2)-(800/2);
        const top = (screen.height/2)-(800/2);
        const options = `left=${left}, top=${top} , width=800, height=800, location=no, menubar=no, status=no, toolbar=no, scrollbars=yes, resizable=yes`;
        window.open(filesImagesUrl, 'Files/Images', options);
    };

    
    
    const onRendered = () => {
        const featureMarker = new Feature({
            geometry: new Point([marker.longitude, marker.latitude]),
            location_uuid: marker.id,
            ol_uid: marker.id
        });

        featureMarker.setStyle(readsLayerStyle(marker));
        //map with marker
        const newMap = new OLMap({
            target: 'map-detail-container-id',
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                new VectorLayer({
                    source: new VectorSource({
                        features:[featureMarker]
                    }),
                })
            ],
            view: new View({
                center: [marker.longitude, marker.latitude],
                zoom: 17,
                projection: 'EPSG:4326',
            })
            
        });
    };
    
    const [dialogRender, setdialogRender] = useState(() => (props: any) =>{
        return (
             <Draggable
                 handle='#simple-modal-title'
                 cancel={'[class*="MuiDialogContent-root"]'}
                 
             >
                 <Paper {...props} />
             </Draggable>
             );
     });

    return (
        <Dialog
            open={openModal}
            onClose={onClose}
            onRendered={onRendered}
            id='simple-modal-title'
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            PaperComponent={dialogRender}
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}
            hideBackdrop={true}>
            <DialogTitle style={{ cursor: 'move' }} id="simple-modal-title">
                Details
            </DialogTitle>
            <Paper elevation={2} className={classes.modalContent}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="h4">
                    {fullPlate}
                </Typography>
                <IconButton onClick={onClose}>
                        <CloseIcon />
                </IconButton>
            </Grid>
               
                <Typography variant="body2">
                    Date: {moment(marker.dateRecord).format('YYYY/MM/DD hh:mm a')}
                </Typography>
                <Typography variant="body2">
                    Customer: {marker.customerCode}
                </Typography>
                <Typography variant="body2">
                    Type: {EnforcementDataType[marker.type]}
                </Typography>
                <Typography variant="body2">
                    Agent: {marker.agentFullName}
                </Typography>
                {
                    (marker.type === EnforcementDataType.Boot) &&
                    <Typography  variant="body2">
                        Location: {marker.locationName}
                    </Typography>
                }
                {
                    (marker.type === EnforcementDataType.Hit) &&
                    <Typography  variant="body2">
                        Rejected Reason: {(marker.rejectionReason != null  && marker.rejectionReason != '') ? marker.rejectionReason : 'Officer did not set a reason.'}
                    </Typography>
                }
                {
                    (marker.type === EnforcementDataType.Boot && marker.bootRecord) &&
                    <Fragment>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<CarIcon />}
                            onClick={(event) => onClickViewViolationHandler(marker.bootRecord ? marker.bootRecord.violationUrl : '')}>
                            Boot Record
                        </Button>
                        <Button
                            hidden={!marker.bootRecord.filesImagesUrl}
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ImageIcon />}
                            onClick={(event) => onClickViewFilesImagesHandler(marker.bootRecord ? marker.bootRecord.filesImagesUrl : '')}>
                            Files/Images
                    </Button>
                    </Fragment>
                }
                <Paper >
                
                {<div className={classes.map} id='map-detail-container-id' ></div>
            }
                
                </Paper>
            </Paper>
        </Dialog>
    );
};
