import { Button, Drawer, Toolbar } from '@material-ui/core';
import { MenuOpen as OpenSettingsIcon, VisibilityOff as CloseSettingsIcon } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';

import TileSettings from './TileSettings';
import { useStylesTileSettingsDrawer } from './TileSettingsDrawer.styles';

const TileSettingsDrawer = (): JSX.Element => {
  const classes = useStylesTileSettingsDrawer();

  const [isOpen, toggleDrawer] = useState(true);

  const ToggleButton = (): JSX.Element => {
    return (
      <Button
        id='tileSettingsDrawerButton'
        onClick={() => toggleDrawer(!isOpen)}
        variant='contained'
        color='primary'
        style={{
          position: 'absolute',
          top: 64,
          // left: 0,
          right: isOpen ? 500 : 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        className={classes.tileSettingsDrawerButton}
      >
        {isOpen ? <CloseSettingsIcon /> : <OpenSettingsIcon />}
        <span style={{ marginLeft: '.5rem' }}>
          {isOpen ? 'Close' : 'Open'}
        </span>
      </Button>
    )
  }

  const drawerContentClassName = useMemo(() => `${classes.tileSettingsDrawerContent} ${isOpen ? '' : classes.tileSettingsDrawerContentClose}`, [isOpen]);

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
        classes={{
          paper: isOpen ? classes.drawerOpen : classes.drawerClose
        }}
        variant='permanent'
      >
        <Toolbar />
        <div id="drawerContent" className={drawerContentClassName}>
          < TileSettings />
        </div>
      </Drawer>      
      <ToggleButton />
    </>
  )
}

export default TileSettingsDrawer;
