import { FieldColumn } from './templates/types';

export enum ChartType {
    Grid,
    Number,
    Bar,
    Donut,
    Table,
    BarAverage,
    DonutAverage,
    Pie,
    Line,
}

export const getChartTypeName = (type: ChartType | null): string => {
    switch (type) {
        case ChartType.Number:
            return 'NumberChart';
        case ChartType.Bar:
            return 'BarChart';
        case ChartType.Pie:
            return 'PieChart';
        case ChartType.Line:
            return 'LineChart';
        case ChartType.Donut:
            return 'DonutChart';
        case ChartType.Table:
            return 'TableChart';
        case ChartType.Grid:
            return 'GridChart';
        case ChartType.BarAverage:
            return 'BarAverageChart';
        case ChartType.DonutAverage:
            return 'DonutAverageChart';
        default:
            return 'EmptyChart';
    }
}

export enum TileModelCategory {
    Booting,
    EasyLPR,
    HelpCenter
}

export interface TileModelCategoryPoco {
    categoryId: TileModelCategory;
    name: string;
}

// TODO: Static parameters list on backend to map anything it finds on sqlQuery
export interface TileModel {
    tileId?: string;
    name: string;
    chartType: ChartType | null;
    categoryId: TileModelCategory | null,
    databaseId: string;
    sqlQuery: string;
    includedParams?: SqlQueryParam;
    fieldsColumns: FieldColumn[];
    positionX: number;
    positionY: number;
    width: number;
    height: number;
}

export interface TileModelPoco {
    tileId: string;
    name: string;
    chartType: ChartType;
    categoryId: TileModelCategory;
    data: string;
}

export interface TileSettingsModel extends TileModel {
    testValues: TileSettingsTestValues; // TODO: Migrate to query params, for will be the same
}

export interface TileSettingsModelActions {
    setName: (name: string) => void;
    setChartType: (chartType: number) => void;
    setCategoryId: (categoryId: TileModelCategory) => void;
    setDatabaseId: (databaseId: string) => void;
    setSqlQuery: (sqlQuery: string) => void;
    setTestData: (testData: TileSettingsTestValues) => void;
    setFieldsColumns: (columns: FieldColumn[]) => void;
    saveTile: () => void;
    deleteTile: (tileId: string) => void;
}

export interface TileSettingsTestValues {
    customerId: string;
    date: Date | string;
    dateTo: Date | string | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TileParamaterValues extends TileSettingsTestValues { }

export type SqlQueryParam = {
    [x: string]: any;
}

export interface SqlResult {
    errors: string[];
    isValidQuery: boolean;
    tables: {
        columns: {
            name: string;
            type: string;
        }[];
        rows: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values: SqlRowValue[];
        }[];
    }[];
}

export type SqlRowValue = string | number | null;

export interface ColumnViewModel {
    displayName: string;
    name: string;
    type: string;
}

export const CompatibleChartTypes = {
    [ChartType.BarAverage]: [ChartType.DonutAverage]
}

// TODO: Move to API
export const AllAvailableCustomers = [
    { id: '1A224A90-C6E9-4C05-B86F-209D2CE466C9', name: 'Alexandria', code: 'ALX' },
    { id: '57F5F2DD-22E3-4897-A866-BA0962F7BE23', name: 'AnyTown', code: 'AYT' },
    { id: '8560B992-BF0E-4407-B263-7D4D731DA7E2', name: 'Baltimore', code: 'BAL' },
    { id: '86676F97-7258-4C67-A375-C697B566A955', name: 'B&M', code: 'BANDM' },
    { id: 'C7BDE912-4659-450A-AD36-DAFDB92CBC9C', name: 'Berkeley', code: 'BRK' },
    { id: 'D07B3AFA-F0A8-4B1B-9497-F23A652899B1', name: 'Cleveland', code: 'CLE' },
    { id: '44CEAB29-46F6-4E19-9A1B-1497D5B1A2A5', name: 'Demo', code: 'Dem' },
    { id: '2E63A30D-5C62-492A-AE7D-EDC45782F0E5', name: 'Denver/ACS', code: 'DEN' },
    { id: '8FC127B3-AC42-437A-B4E4-36B795C571A1', name: 'Florida Atlantic', code: 'FAU' },
    { id: 'A0E30819-E7F0-4470-9766-F8FE3BA2451E', name: 'Fredericksburg', code: 'FRD' },
    { id: '6169DD5C-248D-4F78-899D-A0C0A6688CDF', name: 'Houston', code: 'HOU' },
    { id: '70FBF4BD-D440-4571-BBBF-3F05176C6F72', name: 'Hoboken Parking Utility', code: 'HPU' },
    { id: '92BA097B-F99E-4A33-B848-94A1114AF4A2', name: 'Jersey City Parking Authority', code: 'JCY' },
    { id: '541981CA-7766-4A38-BFBA-182D059C6621', name: 'Los Angeles', code: 'LADOT' },
    { id: '4BECB4E4-F996-482B-BE1C-79585D2FC003', name: 'Montgomery County', code: 'MCO' },
    { id: 'C1C35BAA-F91C-4F59-8F38-208B3C6C2ECF', name: 'MobileNow', code: 'MOBNOW' },
    { id: '9A5A4921-1FDE-464A-9B9D-AA2285B470F1', name: 'Nassau County', code: 'NAS' },
    { id: '14E8422E-3B05-4BFD-8C99-D05217C85871', name: 'National Grid', code: 'NGD' },
    { id: '7F137581-C421-4A1F-9D05-EDFB3909B528', name: 'INACTIVE-New Orleans', code: 'NOL' },
    { id: 'E53F699F-E89F-46DE-9451-48BFF3454CCD', name: 'New Orleans', code: 'NOLA' },
    { id: '2D9AE98F-AB4F-4BED-89AF-9BD43DBC18E8', name: 'Norwalk', code: 'NOR' },
    { id: '55870B6A-5A70-4515-B210-822132AF70A3', name: 'NOVA', code: 'NOVA' },
    { id: '6ED6BAE5-8702-4133-AFA0-9DE5FD46D60E', name: 'New York City', code: 'NYC' },
    { id: '475B6ACD-D1C6-4D3C-A797-0A8333A83A53', name: 'NYCHA', code: 'NYCHA' },
    { id: '04260990-F298-4E85-9625-B58E06C9DCA9', name: 'Oakland', code: 'OAK' },
    { id: '4B424FD1-5A78-4AF4-9657-0E822EDC92DA', name: 'old', code: 'oldbandm' },
    { id: 'A96D1CED-156C-45AD-8937-31B7A3851D92', name: 'Pasadena', code: 'PAS' },
    { id: '922BB239-A00A-413A-8605-84D0CDC63EDD', name: 'PayLock Demo', code: 'PAY' },
    { id: '924F2F61-28C4-4C35-B2CD-4CBE92E7A082', name: 'Prince George\'s County', code: 'PGC' },
    { id: '097D1381-F555-43FB-8ABB-C06A100575FB', name: 'PGC Collections', code: 'PGCCOLL' },
    { id: '57FE9416-ADD7-45BC-AC18-7EA1738D28F2', name: 'Paylock', code: 'PLK' },
    { id: 'CBEFC8E6-4844-4363-A5A4-811B42EAA678', name: 'Protocol Parking', code: 'PP' },
    { id: 'EDEADA74-836B-4189-B92F-DFC132833497', name: 'Providence', code: 'PRV' },
    { id: 'B01C3192-E361-4221-8F14-C9F6E5D27376', name: 'Richmond/Lanier', code: 'RIC' },
    { id: '0DF66086-D0EB-418A-B53C-78CFB9895E01', name: 'Rochester', code: 'ROC' },
    { id: 'D44193D1-2485-4961-ADCE-278574F7BDD2', name: 'Seattle', code: 'SEA' },
    { id: '803CEEC7-F468-4547-A181-89CA08D61B12', name: 'Salt Lake City', code: 'SLC' },
    { id: 'AF8A3402-4253-43EA-9167-33904D290ED2', name: 'Southern Methodist University', code: 'SMU' },
    { id: '6C085AA7-6101-4F5C-8750-1CAE583AE92D', name: 'Stamford', code: 'STM' },
    { id: '35D4B33B-CF5C-4DBC-861A-998A40F30471', name: 'Suffolk County', code: 'SUF' },
    { id: '097F9FD1-53B0-413B-A4CF-416C32CA5D73', name: 'Syracuse', code: 'SYR' },
    { id: '37CF1551-7888-4851-8FEC-CC1F8E476402', name: 'Texas A&M', code: 'TAMU' },
    { id: '1B13A308-EC4C-4348-B9B7-AB66839AB337', name: 'TACS', code: 'TCS' },
    { id: 'AF06BA9A-255F-4C1F-88DE-4F17540E0690', name: 'Wilmington', code: 'WIL' },
    { id: '3A24D918-2814-4BF8-A0E8-9CFA3AC57A58', name: 'Wilmington/Tow', code: 'WILMT' },
    { id: '8619F8F5-0123-4681-AA7C-7ADD483156E0', name: 'YKS', code: 'YKS' }
]

// TODO: Make it a dynamic list, make it a whole feature to include paramaters like in report builder
export const availableParams: SqlQueryParam[] = [
    { name: '@Customer_Id', displayName: 'Customer Id' },
    { name: '@Customer_Code', displayName: 'Customer Code' },
    { name: '@Start_Date', displayName: 'Start Date' },
    { name: '@End_Date', displayName: 'End Date' },
]