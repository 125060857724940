import { createStyles, makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 500;

export const useStylesTileSettingsDrawer = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      // flexShrink: 0,
      // whiteSpace: 'nowrap',
      '& .MuiDrawer-paper': {
        backgroundColor: 'transparent',
        borderLeft: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('witdh', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      zIndex: 2,
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 0,
      // [theme.breakpoints.up('sm')]: {
      //   width: theme.spacing
      // }
    },
    tileSettingsDrawerContent: {
      position: 'absolute',
      top: 65,
      right: 0,
      border: '1px solid #E2E2E2',
      // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    tileSettingsDrawerContentClose: {
      display: 'none',
      visibility: 'hidden',
    },
    tileSettingsDrawerButton: {
      position: 'absolute',
      // top: 80,
      // left: 20,
    },
  }),
);
