import { Paper, Typography, IconButton, Divider, TextField, Modal, Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { BaseChartProps, TileUserSettings } from '../types';
import {
  Subject as TitleIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
  PieChart as PieChartIcon,
  Timeline as TimelineIcon,
  TableChart as TableChartIcon,
  InsertChart as InsertChartIcon
} from '@material-ui/icons';
import useStyles from './ChartUserSettings.styles';
import { ChartType } from '../../tileBuilderTypes';

interface ChartUserSettingsProps {
  isOpen: boolean;
  onClose?: () => void;
  onChartTypeSelected: (chartType: ChartType) => void;
  onSave?: (settings: TileUserSettings) => void;
}

const ChartUserSettings = (props: ChartUserSettingsProps) => {
  const {
    isOpen,
    onClose,
    onChartTypeSelected
  } = props;

  const classes = useStyles();

  return (
    <Modal
      id='tileSettingsModal'
      open={isOpen}
      style={{ width: 600, top: '25%', left: '25%' }}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      container={document.body}>
      <Paper>
        <Grid container direction='row' justify='center' alignItems='center' alignContent='space-between'>
          <Typography variant='h6'>
            Chart Type
          </Typography>
          <IconButton aria-label="close" onClick={onClose} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container direction='row' justify='space-between' alignItems='center' style={{ padding: 12, paddingBottom: 0 }}>
          <Grid item md={6} container direction="row" justify='center' alignItems="center" alignContent="center">
            <IconButton className={classes.interfaceTypeButton} aria-label="close" onClick={() => onChartTypeSelected(ChartType.Bar)} style={{ border: '1px solid black' }}>
              <InsertChartIcon style={{ width: 90, height: 90 }} />
              <Typography>
                Bar
              </Typography>
            </IconButton>
          </Grid>
          <Grid item md={6} container direction="row" justify='center' alignItems="center" alignContent="center">
            <IconButton className={classes.interfaceTypeButton} aria-label="close" onClick={() => onChartTypeSelected(ChartType.Pie)} style={{ border: '1px solid black' }}>
              <PieChartIcon style={{ width: 90, height: 90 }} />
              <Typography>
                Pie
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction='row' justify='center' alignItems='center' style={{ margin: 12 }}>
          <Typography>
            Settings
          </Typography>
        </Grid>
        <Grid container direction='row' style={{ padding: 12 }}>
          {/* <Grid item md={12} container direction="row" style={{ marginBottom: 12 }}>
                        <FormControlLabel control={<Checkbox name="checkedC" />} label="Weekly Avg." />
                    </Grid> */}
          <Grid item md={12} container direction="row" style={{ marginBottom: 12 }}>
            <TextField
              style={{ backgroundColor: '#DC3545' }}
              id="red-uncontrolled"
              label="Red Threshold"
              defaultValue="5"
              variant="filled"
            />
          </Grid>
          <Grid item md={12} container direction="row" style={{ marginBottom: 12 }}>
            <TextField
              style={{ backgroundColor: '#FFC107' }}
              id="yellow-uncontrolled"
              label="Yellow Threshold"
              defaultValue="10"
              variant="filled"
            />
          </Grid>
          <Grid item md={12} container direction="row" style={{ marginBottom: 12 }}>
            <TextField
              style={{ backgroundColor: '#28A745' }}
              id="green-uncontrolled"
              label="Green Threshold"
              defaultValue="15"
              variant="filled"
            />
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
};

export default ChartUserSettings;