import {
    AppBar,
    Box,
    Collapse,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Toolbar,
} from '@material-ui/core';
import {
    AddBox as AddIcon,
    Assessment as ReportsIcon,
    Dashboard as DashboardIcon,
    ExitToApp as LogoutIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Map as MapIcon,
    Menu as MenuIcon,
} from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFatureToogle } from '../../app/session/sessionThunks';

import { SessionState } from '../../app/session/sessionTypes';
import { hasAccessToBuilders } from '../../app/session/utils';
import { ApplicationState } from '../../app/store';
import { Get } from '../../services/RestClient';
import { DashboardSetup } from '../dashboard-builder/types';
import { DashboardFilterState } from '../dashboards/state/DashboardFilterState';
import { useStyles } from './LeftNavigationMenuStyles';
import { ListItemLink } from './ListItemLink';

export default function LeftNavigationMenu(): JSX.Element {
    const classes = useStyles();

    const dashboardFilter = useSelector((state: ApplicationState) => state.dashboardFilter as DashboardFilterState);
    const session = useSelector((state: ApplicationState) => state.session as SessionState);

    const [selectedCustomerCode, setSelectedCustomerCode] = useState('paylock');
    const [selectedCustomerSubDomain, setSelectedCustomerSubDomain] = useState<null | string>(null);
    const [bootViewMapMenuOpen, setBootViewMapMenuOpen] = useState(false);
    const [showScenarios, setShowScenarios] = useState(false);
    const [showGpsDevices, setShowGpsDevices] = useState(false);

    useEffect(() => {
        let selectedCustomerCode = 'paylock';
        let selectedCustomerSubDomain = null;
        if (session.currentUser !== null) {
            const selectedCustomer = session.currentUser.customers.find(customer => customer.id === dashboardFilter.customerId);
            if (selectedCustomer) {
                selectedCustomerCode = selectedCustomer.code;
                selectedCustomerSubDomain = selectedCustomer.parkingviewClientSubDomain;
            }
        }

        setSelectedCustomerSubDomain(selectedCustomerSubDomain);
        setSelectedCustomerCode(selectedCustomerCode);
    }, [dashboardFilter.customerId]);

    const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
    const [myDashboardsMenuOpen, setMyDashboardsMenuOpen] = useState(true);

    const [customDashboards, setCustomDashboards] = useState([] as DashboardSetup[]);

    const handleLogoError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const images = document.getElementsByClassName('main-logo');
        for (let i = 0; i < images.length; i++) {
            images[i].setAttribute('src', '/img/customers/paylock.jpeg');
        }
    };

    useEffect(() => {
        getFatureToogle(session.currentUser?.id, 'NBA-830-show-hide-scenario-link').then((response)=>{
            setShowScenarios(response);
        });
        getFatureToogle(session.currentUser?.id, 'NBA-948-show-hide-gpsdevices-link').then((response)=>{
            setShowGpsDevices(response);
        });
    }, []);


    // TODO: Validate customerId
    // TODO: Add loader
    const fetchCustomDashboards = () => {
        Get({
            url: `${process.env.REACT_APP_BOOTVIEW_SERVICES_URL}/stattrak/dashboardBuilder/all`,
            params: {
                userId: null,
            },
        }).then((dashboards: DashboardSetup[]) => {
            setCustomDashboards(dashboards);
        });
    };

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const queryParameters = useQuery();

    // useEffect(() => {
    //     if (customDashboards) {
    //         const defaultDashboard = customDashboards.find((d: DashboardSetup) => d.isDefault === true);
    //         if (defaultDashboard) {

    //             if (!window.location.search.includes('defaulted')) {
    //                 window.location.href = `/customer-dashboards/custom/${defaultDashboard.id}?defaulted=true`;
    //             }
    //             console.log('queryParameters >>>', window.location);
    //             // window.location.href = `/customer-dashboards/custom/${defaultDashboard.id}?defaulted=true`;
    //         }
    //     }
    // }, [customDashboards]);

    // const routeParams = useParams();
    // const location = useLocation();

    const showBuilders: boolean = useMemo(() => hasAccessToBuilders(session.currentUser), [session.currentUser])

    useEffect(() => {
        if (showBuilders) {
            fetchCustomDashboards();
        }
    }, [showBuilders]);

    // const [customComponent, setCustomComponent] = useState(<React.Fragment></React.Fragment>);

    // useEffect(() => {
    //     console.log('location>>>', location, routeParams);
    //     if (location.pathname.toLowerCase().includes('/customer-dashboards/custom/')) {
    //         setCustomComponent(<CustomDashboardOptions />);
    //     }
    // }, [routeParams.dashboardId]);

    // const handleOnEditClick = () => {
    //     console.log('edit >>>', routeParams.dashboardId);
    // };

    // const handleOnDeleteClick = () => {
    //     console.log('delete >>>', routeParams.dashboardId);
    // };

    // const CustomDashboardOptions = (): JSX.Element => {
    //     return (
    //         <Box id="dashboardOptions" style={{ marginTop: '0', position: 'absolute', bottom: 12, left: 12, width: '90%' }}>
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 className={classes.saveButton}
    //                 startIcon={<SaveIcon />}
    //                 onClick={() => handleOnEditClick()}>
    //                 Edit
    //             </Button>
    //             <Button
    //                 variant="contained"
    //                 color="secondary"
    //                 className={classes.deleteButton}
    //                 startIcon={<DeleteIcon />}
    //                 onClick={() => handleOnDeleteClick()}>
    //                 Delete
    //             </Button>
    //         </Box>
    //     );
    // };

    return (
        <Box>
            <Hidden mdUp>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                    <Collapse in={hamburgerMenuOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                        <MenuItems />
                    </Collapse>
                </AppBar>
                <List component="div" disablePadding className={classes.logoWrapper}>
                    <ListItem className={classes.logo}>
                        <img className="main-logo" src={`/img/customers/${selectedCustomerCode}.jpeg`} onError={handleLogoError} />
                    </ListItem>
                </List>
            </Hidden>
            <Hidden smDown>
                <Box boxShadow={3}>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{ paper: classes.drawerPaper }}
                        anchor="left">
                        <List component="div" disablePadding className={classes.logoWrapper}>
                            <ListItem className={classes.logo}>
                                <img className="main-logo" src={`/img/customers/${selectedCustomerCode}.jpeg`} onError={handleLogoError} />
                            </ListItem>
                        </List>
                        <Divider />
                        <MenuItems />
                    </Drawer>
                </Box>
            </Hidden>
        </Box>
    );

    function MenuItems(): JSX.Element {
        return (
            <React.Fragment>
                <List>
                    <ListItem button onClick={() => setMyDashboardsMenuOpen(!myDashboardsMenuOpen)} className={classes.menuElement}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Stat-trak" />
                        {myDashboardsMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={myDashboardsMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                // TODO: Add loading modal.
                                showBuilders && customDashboards && customDashboards?.map((dashboard) =>
                                    <ListItemLink key={dashboard.id} primary={dashboard.name} to={`/customer-dashboards/custom/${dashboard.id}`} />
                                )
                            }
                        </List>
                        <List component="div" disablePadding>
                            <ListItemLink primary="Main Dashboard" to="/" />
                            {/* <ListItemLink primary="Enforcement" to="/customer-dashboards/enforcement" /> */}
                            {
                                showBuilders &&
                                <>
                                    <ListItemLink primary="Add Dashboard" to="/customer-dashboards/builder" icon={<AddIcon />} />
                                    <ListItemLink primary="Tile Builder" to="/customer-dashboards/tile-builder" icon={<DashboardIcon />} />
                                </>
                            }
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem component={Link} button underline="none" className={classes.menuElement}>
                        <ListItemIcon>
                            <ReportsIcon />
                        </ListItemIcon>
                        <Link rel="noopener noreferrer" href='http://reporting.paylock.com/portal/' underline="none" target="_blank">
                            Report Portal
                        </Link>
                    </ListItem>
                    <ListItem button onClick={() => setBootViewMapMenuOpen(!bootViewMapMenuOpen)} className={classes.menuElement}>
                        <ListItemIcon>
                            <MapIcon />
                        </ListItemIcon>
                        <ListItemText primary="Mapping Portal" />
                        {bootViewMapMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={bootViewMapMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                showScenarios &&
                                <ListItemLink primary="Scenarios" to="/customer-dashboards/scenarios" openTab={true} />
                            }
                            <ListItemLink primary="Enforcement Live" to="/customer-dashboards/live-map" openTab={true} />
                            <ListItemLink primary="Opti-Route" to="/customer-dashboards/optiroute" openTab={true} />
                            {
                                showGpsDevices &&
                                <ListItemLink primary="GPS Devices" to="/customer-dashboards/gpsdevices" openTab={true} />
                            }
                            
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <BootViewMenu />
                {
                    (selectedCustomerSubDomain !== null && selectedCustomerSubDomain !== '') &&
                    <>
                        <Divider />
                        <List>
                            <ListItem component={Link} button underline="none" className={classes.menuElement}>
                                <ListItemIcon>
                                    <img className={classes.cameraIcon} src={'/img/Camera.png'} onError={handleLogoError} />
                                </ListItemIcon>
                                <Link rel="noopener noreferrer" target="_blank" href={`http://${selectedCustomerSubDomain}.myparkinginfo.com/cws/admin/`} underline="none">
                                    Parking View
                                </Link>
                            </ListItem>
                        </List>
                    </>
                }
                <Divider />
                <List>
                    <ListItem button className={classes.menuElement}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <Link href={`${process.env.REACT_APP_BOOTVIEW_URL}/Logout.aspx`}>
                            Sign Out
                        </Link>
                    </ListItem>
                </List>
                {/* {customComponent} */}
            </React.Fragment>
        );
    }
}

const useBootViewMenuStyles = makeStyles((theme) => ({
    bootItemContainer: {
        paddingTop: 0,
        paddingBottom: 0, // TODO: MERGE STYLING
        '& .MuiListItemIcon-root': {
            marginRight: '.5rem !important',
            minWidth: '0px',
        },
    },
    nested: {
        paddingLeft: theme.spacing(9.25),
        '& .MuiTypography-root': {
            paddingBottom: theme.spacing(.6),
            paddingTop: theme.spacing(.6),

            '& > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
                paddingTop: theme.spacing(2),
            },
        },
    },
    bootIcon: {
        color: '#eae9ef',
        width: 18,
        display: 'inline-block',
        marginRight: '.5rem !important',
    },
}));

const BootViewMenu = (): JSX.Element => {
    const classes = useBootViewMenuStyles();

    const [bootViewMenuOpen, setBootViewMenuOpen] = useState(true);

    const handleLogoError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const images = document.getElementsByClassName('main-logo');
        for (let i = 0; i < images.length; i++) {
            images[i].setAttribute('src', '/img/customers/paylock.jpeg');
        }
    };

    return (
        <List>
            <ListItem className={classes.bootItemContainer} button onClick={() => setBootViewMenuOpen(!bootViewMenuOpen)}>
                <ListItemIcon>
                    <img className={classes.bootIcon} src={'/img/Boot.png'} onError={handleLogoError} />
                </ListItemIcon>
                <ListItemText primary="BootView" />
                {bootViewMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={bootViewMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemLink primary="BootView Summary" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/Dashboard.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Notice a Car" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/NoticeDefinition.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Boot a Car" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/BootRecordDefinition.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Tow a Car" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/TowCar.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Release a Boot" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/AssistedReleases.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Return a Boot" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/BootReturnSearch.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Contact a Motorist" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/ContactMotoristSearch.aspx`} isSubmenu={true} />
                    <ListItemLink primary="Search" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/Search/Default.aspx#/boot`} isSubmenu={true} />
                    <ListItemLink primary="BootView Reports" href={`${process.env.REACT_APP_BOOTVIEW_URL}/Secure/ReportsLink.aspx`} isSubmenu={true} />
                </List>
            </Collapse>
        </List>
    )
}
