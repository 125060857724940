import { AppBar, Box, CssBaseline, Grid, Toolbar, Typography, Collapse, Button, useMediaQuery } from '@material-ui/core';
import { Dashboard as DashboardIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clone from 'rfdc';

import { Boots, ColorScheme, HelpCenter, ImportFiles, InformationOverTime } from '.';
import { ApplicationState } from '../../../app/store';
import { DashboardFilterBar } from '../Commons';
import Error from '../Commons/Error/Error';
import * as DashboardFilterState from '../state/DashboardFilterState';
import Enforcement from './Enforcement/Enforcement';
import { useStyles } from './MainDashboardStyles';
import { MainDashboardState } from './state/MainDashboardState';
import { loadMainDashboardData } from './state/mainDashboardThunks';


export default function MainDashboard(): JSX.Element {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const [bootViewMenuOpen, setBootViewMenuOpen] = useState(true);
    const classes = useStyles();

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mainDashboard as MainDashboardState,
        };
    };

    const state = useSelector(selector);
    const dispatch = useDispatch();

    const applyFilterAndLoadData = () => {
        dispatch(loadMainDashboardData());
        setDashboardFilter(clone({ proto: true })(state.dashboardFilter));
    };

    useEffect(() => {
        dispatch(DashboardFilterState.updateFilterType({ filterType: 'average' }));
    }, []);

    const [dashboardFilter, setDashboardFilter] = useState({} as DashboardFilterState.DashboardFilterState);

    useEffect(() => {
        setDashboardFilter(clone({ proto: true })(state.dashboardFilter));
    }, [state.dashboardFilter]);

    return (
        <Box>
            <CssBaseline />
            <Collapse in={bootViewMenuOpen} timeout="auto" unmountOnExit>
                <AppBar className={classes.appBar} elevation={1} position="sticky" style={{ paddingLeft: 1 }}>
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center" alignContent="center">
                            <Grid item xs={12} md={12} lg={2} container alignItems="center" alignContent="center">
                                <DashboardIcon className={classes.iconDashboard} />
                                <Typography variant="h6" color="inherit" className={classes.title}>
                                    Dashboard
                                    <Typography variant="h6" color="inherit" className={classes.subTitle}>
                                        by Stat-Trak
                                    </Typography>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={12} lg={8} container justify="flex-start" alignItems="center" alignContent="center">
                                <Box id="DashboardLabel" className={classes.filterBar}>
                                    <DashboardFilterBar onApply={applyFilterAndLoadData} applyEnabled={!state.data.isLoading} />
                                </Box>
                            </Grid>

                            {
                                matches
                                ? null
                                : 
                                <Grid item xs={1} md={1} lg={1} container justify="flex-end" alignItems="center" alignContent="center">
                                    <p style={{textOrientation: 'upright', writingMode:'vertical-lr', fontSize:'12px', fontWeight: 800, paddingRight: '27px'}}>LEGEND</p>
                                </Grid>
                            }

                            <Grid item xs={12} md={6} lg={1} xl={matches ? 2 : 1} container justify="flex-end" alignItems="center" alignContent="center" >
                                <ColorScheme />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Collapse>
            <Grid container direction="row" alignItems="center" alignContent="center">
                <Button
                    style={{ borderTopLeftRadius:0, borderTopRightRadius:0, borderBottomLeftRadius:0  }}
                    endIcon={bootViewMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => setBootViewMenuOpen(!bootViewMenuOpen)}>
                    {bootViewMenuOpen ? 'Hide' : 'Show'}
                </Button>
            </Grid>

            {
                state.data.error &&
                <Error label={`${state.data.error}`} />
            }
            {
                (state.data.isLoading || state.data.initialized) &&
                <Box className={classes.root}>
                    <Grid container spacing={3} direction="row" justify="space-between" alignItems="stretch" alignContent="stretch">
                        <Grid item xs={12}>
                            <ImportFiles filter={dashboardFilter} />
                        </Grid>
                        {/* <Grid item xs={12} md={3} style={{ display: 'none' }}>
                            <Grid container spacing={3} direction="row" justify="flex-end" alignItems="stretch" alignContent="stretch">
                                <Grid item xs={12}>
                                    <Enforcement filter={dashboardFilter} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Boots filter={dashboardFilter} />
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={12}>
                                    <InformationOverTime filter={dashboardFilter} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={12}>
                                    <HelpCenter filter={dashboardFilter} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
}
