import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { updateFilterAction, updateFilterTypeAction } from './DashboardFilterActions';
import Cookies from 'js-cookie';
import { Scenario } from '../Scenarios/state/mapDashboardStateV2';


export interface DashboardFilterState {
    scenario: number;
    sceneType: number;
    customerId: string;
    type: 'average' | 'range';
    daysToAverage: number;
    averageDescription: string;
    excludeAverage: boolean;
    date: string;
    dateTo: string | null;
    weekDays: string [];
    occurrences: string [];
}

const dashsboardFilterSlice = createSlice({
    name: 'dashboardFilter',
    initialState: {
        scenario: Scenario.Boots,
        sceneType:0,
        customerId: '',
        daysToAverage: 7,
        type: 'average',
        averageDescription: '1 Weeks',
        excludeAverage: false,
        date: moment(new Date()).format('YYYY/MM/DD hh:mm a'),
        dateTo: moment(new Date()).format('YYYY/MM/DD hh:mm a'),
        weekDays: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
        occurrences: ['1','2','3'],
    } as DashboardFilterState,
    reducers: {
        updateScenario: (state, action: PayloadAction<({ scenario: number, description: string })>) => {
            return {
                ...state,
                scenario: action.payload.scenario
            };
        },
        updateSceneType: (state, action: PayloadAction<({ sceneType: number, description: string })>) => {
            return {
                ...state,
                sceneType: action.payload.sceneType
            };
        },
        updateCustomer: (state, action: PayloadAction<({ customerId: string })>) => {
            const newState = { 
                ...state,
                customerId: action.payload.customerId,
            };
            //make sure to set the dashboard filter cookie after changing customer
            Cookies.set('dashboardFilter', newState);
            return newState;
        },
        updateDate: (state, action: PayloadAction<({ date: Date })>) => {
            const formattedDate = moment(action.payload.date).format('YYYY/MM/DD hh:mm a');
            return {
                ...state,
                date: formattedDate,
            };
        },
        updateDaysToAverage: (state, action: PayloadAction<({ daysToAverage: number, description: string })>) => {
            return {
                ...state,
                daysToAverage: action.payload.daysToAverage,
                averageDescription: action.payload.description
            };
        },
        updateDateTo: (state, action: PayloadAction<({ date: Date })>) => {
            const formatteDate = moment(action.payload.date).format('YYYY/MM/DD hh:mm a');
            return {
                ...state,
                dateTo: formatteDate,
            };
        },
        updateWeekDays: (state, action: PayloadAction<({ weekDays: string[] })>) => {
            return {
                ...state,
                weekDays: action.payload.weekDays,
            };
        },
        updateOccurrences: (state, action: PayloadAction<({ occurrences: string[] })>) => {
            return {
                ...state,
                occurrences: action.payload.occurrences,
            };
        },
        updateFilterType: updateFilterTypeAction,
        updateFilter: updateFilterAction,
    },
});

export const { actions, reducer } = dashsboardFilterSlice;
export const { updateCustomer, updateDate, updateDaysToAverage, updateFilterType, updateDateTo, updateWeekDays, updateFilter, updateOccurrences, updateSceneType, updateScenario } = actions;
