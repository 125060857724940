import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles as useAverageCardStyles } from '../../dashboards/Commons/AverageCard/AverageCardStyles';

export default function FileDateCardSkeleton (): JSX.Element {
    const classes = useAverageCardStyles();

    return (<Box className={classes.cardContent} justifyContent="space-between">
        <Grid container justify="center" alignItems="center" alignContent="center">
            <Grid item xs={6}>
                <Skeleton width="50%" variant="text" animation={false} />
            </Grid>
            <Grid item xs={6} container justify="flex-end">
                <Skeleton width="60%" variant="text" animation={false} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton height="10px" variant="rect" animation="wave" />
            </Grid>
            <Grid item xs={6}>
                <Skeleton width="60%" animation={false} />
            </Grid>
        </Grid>
    </Box>);
}
