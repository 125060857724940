/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { Fragment } from 'react';
import { MapMarker, EnforcementDataType, MapSourceType, MapDashboardStateModel } from '../../state/mapDashboardStateV2';
import { Modal, Typography, Paper, Button, Grid } from '@material-ui/core';
import { DriveEta as CarIcon, CameraAlt as ImageIcon } from '@material-ui/icons';
import { useStyles } from './dataDetailStyles';
import moment from 'moment';
import OSM from 'ol/source/OSM';
import OLMap from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import readsLayerStyle  from '../Map/readsLayerStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationState } from '../../../../../app/store';
import { DashboardFilterState } from '../../../state/DashboardFilterState';
import { useSelector } from 'react-redux';
import { SessionState } from '../../../../../app/session/sessionTypes';

type MarkerDetailProps = {
    openModal: boolean;
    markers?: MapMarker [] | null;
    onClose: () => void;
    mapState: MapDashboardStateModel;
}


export const DataDetail = (props: MarkerDetailProps): JSX.Element => {
    const { openModal, onClose, mapState } = props;
    const classes = useStyles();

    const selector = (state: ApplicationState) => {
        return {
            session: state.session as SessionState,
            dashboardFilter: state.dashboardFilter as DashboardFilterState,
        };
    };
    const globalState = useSelector(selector);

    const currentCustomer = globalState.session.currentUser?.customers.find(x => x.id == globalState.dashboardFilter.customerId);

    let showDebtInformationDetail = false;
    let showDebtInformationSummary = false;
    if(currentCustomer!= null)
    {
        showDebtInformationDetail = currentCustomer.hotlistVehicleDebtDetail;
        showDebtInformationSummary = currentCustomer.hotlistVehicleDebtSummary;
        //showDebtInformation = mapState.debtVehiclesInHotlist != null && mapState.debtVehiclesInHotlist > 0;
    }

    let hotlistTotal =  '0';
    if(showDebtInformationDetail)
    {
        hotlistTotal = mapState.debtVehiclesInHotlist != null ? mapState.debtVehiclesInHotlist.toLocaleString() : '0';
    }
    else if(showDebtInformationSummary)
    {
        hotlistTotal = mapState.debtVehiclesInHotlistSummary != null ? mapState.debtVehiclesInHotlistSummary.toLocaleString() : '0';
    }
    

    const markers = props.markers ? props.markers : {} as MapMarker[];
    let dateRange = '';
    let hourRange = '';
    let sourceTypes = '';
    let debtRange = '';
    let runaways = '';

    if(globalState.dashboardFilter!= null)
    {
        dateRange  = moment(globalState.dashboardFilter.date).format('YYYY/MM/DD')+ ' - '+  moment(globalState.dashboardFilter.dateTo).format('YYYY/MM/DD');
    }

    if(mapState.filter != null)
    {
        hourRange = mapState.filter.fromTime+ ' - '+ mapState.filter.toTime;

        mapState.filter.sourceTypes.forEach(source => {
            sourceTypes +=  MapSourceType[source as unknown as number]  + ',';
        });
        sourceTypes = sourceTypes.slice(0,-1);

        debtRange = mapState.filter.debtRange.length == 1 ? '($) '+mapState.filter.debtRange[0] : 'Multiple Selected';
        
        mapState.filter.runAways.forEach(runaway => {
            runaways +=  runaway + ',';
        });
        runaways = runaways.slice(0,-1);
    }

    const uniquePlates: Array<string> = [];
    let uniquePlatesDebt = 0;

    markers.forEach((marker: MapMarker)=> {
        if(uniquePlates.find(x => x == marker.licPlate) == null)
        {
            uniquePlates.push(marker.licPlate);
            uniquePlatesDebt += marker.amtDue;
        }
    });
    /*const onRendered = () => {
        
    };*/
    


    return (
        <Modal
            open={openModal}
            onClose={onClose}
            //onRendered={onRendered}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}>
            <Paper elevation={2} className={classes.modalContent}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Typography variant="h4">
                   Details
                </Typography>
                <IconButton onClick={onClose}>
                        <CloseIcon />
                </IconButton>
            </Grid>
                <Typography variant="body2">
                  Criteria:
                  <ul>
                        <li>Date Range: <strong>{dateRange}</strong></li>
                        <li>Hour Range: <strong>{hourRange}</strong></li>
                        <li>Source: <strong>{sourceTypes}</strong></li>
                        <li>Debt: <strong>{debtRange}</strong></li>
                        <li>Runaways: <strong>{runaways}</strong></li>
                    </ul>
                </Typography>
                <Typography variant="body2">
                   Vehicle info:
                   <ul>
                        <li># Vehicles in Hotlist: <strong>{mapState.vehiclesInHotlist}</strong></li>
                        <li># of Vehicles Found using LPR: <strong>{uniquePlates.length}</strong></li>
                        <li>% of Vehicles Found on Hotlist: <strong>{mapState.vehiclesInHotlist != 0 && uniquePlates.length != 0 ? (uniquePlates.length/(mapState.vehiclesInHotlist/100)).toFixed(2) : 0}%</strong></li>
                        <li># Vehicles were seen: <strong>{markers.length}</strong></li>
                    </ul>
                </Typography>
                {
                (showDebtInformationDetail || showDebtInformationSummary) &&
                <Typography variant="body2">
                    Debt: 
                    <ul>
                        <li>$ of Vehicles on Hotlist: <strong>${hotlistTotal}</strong></li>
                        {showDebtInformationDetail && <li>$ of Vehicles Found on Hotlist: <strong>${uniquePlatesDebt.toLocaleString()}</strong></li>}
                        {showDebtInformationDetail && <li>% of $ Found on Hotlist: <strong>{mapState.debtVehiclesInHotlist != 0 && uniquePlatesDebt != 0 ? (uniquePlatesDebt/(mapState.debtVehiclesInHotlist/100)).toFixed(2) : 0}%</strong></li>}
                    </ul>
                </Typography>
                }
            </Paper>
        </Modal>
    );
};
