import React from 'react';
import { Card, CardContent, Typography, Divider } from '@material-ui/core';
// import { KeyboardArrowUp as ArrowUpIcon, KeyboardArrowDown as ArrowDownIcon  } from '@material-ui/icons';
import AverageCardSkeleton from './AverageCardSkeleton';
import AverageCardProgressBar from './AverageCardProgressBar';
import { useStyles } from './AverageCardStyles';

type AverageCardProps = {
    title: string;
    subtitle: string;
    total: number;
    averagePerPeriod: number;
    isCurrency?: boolean | undefined;
    isLoading?: boolean | undefined;
    averagesCollection?: Array<[string, number]>;
    className?: string | undefined;
    variant?: 'outlined' | 'elevation' | undefined;
}

export default function AverageCard (props: AverageCardProps): JSX.Element {
    const { title, subtitle, total, averagePerPeriod, isCurrency, isLoading, averagesCollection = Array<[string, number]>(), className, variant = 'outlined' } = props;

    const classes = useStyles();
    const statusClass = getStatusClass();
    const format = !isCurrency ? {} : { style: 'currency', currency: 'USD' };
    const formattedTotal = total.toLocaleString('en-US', format);
    const formattedAveragePerPeriod = Math.round(averagePerPeriod).toLocaleString('en-US', format);

    return (
        <Card className={`${classes.root} ${statusClass} ${className}`} elevation={1} variant={variant}>
            {
                isLoading
                    ? <AverageCardSkeleton />
                    : <CardContent className={classes.cardContent}>
                        <div className={classes.mainLabels}>
                            <Typography variant="button">{title}</Typography>
                            <Typography variant="h5" className="value">
                                {formattedTotal}
                            </Typography>
                        </div>
                        <div className={classes.secondaryLabels}>
                            <Typography variant="body2">{subtitle} Avg.</Typography>
                            <Typography variant="body2">{formattedAveragePerPeriod}</Typography>
                        </div>
                        <AverageCardProgressBar value={total} averageValue={averagePerPeriod} />
                        {
                            averagesCollection.map((avg, index) =>
                                <React.Fragment key={index}>
                                    <Divider className={classes.divider} />
                                    <div className={classes.secondaryLabels}>
                                        <Typography variant="body2">
                                            {avg[0]}
                                        </Typography>
                                        <Typography variant="body2">
                                            {avg[1]}
                                        </Typography>
                                    </div>
                                    {/* <AverageCardProgressBar value={value} averageValue={avg[1]} /> */}
                                </React.Fragment>
                            )
                        }
                    </CardContent>
            }
        </Card>
    );

    function getStatusClass () {
        if (total === 0 && averagePerPeriod === 0)
            return 'error';
        else if (total >= averagePerPeriod)
            return 'success';
        else if (total > (averagePerPeriod / 2))
            return 'warning';

        return 'error';

        // return value === 0 && averageValue === 0 ? 'error' : value >= averageValue ? 'success' : value > (averageValue / 2) ? 'warning' : 'error';
    }
}


