import * as turf from '@turf/turf';
import GeoJson from 'ol/format/GeoJSON';
import Feature, { FeatureLike } from 'ol/Feature';
import { Position } from '@turf/turf';



export function polygonDivide(polygonFeature: Feature, nDivisions: number) {//, colors
    const formatGeoJSON = new GeoJson; 
    const polygon = formatGeoJSON.writeFeatureObject(polygonFeature);
    const polygonBbox = turf.bbox(polygon);

    const colors: string[] = [];
    for (let i = 0; i < nDivisions; i++) {
      colors[i] = getRandomRgb(0.5);
    }
  
    //Create big number (let's say 5000) of random points in the bounding box of the polygon
    const randomPoints = turf.randomPoint(5000, {bbox: polygonBbox});
    
    //Filter out points that fall outside of the polygon 
    randomPoints.features = randomPoints.features.filter((feature) => {
      return(turf.booleanPointInPolygon(feature.geometry.coordinates, polygon as any));
    });
  
    //Group points into N clusters 
    const clusteredPoints = turf.clustersKmeans(randomPoints, {
      numberOfClusters: nDivisions,
    });
    
    //Get centroids of clusters
    const centroidPoints = [];
    for (let i = 0; i < nDivisions; i++) {
      const randomPointByCluster = clusteredPoints.features.find(
        function(currentRandoPoint) {
          return(currentRandoPoint.properties.cluster == i);
        }
      );
      if(randomPointByCluster != null)
      {
        centroidPoints[i] = turf.point(randomPointByCluster.properties.centroid as Position);
      }
    }
  
    //Create voronoi polygons inside polygon bounding box around centroids of clusters
    const voronoiPolygons = turf.voronoi(
      {type: 'FeatureCollection', features: centroidPoints},
      {bbox: polygonBbox}
    );
  

    //Clip created voronoi polgygons with the polygon
    
    const polygonArea = turf.area(polygon);
    const idealPieceArea = polygonArea / nDivisions;
    const clippedVoronoiPolygons = voronoiPolygons.features.map((feature, i) => {
        const clippedFeature = turf.intersect(feature.geometry, polygon as any);
        const clippedFeatureArea = turf.area(clippedFeature as any);
        if(clippedFeature != null && clippedFeature.properties != null)
        {
            clippedFeature.properties.percentage = clippedFeatureArea / idealPieceArea;
            clippedFeature.properties.color = colors[i];
        }
        return(clippedFeature!);
    });

    const clippedFeatureCollections = turf.featureCollection(clippedVoronoiPolygons);
  
    const dividedPolygonFeatures = formatGeoJSON.readFeatures(clippedFeatureCollections);
    
  
    return(dividedPolygonFeatures);
  }


  export function getRandomRgb(opacity: number) {
    const rgb = [Math.floor(Math.random() * 256),Math.floor(Math.random() * 256),Math.floor(Math.random() * 256)];
    return('rgb('+rgb.join(', ') + ', ' + opacity + ')');
  }