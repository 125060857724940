import { DashboardModel as DashboardModel } from '../../Commons/types';

export default class NoticesTowsSummary implements DashboardModel {
    isLoading = false;
    error: string | null = null;
    noticedVehicles = 0;
    towedVehicles = 0;

    constructor (
        isLoading?: boolean,
        error?: string | null,
        noticedVehicles?: number,
        towedVehicles?: number) {
        this.isLoading = isLoading ?? false;
        this.error = error ?? null;
        this.noticedVehicles = noticedVehicles ?? 0;
        this.towedVehicles = towedVehicles ?? 0;
    }
}
