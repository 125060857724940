import { DashboardBuilderState } from './dashboardBuilderState';
import { PayloadAction } from '@reduxjs/toolkit';
import * as cloneRfdc from 'rfdc';
import { CustomDashboardTile } from '../types';

const clone = <T>(state: T): T => {
  return cloneRfdc.default({ proto: true })(state);
};

const setBuilderIsLoadingAction = (state: DashboardBuilderState, action: PayloadAction<boolean>): DashboardBuilderState => {
  return {
    ...state,
    isLoading: action.payload
  }
};

// TODO: Improve name
const updateBuilderAction = (state: DashboardBuilderState, action: PayloadAction<DashboardBuilderState | null>): DashboardBuilderState => {
  return {
    ...state,
    ...action.payload,
  };
};

const updateDashboardTileAction = (state: DashboardBuilderState, action: PayloadAction<CustomDashboardTile>): DashboardBuilderState => {
  const newState = clone<DashboardBuilderState>(state);

  const st = newState.selectedTiles.find(t => t.tileId === action.payload.tileId);  
  if (st) {
    st.chartType = action.payload.chartType;
    if (st.model) {
      st.model.chartType = action.payload.chartType;
    }
  }

  console.log('updateDashboardTileAction >>>', action.payload, st, newState);

  return newState;
}

export { setBuilderIsLoadingAction, updateBuilderAction, updateDashboardTileAction };
