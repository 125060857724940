import React, { useEffect, useState } from 'react';
import { Box, Toolbar, Grid } from '@material-ui/core';
import MapFilterBar from './FilterBar/MapFilterBar';
import Map from './Map/Map';
import { ApplicationState } from '../../../../app/store';
import * as DashboardFilterState from '../../state/DashboardFilterState';
import { MapDashboardStateModel, MapLayerType } from '../state/mapDashboardStateV2';
import { useSelector, useDispatch } from 'react-redux';
import { applyFilterAndFetchMarkers, changePageAndFetchMarkers, initializeMapFilterSource } from '../state/mapDashboardThunks';
import { useStyles } from './mapSearchStyles';
import { MarkersList } from './MarkerList/MarkersList';
import { MapFilterState, mapFilterStateInit } from './FilterBar/mapFilterState';
import LoadingModal from '../../Commons/LoadingModal/LoadingModal';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { selectMarker, unselectMarker } from '../state/mapDashboardSlice';
import { SessionState } from '../../../../app/session/sessionTypes';
import moment from 'moment';
import ConfirmDialog from '../../Commons/ConfirmDialog/ConfirmDialog';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const MapSearch = (): JSX.Element => {
    const classes = useStyles();
    const queryParameters = useQuery();

    const [selectedCustomerSubDomain, setSelectedCustomerSubDomain] = useState<undefined | string>(undefined);
    const [openDelayWarning, setOpenDelayWarning] = useState(false);
    const [currentMapFilter, setCurrentMapFilter] = useState<MapFilterState>();

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mapDashboard as MapDashboardStateModel,
            session: state.session as SessionState,
        };
    };

    const state = useSelector(selector);
    const dispatch = useDispatch();

    // TODO: Validate dashboard filter state in a better way
    useEffect(() => {
        const mapFilterCookie: MapFilterState = (Cookies.getJSON('mapFilter') as unknown as MapFilterState);
        
        if(mapFilterCookie) { 
            dispatch(applyFilterAndFetchMarkers(mapFilterCookie));
        } else {
            dispatch(initializeMapFilterSource());
        }
        
    }, []);

    useEffect(() => {
         dispatch(initializeMapFilterSource());
         let selectedCustomerSubDomain = undefined;
        if (state.session.currentUser !== null) {
            const selectedCustomer = state.session.currentUser.customers.find(customer => customer.id === state.dashboardFilter.customerId);
            if (selectedCustomer) {
                selectedCustomerSubDomain = selectedCustomer.parkingviewClientSubDomain;
            }
        }

        setSelectedCustomerSubDomain(selectedCustomerSubDomain);
    }, [state.dashboardFilter.customerId]);

    const searchMapMarkers = (filter: MapFilterState): void => {
        setCurrentMapFilter(filter);
        const startDate = moment(state.dashboardFilter.date, 'YYYY/MM/DD');
        const today  = moment();
        const montDiff = today.diff(startDate, 'month');
        if(montDiff >= 4)
        {
            setOpenDelayWarning(true);
        }
        else
        {
            Cookies.set('mapFilter', filter);
            filter.isArchiveQuery = false;
            dispatch(applyFilterAndFetchMarkers(filter));
        }
    };

    const handleAcceptDelayWarning = () => {
        setOpenDelayWarning(false);
        if(currentMapFilter != null)
        {
            const mapFilter = {...currentMapFilter};
            currentMapFilter.isArchiveQuery = true;
            mapFilter.isArchiveQuery = true;
            dispatch(applyFilterAndFetchMarkers(mapFilter));
        }
        else
        {
            alert('We had an issue performing the query, filter could not be found.');
        }
    };
    
    const handleClose = () => {
        setOpenDelayWarning(false);
    };

    useEffect(() => {
        // fetchEnforcementData();
    }, [state.dashboardFilter]);

    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <MapFilterBar
                        isLoading={state.data.isLoading}
                        bootingOfficers={state.data.filterSource.bootingOfficers}
                        parkingAreas={state.data.filterSource.parkingAreas}
                        onApply={searchMapMarkers} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.contentWrapper} alignContent="stretch" alignItems="stretch" id="mapSearchContent" style={{ position: 'relative' }}>
                <LoadingModal
                    isOpen={state.data.isLoading}
                    container={(() => document.getElementById('mapSearchContent'))()}
                    message="Loading LPR reads, please wait..." />
                <Grid item xs={12}  lg={9} className={classes.openLayersMapWrapper}>
                    <Map
                        center={[state.data.filterSource.customerLongitude, state.data.filterSource.customerLatitude]}
                        markers={state.data.markers}
                        layerType={state.data.filter ? state.data.filter.layerType : MapLayerType.Reads}
                        customerId={state.dashboardFilter.customerId ?? ''}
                        mapFilter={state.data.filter}
                        selectedMarker={state.data.selectedMarker}
                        onSelectedMarker={(selectedMarkerId: string) => dispatch(selectMarker(selectedMarkerId))}
                    />
                </Grid>
                <Grid item xs={12}  lg={3} container className={classes.markersListWrapper} alignContent="stretch" alignItems="stretch" justify="center">
                    <MarkersList
                        isLoading={state.data.isLoading}
                        markers={state.data.filteredMarkers}
                        mapState={state.data}
                        selectedMarker={state.data.selectedMarker}
                        selectedCustomerSubDomain={selectedCustomerSubDomain}
                        onSelectedMarker={(selectedMarkerId: string) => dispatch(selectMarker(selectedMarkerId))}
                        onClearSelectedMarker={() => dispatch(unselectMarker())}
                        //pagination={state.data.pagination}
                        //onPageChanged={(page: number) => dispatch(changePageAndFetchMarkers(page))}
                    />
                </Grid>
            </Grid>
            <ConfirmDialog isOpen={openDelayWarning} message={'Note: this query may result in a longer wait time. do you still want to continue?'} onAccept={handleAcceptDelayWarning} onDeny={handleClose}></ConfirmDialog>
        </Box>
    );
};

export default MapSearch;
