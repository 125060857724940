/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/class-name-casing */
import React, { Fragment, useEffect, useState } from 'react';
import { MapMarker, EnforcementDataType, MapSourceType, SuccessResult, MapDashboardStateModel, OptirotueReadImages, ImageList } from '../../state/mapDashboardStateV2';
import { Typography, Paper, Button, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { DriveEta as CarIcon, CameraAlt as ImageIcon, IndeterminateCheckBoxRounded } from '@material-ui/icons';
import { useStyles } from './markerDetailStyles';
import moment from 'moment';
import OSM from 'ol/source/OSM';
import OLMap from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Vector } from 'ol/layer';
import readsLayerStyle  from '../Map/readsLayerStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { applyFilterAndFetchMarkers, getAssociatedImages, toggleMarkerStatus } from '../../state/mapDashboardThunks';
import LoadingModal from '../../../Commons/LoadingModal/LoadingModal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable'; 
import { ApplicationState } from '../../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import * as DashboardFilterState from '../../../state/DashboardFilterState';


type MarkerDetailProps = {
    openModal: boolean;
    marker?: MapMarker | null;
    onClose: () => void;
    selectedCustomerSubDomain?: string;
    onSelectedPlate: (plate: string) => void;
    mapState:  MapDashboardStateModel;
    markers: MapMarker[];
}


export const MarkerDetail = (props: MarkerDetailProps): JSX.Element => {
    const { openModal, onClose, selectedCustomerSubDomain, onSelectedPlate, mapState } = props;
    const classes = useStyles();

    const [isActive, setIsActive] = useState(true);
    const [isActiveLoading, setIsActiveLoading] = useState(false);
    const [realatedReadImages, setRealatedReadImages] = useState<OptirotueReadImages>();
    const [marker, setMarker] = useState<MapMarker>();
    const [map, setMap] = useState<OLMap>();

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.marker != null)
        {
            setMarker(props.marker);
        }
    }, [props?.marker]);
    
    

    useEffect(() => {
        setIsActive(Boolean(marker?.plateStatus));
    }, [marker]);

    const filterOptirotueImagelist = (optirouteImageList: OptirotueReadImages)=>{
        const filteredList: ImageList[] = [];
        if(optirouteImageList != null)
        {
            optirouteImageList.list.forEach((item)=>{
                if(item.read != null)
                {
                    props.markers.forEach((mark) =>{
                        if(mark.lprDataID == item.read.ssrs_id)
                        {
                            filteredList.push(item);
                        }
                    });
                }
           });
           optirouteImageList.list = filteredList;
           setRealatedReadImages(optirouteImageList);
        }
    };

    let fullPlate = marker?.licPlate;
    if (marker?.licState) fullPlate += `-${marker?.licState}`;

    const onClickViewViolationHandler = (violationUrl: string) => {
        window.open(violationUrl, '_blank');
    };

    const selector = (state: ApplicationState) => {
        return {
            dashboardFilter: state.dashboardFilter as DashboardFilterState.DashboardFilterState,
            data: state.mapOptiRouteDashboard as MapDashboardStateModel,
        };
    };

    const appState = useSelector(selector);

    const onClickViewFilesImagesHandler = (filesImagesUrl: string) => {
        const left = (screen.width/2)-(800/2);
        const top = (screen.height/2)-(800/2);
        const options = `left=${left}, top=${top} , width=800, height=800, location=no, menubar=no, status=no, toolbar=no, scrollbars=yes, resizable=yes`;
        window.open(filesImagesUrl, 'Files/Images', options);
    };

    const onClickViewReadsImagesHandler = (filesImagesUrl: string) => {
        setIsActiveLoading(true);
        if(marker?.lprDataID != null && appState.dashboardFilter.dateTo != null && selectedCustomerSubDomain != null)
        {
            getAssociatedImages(marker?.lprDataID, appState.dashboardFilter.date, appState.dashboardFilter.dateTo, mapState.filter.fromTime, mapState.filter.toTime, selectedCustomerSubDomain).then((response: OptirotueReadImages) => {

                if(response.success)
                {
                    filterOptirotueImagelist(response);
                }
                else
                {
                    alert('We could not find images associated to this read');
                }
                setIsActiveLoading(false);
            }).catch((err: string) => {
                setIsActiveLoading(false);
                console.log(err);
            });
        }
        
    };

    const onToggleActive = (event: any) => {
        setIsActiveLoading(true);
        const checked = event.target.checked;
        if(marker != null)
        {
            toggleMarkerStatus(marker, + event.target.checked).then((response: SuccessResult) => {
                if(response.success)
                {
                    setIsActive(checked);
                    dispatch(applyFilterAndFetchMarkers(appState.data.filter));
                }
                setIsActiveLoading(false);
            }).catch((err: string) => {
                setIsActiveLoading(false);
                console.log(err);
            });
        }
    };

    const onPlateClicked = () => {
        if(marker != null)
        {
            onSelectedPlate(marker.licPlate);
        }
        onClose();
    }

    const onDialogClose = () => {
        setRealatedReadImages(undefined);
        onClose();
    }

    const onReadImagesClickHandler = (ssrsId: string) => {
        let found  = false;
        props.markers.forEach((mark) =>{
            if(mark.lprDataID == ssrsId)
            {
                setMarker(mark);
                setIsActive(Boolean(mark.plateStatus));
                if(map != null)
                {
                    const readLayer = map.getLayers().getArray().find(layer => layer.get('name') == 'read') as Vector;
                    readLayer?.getSource().clear();//clear current marker
                    const featureMarker = new Feature({
                        geometry: new Point([mark?.longitude, mark?.latitude]),
                        location_uuid: mark?.lprDataID,
                        ol_uid: mark?.lprDataID
                    });
                    featureMarker.setStyle(readsLayerStyle(mark));
                    readLayer?.getSource().addFeature(featureMarker);
                }
                found = true;
                return;
            }
        });
        if(!found)
        {
            alert('We could not find information for this point');
        }
    }
    
    const onRendered = () => {
        if(marker != null)
        {
            const featureMarker = new Feature({
                geometry: new Point([marker?.longitude, marker?.latitude]),
                location_uuid: marker?.lprDataID,
                ol_uid: marker?.lprDataID
            });

            featureMarker.setStyle(readsLayerStyle(marker));
            
            //map with marker
            const dataLayer = new VectorLayer({
                source: new VectorSource({
                    features:[featureMarker]
                }),
            });
            dataLayer.set('name', 'read');
            const newMap = new OLMap({
                target: 'map-detail-container-id',
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    }),
                    dataLayer
                ],
                view: new View({
                    center: [marker?.longitude, marker?.latitude],
                    zoom: 17,
                    projection: 'EPSG:4326',
                })
                
            });
            setMap(newMap);
        }
        
    };
    
    const [dialogRender, setdialogRender] = useState(() => (props: any) =>{
        return (
             <Draggable
                 handle='#simple-modal-title'
                 cancel={'[class*="MuiDialogContent-root"]'}
                 
             >
                 <Paper {...props} />
             </Draggable>
             );
     });


    return (
        <Fragment>
            <Dialog
                open={openModal}
                onClose={onDialogClose}
                onRendered={onRendered}
                maxWidth={false}
                id='simple-modal-title'
                aria-labelledby='simple-modal-title'
                PaperComponent={dialogRender}
                aria-describedby='simple-modal-description'
                BackdropProps={{ style: { position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}
                hideBackdrop={true}>
                    
                <DialogTitle style={{ cursor: 'move' }} id="simple-modal-title">
                    Details
                </DialogTitle>
                <Grid container direction="row">
                    <Grid item xs={8}>
                        <Paper id='mapMarkerDetailModalFragment' elevation={2} className={classes.modalContent}>
                            <Grid container direction='row' justify="space-between" alignItems="center">
                                <Typography variant="h4">
                                    <a onClick={onPlateClicked} style={{cursor: 'pointer', textDecoration: 'underline'}}>{fullPlate}</a> - <a onClick={onPlateClicked} style={{cursor: 'pointer'}}>[{marker?.occurrences}]</a>
                                </Typography>
                                <IconButton onClick={onDialogClose}>
                                        <CloseIcon />
                                </IconButton>
                            </Grid>
                        
                            <Typography variant="body2">
                                Date: {moment(marker?.typeDate).format('YYYY/MM/DD hh:mm a')}
                            </Typography>
                            <Typography variant="body2">
                                Customer: {marker?.clientCode}
                            </Typography>
                            <Typography variant="body2">
                                Type: {marker?.srcType}
                            </Typography>
                            {
                            marker?.amtDue != null &&
                            <Typography variant="body2">
                                Amount Due: ${marker?.amtDue}
                            </Typography>
                            } 
                            {
                            marker?.numRunawaysSinceLastPayment != null && 
                            <Typography variant="body2">
                                # RunAways: {marker?.numRunawaysSinceLastPayment}
                            </Typography>
                            }  
                            {
                            marker?.address != null && marker?.address != '' &&
                            <Typography  variant="body2">
                                Location: {marker?.address}
                            </Typography>
                            }
                            {
                                (marker?.bootRecord) &&
                                <Fragment>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<CarIcon />}
                                        onClick={(event) => onClickViewViolationHandler(marker?.bootRecord ? marker?.bootRecord.violationUrl : '')}>
                                        Boot Record
                                    </Button>
                                </Fragment>
                            }
                            {
                                (marker?.bootRecord != null) && marker?.bootRecord.filesImagesUrl != null && marker?.bootRecord.filesImagesUrl !='' &&
                                <Fragment>
                                    <Button
                                        hidden={!marker?.bootRecord.filesImagesUrl}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<ImageIcon />}
                                        onClick={(event) => onClickViewFilesImagesHandler(marker?.bootRecord ? marker?.bootRecord.filesImagesUrl : '')}>
                                        Files/Images
                                    </Button>
                                </Fragment>
                            }
                            {
                                (selectedCustomerSubDomain != null) && (selectedCustomerSubDomain != '') && (marker?.lprDataID != null) && marker?.lprDataID != ''  &&
                                <Fragment>
                                    <Button
                                        className={classes.readButton}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<ImageIcon />}
                                        onClick={(event) => onClickViewReadsImagesHandler(`https://${selectedCustomerSubDomain}.myparkinginfo.com/cws/admin/lprread/media/${marker?.lprDataID}`)}>
                                        Read/Images
                                    </Button>
                                    
                                </Fragment>
                            }
                            {
                                (selectedCustomerSubDomain) && (marker?.lprDataID) && marker?.hotlistSrcLocIf != null && marker?.hotlistSrcLocIf != 0 &&
                                <Fragment>
                                    <FormControlLabel control={<Checkbox checked={isActive} onChange={onToggleActive} name="jason" />} label="Active"/>
                                        <LoadingModal
                                        isOpen={isActiveLoading}
                                        container={(() => document.getElementById('mapMarkerDetailModalFragment'))()}
                                        message="Please wait..." />
                                </Fragment>
                            }

                            <Paper >
                                {<div className={classes.map} id='map-detail-container-id' ></div>}
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        {
                            realatedReadImages!=null &&
                            <Paper elevation={2} className={classes.imageSection}>
                                {
                                realatedReadImages.list.map((item: ImageList) => {
                                    if(item != null && item.read != null )
                                    {
                                        return(
                                        <Paper key={item.read.lpr_read_id}>
                                            <Button onClick={(event) => onReadImagesClickHandler(item.read.ssrs_id)}>
                                                    <img
                                                        width='175px'
                                                        src={`data:image/png;base64, ${item.contextPhoto}`}
                                                        alt='context image'
                                                        loading="lazy"
                                                    />
                                            </Button>
                                            <Button onClick={(event) => onReadImagesClickHandler(item.read.ssrs_id)}>
                                                    <img
                                                        src={`data:image/png;base64, ${item.lprPhoto}`}
                                                        alt='LPR image'
                                                        loading="lazy"
                                                    />
                                            </Button>
                                        </Paper>
                                        )
                                    }
                                })
                                }
                            </Paper>                         
                        }
                    </Grid>
                </Grid>
            </Dialog>
        </Fragment>
    );
};
