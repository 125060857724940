import { MapFilterState } from '../MapSearch/FilterBar/mapFilterState';

export interface MapDashboardStateModel {
    isLoading: boolean;
    error: string | null;
    filter: MapFilterState;
    markers: MapMarker[];
    paginatedMarkers: MapMarker[];
    filteredMarkers: MapMarker[];
    pagination: PaginationResult;
    selectedMarker: MapMarker | null | undefined;
    filterSource: MapFilterSource;
    listIsLoading: boolean;
    bootExtraInfo: BootRecordExtra[];
}

export type MapMarker = {
    id: string;
    plate: string;
    plateState: string;
    type: EnforcementDataType;
    latitude: number;
    longitude: number;
    importSource: string;
    dateRecord: Date;
    customerId: string;
    customerCode: string;
    customerFullName?: string;
    agentId: string;
    agentFullName: string;
    locationId: string;
    locationName: string;
    rejectionReason: string;
    bootRecord?: BootRecord;
    isLastPosition: boolean;
}

export type BootRecord = {
    violationNumber: string;
    collectionAttemptId: string;
    violationUrl: string;
    filesImagesUrl: string;
}


export type BootRecordExtra = {
    sticker_Id: string;
    boot_Serial_Number: string;
    boot_Record_Status_Label: string;
    number_RunAways: number;
    tow_Eligible_Date: Date;
}

export type BootExtraMapSearchResult = {
    results: BootRecordExtra[];
}

export enum EnforcementDataType {
    Scan = 0,
    Hit = 1,
    Boot = 2
}

export type MapSearchResult = {
    results: MapMarker[];
    pagination: PaginationResult;
}

export type Pagination = {
    currentPage: number;
    pageSize: number;
}

export type PaginationResult = {
    totalPages: number;
    totalItems: number;
} & Pagination;

export type MapFilterSource = {
    bootingOfficers: BootingOfficer[];
    parkingAreas: ParkingArea[];
    customerLongitude: number;
    customerLatitude: number;
}

export type BootingOfficer = {
    bootingOfficerId: string;
    name: string;
}

export type ParkingArea = {
    parkingAreaId: string;
    name: string;
}

export enum MapFilterSearchBy {
    All = 0,
    Agents = 1,
    Locations = 2
}

export enum MapFilterDataType {
    Scan = 0,
    Hit = 1,
    Booted = 2
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export enum MapLayerType {
    Reads = 1,
    Heat = 2,
    Route = 3,
}

export enum SceneType {
    RegularPlates = 0,
    NewPlates = 1
}