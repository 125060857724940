import { AppBar, Box, CssBaseline, Grid, Toolbar, Typography } from '@material-ui/core';
import { Map as MapIcon } from '@material-ui/icons';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DashboardFilterBar from '../Commons/DashboardFilterBar/DashboardFilterBar';
import { updateFilterActionPayload } from '../state/DashboardFilterActions';
import { updateDate, updateDateTo, updateFilter, updateFilterType } from '../state/DashboardFilterState';
import { useStyles } from './MapDashboardStyles';
import { MapSearch } from './MapSearch/MapSearch';

export default function OptiRouteMapDashboard (): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        const dashboardFilterCookie: updateFilterActionPayload = (Cookies.getJSON('dashboardFilter') as unknown as updateFilterActionPayload);

        if(dashboardFilterCookie) { 
            dispatch(updateFilter({ ...dashboardFilterCookie, type: 'range', excludeAverage: true, dateTo: dashboardFilterCookie.date }));
        } else {
            dispatch(updateFilterType({ filterType: 'range', excludeAverage: true }));
        }
        //for optiroute we want to default to 1 month of information
        const now: Date = new Date();
        dispatch(updateDateTo({ date: now }));
        const pastDate: Date = new Date();
        pastDate.setDate(pastDate.getDate() -30);
        dispatch(updateDate({ date: pastDate }));
    }, []);

    return (
        <Box className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.appBar} elevation={1} position="sticky" style={{paddingLeft:10}}>
                <Toolbar>
                    <Grid container spacing={2} justify="space-between" alignItems="center" alignContent="center">
                        <Grid item xs={12} md={12} lg={2} container alignItems="center" alignContent="center">
                            <MapIcon className={classes.icon} />
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Opti-Route
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={10} container justify="flex-end" alignItems="center" alignContent="center">
                            <Grid item xs={12}>
                                <DashboardFilterBar onApply={() => console.log('Filter is handled on other component nothing to do here')}   hideViewButton={true} hideTimer={true} showWeekDays={true} showOccurrences={true} showSceneSelection={true}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <MapSearch />
        </Box>
    );
}
